import classNames from "classnames";
import React, { ReactElement } from "react";
import { Button } from "react-bootstrap";
import { StepProps } from "../organisatie-registreren";
import classes from "../registreren.module.scss";

export const EmailVerzonden = ({ currentStep, setCurrentStep }: StepProps): ReactElement => {
  return (
    <>
      <h2 className={classNames(classes.title)}>E-mail verzonden</h2>
      <div className="px-1">
        <p>
          Er is een e-mail verzonden naar <strong>adeadviseur@adviesbureau.nl</strong> via deze mail kunt je jouw
          account activeren.
        </p>
        <p>Er is een e-mail verzonden naar adeadviseur@adviesbureau.nl via deze mail kunt je jouw account activeren.</p>
        <div className={"text-center pb-5"}>
          <Button variant={"light"}>E-mail opnieuw verzenden</Button>
        </div>
      </div>
      <Button
        className={"my-3 mx-1"}
        variant={"light"}
        onClick={() => {
          setCurrentStep(currentStep - 1);
        }}
      >
        Terug
      </Button>
    </>
  );
};
