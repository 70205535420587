import { LabeledTextInput } from "adviesbox-shared";
import classNames from "classnames";
import React, { ReactElement } from "react";
import { Button } from "react-bootstrap";
import { StepProps } from "../organisatie-registreren";
import classes from "../registreren.module.scss";
export const TweedeFactor = ({ currentStep, setCurrentStep }: StepProps): ReactElement => {
  return (
    <>
      <h2 className={classNames(classes.title)}>Tweede factor activeren</h2>
      <p className={"pl-1"}>Scan de QR code met de gewenste tweede factor applicatie</p>
      <div className={classNames(classes.qr_img)}>
        <img
          src={
            "https://upload.wikimedia.org/wikipedia/commons/thumb/3/32/Qr-nl-wikipedia-or.svg/1200px-Qr-nl-wikipedia-or.svg.png"
          }
          alt={"qr-code"}
        />
      </div>
      <h2 className={"w-100"}>Code</h2>
      <p className={"pl-1"}>Als de QR code niet werkt kan je met onderstaande code de tweede factor activeren.</p>
      <div className={"2fa-code border p-2 mr-3 mb-4"}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.
      </div>
      <LabeledTextInput name={"authenticatiecode"} caption={"Authenticatiecode"} labelColSize={7} />
      <Button
        className={"my-3 mx-1"}
        variant={"light"}
        onClick={() => {
          setCurrentStep(currentStep - 1);
        }}
      >
        Terug
      </Button>
      <Button
        className={"float-right m-3"}
        onClick={() => {
          setCurrentStep(currentStep + 1);
        }}
      >
        Volgende
      </Button>
    </>
  );
};
