import { AdresPresentation, AuthContext, SettingsContext, SyncHeightProps } from "adviesbox-shared";
import { FormikContextType, connect, getIn } from "formik";
import React, { InputHTMLAttributes, ReactElement, useContext, useRef } from "react";

import { AdresType } from "../../types";
import LabeledHdnKeuzelijst from "../hdn-keuzelijst/LabeledHdnKeuzelijst";
import getPostcodeInfo from "./get-postcode-info";
import { hasValue } from "../../utils/helpers";

export type AdresProps = {
  readOnly?: boolean;
  name: string;
  plaatsCaption?: string;
  landCaption?: string;
  metLand?: boolean;
  verplicht?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

const AdresInputComponent = ({
  readOnly,
  name,
  formik,
  plaatsCaption,
  landCaption,
  sourceRef,
  targetRef,
  metLand = true,
  verplicht = false
}: AdresProps & SyncHeightProps & { formik: FormikContextType<{}> }): ReactElement => {
  const { user } = useContext(AuthContext);
  const settings = useContext(SettingsContext);
  const adres = getIn(formik.values, name) as AdresType;

  let postcode = "";
  let huisnummer: number | null = null;
  let landCode = "";

  if (hasValue(adres) && hasValue(adres.postcode)) {
    postcode = adres.postcode.toUpperCase().replace(/\s/g, "");
    huisnummer = adres.huisnummer;
    landCode = adres.landCode;
  }

  const postcodeResolved = useRef(false);
  const previousPostcodeHuisnummer = useRef({ postcode, huisnummer });

  async function postcodeHuisnummerChanged(): Promise<void> {
    const postcodeError = hasValue(getIn(formik.errors, `${name}.postcode`));
    if (metLand === false)
    {
      landCode = "NL";
    }
    if (landCode === "NL" && adres.postcode && huisnummer && !postcodeError) {
      if (
        postcode === previousPostcodeHuisnummer.current.postcode &&
        huisnummer === previousPostcodeHuisnummer.current.huisnummer &&
        !postcodeResolved
      ) {
        return;
      }

      postcodeResolved.current = true;
      previousPostcodeHuisnummer.current = {
        postcode,
        huisnummer
      };

      const postcodeInfo = await getPostcodeInfo(user, settings, postcode, huisnummer);
      if (postcodeInfo) {
        if (postcodeInfo.postcode) {
          postcodeInfo.postcode = postcodeInfo.postcode.toUpperCase().replace(/\s/g, "");
        }

        if (
          postcodeInfo.huisnummer === previousPostcodeHuisnummer.current.huisnummer &&
          postcodeInfo.postcode === previousPostcodeHuisnummer.current.postcode
        ) {
          const currVal = getIn(formik.values, name);
          formik.setFieldValue(`${name}`, {
            ...currVal,
            plaats: postcodeInfo.plaats,
            straat: postcodeInfo.straat
          });
        }
      }
    }
  }
  return (
    <>
      <AdresPresentation
        landCode={landCode}
        name={name}
        onBlur={postcodeHuisnummerChanged}
        readonly={readOnly}
        plaatsCaption={plaatsCaption}
        sourceRef={sourceRef}
        targetRef={targetRef}
        verplicht={verplicht}
      />
      {metLand && (
        <LabeledHdnKeuzelijst
          caption={landCaption ? landCaption : "Land"}
          name={`${name}.landCode`}
          keuzelijst="LandType"
          berichtSoortType="AX"
          readonly={readOnly}
        />
      )}
    </>
  );
};

export const AdresInput = connect<AdresProps & SyncHeightProps>(AdresInputComponent);
