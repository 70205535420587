import React, { ReactElement } from "react";
import { LabeledTextInput } from "adviesbox-shared";
import { UserProps } from "../../shared/properties/user-props";
import {
  Gebruikersactie,
  MagActieUitvoeren,
  Matrixonderdeel
} from "../../shared/autorisatie-matrix/autorisatie-matrix";

type ContactgegevensProps = { selected: number };

export const Contactgegevens = (props: ContactgegevensProps & UserProps): ReactElement => {
  const isReadOnly = !(
    MagActieUitvoeren(Matrixonderdeel.Vestigingen, Gebruikersactie.Toevoegen, props.user, props.userDetails) ||
    MagActieUitvoeren(Matrixonderdeel.Vestigingen, Gebruikersactie.Schrijven, props.user, props.userDetails)
  );

  return (
    <>
      <LabeledTextInput
        name={`vestigingen[${props.selected}].telefoonnummer`}
        caption="Telefoonnummer"
        verplicht={true}
        readOnly={isReadOnly}
      />
      <LabeledTextInput
        autoComplete="new-password"
        name={`vestigingen[${props.selected}].emailadres`}
        caption="E-mailadres"
        verplicht={true}
        readOnly={isReadOnly}
      />
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") Contactgegevens.displayName = "Contactgegevens";
