import { LabeledBevestigingInput, LabeledRadioInput, LabeledTextInput } from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement } from "react";
import {
  MagActieUitvoeren,
  Matrixonderdeel,
  Gebruikersactie
} from "../../shared/autorisatie-matrix/autorisatie-matrix";
import { UserProps } from "../../shared/properties/user-props";
import { Direction } from "../../shared/types";
import { MedewerkersState, ToegangsrechtenEnumInterface } from "../infra/medewerkers-schema";
import { MedewerkersSubProps } from "../medewerkers";
import { LabeledTagInput } from "../tag-input-component/labeled-tag-input";
import { MedewerkerLicentie } from "../medewerker-licentie/medewerker-licentie";

export const Licentiegegevens = (props: MedewerkersSubProps & UserProps): ReactElement => {
  useFormikContext<MedewerkersState>();
  const index = props.data.actualSelectedIndex;

  const magSchrijven = MagActieUitvoeren(
    Matrixonderdeel.Medewerkers,
    Gebruikersactie.Schrijven,
    props.user,
    props.userDetails
  );
  const magToevoegen = MagActieUitvoeren(
    Matrixonderdeel.Medewerkers,
    Gebruikersactie.Toevoegen,
    props.user,
    props.userDetails
  );
  const magVerwijderen = MagActieUitvoeren(
    Matrixonderdeel.Medewerkers,
    Gebruikersactie.Verwijderen,
    props.user,
    props.userDetails
  );
  const magLezen =
    magSchrijven ||
    magToevoegen ||
    magVerwijderen ||
    MagActieUitvoeren(Matrixonderdeel.Medewerkers, Gebruikersactie.Lezen, props.user, props.userDetails);

  if (!magLezen) return <></>;

  const isReadOnly = !(magSchrijven || magToevoegen);

  return (
    <>
      {props.data.medewerkers[index]?.medewerkerId && (
        <MedewerkerLicentie
          licentieBeschikbaar={props.data.licentieBeschikbaar}
          alleOrganisatieUniekeLicenties={props.data.alleOrganisatieUniekeLicenties}
          name={`medewerkers[${index}].licentiegegevens.gekozenProductsamenstellingId`}
          readOnly={isReadOnly}
        />
      )}
      {props.data.medewerkers[index]?.medewerkerId && (
        <LabeledTagInput
          name={`medewerkers[${index}].licentiegegevens.gekozenVestigingen`}
          caption="Vestiging(en)"
          options={props.data.alleOrganisatieVestigingen}
          editable={!isReadOnly}
        />
      )}

      {props.data.medewerkers[index]?.medewerkerId && (
        <LabeledTagInput
          name={`medewerkers[${index}].licentiegegevens.gekozenGebruikersgroepen`}
          caption="Gebruikersgroep(en)"
          options={props.data.alleOrganisatieGebruikersgroepen}
          editable={!isReadOnly}
        />
      )}
      <LabeledRadioInput
        caption="Toegang tot klanten van de organisatie"
        tooltip="Geef hier aan of de medewerker lees en/of schrijfrechten heeft voor alle klanten van de hele organisatie. De medewerker heeft altijd toegang tot zijn eigen klanten en dossiers en kan deze bewerken."
        name={`medewerkers[${index}].licentiegegevens.organisatieToegangsrechten`}
        options={ToegangsrechtenEnumInterface}
        layout={Direction.Vertical}
        readOnly={isReadOnly}
      />
      <LabeledRadioInput
        caption="Toegang tot klanten van de vestiging"
        tooltip="Geef hier aan of de medewerker lees en/of schrijfrechten heeft voor alle klanten van de vestiging. De medewerker heeft altijd toegang tot zijn eigen klanten en dossiers en kan deze bewerken."
        name={`medewerkers[${index}].licentiegegevens.vestigingToegangsrechten`}
        options={ToegangsrechtenEnumInterface}
        layout={Direction.Vertical}
        readOnly={isReadOnly}
      />
      <LabeledBevestigingInput
        name={`medewerkers[${index}].licentiegegevens.isActief`}
        caption="Actief"
        readonly={isReadOnly}
      />
      <LabeledTextInput caption="Platform-ID" name={`medewerkers[${index}].platformId`} readonly={true} />
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") Licentiegegevens.displayName = "Licentiegegevens";
