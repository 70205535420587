import { LocalDateTime } from "@js-joda/core";
import {
  LicentieovereenkomstenOutput,
  Licentieovereenkomst,
  Organisatie,
  OrganisatiesOutput,
  LicentiesoortOpties,
  LicentieovereenkomstDocument
} from "../../.generated/licenties/licentiestypes";
import { createMapEnum } from "../../shared/utils/create-map-enum";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import {
  organisatieSchema,
  LicentieOvereenkomstenState,
  licentieOvereenkomstenSchema,
  licentieSchema,
  OrganisatieState,
  LicentieSoort,
  documentSchema
} from "./contract-schema";
import { convertUtcToTimeZone } from "adviesbox-shared";

const mapOrganisatie = createMapToUi(organisatieSchema)
  .with<string>()
  .from<Organisatie>({
    id: (_, c) => c,
    bedrijfsnaam: v => v.naam,
    iban: v => v.iban,
    kvkNummer: v => v.kvKnummer,
    logo: v => v.logo,
    platformId: v => v.platformId,
    loading: () => false,
    sendPut: () => false,
    hasChanged: () => false
  });

const mapLicentieSoortEnum = createMapEnum(LicentiesoortOpties).to<LicentieSoort>({
  Betaald: LicentieSoort.Betaald,
  Proef: LicentieSoort.Proef,
  Intern: LicentieSoort.Intern
});

const mapLicentieOvereenkomstDocumenten = createMapToUi(documentSchema)
  .with<string>()
  .from<LicentieovereenkomstDocument>({
    id: v => v.licentieovereenkomstDocumentId,
    naam: v => v.naam,
    url: v => v.url,
    bestandsnaam: v => v.bestandsnaam
  });

const mapLicentie = createMapToUi(licentieSchema)
  .with<string>()
  .from<Licentieovereenkomst>({
    id: (_, c) => c,
    begindatum: v =>
      v.begindatum ? convertUtcToTimeZone(v.begindatum)?.toLocalDate() : LocalDateTime.now().toLocalDate(),
    contractId: v => v.contractId,
    einddatum: v => (v.einddatum ? convertUtcToTimeZone(v.einddatum)?.toLocalDate() : null),
    isActief: v => v.isActief,
    isGeblokkeerd: v => v.isGeblokkeerd,
    licentiesoort: v => mapLicentieSoortEnum(v.licentiesoort),
    organisatieId: v => v.organisatieId,
    productId: v => v.productId,
    productNaam: v => v.productNaam,
    loading: () => false,
    sendPut: () => false,
    licentieOvereenkomstDocumenten: v => {
      const licentieDocumentenArray = [];
      for (const key in v.licentieovereenkomstDocumenten) {
        if (v.licentieovereenkomstDocumenten.hasOwnProperty(key)) {
          licentieDocumentenArray.push(mapLicentieOvereenkomstDocumenten(key)(v.licentieovereenkomstDocumenten[key]));
        }
      }

      return licentieDocumentenArray;
    }
  });

const mapLicenties = createMapToUi(licentieOvereenkomstenSchema).from<LicentieovereenkomstenOutput>({
  licenties: v => {
    const productOnderdelenArray = [];
    for (const key in v.licentieovereenkomsten) {
      if (v.licentieovereenkomsten.hasOwnProperty(key)) {
        productOnderdelenArray.push(mapLicentie(key)(v.licentieovereenkomsten[key]));
      }
    }

    return productOnderdelenArray;
  }
});

export function mapLicentieOvereenkomstenDlToUi(
  data: LicentieovereenkomstenOutput
): LicentieOvereenkomstenState | null {
  // && data.isValid && data.Contract ? data.Contract[contractId] : null;
  const licenties = data && data.isValid && data.licentieovereenkomsten ? data : null;

  if (licenties) {
    return mapLicenties(licenties);
  }

  return null;
}
export function mapOrganisatieDlToUi(data: OrganisatiesOutput, id: string): OrganisatieState | null {
  // && data.isValid && data.Contract ? data.Contract[contractId] : null;
  const organisatie = data && data.isValid && data.organisaties ? data.organisaties[id] : null;

  if (organisatie) {
    return mapOrganisatie(id)(organisatie);
  }

  return null;
}
