import { AuthContext, ErrorPage, PageLoading, useRequestInit, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement, useContext, useMemo } from "react";
import useAbortableFetch from "use-abortable-fetch";
import { LicentiesApi } from "../licenties/infra/licenties-api";
import {
  GebruikersgroepenOutput,
  LicentiesOutput,
  MedewerkerExOutput,
  OrganisatiesExOutput,
  VestigingenOutput
} from "../.generated/licenties/licentiestypes";
import { MagActieUitvoeren, Matrixonderdeel } from "../shared/autorisatie-matrix/autorisatie-matrix";
import { Title } from "../shared/components/title/title";
import UserDetailsContext from "../shared/user-details/user-details-context";
import { mapMedewerkersDlToUi } from "./infra/map-medewerkers-dl-to-ui";
import {
  medewerkersSchema,
  optiesMetExtraIdsSchema,
  OptiesMetExtraIdsType,
  OrganisatieLicentiesType,
  vestigingenType
} from "./infra/medewerkers-schema";
import { Medewerkers } from "./medewerkers";

const MedewerkersAjaxComponent = (): ReactElement => {
  const { settings, params, requestInit } = useRequestInit();

  const { user } = useContext(AuthContext);
  const { userDetails } = useContext(UserDetailsContext);

  // als de requestedOrganisatie van de userDetails wijzigt, dan moet MagActieUitvoeren opnieuw bepaald worden
  const heeftToegang = useMemo(() => MagActieUitvoeren(Matrixonderdeel.Medewerkers, null, user, userDetails),
  [user, userDetails, userDetails.requestedOrganisatie]); // eslint-disable-line react-hooks/exhaustive-deps

  const urlMedewerkers = useMemo(() => (heeftToegang ? LicentiesApi.getMedewerkersUrl(settings, params) : null), [
    heeftToegang,
    settings,
    params
  ]);

  const urlGebruikersgroepen = useMemo(
    () => (heeftToegang ? LicentiesApi.getGebruikersgroepenUrl(settings, params) : null),
    [heeftToegang, settings, params]
  );

  const urlVestigingen = useMemo(() => (heeftToegang ? LicentiesApi.getVestigingenUrl(settings, params) : null), [
    heeftToegang,
    settings,
    params
  ]);

  const urlOrganisatie = useMemo(() => (heeftToegang ? LicentiesApi.getOrganisatieUrl(settings, params) : null), [
    heeftToegang,
    settings,
    params
  ]);

  const urlLicentie = useMemo(() => (heeftToegang ? LicentiesApi.getLicentiesUrl(settings, params) : null), [
    heeftToegang,
    settings,
    params
  ]);

  const { data: dataMDW, error: errorMDW, loading: loadingMDW } = useAbortableFetch<MedewerkerExOutput>(
    urlMedewerkers,
    requestInit
  );
  const { data: dataVST, error: errorVST, loading: loadingVST } = useAbortableFetch<VestigingenOutput>(
    urlVestigingen,
    requestInit
  );
  const { data: dataGG, error: errorGG, loading: loadingGG } = useAbortableFetch<GebruikersgroepenOutput>(
    urlGebruikersgroepen,
    requestInit
  );
  const { data: dataORG, error: errorORG, loading: loadingORG } = useAbortableFetch<OrganisatiesExOutput>(
    urlOrganisatie,
    requestInit
  );
  const { data: dataLIC, error: errorLIC, loading: loadingLIC } = useAbortableFetch<LicentiesOutput>(
    urlLicentie,
    requestInit
  );

  if (!heeftToegang) {
    return <ErrorPage error={{ ...Error("Geen rechten toegekend voor deze gebruiker"), status: 403 }} data={null} />;
  }

  if (errorMDW) {
    return <ErrorPage error={new Error(`Error : ${errorMDW}`)} data={dataMDW} />;
  }

  if (errorVST) {
    return <ErrorPage error={new Error(`Error : ${errorVST}`)} data={dataMDW} />;
  }

  if (errorGG) {
    return <ErrorPage error={new Error(`Error : ${errorGG}`)} data={dataMDW} />;
  }

  if (errorORG) {
    return <ErrorPage error={new Error(`Error : ${errorORG}`)} data={dataMDW} />;
  }

  if (errorLIC) {
    return <ErrorPage error={new Error(`Error : ${errorLIC}`)} data={dataMDW} />;
  }

  if (
    typeof dataMDW === "string" ||
    typeof dataGG === "string" ||
    typeof dataVST === "string" ||
    typeof dataORG === "string" ||
    typeof dataLIC === "string"
  ) {
    return <ErrorPage error={new Error(`Returned data type is string`)} />;
  }

  if (
    loadingMDW ||
    !dataMDW ||
    loadingVST ||
    !dataVST ||
    !dataVST.vestigingen ||
    loadingGG ||
    !dataGG ||
    !dataGG.gebruikersgroepen ||
    loadingORG ||
    !dataORG ||
    !dataORG.organisaties ||
    loadingLIC ||
    !dataLIC ||
    !dataLIC.licenties ||
    !params.organisatie
  ) {
    return <PageLoading />;
  }

  const vestigingenDetails: vestigingenType[] = [];
  const vestigingen = dataVST.vestigingen;
  const alleOrganisatieVestigingen = Object.keys(vestigingen).map(id => {
    const vestiging = {
      vestigingId: id,
      extraVestigingGegevens: vestigingen[id]
    };
    vestigingenDetails.push(vestiging);

    return { id, naam: vestigingen[id]?.naam };
  });

  const gebruikersgroepen = dataGG.gebruikersgroepen;
  const alleOrganisatieGebruikersgroepen = Object.keys(gebruikersgroepen).map(id => {
    return { id, naam: gebruikersgroepen[id].naam };
  });

  const organisatie = params?.organisatie ? dataORG.organisaties[params?.organisatie] : null;

  const licentiesDl = dataLIC.licenties;
  const uniqueLicentieIds: OptiesMetExtraIdsType[] = [];
  const alleLicentieIds: OrganisatieLicentiesType[] = [];
  Object.keys(licentiesDl).forEach(id => {
    if (uniqueLicentieIds.filter(v => v?.id === licentiesDl[id]?.productsamenstellingId).length === 0) {
      const item: OptiesMetExtraIdsType = {
        ...optiesMetExtraIdsSchema.default(),
        id: licentiesDl[id]?.productsamenstellingId ?? "",
        licentieovereenkomstId: licentiesDl[id]?.licentieovereenkomstId,
        licentieId: id
      };
      uniqueLicentieIds.push(item);
    }
    alleLicentieIds.push({
      medewerkerId: licentiesDl[id]?.medewerkerId,
      licentieId: id,
      productsamenstellingId: licentiesDl[id]?.productsamenstellingId,
      licentieovereenkomstId: licentiesDl[id]?.licentieovereenkomstId
    });
  });

  const res =
    mapMedewerkersDlToUi(
      dataMDW,
      alleOrganisatieVestigingen,
      alleOrganisatieGebruikersgroepen,
      organisatie,
      params?.organisatie,
      vestigingenDetails,
      uniqueLicentieIds,
      alleLicentieIds
    ) ?? medewerkersSchema.default();

  return (
    <>
      <Title appName="Contract" altTitle={"Medewerkers"} />
      <Medewerkers {...res} onLoadingData={true} user={user} userDetails={userDetails} />
    </>
  );
};

export const MedewerkersAjax = withErrorBoundary(MedewerkersAjaxComponent);
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") MedewerkersAjax.displayName = "MedewerkersAjax";
