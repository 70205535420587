import { Geslacht, KlantdossierRechtenOptions, MedewerkerEx } from "../../.generated/licenties/licentiestypes";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import { medewerkersSchema, MedewerkerState } from "./medewerkers-schema";

export const mapMedewerkersUiToDl = createMapToDl(medewerkersSchema)
  // TODO: Remove any
  .to<any | { [key: string]: MedewerkerEx } | null>({
    // TODO: map keys
  });

export function mapMedewerkerUIToDl(data: MedewerkerState): MedewerkerEx | null {
  if (data) {
    return {
      voornaam: data.persoonsgegevens.voornaam,
      tussenvoegsels: data.persoonsgegevens.tussenvoegsel,
      achternaam: data.persoonsgegevens.achternaam,
      voorletters: data.persoonsgegevens.voorletters,
      geslacht: Geslacht.Neutraal,
      telefoon: data.contactgegevens.telefoon || null,
      telefoonWerk: data.contactgegevens.telefoonWerk,
      telefoonMobiel: data.contactgegevens.telefoonMobiel,
      email: data.email,
      isActief: data.licentiegegevens.isActief ?? false,
      klantdossierRechtenVestigingen: data.licentiegegevens.vestigingToegangsrechten
        ? KlantdossierRechtenOptions[data.licentiegegevens.vestigingToegangsrechten]
        : KlantdossierRechtenOptions.Geen,
      klantdossierRechtenOrganisatie: data.licentiegegevens.organisatieToegangsrechten
        ? KlantdossierRechtenOptions[data.licentiegegevens.organisatieToegangsrechten]
        : KlantdossierRechtenOptions.Geen,
      functie: null,
      platformId: null,
      klantId: null,
      roepnaam: null,
      gebruikersgroepen: data.licentiegegevens.gekozenGebruikersgroepen.map(x => x.id),
      vestigingen: data.licentiegegevens.gekozenVestigingen.map(x => x.id),
      productsamenstellingId: null,
      productsamenstelling: null
    };
  }

  return null;
}
