import React, { ReactElement } from "react";
import { OptiesMetExtraIdsType } from "../infra/medewerkers-schema";
import { Label, SelectInput } from "adviesbox-shared";

type MedewerkerLicentieGegevensProps = {
  licentieBeschikbaar: boolean;
  alleOrganisatieUniekeLicenties: OptiesMetExtraIdsType[];
  name: string;
  readOnly: boolean;
};

export const MedewerkerLicentie = ({
  licentieBeschikbaar,
  alleOrganisatieUniekeLicenties,
  name,
  readOnly
}: MedewerkerLicentieGegevensProps): ReactElement => {
  const licentieOpties = alleOrganisatieUniekeLicenties.map((a) => ({
    label: a?.naam,
    value: a?.id
  }));

  return (
    <>
      <div className={"form-group form-row"}>
        <div className={`col-6`}>
          <Label caption={"Licentie"} name={name} />
        </div>
        <div className={`col-6`}>
          <div className="input-group">
            <SelectInput
              className={!licentieBeschikbaar ? "is-invalid" : ""}
              name={name}
              readonly={readOnly || licentieOpties.length === 1}
              options={licentieOpties}
              appendChildren={
                !licentieBeschikbaar ? (
                  <div className="foutmelding">Er zijn geen licenties meer beschikbaar</div>
                ) : null
              }
            />
          </div>
        </div>
      </div>
    </> 
  )
};