import { AdviesBoxFormik, Card, Icon, ProgressBar, StepType } from "adviesbox-shared";
import classNames from "classnames";
import React, { Dispatch, ReactElement, SetStateAction, useMemo, useState } from "react";
import { AccountActiveren } from "./components/account-activeren";
import { BackupCodes } from "./components/backup-codes";
import { Contactpersoon } from "./components/contactpersoon";
import { EmailVerzonden } from "./components/email-verzonden";
import { Kantoorgegevens } from "./components/kantoorgegevens";
import { Overeenkomst } from "./components/overeenkomst";
import { TweedeFactor } from "./components/tweede-factor";
import { registratieSchema } from "./infra/registreren-schema";
import classes from "./registreren.module.scss";

export type StepProps = {
  setCurrentStep: Dispatch<SetStateAction<number>>;
  currentStep: number;
};

export const OrganisatieRegistreren = ({ startStep = 1 }: { startStep?: number }): ReactElement => {
  const progressState = useState<number>(startStep);
  const [currentStep, setCurrentStep] = progressState;

  const steps: { [key: string]: StepType } = useMemo(() => {
    return {
      Kantoorgegevens: {
        component: <Kantoorgegevens currentStep={currentStep} setCurrentStep={setCurrentStep} />,
        type: "full"
      },
      Contactpersoon: {
        component: <Contactpersoon currentStep={currentStep} setCurrentStep={setCurrentStep} />,
        type: "full"
      },
      EmailVeronden: {
        component: <EmailVerzonden currentStep={currentStep} setCurrentStep={setCurrentStep} />,
        type: "half",
        altStepButton: <Icon name={"unread"} />,
        altStepButtonClassName: (active, current) =>
          classNames(classes.custom_step_index, active ? classes.active : "", current ? classes.current : "")
      },
      "Account activeren": {
        component: <AccountActiveren currentStep={currentStep} setCurrentStep={setCurrentStep} />,
        type: "full"
      },
      "Tweede factor": {
        component: <TweedeFactor currentStep={currentStep} setCurrentStep={setCurrentStep} />,
        type: "full"
      },
      "Backup codes": {
        component: <BackupCodes currentStep={currentStep} setCurrentStep={setCurrentStep} />,
        type: "full"
      },
      Overeenkomst: {
        component: <Overeenkomst currentStep={currentStep} setCurrentStep={setCurrentStep} />,
        type: "full"
      }
    };
  }, [currentStep, setCurrentStep]);

  return (
    <AdviesBoxFormik
      initialValues={registratieSchema.default()}
      render={() => {
        return (
          <div className="container">
            <div className="col-8 offset-2 p-5">
              <ProgressBar progressState={progressState} steps={steps} />
            </div>
            <Card className={"col-6 offset-3"}>{Object.values(steps)[currentStep - 1].component}</Card>
          </div>
        );
      }}
      validationSchema={registratieSchema}
      onSave={
        /* istanbul ignore next */ () => {
          // eslint-disable-next-line no-console
          console.log("saving...");
        }
      }
    />
  );
};
