/* istanbul ignore file */
import React, { ReactElement, useContext } from "react";
import { FormikConsumer, FormikContextType } from "formik";
import { ISWSideEffect, ISWAsyncCondition, ISWSideEffectWithContext } from "./isw-types";
import { useISWSideEffects } from "./use-isw-side-effects";
import { __RouterContext } from "react-router";

type FormikSideEffectProps<T> = {
  sync?: ISWSideEffect<T> | ISWSideEffectWithContext<T, any>;
  async?: ISWAsyncCondition<T, any>;
  runOnFirstRender?: boolean;
};

const ISWSideEffectChild = <T extends {}>({
  formik,
  sync,
  async,
  runOnFirstRender
}: FormikSideEffectProps<T> & {
  formik: FormikContextType<T>;
}): null => {
  useISWSideEffects<T>(formik, sync, async, runOnFirstRender);

  return null;
};

export const ISWSideEffects = <T extends {}>({
  sync,
  async,
  runOnFirstRender
}: FormikSideEffectProps<T>): ReactElement => {
  const hasRouterContext = useContext(__RouterContext);

  if (!hasRouterContext) throw new Error("Component must be inside a react-router context to use ISWSideEffects");

  return (
    <FormikConsumer>
      {formik => <ISWSideEffectChild sync={sync} async={async} runOnFirstRender={runOnFirstRender} formik={formik} />}
    </FormikConsumer>
  );
};
