/* istanbul ignore file */
import { VestigingenSchermState, VestigingType } from "./vestigingen-schema";
import {
  createISWAsyncSideEffect,
  initISWAsyncSideEffect
} from "../../shared/components/isw-side-effects/create-isw-helpers";
import { NewOutput, Vestiging, VestigingenOutput } from "../../.generated/licenties/licentiestypes";
import produce from "immer";
import { hasValue } from "adviesbox-shared";
import { mapAdresUiNaarDlAdresInput } from "../../shared/generic-parts/adres/map-ui-2-dl";
import { AdresType } from "../../shared/types";
import { validateFormikValues } from "../vestigingen-helper";

type Context = {
  selected: number;
  setSelected?: (() => void) | null;
  vestiging?: VestigingType;
};

const determinePostadres = (vestiging: VestigingType): AdresType => {
  if (!vestiging.afwijkendPostadresTonen || vestiging.postadres === null) {
    const postadres = produce(vestiging.bezoekadres, v => {
      v.huisnummer = vestiging.bezoekadres.huisnummer;
      v.landCode = vestiging.bezoekadres.landCode;
      v.plaats = vestiging.bezoekadres.plaats;
      v.postcode = vestiging.bezoekadres.postcode;
      v.straat = vestiging.bezoekadres.straat;
      v.toevoeging = vestiging.bezoekadres.toevoeging;
    });
    return postadres;
  }
  return vestiging.postadres;
};

export const postNewVestiging = createISWAsyncSideEffect<VestigingenSchermState, Context>(
  async ({ draft, fetchData, settings, context, formik }) => {
    if (context.vestiging) {
      const bezoekadres = context.vestiging.bezoekadres;
      const postadres = determinePostadres(context.vestiging);
      const isError = await validateFormikValues(formik, context.selected);
      const isAfwijkendPostadres = draft.vestigingen[context.selected].afwijkendPostadresTonen
        ? mapAdresUiNaarDlAdresInput(postadres)
        : null;

      if (!isError) {
        const vestigingResponse = await fetchData<NewOutput, Vestiging>({
          url: `${settings.licentiesOrigin}/Organisatie/${draft.organisatie.id}/Vestigingen`,
          method: "POST",
          body: {
            naam: context.vestiging.naam,
            bezoekadres: mapAdresUiNaarDlAdresInput(bezoekadres),
            postadres: isAfwijkendPostadres,
            emailadres: context.vestiging.emailadres,
            telefoonnummer: context.vestiging.telefoonnummer,
            platformId: context.vestiging.platformId,
            isHoofdvestiging: context.vestiging.isHoofdvestiging,
            afmRegistratienummer: context.vestiging.afmRegistratienummer,
            organisatieId: draft.organisatie.id
          }
        });

        if (vestigingResponse !== null && vestigingResponse.isValid && vestigingResponse.id) {
          draft.vestigingen.map(vestiging => {
            if (vestiging.id === null && vestigingResponse.id) {
              vestiging.id = vestigingResponse.id;
            }
            return vestiging;
          });

          const result = await fetchData<VestigingenOutput>({
            method: "GET",
            url: `${settings.licentiesOrigin}/Vestigingen/${vestigingResponse.id}`
          });

          if (result.isValid && result.vestigingen) {
            draft.vestigingen[context.selected].platformId = result.vestigingen[vestigingResponse.id]?.platformId;
          }

          draft.vestigingen[context.selected].hasChanged = false;
          draft.vestigingen[context.selected].postChanges = false;
          draft.vestigingen[context.selected].savedChanges = true;
          draft.vestigingen[context.selected].putChanges = false;

          if (
            draft.vestigingen[context.selected].triggeredByModal &&
            context.setSelected !== null &&
            typeof context.setSelected === "function"
          ) {
            draft.vestigingen[context.selected].triggeredByModal = false;
            context.setSelected();
          }

          draft.shouldResetForm = true;
        }
        draft.loading = false;
        return;
      }
    }
  }
);

export const putVestiging = createISWAsyncSideEffect<VestigingenSchermState, Context>(
  async ({ draft, fetchData, settings, context, formik }) => {
    if (context.vestiging) {
      const bezoekadres = context.vestiging.bezoekadres;
      const postadres = determinePostadres(context.vestiging);
      const isError = await validateFormikValues(formik, context.selected);

      const isAfwijkendPostadres = draft.vestigingen[context.selected].afwijkendPostadresTonen
        ? mapAdresUiNaarDlAdresInput(postadres)
        : null;
      if (!isError) {
        await fetchData<void, Vestiging>({
          url: `${settings.licentiesOrigin}/Vestigingen/${context.vestiging.id}`,
          method: "PUT",
          body: {
            naam: context.vestiging.naam,
            bezoekadres: mapAdresUiNaarDlAdresInput(bezoekadres),
            postadres: isAfwijkendPostadres,
            emailadres: context.vestiging.emailadres,
            telefoonnummer: context.vestiging.telefoonnummer,
            platformId: context.vestiging.platformId,
            isHoofdvestiging: context.vestiging.isHoofdvestiging,
            afmRegistratienummer: context.vestiging.afmRegistratienummer,
            organisatieId: draft.organisatie.id
          }
        });
        draft.vestigingen[context.selected].hasChanged = false;
        draft.vestigingen[context.selected].postChanges = false;
        draft.vestigingen[context.selected].savedChanges = true;
        draft.vestigingen[context.selected].putChanges = false;

        if (
          draft.vestigingen[context.selected].triggeredByModal &&
          context.setSelected !== null &&
          typeof context.setSelected === "function"
        ) {
          draft.vestigingen[context.selected].triggeredByModal = false;
          context.setSelected();
        }
        draft.shouldResetForm = true;
      }

      draft.loading = false;
      return;
    }
  }
);

export const deleteVestiging = createISWAsyncSideEffect<VestigingenSchermState, Context>(
  async ({ draft, fetchData, settings, context }) => {
    if (context.vestiging && context.vestiging.isDeleted) {
      await fetchData<void>({
        url: `${settings.licentiesOrigin}/Vestigingen/${context.vestiging.id}`,
        method: "DELETE"
      });

      draft.shouldResetForm = true;
      draft.loading = false;
      draft.vestigingen.splice(context.selected, 1);
      return;
    }
  }
);

export const asyncVestigingenSideEffects = initISWAsyncSideEffect<VestigingenSchermState, Context>(
  ({ has, runAsync, context, curr }) => {
    if (hasValue(context.selected) && has.vestigingen.changed) {
      const vestigingen = curr.vestigingen;

      const vestigingMetHoofdvestiging = curr.vestigingen.map(vestiging => {
        return vestiging.isHoofdvestiging === true;
      });

      const filteredVestigingMetHoofdVestigingArray = vestigingMetHoofdvestiging.filter(value => {
        return value === true;
      });

      vestigingen.forEach(vestiging => {
        if (
          !vestiging.id ||
          (vestiging.hasChanged && vestiging.putChanges) ||
          (vestiging.isDeleted && vestigingen[context.selected]?.id)
        ) {
          const ContextWithVestiging: Context = {
            selected: context.selected,
            setSelected: context.setSelected ? context.setSelected : null,
            vestiging: vestiging
          };

          if (!vestiging.id && vestiging.putChanges && filteredVestigingMetHoofdVestigingArray.length <= 1) {
            return runAsync(postNewVestiging(ContextWithVestiging));
          }
          if (
            vestiging.hasChanged &&
            vestiging.putChanges &&
            vestiging.id &&
            filteredVestigingMetHoofdVestigingArray.length <= 1
          ) {
            return runAsync(putVestiging(ContextWithVestiging));
          }

          if (vestiging.isDeleted && vestigingen[context.selected]?.id) {
            return runAsync(deleteVestiging(ContextWithVestiging));
          }
        }
      });
    }
  }
);
