import React, { ReactElement, useState } from "react";
import { FormikProps, Form, useFormikContext } from "formik";
import {
  PageLoading,
  CardWrapper,
  Card,
  DataGrid,
  SortType,
  AdviesBoxColumn,
  createSpanWithId,
  hasValue,
  PlatformFoutenSamenvatting
} from "adviesbox-shared";
import {
  VestigingenSchermState,
  VestigingenSchermProps,
  vestigingSchema,
  vestigingenSchermSchema,
  VestigingType,
  ShowHasChangesModal,
  ShowDeleteModal
} from "./infra/vestigingen-schema";

import { syncVestigingenSideEffects } from "./infra/determine-sync-vestigingen-side-effects";
import { asyncVestigingenSideEffects } from "./infra/determine-async-vestigingen-side-effects";
import { ISWSideEffects } from "../shared/components/isw-side-effects/isw-side-effects";
import { Debug } from "../shared/components/formik/Debug";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { Vestigingsgegevens } from "./vestigingsgegevens/vestigingsgegevens";
import { Contactgegevens } from "./contactgegevens/contactgegevens";
import ModalDeleteButton from "./infra/modalDeleteButton";
import { CellInfo } from "react-table-6";
import { PaginationComponent } from "../shared/components/pagination/pagination";
import { HasChangesModal } from "./has-changes-modal/has-changes-modal";
import { SaveButtonNoSaveData } from "../shared/components/save-button/save-button-no-save-data";
import memoizeOne from "memoize-one";
import { DeleteVestigingModal, VestigingDeleteType } from "./vestigingen-data-grid-components";
import { Modal } from "react-bootstrap";
import { Title } from "../shared/components/title/title";
import { UserProps } from "../shared/properties/user-props";
import { Gebruikersactie, MagActieUitvoeren, Matrixonderdeel } from "../shared/autorisatie-matrix/autorisatie-matrix";

const VestigingenComponent = (
  props: FormikProps<VestigingenSchermState> & VestigingenSchermProps & UserProps
): ReactElement => {
  const formik = useFormikContext<VestigingenSchermState>();
  const { values, setFieldValue } = formik;

  const magSchrijven = MagActieUitvoeren(
    Matrixonderdeel.Vestigingen,
    Gebruikersactie.Schrijven,
    props.user,
    props.userDetails
  );
  const magToevoegen = MagActieUitvoeren(
    Matrixonderdeel.Vestigingen,
    Gebruikersactie.Toevoegen,
    props.user,
    props.userDetails
  );
  const magVerwijderen = MagActieUitvoeren(
    Matrixonderdeel.Vestigingen,
    Gebruikersactie.Verwijderen,
    props.user,
    props.userDetails
  );
  const magLezen =
    magSchrijven ||
    magToevoegen ||
    magVerwijderen ||
    MagActieUitvoeren(Matrixonderdeel.Vestigingen, Gebruikersactie.Lezen, props.user, props.userDetails);

  const { isSubmitting } = props;
  const selectedState = useState(0);
  const [selected, setSelected] = selectedState;
  const [newSelected, setNewSelected] = useState(0);
  const { vestigingen } = values;
  const selectedVestiging =
    vestigingen && vestigingen[selected] ? vestigingen[selected] /* istanbul ignore next */ : null;
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<ShowDeleteModal>({ show: false, selected: null });
  if (!hasValue(selectedVestiging) && vestigingen.length) setSelected(0);

  /* istanbul ignore next */
  const deleteVestiging = (data: VestigingDeleteType): void => {
    formik.setFieldValue("loading", true);
    formik.setFieldValue(`vestigingen[${data.selected}].isDeleted`, true);
    setDeleteModalOpen({ show: false, selected: 0 });
  };

  const deleteButton = memoizeOne(
    (
      setShowPopup: React.Dispatch<React.SetStateAction<ShowDeleteModal>>,
      updateSelected: (selected: number) => void
    ): ((cellInfo: CellInfo) => ReactElement) => {
      const cellDeleteButton = (c: CellInfo): ReactElement => {
        return <ModalDeleteButton index={c.index} updateSelected={updateSelected} setShowPopup={setShowPopup} />;
      };
      const noButton = (c: CellInfo): ReactElement => {
        return <></>;
      };

      if (!magVerwijderen) return noButton;

      return cellDeleteButton;
    }
  );
  const [showHasChangesModal, setShowHasChangesModal] = useState<ShowHasChangesModal>({ show: false, selected: null });

  /* istanbul ignore next */
  const updateSelected = (newSelected: React.SetStateAction<number>, skip?: boolean, keepSelected?: boolean): void => {
    if (values.vestigingen[selected]?.hasChanged && selected !== newSelected && !skip) {
      setShowHasChangesModal({ show: true, selected: selected });
      setSelected(selected);
      setNewSelected(newSelected);
      return;
    }

    if (keepSelected) {
      setSelected(selected);
      return;
    }

    setSelected(newSelected);
  };

  /* istanbul ignore next */
  const closeModalAndSwitchSelected = (keepSelected?: boolean): void => {
    if (showHasChangesModal.show) {
      setShowHasChangesModal({ show: false, selected: null });
      updateSelected(newSelected, true, keepSelected);
    }
  };

  const vestigingenColumns = (): AdviesBoxColumn[] => [
    {
      Header: "Naam vestiging",
      id: "naam",
      accessor: "naam",
      Cell: (c): ReactElement => createSpanWithId(c.index, 1, c.original.naam)
    },
    {
      Header: "Plaats",
      id: "plaats",
      accessor: "plaats",
      Cell: (c): ReactElement => createSpanWithId(c.index, 2, c.original.bezoekadres.plaats)
    },
    {
      Header: "Postcode",
      id: "postcode",
      accessor: "postcode",
      Cell: (c): ReactElement => createSpanWithId(c.index, 3, c.original.bezoekadres.postcode)
    },
    {
      Header: "Hoofdvestiging",
      id: "isHoofdvestiging",
      accessor: "isHoofdvestiging",
      Cell: (c): ReactElement => createSpanWithId(c.index, 4, c.original.isHoofdvestiging ? "Ja" : "Nee")
    },
    {
      id: "deleteButton",
      Cell: deleteButton(setDeleteModalOpen, updateSelected),
      maxWidth: 30
    }
  ];

  if (!magLezen) return <></>;
  const magOpslaan = magToevoegen || magSchrijven;

  return (
    <Form>
      <Title appName="Contract" altTitle={"Vestigingen"} />

      {isSubmitting && <PageLoading />}

      <ISWSideEffects<VestigingenSchermState>
        sync={syncVestigingenSideEffects({ selected, formik })}
        async={asyncVestigingenSideEffects({
          selected,
          setSelected: /* istanbul ignore next */ () => closeModalAndSwitchSelected()
        })}
      />

      <CardWrapper className="px-3">
        <div className="text-container">
          <h2>Vestigingen</h2>
        </div>
      </CardWrapper>

      <PlatformFoutenSamenvatting />

      <CardWrapper className="px-3">
        <Card className="w-xl-100 w-lg-100 w-md-50 w-50">
          <DataGrid
            masterDetail
            name="vestigingen"
            rowCaption="Nieuwe vestiging"
            columns={vestigingenColumns()}
            sortable={false}
            sortConfig={{
              naam: SortType.Descending
            }}
            initialSort="naam"
            rowSelected={[selected, updateSelected]}
            showButtonAddRow={magToevoegen}
            showPagination={true}
            errorHighlight={false}
            getNewRowValues={(): VestigingType => ({
              ...vestigingSchema.default()
            })}
            pageSize={formik.values.vestigingenInView}
            defaultPageSize={formik.values.vestigingenInView}
            PaginationComponent={PaginationComponent(true, "vestigingenInView", "vestigingen")}
          />
        </Card>
      </CardWrapper>
      {magOpslaan && (
        <CardWrapper className="pl-4 py-1">
          <div className="button-container">
            <SaveButtonNoSaveData
              isEdited={values.vestigingen[selected]?.hasChanged}
              saveResult={"default"}
              callBack={
                /* istanbul ignore next */ () => {
                  setFieldValue(`vestigingen[${selected}].putChanges`, true);
                  setFieldValue(`loading`, true);
                }
              }
            />
          </div>
        </CardWrapper>
      )}
      <CardWrapper className="px-3">
        {selectedVestiging && (
          <Card title="Vestigingsgegevens">
            <Vestigingsgegevens selected={selected} user={props.user} userDetails={props.userDetails} />
          </Card>
        )}
        {selectedVestiging && (
          <Card title="Contactgegevens">
            <Contactgegevens selected={selected} user={props.user} userDetails={props.userDetails} />
          </Card>
        )}
        <Modal show={isDeleteModalOpen.show}>
          <DeleteVestigingModal
            formik={formik}
            index={selected}
            onDelete={deleteVestiging}
            closeModal={() => setDeleteModalOpen({ show: false, selected: 0 })}
          />
        </Modal>
        <HasChangesModal
          closeModal={setShowHasChangesModal}
          show={showHasChangesModal}
          onDeleteChanges={
            /* istanbul ignore next */ () => {
              const vestigingenInView = values.vestigingenInView;
              formik.resetForm();
              setFieldValue(`vestigingenInView`, vestigingenInView);
              closeModalAndSwitchSelected(true);
            }
          }
          onConfirmSave={
            /* istanbul ignore next */ () => {
              setFieldValue(`vestigingen[${selected}].putChanges`, true);
              setFieldValue(`vestigingen[${selected}].triggeredByModal`, true);
              setFieldValue(`loading`, true);
            }
          }
        />
      </CardWrapper>
      <Debug />
    </Form>
  );
};

export const Vestigingen = withAdviesboxFormik<VestigingenSchermProps & UserProps, VestigingenSchermState>({
  // Transform outer props into form values
  mapPropsToValues: (e: VestigingenSchermProps): VestigingenSchermState => e,
  validationSchema: vestigingenSchermSchema
})(VestigingenComponent);
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") Vestigingen.displayName = "Vestigingen";
