import * as Yup from "yup";
import { InferType } from "yup";
/* istanbul ignore file */

export enum SearchCategories {
  Naam = "Naam",
  Postcode = "Postcode",
  Woonplaats = "Woonplaats",
  KVKnummer = "KVK-nummer"
}

export const zoekResultaatOrganisatieSchema = Yup.object({
  platformId: Yup.string()
    .nullable()
    .default(""),
  organisatieId: Yup.string()
    .nullable()
    .default(""),
  logo: Yup.string()
    .nullable()
    .default(""),
  kvknummer: Yup.string().default(""),
  iban: Yup.string().default(""),
  postcode: Yup.string().default(""),
  woonplaats: Yup.string().default(""),
  naamOrganisatie: Yup.string().default("")
});

export type ZoekResultaatOrganisatieType = InferType<typeof zoekResultaatOrganisatieSchema>;

export const zoekenSchema = Yup.object({
  searchValue: Yup.string()
    .default("")
    .test({
      test: function(value: string) {
        if (this.parent.searchCategory === SearchCategories.Naam && value.length === 1) {
          return this.createError({
            message: "Een zoekactie moet uit minimaal twee tekens bestaan."
          });
        }
        if (this.parent.searchCategory === SearchCategories.Woonplaats && value.length === 1) {
          return this.createError({
            message: "Een zoekactie moet uit minimaal twee tekens bestaan."
          });
        }
        if (
          this.parent.searchCategory === SearchCategories.Postcode &&
          value.replace(/_|\s/g, "").length >= 1 &&
          value.replace(/_|\s/g, "").length < 6
        ) {
          return this.createError({
            message: "Vul alsjeblieft een geldige postcode in."
          });
        }
        if (this.parent.searchCategory === SearchCategories.KVKnummer) {
          if (value.length < 2) {
            return this.createError({
              message: "Een zoekactie moet uit minimaal 2 tekens bestaan"
            });
          }

          if (value.match(/[^\d\s]/gm)) {
            return this.createError({
              message: "KVK-nummer mag geen letters bevatten"
            });
          }
        }
        return true;
      }
    }),
  searchCategory: Yup.string().default(SearchCategories.Naam),
  searching: Yup.boolean().default(false),
  zoekresultaten: Yup.array(zoekResultaatOrganisatieSchema).default([])
});

export type ZoekenType = InferType<typeof zoekenSchema>;

export const zoekschermSchema = Yup.object({
  zoeken: zoekenSchema,
  deletedOrganisatieId: Yup.string()
    .nullable()
    .default(null)
});

export type ZoekschermState = InferType<typeof zoekschermSchema>;
