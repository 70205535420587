/* istanbul ignore file */

import { SettingsType, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement } from "react";
import { Title } from "../shared/components/title/title";
import { useAdviesboxData } from "../shared/hooks/use-adviesbox-data";
import { logboekSchema } from "./infra/logboek-schema";
import { mapDlTargetToLogboekUiField } from "./infra/map-logboek-dl-target-to-ui-field";
import { mapLogboekDlToUi } from "./infra/map-logboek-dl-to-ui";
import { mapLogboekUiToDl } from "./infra/map-logboek-ui-to-dl";
import { Logboek } from "./logboek";

// import { PageLoading } from "adviesbox-shared";
// import { ErrorPage } from "adviesbox-shared";

type RouteParams = {
  vestiging: string;
  adviesdossier: string;
};

const createUrl = (s: SettingsType, p: RouteParams): string =>
  `${s.klantdossiersFormsOrigin}/Adviesdossiers/${p.adviesdossier}/LicentiebeheerLogboek`;

const LogboekAjaxComponent = (): ReactElement => {
  // const { loading, error, data, platformData, saveData } = useAdviesboxData(
  const { saveData } = useAdviesboxData(
    createUrl,
    p => p.adviesdossier,
    mapLogboekDlToUi,
    mapLogboekUiToDl,
    mapDlTargetToLogboekUiField
  );

  // if (error) {
  //   return <ErrorPage error={error} data={platformData} />;
  // }

  // if (loading || !data) {
  //   return <PageLoading />;
  // }

  return (
    <>
      <Title appName="Contract" altTitle={"Logboek"} />
      <Logboek saveData={saveData} {...logboekSchema.default()} />
    </>
  );
};

export const LogboekAjax = withErrorBoundary(LogboekAjaxComponent);
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") LogboekAjax.displayName = "LogboekAjax";
