/* istanbul ignore file */

import { AuthContext, ErrorPage, PageLoading, useRequestInit, withErrorBoundary, ForceRerenderContext } from "adviesbox-shared";
import React, { ReactElement, useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import useAbortableFetch from "use-abortable-fetch";
import { LicentiesApi } from "../licenties/infra/licenties-api";
import { OrganisatiesOutput, VestigingenOutput } from "../.generated/licenties/licentiestypes";
import { MagActieUitvoeren, Matrixonderdeel } from "../shared/autorisatie-matrix/autorisatie-matrix";
import { RouteParams } from "../shared/paramrouting/paramrouting-context";
import UserDetailsContext from "../shared/user-details/user-details-context";
import { mapOrganisatieDlToUi, mapVestigingDlToUi } from "./infra/map-vestigingen-dl-to-ui";
import { VestigingenSchermState } from "./infra/vestigingen-schema";
import { Vestigingen } from "./vestigingen";

//ORG = Organisatie
//VES = Vestiging

const VestigingenAjaxComponent = (): ReactElement => {
  const loadingDone = useContext(ForceRerenderContext);
  const { requestInit: requestInitORG, settings: settingsORG } = useRequestInit();
  const { requestInit: requestInitVES, settings: settingsVES } = useRequestInit();
  const { user } = useContext(AuthContext);
  const { userDetails } = useContext(UserDetailsContext);

  const params = useParams() as RouteParams & { organisatie?: string };

  // als de requestedOrganisatie van de userDetails wijzigt, dan moet MagActieUitvoeren opnieuw bepaald worden
  const heeftToegang = useMemo(() => MagActieUitvoeren(Matrixonderdeel.Vestigingen, null, user, userDetails),
    [user, userDetails, userDetails.requestedOrganisatie]); // eslint-disable-line react-hooks/exhaustive-deps

  const urlORG = useMemo(() => LicentiesApi.getOrganisatieUrl(settingsORG, params), [settingsORG, params]);
  const urlLOK = useMemo(() => LicentiesApi.getVestigingenUrl(settingsVES, params), [settingsVES, params]);

  const { loading: loadingVES, error: platformErrorVES, data: platformDataVES } = useAbortableFetch<VestigingenOutput>(
    urlLOK,
    requestInitVES
  );
  const { loading: loadingORG, error: platformErrorORG, data: platformDataORG } = useAbortableFetch<OrganisatiesOutput>(
    urlORG,
    requestInitORG
  );

  const dataVES = useMemo(
    () => (platformDataVES && typeof platformDataVES !== "string" ? mapVestigingDlToUi(platformDataVES) : null),
    [platformDataVES]
  );

  const dataORG = useMemo(
    () =>
      platformDataORG && typeof platformDataORG !== "string"
        ? mapOrganisatieDlToUi(platformDataORG, params.organisatie ?? "")
        : null,
    [platformDataORG, params]
  );

  useEffect((): void => {
    if (loadingVES && platformDataVES && loadingORG && platformDataORG && dataORG && dataVES && loadingDone) {
      loadingDone();
    }
  }, [platformDataVES, loadingVES, loadingORG, platformDataORG, loadingDone, dataORG, dataVES]);

  if (!heeftToegang) {
    return <ErrorPage error={{ ...Error("Geen rechten toegekend voor deze gebruiker"), status: 403 }} data={null} />;
  }

  if (platformErrorORG) {
    return <ErrorPage error={platformErrorORG} data={platformDataORG} />;
  }

  if (platformErrorVES) {
    return <ErrorPage error={platformErrorVES} data={platformDataVES} />;
  }

  if (loadingORG || loadingVES || !dataORG || !dataVES) {
    return <PageLoading />;
  }

  const screenData: VestigingenSchermState = {
    organisatie: {
      ...dataORG
    },
    cancelHasChanges: false,
    loading: false,
    shouldResetForm: false,
    vestigingenInView: 10,
    vestigingen: dataVES.vestigingen
  };

  return <Vestigingen {...screenData} user={user} userDetails={userDetails} />;
};

export const VestigingenAjax = withErrorBoundary(VestigingenAjaxComponent);
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") VestigingenAjax.displayName = "VestigingenAjax";
