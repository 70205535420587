import { CheckBox } from "adviesbox-shared";
import classNames from "classnames";
import React, { ReactElement } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { StepProps } from "../organisatie-registreren";
import classes from "../registreren.module.scss";

export const Overeenkomst = ({ currentStep, setCurrentStep }: StepProps): ReactElement => {
  return (
    <>
      <h2 className={classNames(classes.title)}>Overeenkomst</h2>
      <p className={"px-2"}>
        Hieronder staat de overeenkomt die je aangaat met Intersoftware B.V. voor het gebruik van Adviesbox. Deze
        overeenkomst is later in te zien en te downloaden via de contract app.
      </p>
      {/* TODO: Implement overeenkomst viewer */}
      <div className={"px-2"}>
        <CheckBox caption={"Ik accepteer de overeenkomst"} name={"overeenkomst"} />
      </div>
      <Button
        className={"my-3 mx-1"}
        variant={"light"}
        onClick={() => {
          setCurrentStep(currentStep - 1);
        }}
      >
        Terug
      </Button>
      <Link className={"float-right m-3 btn btn-primary "} to={"/registratie-succesvol"}>
        Bevestigen
      </Link>
    </>
  );
};
