import { logErrorToApplicationInsights } from "adviesbox-shared";
import { ValidationResultModel } from "../../.generated/forms/formstypes";
import { UiError, UiName } from "../types";

export const validationResultsToUiError = (
  results: ValidationResultModel[],
  mapperDlNameToUiName: (target: string) => UiName | null
): UiError[] => {
  return results.map(
    (result: ValidationResultModel): UiError => {
      let field: UiName | null = null;

      if (result.target) {
        field = mapperDlNameToUiName(result.target);
        /* istanbul ignore next */
        if (!field) {
          const error = new Error(`Geen mapping voor '${result.target}'`);
          logErrorToApplicationInsights(error);

          if (process.env.NODE_ENV === "development") {
            /* eslint-disable-next-line no-console */
            console.warn(error.message);
          }
        }
      }

      return {
        field: field && field.field,
        label: field && field.label,
        error: result.message ?? result.code ?? "Onbekende fout"
      };
    }
  );
};
