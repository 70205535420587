import { createGetTextResources } from "../../shared/utils/text-resources-algemeen";
/* istanbul ignore file */

export const getLicentieaanbodTextResources = createGetTextResources({
  productNaamLeeg: "Productnaam is verplicht",
  productCodeLeeg: "Code is verplicht",
  productNaamDoesNotMatch: "De ingevoerde naam komt niet overeen met de naam van het product.",
  licentieSamenstellingNaamDoesNotMatch:
    "De ingevoerde naam komt niet overeen met de naam van de licentiesamenstelling.",
  licentieOnderdeelNaamDoesNotMatch: "De ingevoerde naam komt niet overeen met de naam van het licentieonderdeel."
});
