/* istanbul ignore file */
import React, { ReactElement } from "react";
import { Modal as ModalBootstrap } from "react-bootstrap";
import { ShowHasChangesModal } from "../infra/medewerkers-schema";
import classes from "../medewerkers.module.scss";

type HasChangesModalProps = {
  onDeleteChanges: () => void;
  onConfirmSave: () => void;
  show: ShowHasChangesModal;
  closeModal: React.Dispatch<React.SetStateAction<ShowHasChangesModal>>;
};

export const HasChangesModal = ({
  closeModal,
  show,
  onConfirmSave,
  onDeleteChanges
}: HasChangesModalProps): ReactElement => {
  if (show.selected === null) return <></>;
  return (
    <ModalBootstrap show={show.show}>
      <ModalBootstrap.Header>
        <div className="d-flex w-100">
          <ModalBootstrap.Title>Openstaande aanpassingen</ModalBootstrap.Title>
          <button
            type="button"
            className={"close ml-auto"}
            onClick={() => closeModal({ show: false, selected: null })}
            id={`btnClose-medewerkers-modal-${show.selected}`}
          >
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </div>
      </ModalBootstrap.Header>
      <ModalBootstrap.Body>
        <div>
          <p className="px-2">Er zijn nog aanpassingen in het huidige scherm wil je deze opslaan of verwijderen?</p>
        </div>
      </ModalBootstrap.Body>
      <ModalBootstrap.Footer className={classes.modal_footer}>
        <button
          type="button"
          data-testid={`medewerkers-aanpassing-verwijderen-button`}
          className="btn btn-danger"
          id={`bevestig-verwijderen-medewerkers-modal-${show.selected}`}
          onClick={onDeleteChanges}
        >
          Gegevens terugzetten
        </button>
        <button
          type="button"
          className="btn btn-link"
          data-testid={`medewerkers-aanpassing-anuleer-button`}
          id={`annuleer-verwijderen-medewerkers-modal-${show.selected}`}
          onClick={onConfirmSave}
        >
          Gegevens opslaan
        </button>

        <button
          type="button"
          className="btn btn-link"
          data-testid={`medewerkers-aanpassing-anuleer-button`}
          id={`annuleer-verwijderen-medewerkers-modal-${show.selected}`}
          onClick={() => closeModal({ show: false, selected: null })}
        >
          Annuleren
        </button>
      </ModalBootstrap.Footer>
    </ModalBootstrap>
  );
};
