import {
  MedewerkerState,
  ToegangsrechtenEnumInterface,
  medewerkerSchema,
  OptiesMetExtraIdsType,
  OptiesType,
  OrganisatieLicentiesType
} from "../infra/medewerkers-schema";
import {
  AdviesBoxFormik,
  LabeledBevestigingInput,
  LabeledRadioInput,
  LabeledTextInput,
  hasValue,
  useRequestInit,
  PlatformFoutenSamenvatting
} from "adviesbox-shared";
import { FormikErrors, FormikProps } from "formik";
import React, { ReactElement } from "react";
import { emailInGebruik, volledigeNaam } from "../medewerkers-helper";
import { Direction } from "../../shared/types";
import { ISWSideEffects } from "../../shared/components/isw-side-effects/isw-side-effects";
import Modal from "../../shared/components/modal/Modal";
import { mapMedewerkerUIToDl } from "../infra/map-medewerkers-ui-to-dl";
import { saveNewMedewerkerApi, createLicentiesNewMedewerkerApi } from "../infra/medewerkers-api";
import { syncAddMedewerkerSideEffects } from "./determine-addmedewerker-sync-side-effects";
import { MedewerkerLicentie } from "../medewerker-licentie/medewerker-licentie";
import { LabeledTagInput } from "../tag-input-component/labeled-tag-input";

type AddMedewerkerModalProps = {
  data: MedewerkerState;
  alleOrganisatieLicenties: OrganisatieLicentiesType[];
  alleOrganisatieUniekeLicenties: OptiesMetExtraIdsType[];
  alleOrganisatieGebruikersgroepen: OptiesType[];
  alleOrganisatieVestigingen: OptiesType[];
  licentieBeschikbaar: boolean;
  organisatieId: string;
  onSave?: (data: MedewerkerState) => void;
  closeModal?: () => void;
};

export const errorFormik = (email: string, errors: FormikErrors<MedewerkerState>): boolean => {
  return (
    !email ||
    !!errors.contactgegevens?.telefoon ||
    !!errors.persoonsgegevens?.achternaam ||
    !!errors.persoonsgegevens?.voorletters ||
    !!errors.persoonsgegevens?.voornaam
  );
};

export const AddMedewerkerModal = ({
  data,
  alleOrganisatieLicenties,
  alleOrganisatieUniekeLicenties,
  alleOrganisatieGebruikersgroepen,
  alleOrganisatieVestigingen,
  licentieBeschikbaar,
  organisatieId,
  onSave,
  closeModal
}: AddMedewerkerModalProps): ReactElement => {
  const { settings, user } = useRequestInit();
  const licentie = alleOrganisatieUniekeLicenties.find(l => l.naam === "Advies")?.id || "Geen";
  const medewerkerGebruikersgroep = alleOrganisatieGebruikersgroepen.find(v => v.naam === "Medewerker");

  const initialValues: MedewerkerState = {
    ...data,
    licentiegegevens: {
      ...data.licentiegegevens,
      gekozenProductsamenstellingId: licentie,
      gekozenGebruikersgroepen: medewerkerGebruikersgroep ? [medewerkerGebruikersgroep] : [],
      gekozenVestigingen: alleOrganisatieVestigingen.length === 1 ? alleOrganisatieVestigingen : []
    }
  };

  return (
    <AdviesBoxFormik<MedewerkerState>
      initialValues={initialValues}
      validationSchema={medewerkerSchema}
      onSave={onSave}
      closeModal={closeModal}
      validateOnMount={true}
      render={({
        submitForm,
        values,
        setFieldValue,
        setTouched,
        errors
      }: FormikProps<MedewerkerState>): ReactElement => (
        <>
          <ISWSideEffects<MedewerkerState> sync={syncAddMedewerkerSideEffects()} />
          <Modal
            onSubmitClick={async () => {
              setFieldValue(`errors`, []);
              const email = await emailInGebruik(settings, user, values?.email);
              if (!email) {
                setFieldValue(`emailIsAlGebruikt`, false);
                setFieldValue(
                  `volledigeNaam`,
                  volledigeNaam(
                    values.persoonsgegevens.voornaam,
                    values.persoonsgegevens.voorletters,
                    values.persoonsgegevens.tussenvoegsel,
                    values.persoonsgegevens.achternaam,
                    "geen naam"
                  )
                );

                const medewerker = mapMedewerkerUIToDl(values);
                const res: any = await saveNewMedewerkerApi(settings, user, organisatieId, medewerker, setFieldValue);

                if (typeof res === "object" && res?.isValid && hasValue(res.id) && res.id !== "") {
                  setFieldValue(`medewerkerId`, res.id);

                  const gekozenProductsamenstellingId = values.licentiegegevens.gekozenProductsamenstellingId;
                  const availableLicentie =
                    alleOrganisatieLicenties?.find(
                      v => !v.medewerkerId && v.productsamenstellingId === gekozenProductsamenstellingId
                    ) ?? null;
                  if (availableLicentie) {
                    await createLicentiesNewMedewerkerApi(settings, user, res.id, availableLicentie);
                  }
                  submitForm && submitForm();
                } else {
                  setFieldValue(`errors`, res);
                }
              } else {
                setFieldValue(`emailIsAlGebruikt`, true);
                setTouched({
                  email: true
                });
              }
            }}
            onCancelClick={closeModal}
            title="Nieuwe medewerker toevoegen"
            invalid={errorFormik(values.email, errors)}
            body={
              <>
                <PlatformFoutenSamenvatting />
                <div className={"d-flex mb-4"}>
                  <div className={"w-100 border mr-1 p-2 rounded"}>
                    <h2>Persoonsgegevens</h2>
                    <>
                      <LabeledTextInput name={`persoonsgegevens.voornaam`} caption="Voornaam" verplicht={true} />
                      <LabeledTextInput
                        name={`persoonsgegevens.voorletters`}
                        caption="Voorletter(s)"
                        verplicht={true}
                      />
                      <LabeledTextInput name={`persoonsgegevens.tussenvoegsel`} caption="Tussenvoegsel" />
                      <LabeledTextInput name={`persoonsgegevens.achternaam`} caption="Achternaam" verplicht={true} />
                    </>
                  </div>
                  <div className={"w-100 border ml-1 p-2 rounded"}>
                    <h2>Contactgegevens</h2>
                    <>
                      <LabeledTextInput name={`contactgegevens.telefoon`} caption="Telefoonnummer" />
                      <LabeledTextInput autoComplete="new-password" name={`email`} caption="E-mailadres" />
                    </>
                  </div>
                </div>
                <div className={"d-flex mb-4"}>
                  <div className={"w-50 border mr-1 p-2 rounded"}>
                    <h2>Licentiegegevens</h2>
                    <MedewerkerLicentie
                      licentieBeschikbaar={licentieBeschikbaar}
                      alleOrganisatieUniekeLicenties={alleOrganisatieUniekeLicenties}
                      name={`licentiegegevens.gekozenProductsamenstellingId`}
                      readOnly={false}
                    />
                    <LabeledTagInput
                      name={`licentiegegevens.gekozenVestigingen`}
                      caption="Vestiging(en)"
                      options={alleOrganisatieVestigingen}
                    />
                    <LabeledTagInput
                      name={`licentiegegevens.gekozenGebruikersgroepen`}
                      caption="Gebruikersgroep(en)"
                      options={alleOrganisatieGebruikersgroepen}
                    />
                    <LabeledRadioInput
                      caption="Toegang tot klanten van de organisatie"
                      name={`licentiegegevens.organisatieToegangsrechten`}
                      options={ToegangsrechtenEnumInterface}
                      layout={Direction.Vertical}
                    />
                    <LabeledRadioInput
                      caption="Toegang tot klanten van de vestiging"
                      name={`licentiegegevens.vestigingToegangsrechten`}
                      options={ToegangsrechtenEnumInterface}
                      layout={Direction.Vertical}
                    />
                    <LabeledBevestigingInput name={`licentiegegevens.isActief`} caption="Actief" />
                  </div>
                </div>
              </>
            }
          />
        </>
      )}
    />
  );
};
