import { FormikContextType } from "formik";
import React, { ReactElement } from "react";
import { Button } from "react-bootstrap";
import { getLicentiesTextResources } from "./infra/licenties-resources";
import { SamenstellingType } from "./infra/licenties-schema";

export const getConfirmButtonText = (
  step: number,
  confirmed: boolean,
  requestedAmountOfLicensesIsNegative: boolean
): string => {
  switch (step) {
    case 1:
      if (!requestedAmountOfLicensesIsNegative) {
        return getLicentiesTextResources("VerderNaarLicentiesBestellen");
      }

      if (!confirmed) {
        return getLicentiesTextResources("VerderNaarLicentiesOpzeggen");
      }

      return "Licenties bestellen";
    case 2:
      if (!requestedAmountOfLicensesIsNegative) {
        return getLicentiesTextResources("LicentiesBestellen");
      }

      return getLicentiesTextResources("LicentiesOpzeggen");
    default:
      return "Volgende";
  }
};

export const onSubmitWizard = async (
  values: SamenstellingType,
  currentStep: number,
  totalSteps: number,
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>,
  setShowConfirm: React.Dispatch<React.SetStateAction<boolean>>,
  setFieldValue: FormikContextType<SamenstellingType>["setFieldValue"],
  amountOfRequestedLicensesIsNegative: boolean
): Promise<void> => {
  if (currentStep === totalSteps) {
    //Save licentiesamenstelling
    if (values.akkoordFacturatie) {
      setFieldValue("waarschuwingsBericht", "");
      setFieldValue("submitButtonIsClicked", true);
      return;
    } else {
      amountOfRequestedLicensesIsNegative
        ? setFieldValue("waarschuwingsBericht", "Ga akkoord met de voorwaarden om door te gaan.")
        : setFieldValue("waarschuwingsBericht", "Je moet akkoord gaan om licenties te bestellen.");
    }
  }

  if (currentStep === 1) {
    setShowConfirm(true);
  }

  if (currentStep < totalSteps) {
    setCurrentStep(currentStep + 1);
    return;
  }
};

export const getFooterElement = (
  step: number,
  setStep: React.Dispatch<React.SetStateAction<number>>,
  confirmed: boolean,
  setConfirmed: React.Dispatch<React.SetStateAction<boolean>>,
  setFieldValue: FormikContextType<SamenstellingType>["setFieldValue"]
): ReactElement => {
  switch (step) {
    case 1:
      if (confirmed) {
        return (
          <div className="mr-auto">
            <Button
              id={"TerugNaarAantalLicenties"}
              name="backToAantalLicentiesAanpassen"
              variant={"link"}
              onClick={/* istanbul ignore next */ () => setConfirmed(false)}
            >
              Terug naar aantal licenties instellen
            </Button>
          </div>
        );
      }
      return <></>;

    case 2:
      return (
        <div className="mr-auto">
          <Button
            name="backToAantalLicentiesAanpassen"
            variant={"link"}
            id={"TerugNaarLicenties"}
            onClick={
              /* istanbul ignore next */ () => {
                setFieldValue("waarschuwingsBericht", "");
                setStep(1);
              }
            }
          >
            Terug naar aantal licenties instellen
          </Button>
        </div>
      );
    default:
      return <></>;
  }
};
