import { createMapToUi } from "../../shared/utils/create-map-to-ui";
/* istanbul ignore file */

import {
  LicentiesAanbodProductState,
  licentiesAanbodProductSchema,
  productSchema,
  licentieSamenstellingProductSchema,
  licentieOnderdelenProductSchema,
  ProductOnderdelenState,
  productOnderdelenSchema,
  productSamenstellingenSchema,
  ProductSamenstellingenState
  /*:SCHEMA:IMPORT:*/
} from "./licentieaanbod-schema";
import {
  ProductenOutput,
  Product,
  ProductonderdelenOutput,
  Productonderdeel,
  ProductsamenstellingenOutput,
  Productsamenstelling
} from "../../.generated/licenties/licentiestypes";

/*:MODAL:MAP:*/

const mapProduct = createMapToUi(productSchema)
  .with<string>()
  .from<Product>({
    id: (_, id) => id,
    productNaam: v => v.naam,
    code: v => v.code,
    actief: v => v.isActief,
    isOnlineBestelbaar: v => v.isOnlineBestelbaar,
    platformId: v => v.platformId,
    hasChanged: () => false,
    isDeleted: () => false,
    putChanges: () => false,
    savedChanges: () => false,
    triggeredByModal: () => false
  });

const mapLicentieonderdelen = createMapToUi(licentieOnderdelenProductSchema)
  .with<string>()
  .from<Productonderdeel>({
    id: (_, id) => id,
    actief: v => v.isActief,
    productNaam: v => v.naam,
    code: v => v.code,
    aantalGekoppeldeGebruikersgroepen: v => v.aantalGekoppeldeGebruikersgroepen,
    isInstelbaarDoorKlant: v => v.isInstelbaarDoorKlant,
    hasChanged: () => false,
    isDeleted: () => false
  });

const mapLicentiesamenstelling = createMapToUi(licentieSamenstellingProductSchema)
  .with<string>()
  .from<Productsamenstelling>({
    id: (_, c) => c,
    actief: v => v.isActief,
    productNaam: v => v.naam,
    code: v => v.code,
    onderdelen: v => v.productonderdelen,
    onderdelenLength: v => v.productonderdelen?.length ?? 0,
    hasChanged: () => false,
    isDeleted: () => false
  });

const mapLicentiesAanbod = createMapToUi(licentiesAanbodProductSchema).from<ProductenOutput>({
  productenInView: () => 10,
  producten: (v: ProductenOutput) => {
    const productArray = [];
    for (const key in v.producten) {
      if (v.producten.hasOwnProperty(key)) {
        productArray.push(mapProduct(key)(v.producten[key]));
      }
    }

    return productArray;
  }
});

const mapProductOnderdelen = createMapToUi(productOnderdelenSchema).from<ProductonderdelenOutput>({
  licentieOnderdelen: v => {
    const productOnderdelenArray = [];
    for (const key in v.productOnderdelen) {
      if (v.productOnderdelen.hasOwnProperty(key)) {
        productOnderdelenArray.push(mapLicentieonderdelen(key)(v.productOnderdelen[key]));
      }
    }

    return productOnderdelenArray;
  },
  productenInView: () => 10,
  loading: () => false
});

const mapLicentieSamenstellingen = createMapToUi(productSamenstellingenSchema).from<ProductsamenstellingenOutput>({
  licentieSamenstellingen: v => {
    const productOnderdelenArray = [];
    for (const key in v.productSamenstellingen) {
      if (v.productSamenstellingen.hasOwnProperty(key)) {
        productOnderdelenArray.push(mapLicentiesamenstelling(key)(v.productSamenstellingen[key]));
      }
    }

    return productOnderdelenArray;
  },
  productenInView: () => 10,
  loading: () => false
});

export function mapProductSamenstellingenDlToUi(
  data: ProductsamenstellingenOutput
): ProductSamenstellingenState | null {
  const productSamenstellingen = data && data.isValid && data.productSamenstellingen ? data : null;

  if (productSamenstellingen) {
    return mapLicentieSamenstellingen(productSamenstellingen);
  }

  return null;
}

export function mapProductOnderdelenDlToUi(data: ProductonderdelenOutput): ProductOnderdelenState | null {
  const productOnderdelen = data && data.isValid && data.productOnderdelen ? data : null;

  if (productOnderdelen) {
    return mapProductOnderdelen(productOnderdelen);
  }

  return null;
}

export function mapLicentieaanbodDlToUi(data: ProductenOutput): LicentiesAanbodProductState | null {
  const licentieaanbod = data && data.isValid && data.producten ? data : null;

  if (licentieaanbod) {
    return mapLicentiesAanbod(licentieaanbod);
  }

  return null;
}
