/* istanbul ignore file */

import { ErrorMessageBox, getAppLocation, HttpErrorPage, SettingsContext } from "adviesbox-shared";
import React, { ReactElement, useContext } from "react";
import { Route, Switch, useParams } from "react-router-dom";
import { ContractAjax } from "../contract/contract-ajax";
import { GebruikersgroepenAjax } from "../gebruikersgroepen/gebruikersgroepen-ajax";
import { HdnNodenummersAjax } from "../hdn-nodenummer/HdnNodenummer-ajax";
import { LicentieaanbodAjax } from "../licentieaanbod/licentieaanbod-ajax";
import { LicentiesAjax } from "../licenties/licenties-ajax";
import { LogboekAjax } from "../logboek/logboek-ajax";
import { MedewerkersAjax } from "../medewerker/medewerkers-ajax";
import Drawer from "../shared/components/drawer/Drawer";
import TopNavigation from "../shared/components/topnav-dossier/TopNavigation";
import { RouteParams } from "../shared/paramrouting/paramrouting-context";
import { useExtendUserDetailsWithRequestedOrganisatie } from "../shared/user-details/utils/user-details-hooks";
import { VestigingenAjax } from "../vestigingen/vestigingen-ajax";
import { ZoekschermAjax } from "../zoekscherm/zoekscherm-ajax";

const AuthenticatedLicentieBeheerAppWithDrawer = (): ReactElement => {
  const settingsContext = useContext(SettingsContext);
  const linkToPortal = getAppLocation(window.location.origin, "LIC", "POR", settingsContext.baseUrls);

  const params = useParams() as RouteParams;
  useExtendUserDetailsWithRequestedOrganisatie(params.organisatie ?? "");

  return (
    <div data-testid="authenticated-licentie-beheer-app-with-router">
      <Drawer>
        <TopNavigation />
        <ErrorMessageBox />
        <Switch>
          <Route path="/organisatie/:organisatie/contract" component={ContractAjax} />
          <Route path="/organisatie/:organisatie/licentieaanbod" component={LicentieaanbodAjax} />
          <Route path="/organisatie/:organisatie/logboek" component={LogboekAjax} />
          <Route path="/organisatie/:organisatie/vestigingen" component={VestigingenAjax} />
          <Route path="/organisatie/:organisatie/gebruikersgroepen" component={GebruikersgroepenAjax} />
          <Route path="/organisatie/:organisatie/licenties" component={LicentiesAjax} />
          <Route path="/organisatie/:organisatie/medewerkers" component={MedewerkersAjax} />
          <Route path="/organisatie/:organisatie/hdn-nodenummer" component={HdnNodenummersAjax} />
          {process.env.NODE_ENV !== "production" /* istanbul ignore next */ && (
            <Route path="/zoeken" component={ZoekschermAjax} />
          )}

          <Route exact path="/" render={/* istanbul ignore next */ () => (window.location.href = linkToPortal)} />
          <Route>
            <HttpErrorPage status={404} returnUrl={linkToPortal} />
          </Route>
        </Switch>
      </Drawer>
      )
    </div>
  );
};

export default AuthenticatedLicentieBeheerAppWithDrawer;
