import {
  AuthContext,
  ErrorPage,
  hasValue,
  PageLoading,
  useRequestInit,
  withErrorBoundary,
  ForceRerenderContext
} from "adviesbox-shared";
import React, { ReactElement, useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import useAbortableFetch from "use-abortable-fetch";
import { LicentiesApi } from "../licenties/infra/licenties-api";
import { LicentieovereenkomstenOutput, OrganisatiesOutput } from "../.generated/licenties/licentiestypes";
import { MagActieUitvoeren, Matrixonderdeel } from "../shared/autorisatie-matrix/autorisatie-matrix";
import AutorisatieNiveauContext from "../shared/autorisatie-niveau-context/autorisatie-niveau-context";
import { Title } from "../shared/components/title/title";
import { RouteParams } from "../shared/paramrouting/paramrouting-context";
import UserDetailsContext from "../shared/user-details/user-details-context";
import { Contract } from "./contract";
import { ContractState } from "./infra/contract-schema";
import { mapLicentieOvereenkomstenDlToUi, mapOrganisatieDlToUi } from "./infra/map-contract-dl-to-ui";

//ORG = Organisatie
//LOK = LicentieOvereenKomst
//GG = GebruikersGroepen

const ContractAjaxComponent = (): ReactElement => {
  const loadingDone = useContext(ForceRerenderContext);
  const { requestInit: requestInitORG, settings: settingsORG } = useRequestInit();
  const { requestInit: requestInitLOK, settings: settingsLOK } = useRequestInit();
  const { isBeheerder } = useContext(AutorisatieNiveauContext);
  const { user } = useContext(AuthContext);
  const { userDetails } = useContext(UserDetailsContext);

  // als de requestedOrganisatie van de userDetails wijzigt, dan moet MagActieUitvoeren opnieuw bepaald worden
  const heeftToegang = MagActieUitvoeren(Matrixonderdeel.Contract, null, user, userDetails);

  const params = useParams() as RouteParams;

  const urlORG = useMemo(() => LicentiesApi.getOrganisatieUrl(settingsORG, params), [settingsORG, params]);
  const urlLOK = useMemo(() => LicentiesApi.getLicentieovereenkomstenUrl(settingsLOK, params), [settingsLOK, params]);

  const { loading: loadingORG, error: platformErrorORG, data: platformDataORG } = useAbortableFetch<OrganisatiesOutput>(
    urlORG,
    requestInitORG
  );

  const { loading: loadingLOK, error: platformErrorLOK, data: platformDataLOK } = useAbortableFetch<
    LicentieovereenkomstenOutput
  >(urlLOK, requestInitLOK);

  const dataORG = useMemo(
    () =>
      platformDataORG && typeof platformDataORG !== "string"
        ? mapOrganisatieDlToUi(platformDataORG, params.organisatie ?? "")
        : null,
    [platformDataORG, params]
  );

  const dataLOK = useMemo(
    () =>
      platformDataLOK && typeof platformDataLOK !== "string" ? mapLicentieOvereenkomstenDlToUi(platformDataLOK) : null,
    [platformDataLOK]
  );

  useEffect((): void => {
    if (
      !loadingLOK &&
      platformDataLOK &&
      !loadingORG &&
      platformDataORG &&
      dataORG &&
      dataLOK &&
      hasValue(isBeheerder) &&
      loadingDone
    ) {
      loadingDone();
    }
  }, [platformDataLOK, loadingLOK, loadingORG, platformDataORG, loadingDone, dataORG, dataLOK, isBeheerder]);

  if (platformErrorORG) {
    return <ErrorPage error={platformErrorORG} data={platformDataORG} />;
  }

  if (platformErrorLOK) {
    return <ErrorPage error={platformErrorLOK} data={platformDataLOK} />;
  }

  if (loadingORG || loadingLOK || !dataORG || !dataLOK) {
    return <PageLoading />;
  }

  // zou even mogelijk moeten zijn
  if (!heeftToegang) {
    return <ErrorPage error={{ ...Error("Geen rechten toegekend voor deze gebruiker"), status: 403 }} data={null} />;
  }

  const screenData: ContractState = {
    organisatie: {
      ...dataORG
    },
    contracten: {
      ...dataLOK
    },
    hasChanged: false,
    saveIsClicked: false
  };

  return (
    <>
      <Title appName="Contract" altTitle={"Contract"} />
      <Contract {...screenData} user={user} userDetails={userDetails} />
    </>
  );
};

export const ContractAjax = withErrorBoundary(ContractAjaxComponent);
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") ContractAjax.displayName = "ContractAjax";
