import {
  Gebruikersgroep,
  GebruikersgroepenOutput,
  Product,
  ProductenOutput,
  Productonderdeel,
  ProductonderdelenOutput
} from "../../.generated/licenties/licentiestypes";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";

import {
  gebruikersgroepSchema,
  gebruikersgroepProductState,
  gebruikersgroepProductSchema,
  productSchema,
  ProductOnderdelenState,
  productOnderdelenSchema,
  gebruikersgroepOnderdeelProductSchema,
  gebruikersGroepDetailsSchema,
  gebruikersGroepDetailsType,
  GekoppeldProductOnderdeel,
  ProductOnderdeel
} from "./gebruikersgroepen-schema";

type GebruikersGroepProductOnderdelenContext = {
  productOnderdelen: ProductOnderdelenState | undefined | string | null;
};
type GebruikersgroepContext = {
  id: string;
  productOnderdelen: ProductOnderdelenState | undefined | string | null;
};

const mapProduct = createMapToUi(productSchema)
  .with<string>()
  .from<Product>({
    id: (_, id) => id,
    productNaam: v => v.naam,
    code: v => v.code,
    actief: v => v.isActief,
    isOnlineBestelbaar: v => v.isOnlineBestelbaar,
    platformId: v => v.platformId,
    isDeleted: () => false,
    putChanges: () => false,
    triggeredByModal: () => false
  });

const mapGebruikersgroepOnderdelen = createMapToUi(gebruikersgroepOnderdeelProductSchema)
  .with<string>()
  .from<Productonderdeel>({
    id: (_, id) => id,
    actief: v => v.isActief,
    productNaam: v => v.naam,
    code: v => v.code,
    aantalGekoppeldeGebruikersgroepen: v => v.aantalGekoppeldeGebruikersgroepen,
    isInstelbaarDoorKlant: v => v.isInstelbaarDoorKlant,
    isDeleted: () => false
  });

const mapGebruikersgroep = createMapToUi(gebruikersgroepSchema)
  .with<GebruikersgroepContext>()
  .from<Gebruikersgroep>({
    groepsNaam: v => v.naam,
    beschrijving: v => v.beschrijving,
    id: (_, context) => context.id,
    organisatieId: v => v.organisatieId,
    productOnderdelen: (v, context) => {
      const productOnderdelen: GekoppeldProductOnderdeel = {};

      /* istanbul ignore else */
      if (typeof context.productOnderdelen !== "string" && context.productOnderdelen) {
        context.productOnderdelen.gebruikersgroepOnderdeel.forEach(object => {
          productOnderdelen[object.productNaam] = {
            gekoppeld: v.productonderdelen
              ? v.productonderdelen.filter(id => id === object.id).length > 0
                ? true
                : false
              : false,
            id: object.id ?? ""
          };
        });
      }

      return productOnderdelen;
    },
    productonderdelenArray: v => v.productonderdelen,
    saveIsClicked: () => false,
    loading: () => false,
    postChanges: () => false,
    triggeredByModal: () => false,
    formIsReset: () => false,
    isDeleted: () => false,
    shouldSave: () => ({
      gebruikersGroepenChanged: false,
      toegangTotChanged: false
    }),
    newGebruikersgroepAfterDelete: _ => null,
    toegangTot: () => null
  });

const mapGebruikersgroepProduct = createMapToUi(gebruikersgroepProductSchema).from<ProductenOutput>({
  productenInView: () => 10,
  producten: (v: ProductenOutput) => {
    const productArray = [];
    for (const key in v.producten) {
      /* istanbul ignore else */
      if (v.producten.hasOwnProperty(key)) {
        productArray.push(mapProduct(key)(v.producten[key]));
      }
    }

    return productArray;
  }
});

const mapProductOnderdelen = createMapToUi(productOnderdelenSchema).from<ProductonderdelenOutput>({
  gebruikersgroepOnderdeel: v => {
    const productOnderdelenArray = [];
    for (const key in v.productOnderdelen) {
      /* istanbul ignore else */
      if (v.productOnderdelen.hasOwnProperty(key) && v.productOnderdelen[key].isActief) {
        //only if active
        productOnderdelenArray.push(mapGebruikersgroepOnderdelen(key)(v.productOnderdelen[key]));
      }
    }

    return productOnderdelenArray;
  },
  productOnderdelen: v => {
    const productOnderdelen: ProductOnderdeel = {};
    for (const key in v.productOnderdelen) {
      /* istanbul ignore else */
      if (v.productOnderdelen.hasOwnProperty(key) && v.productOnderdelen[key].isActief) {
        //only if active
        const onderdeel = mapGebruikersgroepOnderdelen(key)(v.productOnderdelen[key]);
        productOnderdelen[onderdeel.productNaam] = {
          id: onderdeel.id ?? ""
        };
      }
    }

    return productOnderdelen;
  },
  productenInView: () => 10,
  loading: () => false
});

const mapGebruikersgroepen = createMapToUi(gebruikersGroepDetailsSchema)
  .with<GebruikersGroepProductOnderdelenContext>()
  .from<GebruikersgroepenOutput>({
    gebruikersgroep: (v, context) => {
      const gebruikersgroepenArray = [];
      for (const key in v.gebruikersgroepen) {
        /* istanbul ignore else */
        if (v.gebruikersgroepen.hasOwnProperty(key)) {
          const gebruikersgroep = {
            id: key,
            productOnderdelen: context.productOnderdelen
          };
          gebruikersgroepenArray.push(mapGebruikersgroep(gebruikersgroep)(v.gebruikersgroepen[key]));
        }
      }

      return gebruikersgroepenArray;
    },
    productenInView: () => 10
  });

export function mapGebruikersgroepProductDlToUi(data: ProductenOutput): gebruikersgroepProductState | null {
  const gebruikersgroepProduct = data && data.isValid && data.producten ? data : null;

  if (gebruikersgroepProduct) {
    return mapGebruikersgroepProduct(gebruikersgroepProduct);
  }

  return null;
}

export function mapProductOnderdelenDlToUi(data: ProductonderdelenOutput): ProductOnderdelenState | null {
  const productOnderdelen = data && data.isValid && data.productOnderdelen ? data : null;

  if (productOnderdelen) {
    return mapProductOnderdelen(productOnderdelen);
  }

  return null;
}

export function mapGebruikersgroepenDlToUi(
  data: GebruikersgroepenOutput,
  mappedProductOnderdelen: ProductOnderdelenState
): gebruikersGroepDetailsType | null {
  const gebruikersgroepen = data && data.isValid && data.gebruikersgroepen ? data : null;

  if (gebruikersgroepen) {
    return mapGebruikersgroepen({ productOnderdelen: mappedProductOnderdelen })(gebruikersgroepen);
  }

  return null;
}
