import { useRequestInit } from "adviesbox-shared";
import { useContext, useEffect } from "react";
import useAbortableFetch from "use-abortable-fetch";
import { AbortableFetchResult } from "use-abortable-fetch/release/types";
import { LicentieovereenkomstenOutput, OrganisatiesOutput } from "../../../.generated/licenties/licentiestypes";
import UserDetailsContext, { Medewerker, RequestedOrganisatie } from "../user-details-context";

export class ApiAccess {
  private organisatieId: string | undefined;
  private requestInit: RequestInit | undefined;
  private settings: any;
  constructor(organisatieId: string, requestInit: RequestInit, setting: any) {
    this.organisatieId = organisatieId;
    this.requestInit = requestInit;
    this.settings = setting;
  }
  private getOrganisatieUrl(): string | null {
    return this.organisatieId ? `${this.settings.licentiesOrigin}/Organisaties/${this.organisatieId}` : null;
  }
  private getLicentieovereenkomstenUrl(): string | null {
    return this.organisatieId
      ? `${this.settings.licentiesOrigin}/Licentieovereenkomsten?organisatieId=${this.organisatieId}`
      : null;
  }
  public GetOrganisaties(): AbortableFetchResult<OrganisatiesOutput> {
    return useAbortableFetch<OrganisatiesOutput>(this.getOrganisatieUrl(), this.requestInit);
  }
  public GetLicentieovereenkomsten(): AbortableFetchResult<LicentieovereenkomstenOutput> {
    return useAbortableFetch<LicentieovereenkomstenOutput>(this.getLicentieovereenkomstenUrl(), this.requestInit);
  }
}

export function useExtendUserDetailsWithRequestedOrganisatie(organisatieId: string): Medewerker {
  const { setUserDetails, userDetails } = useContext(UserDetailsContext);
  const { settings, requestInit } = useRequestInit();

  const apiAccess = new ApiAccess(organisatieId, requestInit, settings);

  const organisatieRequest = apiAccess.GetOrganisaties();

  const licentieovereenkomstenRequest = apiAccess.GetLicentieovereenkomsten();

  useEffect(() => {
    if (userDetails.requestedOrganisatie?.organisatieId === organisatieId) return;

    if (
      !organisatieRequest ||
      organisatieRequest.loading ||
      !licentieovereenkomstenRequest ||
      licentieovereenkomstenRequest.loading
    )
      return;

    if (organisatieRequest.error ||
      !organisatieRequest.data ||
      typeof organisatieRequest.data === "string" ||
      !organisatieRequest.data.isValid ||
      licentieovereenkomstenRequest.error ||
      !licentieovereenkomstenRequest.data ||
      typeof licentieovereenkomstenRequest.data === "string" ||
      !licentieovereenkomstenRequest.data.isValid) {
      userDetails.requestedOrganisatie = null;
      return;
    }

    const licentieovereenkomstenData = licentieovereenkomstenRequest.data.licentieovereenkomsten;

    let organisatieData: RequestedOrganisatie | null = null;
    if (
      organisatieRequest.data.organisaties
    ) {
      const actualOrganisatie = organisatieRequest.data.organisaties[organisatieId];
      if (actualOrganisatie !== null) {
        organisatieData = {
          ...actualOrganisatie,
          organisatieId: organisatieId,
          licentieovereenkomsten: licentieovereenkomstenData
        };
      }
    }

    // Deze regel hieronder klopt volgens mij niet
    userDetails.requestedOrganisatie = organisatieData;

    setUserDetails(userDetails);
  }, [userDetails, setUserDetails, organisatieRequest, licentieovereenkomstenRequest, organisatieId]);

  return userDetails;
}
