import { AuthContext } from "adviesbox-shared";
import React, { ReactElement } from "react";
import AuthenticatedLicentieBeheerApp from "./AuthenticatedLicentieBeheerApp";
import UnauthenticatedLicentieBeheerApp from "./UnauthenticatedLicentieBeheerApp";

const LicentieBeheerApp = (): ReactElement => {
  const { user } = React.useContext(AuthContext);

  if (user) {
    return <AuthenticatedLicentieBeheerApp />;
  }

  return <UnauthenticatedLicentieBeheerApp />;
};

LicentieBeheerApp.displayName = "LicentieBeheerApp";

export default LicentieBeheerApp;
