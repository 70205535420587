import { UiError } from "../../shared/types";
import { FormikContextType } from "formik";
import { RefObject } from "react";
import { formikIsValidAsync } from "./formik-is-valid-async";
import { setFormikUiErrors } from "./set-formik-ui-errors";

export type SubmitResultTypes = "platformError" | "Exception" | "clientError" | "default" | "completed";

export async function submit(
  saveData: (values: any) => Promise<UiError[] | null>,
  formik: FormikContextType<any>,
  berekenSideEffectRef?: RefObject<{ asyncResult: Promise<unknown> }>,
  callBack?: () => void
): Promise<SubmitResultTypes> {
  if (formik.dirty) {
    //Client validatie
    formik.setSubmitting(true);
    if (await formikIsValidAsync(formik)) {
      // Server validatie & Save
      try {
        formik.setFieldValue("platformApiFouten", null, false);
        berekenSideEffectRef && berekenSideEffectRef.current && (await berekenSideEffectRef.current.asyncResult);

        const result = await saveData(formik.values);

        if (result) {
          setFormikUiErrors(result, formik);
          return "platformError";
        }

        formik.resetForm({ values: { ...formik.values, platformApiFouten: null } });
        callBack && callBack();
        return "completed";
      } catch (e) {
        // TODO: Foutafhandeling
        /* istanbul ignore next */
        /* eslint-disable-next-line no-console */
        process.env.NODE_ENV === "development" && console.error(e);
        return "Exception";
      } finally {
        formik.setSubmitting(false);
      }
    } else {
      formik.setSubmitting(false);
      return "clientError";
    }
  }
  return "default";
}
