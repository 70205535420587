import React, { ReactElement } from "react";
import { LabeledTextInput } from "adviesbox-shared";
import { connect, FormikContextType } from "formik";
import { GebruikersgroepenState } from "../infra/gebruikersgroepen-schema";
import { UserProps } from "../../shared/properties/user-props";
import {
  Gebruikersactie,
  MagActieUitvoeren,
  Matrixonderdeel
} from "../../shared/autorisatie-matrix/autorisatie-matrix";

type GebruikersgroepProps = { selected: number };

const Gebruikersgroep = ({
  selected,
  formik: { values },
  user,
  userDetails
}: {
  formik: FormikContextType<GebruikersgroepenState>;
} & GebruikersgroepProps &
  UserProps): ReactElement => {
  const magLezen = MagActieUitvoeren(Matrixonderdeel.Gebruikersgroepen, Gebruikersactie.Lezen, user, userDetails);
  const magSchrijven = MagActieUitvoeren(
    Matrixonderdeel.Gebruikersgroepen,
    Gebruikersactie.Schrijven,
    user,
    userDetails
  );

  const isReadOnly = magLezen && !magSchrijven;

  const deactivateFirstBeheerderInstance =
    values.gebruikersgroepen?.gebruikersgroep[selected]?.groepsNaam === "Beheerder" &&
    (values.gebruikersgroepen?.gebruikersgroep[selected]?.productOnderdelen["Instellingen"]?.gekoppeld === true ||
      values.gebruikersgroepen?.gebruikersgroep[selected]?.productOnderdelen["Contract"]?.gekoppeld === true);
  return (
    <>
      <LabeledTextInput
        name={`gebruikersgroepen.gebruikersgroep[${selected}].groepsNaam`}
        caption="Groepsnaam"
        disabled={deactivateFirstBeheerderInstance}
        verplicht={true}
        readOnly={isReadOnly}
      />
      <LabeledTextInput
        name={`gebruikersgroepen.gebruikersgroep[${selected}].beschrijving`}
        caption="Beschrijving"
        readOnly={isReadOnly}
      />
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") Gebruikersgroep.displayName = "Gebruikersgroep";
export default connect<GebruikersgroepProps & UserProps, GebruikersgroepenState>(Gebruikersgroep);
