import { createContext } from "react";
import {
  Medewerker as M,
  Organisatie as O,
  Geslacht,
  Licentieovereenkomst as L
} from "../../.generated/licenties/licentiestypes";

export interface Medewerker extends M {
  medewerkerId: string;
  organisatieId: string;
  requestedOrganisatie?: RequestedOrganisatie | null;
}

export interface RequestedOrganisatie extends O {
  organisatieId: string;
  licentieovereenkomsten: { [key: string]: Licentieovereenkomst } | null;
}

export type Licentieovereenkomst = L;

export const defaultUserDetails = {
  userDetails: {
    voornaam: "",
    tussenvoegsels: "",
    achternaam: "",
    roepnaam: null,
    voorletters: "",
    telefoon: null,
    telefoonWerk: null,
    telefoonMobiel: null,
    geslacht: Geslacht.Man,
    email: "",
    functie: null,
    platformId: null,
    klantId: null,
    isActief: false,
    klantdossierRechtenVestigingen: null,
    klantdossierRechtenOrganisatie: null,
    medewerkerId: "",
    organisatieId: "",
    requestedOrganisatie: {
      iban: "",
      kvKnummer: "",
      logo: "",
      naam: "",
      organisatieId: "",
      platformId: null,
      licentieovereenkomsten: {}
    }
  } as Medewerker,
  setUserDetails: (_userDetails: Medewerker): void => {}
};

const UserDetailsContext = createContext(defaultUserDetails);

export default UserDetailsContext;
