import {
  DataGrid,
  FilterType,
  PostcodeInput,
  SettingsType,
  TextInput,
  createSpanWithId,
  hasValue,
  useRequestInit
} from "adviesbox-shared";
import { FormikContextType, FormikProps, connect } from "formik";
import React, { Dispatch, ReactElement, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { SearchCategories, ZoekResultaatOrganisatieType, ZoekschermState } from "../infra";

import { Column } from "react-table-6";
import { Modal as DisplayModal } from "react-bootstrap";
import Modal from "../../shared/components/modal/Modal";
import { PaginationComponent } from "../../shared/components/pagination/pagination";
import { User } from "oidc-client";
import { ZoekenDropdown } from "../zoeken-dropdown/zoeken-dropdown";
import { Zoekresultaat } from "../../.generated/licenties/licentiestypes";
import classes from "./zoeken.module.scss";
import classnames from "classnames";
import { deleteOrganisatie } from "./zoeken-helpers/zoekscherm-api";
import { searchOrganisaties } from "../infra/zoekscherm-api";
import { SleepPromise, sleep } from "../../shared/utils/helpers";

type ZoekenPropType = {
  showTimeoutWarningInitially?: boolean;
  disableTimeout?: boolean;
  fakeTimeoutFn?(): void;
  timeouttime?: number;
  childErrorSetter: Dispatch<number | null>;
};

export const mapZoekresultaten = (resultaten: Zoekresultaat[] | null): ZoekResultaatOrganisatieType[] => {
  if (!resultaten || !resultaten?.length) return [];

  return resultaten.map(v => {
    return {
      platformId: v.organisatie && v.organisatie.platformId,
      organisatieId: v.organisatie && v.organisatie.organisatieId,
      logo: v.organisatie && v.organisatie.logo,
      kvknummer: v.organisatie?.kvKnummer ?? "",
      iban: v.organisatie?.iban ?? "",
      postcode: v.hoofdvestiging?.bezoekadres?.postcode ?? "",
      woonplaats: v.hoofdvestiging?.bezoekadres?.plaats ?? "",
      naamOrganisatie: v.organisatie?.naam ?? ""
    };
  });
};

export const onSubmitDelete = async (
  selectedOrganisatie: ZoekResultaatOrganisatieType | null,
  setFieldValue: FormikProps<ZoekschermState>["setFieldValue"],
  setDeleteIsClicked: React.Dispatch<React.SetStateAction<boolean>>,
  settings: SettingsType,
  user: User | null
): Promise<void> => {
  const orgId = selectedOrganisatie?.organisatieId;
  const deleted = await deleteOrganisatie(orgId, settings, user);
  if (deleted && hasValue(orgId)) {
    setFieldValue("deletedOrganisatieId", orgId);
  }
  setDeleteIsClicked(false);
};

const Zoeken = ({
  disableTimeout = false,
  timeouttime,
  fakeTimeoutFn,
  formik: { values, setFieldValue, setFieldError },
  history,
  childErrorSetter
}: ZoekenPropType & {
  formik: FormikContextType<ZoekschermState>;
} & RouteComponentProps): ReactElement => {
  const { settings, user } = useRequestInit();
  const [abortControllerSearch, setAbortControllerSearch] = useState(new AbortController());
  const [selectedOrganisatie, setSelectedOrganisatie] = useState<ZoekResultaatOrganisatieType | null>(null);
  const [loading, setLoading] = useState(false);
  const [deleteIsClicked, setDeleteIsClicked] = useState(false);
  const [initialRequest, setInitialRequest] = useState(true);
  const maximumTimeout = hasValue(timeouttime) ? timeouttime : 180; // in seconds (currently 3 minutes while platform fixes their share.)
  const [showTimeoutWarning, setShowTimeoutWarning] = useState(false);

  const searchValueLengte = values.zoeken?.searchValue?.replace(/_|\s/g, "").length;
  const invalidPostcode = values.zoeken.searchCategory === SearchCategories.Postcode && searchValueLengte !== 6;
  const invalidKvKnummer =
    values.zoeken.searchCategory === SearchCategories.KVKnummer && isNaN(parseInt(values.zoeken?.searchValue));

  const ZoekResultatenColumns: Column[] = [
    {
      headerStyle: { overflow: "initial" },
      Header: "Organisatie",
      accessor: "naamOrganisatie",
      id: "naamOrganisatie",
      Cell: (c): ReactElement =>
        createSpanWithId(c.index, 1, c.original.naamOrganisatie, c.original.naamOrganisatie, "zoekresultaat")
    },
    {
      headerStyle: { overflow: "initial" },
      Header: "Postcode",
      accessor: "postcode",
      id: "postcode",
      Cell: (c): ReactElement => createSpanWithId(c.index, 2, c.original.postcode, c.original.postcode, "zoekresultaat")
    },
    {
      headerStyle: { overflow: "initial" },
      Header: "Woonplaats",
      accessor: "woonplaats",
      id: "woonplaats",
      Cell: (c): ReactElement =>
        createSpanWithId(c.index, 3, c.original.woonplaats, c.original.woonplaats, "zoekresultaat")
    },
    {
      headerStyle: { overflow: "initial" },
      Header: "KVK-nummer",
      accessor: "kvknummer",
      id: "kvknummer",
      Cell: (c): ReactElement =>
        createSpanWithId(c.index, 4, c.original.kvknummer, c.original.kvknummer, "zoekresultaat")
    }
  ];

  let s: SleepPromise | null = null;
  const getMatchingOrganisaties = async (): Promise<void> => {
    if (s !== null) s.abort();
    if (
      !user ||
      ((!values.zoeken.searchValue || values.zoeken?.searchValue?.length < 2) &&
        values.zoeken.searchCategory !== SearchCategories.KVKnummer) ||
      invalidPostcode ||
      invalidKvKnummer
    )
      return;

    setLoading(true);
    setInitialRequest(false);
    setShowTimeoutWarning(false);
    !abortControllerSearch.signal.aborted && abortControllerSearch.abort();

    const newAbortController = new AbortController();
    const timeoutFn = (): void => {
      !abortControllerSearch.signal.aborted && abortControllerSearch.abort();
      setShowTimeoutWarning(true);
      setLoading(false);
    };
    const catchedTimeOutFnException = (): void => {
      !abortControllerSearch.signal.aborted && abortControllerSearch.abort();
      setShowTimeoutWarning(false);
      setLoading(false);
    };

    setAbortControllerSearch(newAbortController);

    if (!disableTimeout) {
      s = sleep(maximumTimeout * 1000);
      s.then(fakeTimeoutFn || timeoutFn).catch(catchedTimeOutFnException);
    }

    const result = await searchOrganisaties(
      values.zoeken.searchValue,
      values.zoeken.searchCategory,
      settings,
      user,
      newAbortController.signal
    );

    if (result && "code" in result) {
      childErrorSetter(result.code);
      return;
    }

    if (!showTimeoutWarning) {
      if (s !== null) s.abort();
      const mappedResult = mapZoekresultaten(result);
      setLoading(false);
      setFieldValue("zoeken.zoekresultaten", mappedResult);
    }
  };

  const resetResults = (): void => {
    setInitialRequest(true);
    setFieldValue("zoeken.zoekresultaten", []);
  };

  const keyUpEvent = (event?: KeyboardEvent | React.KeyboardEvent<HTMLDivElement>): void => {
    if (event && event.key !== "Enter") {
      return;
    }

    /* eslint-disable-next-line @typescript-eslint/no-floating-promises */
    getMatchingOrganisaties();
  };

  const handleClick = (
    zoekresultaat: ZoekResultaatOrganisatieType,
    _col: Column,
    _index: number
  ): {
    onClick: () => void;
  } => ({
    onClick: (): void => {
      setSelectedOrganisatie(zoekresultaat);
      if (!deleteIsClicked) {
        history.push(`/organisatie/${zoekresultaat.organisatieId}/contract`);
      }
    }
  });

  return (
    <div className="container">
      <div className="row">
        <div className={`flex offset-2 col-8 d-flex flex-row`}>
          <ZoekenDropdown />
          <div className={"d-flex flex-row w-100"} onKeyUp={event => keyUpEvent(event)}>
            {(values.zoeken.searchCategory === SearchCategories.Naam ||
              values.zoeken.searchCategory === SearchCategories.KVKnummer ||
              values.zoeken.searchCategory === SearchCategories.Woonplaats) && (
              <TextInput
                autoComplete="off"
                errorMessageClassName={classes.search_error}
                name="zoeken.searchValue"
                fullWidth={true}
                className={classes.search_input}
                placeholder="Bestaande organisatie zoeken"
              />
            )}
            {values.zoeken.searchCategory === SearchCategories.Postcode && (
              <PostcodeInput
                name="zoeken.searchValue"
                className={classes.search_input}
                errorMessageClassName={classes.search_error}
                fullWidth={true}
                data-testid={"zoeken.postcode"}
                placeholder="Bestaande organisatie zoeken"
              />
            )}
          </div>
          <button
            id="zoeken"
            data-testid={"zoeken.action"}
            type="button"
            onClick={getMatchingOrganisaties}
            className={classnames(
              "btn btn-primary ml-4",
              classes.btn_search,
              `${
                values.zoeken?.searchValue?.length === 1 ||
                (values.zoeken?.searchValue?.length >= 1 && invalidKvKnummer) ||
                (values.zoeken?.searchValue?.length >= 1 && invalidPostcode)
                  ? "disabled"
                  : ""
              }`
            )}
          >
            Zoeken
          </button>
        </div>

        {(loading || !initialRequest || values.zoeken.zoekresultaten.length > 0) && (
          <div className="col-12 mt-4">
            <div className={classnames("card card-adviesbox", classes.searchcard)}>
              <button
                type="button"
                className={classnames(classes.button_container, "close")}
                onClick={resetResults}
                id="zoekresultaten-verwijdere"
                data-testid="zoekresultaten-verwijdere"
                title="zoekresultaten-verwijderen"
              >
                <span aria-hidden="true">×</span>
                <span className="sr-only">Close</span>
              </button>
              {loading && <div className="loader"></div>}
              {!loading && values.zoeken.zoekresultaten.length > 0 && (
                <div>
                  <div className="flex mb-3">
                    <h3>
                      {values.zoeken.zoekresultaten.length} organisatie
                      {values.zoeken.zoekresultaten.length > 1 ? "s" : ""} gevonden
                    </h3>
                  </div>
                  <DataGrid
                    name="zoeken.zoekresultaten"
                    sortable={true}
                    initialSort="naamOrganisatie"
                    filterable={true}
                    filterConfig={{
                      naamOrganisatie: FilterType.Text,
                      postcode: FilterType.Text,
                      woonplaats: FilterType.Text,
                      kvknummer: FilterType.Text
                    }}
                    className={"search-table"}
                    minRows={0}
                    loading={false}
                    defaultPageSize={10}
                    resizable={false}
                    columns={ZoekResultatenColumns}
                    showPagination={true}
                    getTdProps={(
                      _state: any,
                      rowInfo: any,
                      col: any
                    ): {
                      onClick: () => void;
                    } => handleClick(rowInfo.original, col, rowInfo.index)}
                    PaginationComponent={PaginationComponent(false)}
                  />
                </div>
              )}

              {!loading && !initialRequest && values.zoeken.zoekresultaten.length === 0 && (
                <div className={classes.no_result}>Geen organisatie gevonden</div>
              )}
            </div>
          </div>
        )}
        {!loading && !initialRequest && !showTimeoutWarning && values.zoeken.zoekresultaten.length === 0 && (
          <div className={classes.no_result}>Geen klanten gevonden</div>
        )}
        {showTimeoutWarning && (
          <div className={classes.no_result} data-testid="zoekresultaat.geen">
            Het zoeken naar resultaten duurde te lang. Maak alsjeblieft je zoektermen specifieker en probeer het
            opnieuw.
          </div>
        )}
        <DisplayModal show={deleteIsClicked}>
          <Modal
            onSubmitClick={async () =>
              onSubmitDelete(selectedOrganisatie, setFieldValue, setDeleteIsClicked, settings, user)
            }
            onCancelClick={() => setDeleteIsClicked(false)}
            title={`Weet u zeker dat u deze klant wilt verwijderen?`}
            saveButtonTekst={`Klant en onderliggende dossiers verwijderen`}
            body={
              <p className="px-2">
                Weet u zeker dat u {selectedOrganisatie?.naamOrganisatie} wilt verwijderen? Alle onderliggende dossiers
                worden verwijderd.
              </p>
            }
          />
        </DisplayModal>
      </div>
    </div>
  );
};

export default connect<ZoekenPropType, ZoekschermState>(withRouter(Zoeken));
