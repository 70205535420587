import classNames from "classnames";
import React, { ReactElement } from "react";
import { Button } from "react-bootstrap";
import { StepProps } from "../organisatie-registreren";
import classes from "../registreren.module.scss";
export const BackupCodes = ({ currentStep, setCurrentStep }: StepProps): ReactElement => {
  return (
    <>
      <h2 className={classNames(classes.title)}>Backup codes</h2>

      <p className={"px-3 pb-5"}>
        Als je de toegang tot de tweede factor kwijtraakt dan kun je toegang tot je account verkrijgen via onderstaande
        backup codes. Zorg ervoor dat je deze codes veilig opslaat op een plek waar alleen jij toegang to hebt.
      </p>
      <div className={"row font-weight-bold text-center pb-5"}>
        <div className={"col-4"}>4023-9887</div>
        <div className={"col-4"}>4157-4466</div>
        <div className={"col-4"}>8787-4655</div>
        <div className={"col-4"}>2155-5674</div>
        <div className={"col-4"}>4023-9887</div>
        <div className={"col-4"}>4023-9887</div>
      </div>
      <Button
        className={"my-3 mx-1"}
        variant={"light"}
        onClick={() => {
          setCurrentStep(currentStep - 1);
        }}
      >
        Terug
      </Button>
      <Button
        className={"float-right m-3"}
        onClick={() => {
          setCurrentStep(currentStep + 1);
        }}
      >
        Volgende
      </Button>
    </>
  );
};
