import { FormikContextType, setIn, setNestedObjectValues } from "formik";
import React, { Dispatch, ReactElement, SetStateAction, SyntheticEvent, useMemo, useRef, useState } from "react";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import {
  CardWrapper,
  Card,
  DataGrid,
  AdviesBoxColumn,
  Icon,
  LabeledTextInput,
  LabeledRadioInput,
  useAdviesboxDataRepository,
  useRequestInit,
  PlatformFoutenSamenvatting,
  FetchDataButton
} from "adviesbox-shared";
import { SaveButtonNoSaveData } from "../shared/components/save-button/save-button-no-save-data";
import {
  HdnNodenummer as HdnNodenummerInput,
  HdnNodenummerGeldigVoorOptions,
  HdnNodenummersOutput,
  NewOutput,
  VestigingenOutput
} from "../.generated/licenties/licentiestypes";
import {
  hdnNodenummerEntrySchema,
  HdnNodenummerEntryWrapper,
  hdnNodenummerEntryWrapperSchema,
  HdnNodenummerState,
  HdnNodenummerStateSchema,
  HdnNodenummerVestiging
} from "./infra/HdnNodenummer-schema";
import { DevDebug } from "../shared/components/dev-debug/dev-debug";
import { HdnNodenummerdl2ui, mapHdnNodenummerDlTargetToUiField } from "./infra/map-HdnNodenummer-dl-2-ui";
import { HdnNodenummerSyncSideEffects } from "./infra/HdnNodenummer-sync-side-effects";
import { ISWSideEffects } from "../shared/components/isw-side-effects/isw-side-effects";
import { CellInfo } from "react-table-6";
import { PaginationComponent } from "../shared/components/pagination/pagination";
import { LabeledTagInput } from "../medewerker/tag-input-component/labeled-tag-input";
import { HdnNodenummerDeleteModal } from "./hdn-nodenummer-delete-modal/hdn-nodenummer-delete-modal";
import { HdnNodenummerAanpassingenModal } from "./hdn-nodenummer-aanpassingen-modal/hdn-nodenummer-aanpassingen-modal";
import { UserProps } from "../shared/properties/user-props";
import { Gebruikersactie, MagActieUitvoeren, Matrixonderdeel } from "../shared/autorisatie-matrix/autorisatie-matrix";

type RouteParams = {
  organisatie: string;
};

const HdnNodenummerGeldigVoorDataGridComponent = (c: CellInfo): ReactElement => {
  const vestigingenString =
    c.original.current.geldigVoor === HdnNodenummerGeldigVoorOptions.Vestiging
      ? c.original.current.vestigingen?.map((v: HdnNodenummerVestiging) => v.naam).join(",\xa0")
      : "Organisatie";
  return <div className="w-100">{vestigingenString}</div>;
};

const HdnNodenummerVerwijderenDataGridComponent = ({
  setShowDeleteModal,
  setFieldValue,
  selected,
  setSelected,
  values,
  magVerwijderen
}: {
  setShowDeleteModal: Dispatch<SetStateAction<boolean>>;
  selected: number;
  setFieldValue: FormikContextType<HdnNodenummerState>["setFieldValue"];
  values: HdnNodenummerState;
  setSelected: Dispatch<SetStateAction<number>>;
  magVerwijderen: boolean;
}): ((c: CellInfo) => ReactElement) => (c): ReactElement => {
  const click = (e: SyntheticEvent<HTMLDivElement, MouseEvent>): void => {
    e.stopPropagation();
    if (!values.hdnNodenummerEntries[c.index].current.isNieuw) {
      setShowDeleteModal(true);
      return;
    }

    const update = [...values.hdnNodenummerEntries];
    update.splice(c.index, 1);

    if (selected >= update.length) {
      setSelected(update.length - 1);
    }

    setFieldValue("hdnNodenummerEntries", update);
    setFieldValue("platformApiFouten", null);
  };

  if (!magVerwijderen) return <></>;

  return (
    <div
      data-testid={`delete-hdnnodenummer-record-${c.index}`}
      onClick={click}
      className="d-flex align-items-center justify-content-center w-100"
    >
      <Icon name="prullenbak" />
    </div>
  );
};

const HdnNodenummerColumns = (options: {
  setShowDeleteModal: Dispatch<SetStateAction<boolean>>;
  selected: number;
  setFieldValue: FormikContextType<HdnNodenummerState>["setFieldValue"];
  values: HdnNodenummerState;
  setSelected: Dispatch<SetStateAction<number>>;
  magVerwijderen: boolean;
}): AdviesBoxColumn[] => [
  {
    Header: "Nodenummer",
    accessor: "current.nodeNummer",
    id: "nodeNummer"
  },
  {
    Header: "Geldig voor",
    id: "vestigingen",
    Cell: HdnNodenummerGeldigVoorDataGridComponent
  },
  {
    Cell: HdnNodenummerVerwijderenDataGridComponent(options),
    width: 30
  }
];

const HdnNodenummerComponent = ({
  values,
  setFieldValue,
  touched,
  setTouched
}: FormikContextType<HdnNodenummerState & UserProps>): ReactElement => {
  const [selected, setSelected] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAanpassingenModal, setShowAanpassingenModal] = useState(false);
  const { settings, params } = useRequestInit<RouteParams>();
  const triggeredByModal = useRef<{ selection: number; isTriggered: boolean }>({
    selection: 0,
    isTriggered: false
  });

  const magSchrijven = MagActieUitvoeren(
    Matrixonderdeel.HdnNodenummers,
    Gebruikersactie.Schrijven,
    values.user,
    values.userDetails
  );
  const magToevoegen = MagActieUitvoeren(
    Matrixonderdeel.HdnNodenummers,
    Gebruikersactie.Toevoegen,
    values.user,
    values.userDetails
  );
  const magVerwijderen = MagActieUitvoeren(
    Matrixonderdeel.HdnNodenummers,
    Gebruikersactie.Verwijderen,
    values.user,
    values.userDetails
  );
  const magActiveren = MagActieUitvoeren(
    Matrixonderdeel.HdnNodenummersActiveren,
    Gebruikersactie.Uitvoeren,
    values.user,
    values.userDetails
  );
  const magLezen =
    magSchrijven ||
    magToevoegen ||
    magVerwijderen ||
    magActiveren ||
    MagActieUitvoeren(Matrixonderdeel.HdnNodenummers, Gebruikersactie.Lezen, values.user, values.userDetails);

  const selectedHdnEntry = values.hdnNodenummerEntries[selected]?.current ?? null;

  const { fetchData: updateHdnNodenummer } = useAdviesboxDataRepository<void, HdnNodenummerState>(
    `${settings.licentiesOrigin}/HdnNodenummers/${selectedHdnEntry?.HdnNodenummerId}`,
    {
      mapTargetToUiField: mapHdnNodenummerDlTargetToUiField(selected),
      mapUiToDl: /* istanbul ignore next */ (): HdnNodenummerInput => ({
        geactiveerd: selectedHdnEntry.actief,
        geldigVoor: selectedHdnEntry.geldigVoor,
        nodenummer: selectedHdnEntry.nodeNummer,
        vestigingen: selectedHdnEntry.vestigingen.map(v => ({ naam: v.naam, vestigingId: v.id }))
      }),
      mapDlToUi: /* istanbul ignore next */ () => {
        const updatedEntry = { ...selectedHdnEntry, isNieuw: false, hasChanges: false };
        setFieldValue(`hdnNodenummerEntries[${selected}]`, { initial: updatedEntry, current: updatedEntry });
        return null;
      },
      method: "PUT"
    }
  );

  const { fetchData: deleteHdnNodenummer } = useAdviesboxDataRepository<void, HdnNodenummerState>(
    `${settings.licentiesOrigin}/HdnNodenummers/${selectedHdnEntry?.HdnNodenummerId}`,
    {
      mapTargetToUiField: mapHdnNodenummerDlTargetToUiField(selected),
      mapUiToDl: /* istanbul ignore next */ () => {},
      mapDlToUi: /* istanbul ignore next */ () => {
        const update = [...values.hdnNodenummerEntries];
        update.splice(selected, 1);
        setFieldValue("hdnNodenummerEntries", update);

        if (update.length <= selected) {
          setSelected(update.length - 1);
        }

        return null;
      },
      method: "DELETE"
    }
  );

  const { fetchData: activateHdnNodenummer, loading: activateHdnNodenummerLoading } = useAdviesboxDataRepository<
    void,
    HdnNodenummerState
  >(`${settings.licentiesOrigin}/HdnNodenummers/${selectedHdnEntry?.HdnNodenummerId}/Activeren`, {
    mapTargetToUiField: mapHdnNodenummerDlTargetToUiField(selected),
    mapUiToDl: /* istanbul ignore next */ () => {},
    mapDlToUi: /* istanbul ignore next */ () => {
      const updatedEntry = { ...selectedHdnEntry, actief: true };
      setFieldValue(`hdnNodenummerEntries[${selected}]`, { current: updatedEntry, initial: updatedEntry });
      return null;
    },
    method: "POST"
  });

  const { fetchData: createHdnNodenummer } = useAdviesboxDataRepository<NewOutput, HdnNodenummerState>(
    `${settings.licentiesOrigin}/Organisaties/${params.organisatie}/HdnNodenummers`,
    {
      mapTargetToUiField: mapHdnNodenummerDlTargetToUiField(selected),
      mapUiToDl: /* istanbul ignore next */ (): HdnNodenummerInput => ({
        geactiveerd: selectedHdnEntry.actief,
        geldigVoor: selectedHdnEntry.geldigVoor,
        nodenummer: selectedHdnEntry.nodeNummer,
        vestigingen: selectedHdnEntry.vestigingen.map(v => ({ naam: v.naam, vestigingId: v.id }))
      }),
      mapDlToUi: /* istanbul ignore next */ (_, values) => {
        const updatedEntry = { ...selectedHdnEntry, HdnNodenummerId: values?.id, isNieuw: false, hasChanges: false };
        setFieldValue(`hdnNodenummerEntries[${selected}]`, { current: updatedEntry, initial: updatedEntry });
        return null;
      },
      method: "POST"
    }
  );

  const setSelectedChecked = useMemo(
    () => (selection: React.SetStateAction<number>): void => {
      triggeredByModal.current.selection = typeof selection === "number" ? Number(selection) : 0;

      if (triggeredByModal.current.isTriggered) {
        triggeredByModal.current.isTriggered = false;
        return;
      }

      if (selectedHdnEntry?.hasChanges || selectedHdnEntry?.isNieuw) {
        setShowAanpassingenModal(true);
        return;
      }

      setSelected(selection);
    },
    [setSelected, selectedHdnEntry, setShowAanpassingenModal]
  );

  const saveValues = async (): Promise<void> => {
    triggeredByModal.current.isTriggered = true;
    const isValid = await hdnNodenummerEntrySchema.isValid(selectedHdnEntry);

    /* istanbul ignore next */
    if (!isValid) {
      const rowTouched = setNestedObjectValues(selectedHdnEntry, true);
      const touchedUpdate = setIn(touched, `hdnNodenummerEntries[${selected}].current`, rowTouched);
      setTouched(touchedUpdate);
      return;
    }

    if (selectedHdnEntry.isNieuw) {
      createHdnNodenummer();
      return;
    }

    updateHdnNodenummer();
  };

  if (!magLezen) return <></>;

  const magOpslaan = magSchrijven || magToevoegen;

  return (
    <div>
      <ISWSideEffects<HdnNodenummerState> sync={HdnNodenummerSyncSideEffects({ selected })} />
      <CardWrapper className="px-3">
        <div className="text-container">
          <h2>HDN nodenummers</h2>
        </div>
      </CardWrapper>
      <CardWrapper className="px-3" maxRowCount={1}>
        <Card>
          <DataGrid
            masterDetail
            name="hdnNodenummerEntries"
            pageSize={values.hdnNodenummerEntriesInView}
            rowCaption="HDN nodenummer"
            rowSelected={[selected, setSelectedChecked]}
            captionToLowerCase={false}
            columns={HdnNodenummerColumns({
              setShowDeleteModal,
              selected,
              values,
              setFieldValue,
              setSelected,
              magVerwijderen
            })}
            showPagination={true}
            validationSchema={hdnNodenummerEntryWrapperSchema}
            PaginationComponent={PaginationComponent(true, "hdnNodenummerEntriesInView", "HDN nodenummers")}
            showButtonAddRow={magToevoegen}
            getNewRowValues={(): HdnNodenummerEntryWrapper => ({
              current: hdnNodenummerEntrySchema.default(),
              initial: hdnNodenummerEntrySchema.default()
            })}
          />
        </Card>
      </CardWrapper>
      {selectedHdnEntry && (
        <>
          {magOpslaan && (
            <CardWrapper maxRowCount={1} className="pl-4 py-1">
              <div className="button-container">
                <SaveButtonNoSaveData
                  isEdited={selectedHdnEntry?.hasChanges || selectedHdnEntry?.isNieuw}
                  callBack={saveValues}
                />
              </div>
            </CardWrapper>
          )}

          <CardWrapper maxRowCount={1} className="py-1 pl-1">
            <PlatformFoutenSamenvatting />
          </CardWrapper>
          <CardWrapper className="px-3">
            <Card title="HDN nodenummer">
              <LabeledTextInput
                verplicht={true}
                name={`hdnNodenummerEntries[${selected}].current.nodeNummer`}
                caption={"HDN nodenummer"}
                readOnly={!magOpslaan}
              />
            </Card>

            <Card title="Geldig voor">
              <LabeledRadioInput
                name={`hdnNodenummerEntries[${selected}].current.geldigVoor`}
                caption={"Geldig voor"}
                options={[
                  {
                    label: "Vestiging",
                    value: HdnNodenummerGeldigVoorOptions.Vestiging
                  },
                  {
                    label: "Organisatie",
                    value: HdnNodenummerGeldigVoorOptions.Organisatie
                  }
                ]}
                readOnly={!magOpslaan}
              />
              {selectedHdnEntry.geldigVoor === HdnNodenummerGeldigVoorOptions.Vestiging && (
                <LabeledTagInput
                  name={`hdnNodenummerEntries[${selected}].current.vestigingen`}
                  caption="Vestiging(en)"
                  options={values.vestigingenOptions}
                  editable={magOpslaan}
                />
              )}
              {magActiveren && (
                <div className={"pt-5 d-flex justify-content-center"}>
                  <FetchDataButton
                    initialText={"Activeer HDN"}
                    loading={activateHdnNodenummerLoading}
                    keepVisible={true}
                    dataOutDated={!selectedHdnEntry.actief || !selectedHdnEntry.isNieuw}
                    invalid={selectedHdnEntry.actief || selectedHdnEntry.isNieuw}
                    onClick={activateHdnNodenummer}
                  />
                </div>
              )}
            </Card>
          </CardWrapper>

          <HdnNodenummerDeleteModal
            showModal={showDeleteModal}
            onCancelClick={() => setShowDeleteModal(false)}
            onSubmit={() => {
              setShowDeleteModal(false);
              deleteHdnNodenummer();
            }}
          />
          <HdnNodenummerAanpassingenModal
            showModal={showAanpassingenModal}
            onCancelClick={() => {
              triggeredByModal.current.isTriggered = true;
              setShowAanpassingenModal(false);

              /* istanbul ignore next */
              if (
                selected !== values.hdnNodenummerEntries.length - 1 &&
                selectedHdnEntry.isNieuw &&
                values.hdnNodenummerEntries[values.hdnNodenummerEntries.length - 1].current.isNieuw &&
                !values.hdnNodenummerEntries[values.hdnNodenummerEntries.length - 1].current.hasChanges
              ) {
                const update = [...values.hdnNodenummerEntries];
                update.splice(update.length - 1, 1);
                setFieldValue(`hdnNodenummerEntries`, update);
              }
            }}
            onSubmitClick={async () => {
              await saveValues();
              setShowAanpassingenModal(false);
            }}
            onRevertClick={() => {
              setShowAanpassingenModal(false);

              if (values.hdnNodenummerEntries[selected].initial.isNieuw && selectedHdnEntry.isNieuw) {
                const update = [...values.hdnNodenummerEntries];
                update.splice(selected, 1);

                if (update[update.length - 1].initial.isNieuw) {
                  update.splice(update.length - 1, 1);
                }

                const nextSelected =
                  triggeredByModal.current.selection > update.length - 1
                    ? update.length - 1
                    : triggeredByModal.current.selection;

                setFieldValue(`hdnNodenummerEntries`, update);
                setFieldValue(`platformApiFouten`, null);
                setSelected(nextSelected);
                return;
              }

              setFieldValue(`hdnNodenummerEntries[${selected}].current`, values.hdnNodenummerEntries[selected].initial);
            }}
          />
        </>
      )}
      {/* istanbul ignore next */ process.env.NODE_ENV === "development" && <DevDebug />}
    </div>
  );
};

export const HdnNodenummer = withAdviesboxFormik<
  HdnNodenummersOutput & VestigingenOutput & UserProps,
  HdnNodenummerState & UserProps
>({
  mapPropsToValues: HdnNodenummerdl2ui,
  validationSchema: HdnNodenummerStateSchema
})(HdnNodenummerComponent);
