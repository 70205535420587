import { EmailInGebruikOutput, Medewerker, NewOutput } from "../../.generated/licenties/licentiestypes";
import { SettingsType, hasJSONResponse } from "adviesbox-shared";

import { FormikContextType } from "formik";
import { MedewerkerState, OrganisatieLicentiesType } from "./medewerkers-schema";
import { UiError } from "../../shared/types";
import { User } from "oidc-client";
import { mapDlTargetToMedewerkersUiField } from "./map-medewerkers-dl-target-to-ui-field";
import { validationResultsToUiError } from "../../shared/utils/validation-results-to-ui-error";

export const emailInGebruikApi = async (
  settings: SettingsType,
  user: User | null,
  EmailInGebruik: string
): Promise<EmailInGebruikOutput> => {
  const url = `${settings.licentiesOrigin}/Medewerkers/EmailInGebruik/${EmailInGebruik}`;

  const rsp = await fetch(url, {
    method: "GET",
    headers: {
      authorization: `${user?.token_type} ${user?.access_token}`,
      "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
      "Content-Type": "application/json;charset=UTF-8"
    }
  });

  if (!(rsp.ok && hasJSONResponse(rsp))) {
    throw Error(`Fout bij aanroep van web-api '${url}'. Resultaat bevat geen JSON.`);
  }

  const json = await rsp.json();

  if (!json.isValid) {
    throw Error(`Fout bij aanroep van web-api '${url}'. Input is niet valid.`);
  }
  return json;
};

export const saveNewMedewerkerApi = async (
  settings: SettingsType,
  user: User | null,
  organisatieId: string,
  medewerker: Medewerker | null,
  setFieldValue: FormikContextType<MedewerkerState>["setFieldValue"]
): Promise<NewOutput | UiError[]> => {
  const url = `${settings.licentiesOrigin}/Organisaties/${organisatieId}/Medewerkers`;

  if (!medewerker) {
    throw Error(`Medewerker is leeg.`);
  }

  const rsp = await fetch(url, {
    method: "POST",
    headers: {
      authorization: `${user?.token_type} ${user?.access_token}`,
      "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
      "Content-Type": "application/json;charset=UTF-8"
    },
    body: JSON.stringify(medewerker)
  });

  if (!hasJSONResponse(rsp)) {
    throw Error(`Fout bij aanroep van web-api '${url}'. Resultaat bevat geen JSON.`);
  }
  const json = await rsp.json();
  if ((!rsp.ok || !json.isValid) && json.validationResults) {
    const errors = validationResultsToUiError(json.validationResults, mapDlTargetToMedewerkersUiField);
    setFieldValue("platformApiFouten", errors);
    return errors;
  }
  setFieldValue("platformApiFouten", null);
  return json;
};

export const createLicentiesNewMedewerkerApi = async (
  settings: SettingsType,
  user: User | null,
  medewerkerId: string,
  licentie: OrganisatieLicentiesType
): Promise<void> => {
  const url = `${settings.licentiesOrigin}/Licenties/${licentie.licentieId}`;
  try {
    const rsp = await fetch(url, {
      method: "PUT",
      headers: {
        authorization: `${user?.token_type} ${user?.access_token}`,
        "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
        "Content-Type": "application/json;charset=UTF-8"
      },
      body: JSON.stringify({
        platformId: "",
        medewerkerId: medewerkerId,
        productsamenstellingId: licentie.productsamenstellingId,
        licentieovereenkomstId: licentie.licentieovereenkomstId
      })
    });

    if (!rsp.ok) {
      throw Error(`Fout bij aanroep van web-api '${url}'. `);
    }
  } catch (error) {
    throw Error(`Fout bij aanroep van web-api`);
  }
};
