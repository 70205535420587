import {
  AuthContext,
  ErrorBoundary,
  ErrorPage,
  getAppLocation,
  hasNoRoleIsw,
  HttpErrorPage,
  SettingsContext,
  withErrorBoundary
} from "adviesbox-shared";
import React, { ReactElement, useContext, useState } from "react";
import AutorisatieNiveauContext from "../shared/autorisatie-niveau-context/autorisatie-niveau-context";
import { Title } from "../shared/components/title/title";
import { zoekschermSchema } from "./infra";
import Zoekscherm from "./zoekscherm";

export const ZoekschermAjax = ({ usersOrganisatieId }: { usersOrganisatieId: string }): ReactElement => {
  const [childError, setChildError] = useState<number | null>(null);
  const defaultData = zoekschermSchema.default();
  const { baseUrls } = useContext(SettingsContext);

  const linkToPortal = getAppLocation(window.location.origin, "DOC", "POR", baseUrls);
  const { user } = useContext(AuthContext);
  const { isBeheerder } = useContext(AutorisatieNiveauContext);

  if (childError) {
    return <ErrorPage error={{ ...Error("Error"), status: childError }} />;
  }

  if (!(isBeheerder || !hasNoRoleIsw(user))) {
    return <HttpErrorPage data-testid="error-page" status={403} returnUrl={linkToPortal} />;
  }

  return (
    <div>
      <ErrorBoundary>
        <>
          <Title appName="Contract" altTitle="Zoeken" />
          <Zoekscherm {...defaultData} childErrorSetter={setChildError} />
        </>
      </ErrorBoundary>
    </div>
  );
};

ZoekschermAjax.displayName = "ZoekschermAjax";
export default withErrorBoundary(ZoekschermAjax);
