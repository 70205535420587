import * as Yup from "yup";
import { WithSaveData } from "../../shared/utils/save-data";

/* TODO: als Platform data beschikbaar is, schema's correct opstellen*/

/*:MODAL:SCHEMA:*/

/*:CARD:SCHEMA:*/

export const logboekSchema = Yup.object({
  exampleValue: Yup.string().default("Example Default Value")
});

export type LogboekType = Yup.InferType<typeof logboekSchema>;
export type LogboekState = Yup.InferType<typeof logboekSchema>;
export type LogboekProps = Yup.InferType<typeof logboekSchema> & WithSaveData<LogboekState>;
