import * as Yup from "yup";
import { HdnNodenummerGeldigVoorOptions } from "../../.generated/licenties/licentiestypes";

export const hdnNodenummerVestigingSchema = Yup.object({
  naam: Yup.string().nullable(),
  id: Yup.string()
});

export const hdnNodenummerEntrySchema = Yup.object({
  nodeNummer: Yup.string()
    .default("")
    .test("length", "Een nodenummer moet bestaan uit 6 cijfers", val => {
      return val.length === 6 && !isNaN(Number(val));
    }),
  vestigingen: Yup.array(hdnNodenummerVestigingSchema).default([]),
  actief: Yup.boolean()
    .nullable()
    .default(false),
  geldigVoor: Yup.mixed<HdnNodenummerGeldigVoorOptions>()
    .oneOf(Object.values(HdnNodenummerGeldigVoorOptions))
    .default(HdnNodenummerGeldigVoorOptions.Vestiging),
  hasChanges: Yup.boolean().default(false),
  isNieuw: Yup.boolean().default(true),
  HdnNodenummerId: Yup.string().default("")
});

export const hdnNodenummerEntryWrapperSchema = Yup.object({
  initial: hdnNodenummerEntrySchema.shape({
    nodeNummer: Yup.string().default("")
  }),
  current: hdnNodenummerEntrySchema
});

export const HdnNodenummerStateSchema = Yup.object({
  hdnNodenummerEntries: Yup.array(hdnNodenummerEntryWrapperSchema).default([]),
  hdnNodenummerEntriesInView: Yup.number().default(10),
  vestigingenOptions: Yup.array(
    Yup.object({
      id: Yup.string(),
      naam: Yup.string()
    })
  ).default([])
});

export type HdnNodenummerState = Yup.InferType<typeof HdnNodenummerStateSchema>;
export type HdnNodenummerEntry = Yup.InferType<typeof hdnNodenummerEntrySchema>;
export type HdnNodenummerEntryWrapper = Yup.InferType<typeof hdnNodenummerEntryWrapperSchema>;
export type HdnNodenummerVestiging = Yup.InferType<typeof hdnNodenummerVestigingSchema>;
