import React, { ReactElement } from "react";
import { CardWrapper, Card } from "adviesbox-shared";
import Gebruikersgroep from "../gebruikersgroep/gebruikersgroep";
import { ToegangTot } from "../toegang-tot/toegang-tot";
import { UserProps } from "../../shared/properties/user-props";

type Props = { selected: number };

export const GebruikersgroepenDetails = ({ selected, user, userDetails }: Props & UserProps): ReactElement => {
  return (
    <>
      <CardWrapper className="px-3">
        <Card title="Gebruikersgroep">
          <Gebruikersgroep selected={selected} user={user} userDetails={userDetails} />
        </Card>
        <Card title="Toegang tot" subtitle={"Selecteer minimaal 1 optie"}>
          <ToegangTot selected={selected} user={user} userDetails={userDetails} />
        </Card>
      </CardWrapper>
    </>
  );
};
