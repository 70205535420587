import React, { MutableRefObject, useState } from "react";
import { Button } from "react-bootstrap";
import { ReactElement } from "react";
import { FormikContextType } from "formik";
import { withRouter, RouteComponentProps } from "react-router";
import classNames from "classnames";

import { submit, SubmitResultTypes } from "../../utils/save-validation";
import classes from "./SaveButton.module.scss";
import { ValidatiePopup, BrowserNavigation } from "adviesbox-shared";
import SaveNavigation from "../save-navigation/save-navigation";
import { WithSaveData } from "../../utils/save-data";
/* istanbul ignore file */

type SaveButtonProps = {
  callBack?: () => void;
  preSaveConfirmation?: () => Promise<void> | void;
  triggerPreSaveConfirmation?: boolean;
  name?: string;
  initialSaveResult?: SubmitResultTypes; // Voor testen alleen
  context: FormikContextType<any> &
  WithSaveData<any> & {
    berekenSideEffectRef?: MutableRefObject<{ asyncResult: Promise<unknown> }>;
  };
};

const SaveButtonComponent = (props: SaveButtonProps & RouteComponentProps): ReactElement | null => {
  let result: ReactElement | null = null;

  const {
    history,
    context: { saveData, dirty, isSubmitting },
    callBack,
    preSaveConfirmation,
    triggerPreSaveConfirmation
  } = props;

  const [showValidatiePopup, setValidatiePopup] = useState(false);
  const [saveResult, setSaveResultText] = useState<SubmitResultTypes>(props.initialSaveResult || "default");
  const [showNavValidatiePopup, setNavValidatiePopup] = useState(false);

  if (isSubmitting) {
    result = (
      <span id={props.name || "save-button"} className={classes.busy}>
        Bezig met opslaan.
      </span>
    );
  } else if (dirty) {
    const isPlatformError = saveResult === "platformError";
    result = (
      <Button
        id={props.name || "save-button"}
        className={classNames({
          [classes.btn_danger]: isPlatformError,
          [classes.default]: !isPlatformError
        })}
        variant={isPlatformError ? "danger" : "link"}
        onClick={async (): Promise<void> => {
          if (triggerPreSaveConfirmation) {
            preSaveConfirmation && (await preSaveConfirmation());
            return;
          }

          const validationResult = await submit(
            props.context.saveData,
            props.context,
            props.context.berekenSideEffectRef,
            callBack
          );
          setSaveResultText(validationResult);
          setValidatiePopup(validationResult !== "default" && validationResult !== "completed");
        }}
      >
        {isPlatformError ? "Opslag mislukt. Probeer nogmaals" : "Wijzigingen opslaan"}
      </Button>
    );
  } else {
    result = (
      <span
        id={props.name || "save-button"}
        className={classNames({
          [classes.no_save]: saveResult === "default",
          [classes.saved]: saveResult === "completed"
        })}
      >
        Alle gegevens zijn opgeslagen
      </span>
    );
  }

  return (
    <>
      {result}

      <SaveNavigation
        history={history}
        triggerPreSaveConfirmation={triggerPreSaveConfirmation}
        preSaveConfirmation={preSaveConfirmation}
        saveData={saveData}
        setValidatiePopup={setNavValidatiePopup}
        setSaveResultText={setSaveResultText}
        showValidatiePopup={showNavValidatiePopup}
        callBack={callBack}
      />

      <ValidatiePopup
        infotekst={saveResult}
        show={showValidatiePopup}
        onHide={(): void => {
          setValidatiePopup(false);
        }}
      />

      <BrowserNavigation
        preventWarning={triggerPreSaveConfirmation || false}
        dirty={dirty}
        modalShow={showNavValidatiePopup}
      />
    </>
  );
};

SaveButtonComponent.displayName = "SaveButton";

export const SaveButton = withRouter(SaveButtonComponent);
