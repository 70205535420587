import { LabeledTextInput } from "adviesbox-shared";
import classNames from "classnames";
import React, { ReactElement } from "react";
import { Button } from "react-bootstrap";
import { StepProps } from "../organisatie-registreren";
import classes from "../registreren.module.scss";
export const Contactpersoon = ({ currentStep, setCurrentStep }: StepProps): ReactElement => {
  return (
    <>
      <h2 className={classNames(classes.title)}>Contactpersoon</h2>
      <LabeledTextInput name={"voornaam"} caption={"Voornaam"} labelColSize={7} />
      <LabeledTextInput name={"voorletters"} caption={"Voorletters / Tussenvoegsel"} labelColSize={7} />
      <LabeledTextInput name={"tussenvoegsel"} caption={""} labelColSize={7} />
      <LabeledTextInput name={"achternaam"} caption={"Achternaam"} labelColSize={7} />
      <LabeledTextInput name={"geslacht"} caption={"Geslacht"} labelColSize={7} />
      <LabeledTextInput
        autoComplete="new-password"
        name={"emailadres"}
        caption={"E-mailadres (Gebruikersnaam)"}
        labelColSize={7}
      />
      <LabeledTextInput name={"bevestigEmailadres"} caption={"Bevestig e-mailadres"} labelColSize={7} />
      <LabeledTextInput name={"telefoonnummer"} caption={"Telefoonnummer"} labelColSize={7} />
      <Button
        className={"my-3 mx-1"}
        variant={"light"}
        onClick={() => {
          setCurrentStep(currentStep - 1);
        }}
      >
        Terug
      </Button>
      <Button
        className={"float-right m-3"}
        onClick={() => {
          setCurrentStep(currentStep + 1);
        }}
      >
        Volgende
      </Button>
    </>
  );
};
