import { LoginPage, SigninOidc } from "adviesbox-shared";
import React, { ReactElement } from "react";
import { Route } from "react-router";
import logo from "../assets/new-brand-logo.svg";

const UnauthenticatedLicentieBeheerApp = (): ReactElement => (
  <>
    <Route path="/signin-oidc" component={SigninOidc} />
    <LoginPage appLogo={logo} />
  </>
);

UnauthenticatedLicentieBeheerApp.displayName = "UnauthenticatedLicentieBeheerApp";

export default UnauthenticatedLicentieBeheerApp;
