/* istanbul ignore file */
import {
  vestigingSchema,
  organisatieVestigingSchema,
  OrganisatieVestigingState,
  VestigingenType,
  vestigingenSchema
} from "./vestigingen-schema";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import {
  VestigingenOutput,
  Vestiging,
  Organisatie,
  OrganisatiesOutput
} from "../../.generated/licenties/licentiestypes";
import { mapAdresInputDlNaarUi } from "../../shared/generic-parts/adres/map-dl-2-ui";

const mapOrganisatie = createMapToUi(organisatieVestigingSchema)
  .with<string>()
  .from<Organisatie>({
    id: (_, c) => c,
    bedrijfsnaam: v => v.naam,
    iban: v => v.iban,
    kvkNummer: v => v.kvKnummer,
    logo: v => v.logo,
    platformId: v => v.platformId,
    loading: () => false,
    hasChanged: () => false,
    sendPut: () => false
  });

const mapVestiging = createMapToUi(vestigingSchema)
  .with<string>()
  .from<Vestiging>({
    id: (_, id) => id,
    naam: v => v.naam,
    bezoekadres: v => mapAdresInputDlNaarUi(v.bezoekadres),
    emailadres: v => v.emailadres,
    isHoofdvestiging: v => v.isHoofdvestiging,
    postadres: v => (v.postadres?.postcode && v.postadres?.huisnummer ? mapAdresInputDlNaarUi(v.postadres) : null),
    telefoonnummer: v => v.telefoonnummer,
    platformId: v => v.platformId,
    afmRegistratienummer: v => v.afmRegistratienummer,
    waarschuwingsBericht: () => null,
    afwijkendPostadresTonen: v => (v.postadres?.postcode && v.postadres?.huisnummer ? true : false),
    hasChanged: () => false,
    isDeleted: () => false,
    putChanges: () => false,
    postChanges: () => false,
    savedChanges: () => false,
    triggeredByModal: () => false
  });

const mapVestigingen = createMapToUi(vestigingenSchema).from<VestigingenOutput>({
  vestigingen: v => {
    const vestigingArray = [];
    for (const key in v.vestigingen) {
      if (v.vestigingen.hasOwnProperty(key)) {
        vestigingArray.push(mapVestiging(key)(v.vestigingen[key]));
      }
    }

    return vestigingArray;
  }
});

export function mapVestigingDlToUi(data: VestigingenOutput): VestigingenType | null {
  const vestigingen = data && data.isValid && data.vestigingen ? data : null;

  if (vestigingen) {
    return mapVestigingen(vestigingen);
  }

  return null;
}

export function mapOrganisatieDlToUi(data: OrganisatiesOutput, id: string): OrganisatieVestigingState | null {
  const organisatie = data && data.isValid && data.organisaties ? data.organisaties[id] : null;

  if (organisatie) {
    return mapOrganisatie(id)(organisatie);
  }

  return null;
}
