import {
  AdviesBoxColumn,
  Card,
  CardWrapper,
  createSpanWithId,
  DataGrid,
  FormFirstFocus,
  hasValue,
  Icon,
  PageLoading,
  PlatformFoutenSamenvatting
} from "adviesbox-shared";
import { Form, FormikProps, useFormikContext } from "formik";
import memoizeOne from "memoize-one";
import React, { ReactElement, useState } from "react";
import { Button } from "react-bootstrap";
import { CellInfo } from "react-table-6";
import { Gebruikersactie, MagActieUitvoeren, Matrixonderdeel } from "../shared/autorisatie-matrix/autorisatie-matrix";
import { Debug } from "../shared/components/formik/Debug";
import { ISWSideEffects } from "../shared/components/isw-side-effects/isw-side-effects";
import { PaginationComponent } from "../shared/components/pagination/pagination";
import { SaveButtonNoSaveData } from "../shared/components/save-button/save-button-no-save-data";
import { UserProps } from "../shared/properties/user-props";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { LicentieaanbodDeleteModal } from "./delete-modal/licentieaanbod-delete-modal";
import { HasChangesModal } from "./has-changes-modal/has-changes-modal";
import { asyncLicentieaanbodSideEffects } from "./infra/determine-async-licentieaanbod-side-effects";
import { syncLicentieaanbodSideEffects } from "./infra/determine-sync-licentieaanbod-side-effects";
import {
  LicentieAanbodDeleteType,
  licentiesAanbodProductSchema,
  LicentiesaanbodProps,
  LicentiesAanbodState,
  ModalTypeEnum,
  productSchema,
  ProductType,
  ShowDeleteModal,
  ShowHasChangesModal
} from "./infra/licentieaanbod-schema";
import { LicentieaanbodDetails } from "./licentieaanbod-details/licentieaanbod-details";

const LicentieaanbodComponent = (
  props: FormikProps<LicentiesAanbodState> & LicentiesaanbodProps & UserProps
): ReactElement => {
  const formik = useFormikContext<LicentiesAanbodState>();
  const { values, setFieldValue } = formik;
  const { isSubmitting } = props;
  const selectedState = useState(0);
  const [selected, setSelected] = selectedState;
  const [newSelected, setNewSelected] = useState(0);
  const { producten } = formik.values;
  const selectedProduct = producten[selected] ? producten[selected] /* istanbul ignore next */ : null;
  const [showDeleteModal, setShowDeleteModal] = useState<ShowDeleteModal>({ show: false, selected: null });
  const [showHasChangesModal, setShowHasChangesModal] = useState<ShowHasChangesModal>({ show: false, selected: null });
  if (!hasValue(selectedProduct) && producten.length) setSelected(0);

  const magSchrijven = MagActieUitvoeren(
    Matrixonderdeel.Licentieaanbod,
    Gebruikersactie.Schrijven,
    props.user,
    props.userDetails
  );
  const magToevoegen = MagActieUitvoeren(
    Matrixonderdeel.Licentieaanbod,
    Gebruikersactie.Toevoegen,
    props.user,
    props.userDetails
  );
  const magVerwijderen = MagActieUitvoeren(
    Matrixonderdeel.Licentieaanbod,
    Gebruikersactie.Verwijderen,
    props.user,
    props.userDetails
  );
  const magLezen =
    magSchrijven ||
    magToevoegen ||
    magVerwijderen ||
    MagActieUitvoeren(Matrixonderdeel.Licentieaanbod, Gebruikersactie.Lezen, props.user, props.userDetails);

  const deleteProduct = (data: LicentieAanbodDeleteType): void => {
    formik.setFieldValue(`producten[${data.selected}].isDeleted`, true);
    setShowDeleteModal({ show: false, selected: 0 });
  };

  /* istanbul ignore next */
  const updateSelected = (newSelected: React.SetStateAction<number>, skip?: boolean, keepSelected?: boolean): void => {
    if (values.producten[selected]?.hasChanged && selected !== newSelected && !skip) {
      setShowHasChangesModal({ show: true, selected: selected });
      setSelected(selected);
      setNewSelected(newSelected);
      return;
    }

    if (keepSelected) {
      setSelected(selected);
      return;
    }

    setSelected(newSelected);
  };

  /* istanbul ignore next */
  const closeModalAndSwitchSelected = (keepSelected?: boolean): void => {
    if (showHasChangesModal.show) {
      setShowHasChangesModal({ show: false, selected: null });
      updateSelected(newSelected, true, keepSelected);
    }
  };

  const DeleteButton = memoizeOne(
    (
      setShowPopup: React.Dispatch<React.SetStateAction<ShowDeleteModal>>,
      updateSelected: (selected: number) => void,
      heeftId: boolean,
      magVerwijderen: boolean
    ): ((cellInfo: CellInfo) => ReactElement) => {
      const cellDeleteButton = (c: CellInfo): ReactElement => {
        return (
          <div className="d-flex align-items-center justify-content-center w-100 h-100">
            {c.original.code !== "ABX" && (
              <Button
                data-testid={`btn-verwijder-${c.index}`}
                id={`btn-verwijder-${c.index}`}
                variant="outline-secondary"
                onMouseUp={(event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
                  if (event.button !== 0) return;
                  event.stopPropagation();
                  updateSelected(c.index);
                  if (heeftId) setShowPopup({ show: true, selected: c.index });
                  else formik.setFieldValue(`producten[${selected}].isDeleted`, true);
                }}
                aria-label="bewerk knop"
              >
                <Icon name="prullenbak" alt="Verwijderen" />
              </Button>
            )}
          </div>
        );
      };
      const noButton = (c: CellInfo): ReactElement => {
        return <></>;
      };

      if (!magVerwijderen) return noButton;

      return cellDeleteButton;
    }
  );

  const licentieAanbodColumns = (): AdviesBoxColumn[] => [
    {
      Header: "Productnaam",
      id: "productnaam",
      accessor: "productNaam",
      Cell: (c): ReactElement =>
        createSpanWithId(c.index, 1, c.original.productNaam, c.original.productNaam, "licentieaanbod")
    },
    {
      Header: "Code",
      id: "code",
      accessor: "code",
      Cell: (c): ReactElement => createSpanWithId(c.index, 2, c.original.code, c.original.code, "licentieaanbod")
    },
    {
      Header: "Actief",
      id: "actief",
      accessor: "actief",
      Cell: (c): ReactElement =>
        createSpanWithId(c.index, 3, c.original.actief ? "Ja" : "Nee", "keuzeonderdeel-ja-nee", "licentieaanbod")
    },
    {
      width: 35,
      id: "DeleteButton",
      Cell: values.producten[selected]?.id
        ? DeleteButton(setShowDeleteModal, updateSelected, true, magVerwijderen)
        : DeleteButton(setShowDeleteModal, updateSelected, false, magVerwijderen)
    }
  ];

  if (!magLezen) return <></>;
  const magOpslaan = magSchrijven || magToevoegen;

  return (
    <FormFirstFocus>
      <Form>
        {isSubmitting && <PageLoading />}

        <ISWSideEffects<LicentiesAanbodState>
          sync={syncLicentieaanbodSideEffects({ selected, formik })}
          async={asyncLicentieaanbodSideEffects({
            selected,
            setSelected: /* istanbul ignore next */ () => closeModalAndSwitchSelected()
          })}
        />

        <CardWrapper className="px-3">
          <div className="text-container">
            <h2>Licentieaanbod</h2>
          </div>
        </CardWrapper>

        <PlatformFoutenSamenvatting />

        <CardWrapper className="px-3 master-detail-card flex-grow-1">
          <Card className="w-xl-100 w-lg-100 w-md-50 w-50">
            <DataGrid
              masterDetail
              rowCaption="Nieuw product"
              columns={licentieAanbodColumns()}
              rowSelected={[selected, updateSelected]}
              showButtonAddRow={magToevoegen}
              addNewButtonDistinguisher={"product"}
              errorHighlight={false}
              getNewRowValues={(): ProductType => ({
                ...productSchema.default()
              })}
              pageSize={formik.values.productenInView}
              defaultPageSize={formik.values.productenInView}
              showPagination={true}
              PaginationComponent={PaginationComponent(true, "productenInView", "producten")}
              name="producten"
            />
          </Card>
        </CardWrapper>
        {magOpslaan && (
          <CardWrapper className="pl-4 py-1">
            <div className="button-container">
              <SaveButtonNoSaveData
                isEdited={values.producten[selected]?.hasChanged}
                //isSubmitting={values.loading} NVT: will cause infinite spinner when platform throws an error
                saveResult={values.saveResult}
                callBack={
                  /* istanbul ignore next */ () => {
                    setFieldValue(`producten[${selected}].putChanges`, true);
                    setFieldValue(`loading`, true);
                  }
                }
              />
            </div>
          </CardWrapper>
        )}
        {selectedProduct && (
          <LicentieaanbodDetails selected={selected} user={props.user} userDetails={props.userDetails} />
        )}
        <LicentieaanbodDeleteModal
          key={`masterDetail-${selected}-deleteModal`}
          formik={formik}
          type={ModalTypeEnum.product}
          onDelete={deleteProduct}
          show={showDeleteModal}
          closeModal={setShowDeleteModal}
        />
        <HasChangesModal
          closeModal={setShowHasChangesModal}
          show={showHasChangesModal}
          onDeleteChanges={
            /* istanbul ignore next */ () => {
              const productenInView = values.productenInView;
              const licentieOnderdelen = values.licentieOnderdelen;
              const licentieSamenstellingen = values.licentieSamenstellingen;
              formik.resetForm();
              setFieldValue(`productenInView`, productenInView);
              setFieldValue(`licentieOnderdelen`, licentieOnderdelen);
              setFieldValue(`licentieSamenstellingen`, licentieSamenstellingen);
              setFieldValue(`producten[${selected}].hasChanged`, false);
              setFieldValue(`producten[${selected}].savedChanges`, true); // to stop savebutton from re-appearing
              closeModalAndSwitchSelected(true);
            }
          }
          onConfirmSave={
            /* istanbul ignore next */ () => {
              setFieldValue(`producten[${selected}].putChanges`, true);
              setFieldValue(`producten[${selected}].triggeredByModal`, true);
              setFieldValue(`loading`, true);
            }
          }
          type={ModalTypeEnum.product}
        />
        <Debug />
      </Form>
    </FormFirstFocus>
  );
};

export const Licentieaanbod = withAdviesboxFormik<LicentiesaanbodProps & UserProps, LicentiesAanbodState>({
  // Transform outer props into form values
  mapPropsToValues: (e: LicentiesaanbodProps): LicentiesAanbodState => e,
  validationSchema: licentiesAanbodProductSchema
})(LicentieaanbodComponent);
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") Licentieaanbod.displayName = "Licentieaanbod";
