import { Label, LabelProps } from "adviesbox-shared";
import classnames from "classnames";
import { getIn, useFormikContext } from "formik";
import React, { InputHTMLAttributes, ReactElement, ReactNode, useRef, useState } from "react";
import { TagInputComponent as TagInput } from "./tag-input-component";
import { TagInputOptions } from "./tag-input-options";
import classes from "./tagInput.module.scss";

type tagType = {
  id: string;
  naam: string;
};

export type LabeledTagInputProps = {
  caption: string;
  name: string;
  options?: tagType[]; //tag-id will be the index if undefined
  placeholder?: string;
  visible?: boolean;
  editable?: boolean;
  allowKeyboardKeysToRemove?: boolean; //for backspace & delete
  maxTags?: number;
  searchOptionExpands?: number; //# of letters search starts from
  appendChildren?: ReactNode;
  onChange?: (tags: tagType[]) => void;
} & LabelProps;

export const naamIndex = (suggestions: tagType[], query: string): number => {
  return suggestions.findIndex(v => v?.naam === query.trim());
};

export const idIndex = (suggestions: tagType[], id: string): number => {
  return suggestions.findIndex(v => v?.id === id);
};

export const existsInOptions = (suggestion: tagType, input: string): boolean => {
  return suggestion?.naam.toLowerCase().includes(input.toLowerCase().trim());
};

export const LabeledTagInput = ({
  caption,
  name,
  tooltip,
  placeholder,
  options,
  visible,
  appendChildren,
  labelColSize = 7,
  editable = true,
  allowKeyboardKeysToRemove = true,
  searchOptionExpands = 1,
  maxTags = 30,
  onChange,
  ...props
}: LabeledTagInputProps & InputHTMLAttributes<HTMLInputElement>): ReactElement => {
  const inputRef = useRef<HTMLDivElement | null>(null);

  const inputColSize = labelColSize < 12 ? 12 - labelColSize : 12;
  const formik = useFormikContext();
  const tags: tagType[] = getIn(formik.values, name) ?? [];
  const errorMessage = getIn(formik.errors, name);
  const showInputField = editable && tags.length < maxTags;
  const touched = getIn(formik.touched, name);
  const [input, setInput] = useState("");
  const [index, setIndex] = useState(-1);
  let suggestions: tagType[] = options?.length ? options : [];
  suggestions = suggestions.filter(
    s => s && !tags.includes(s) && s?.naam?.toLowerCase().includes(input?.toLowerCase().trim())
  );

  const addTag = (value: tagType): void => {
    if (naamIndex(tags, value?.naam) === -1) {
      const newTags = [...tags, value];
      formik.setFieldValue(name, newTags);
      onChange && onChange(newTags);
    }
    setInput("");
    formik.setFieldTouched(name, false, false);
  };

  const removeTag = (i: number): void => {
    const newTags = [...tags];
    newTags.splice(i, 1);
    formik.setFieldValue(name, newTags);
    onChange && onChange(newTags);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    // enter to save
    if (e.keyCode === 13) {
      e.preventDefault();
      //return if input is empty or its not in the array of suggestions
      if (
        input === "" ||
        input.trim().length === 0 ||
        (options?.length && index === -1 && naamIndex(options, input) === -1) ||
        (options?.length && index !== -1 && !existsInOptions(suggestions[index], input))
      ) {
        setInput("");
        return;
      }
      if (options?.length) {
        //(if) selected from the suggestion list (else) if it was typed
        if (index !== -1) {
          addTag(suggestions[index]);
        } else {
          const idIndex = naamIndex(options, input);
          addTag({ id: options[idIndex]?.id, naam: input.trim() });
        }
      } else {
        addTag({ id: tags.length.toString(), naam: input.trim() });
      }

      setIndex(-1);
    }
    // delete or backspace to remove
    if (allowKeyboardKeysToRemove && (e.keyCode === 46 || e.keyCode === 8)) {
      setIndex(-1); //reset
      if (input !== "") {
        return;
      }
      e.preventDefault();
      removeTag(tags.length - 1);
    }
    //on ArrowUp
    if (options?.length && e.keyCode === 38) {
      e.preventDefault();
      const size = suggestions.length - 1;
      setIndex(index <= 0 ? size : index - 1);
    }

    //on ArrowDown
    if (options?.length && e.keyCode === 40) {
      e.preventDefault();
      const size = suggestions.length - 1;
      setIndex(index > size ? 0 : index + 1);
    }
  };

  const onBlur = (): void => {
    setIndex(-1); //reset
    formik.setFieldTouched(name);
  };

  return (
    <div
      id="tag-form-group"
      data-testid="tag-form-group"
      className={classnames("form-group form-row", {
        invisible: visible === false
      })}
    >
      <div className={`col-${labelColSize}`}>
        <Label caption={caption} name={name} tooltip={tooltip} />
      </div>

      <div className={`col-${inputColSize}`}>
        <div
          id={`tag-component-${name}`}
          data-testid="tag-component"
          className={classnames(classes.tag_component, { "is-invalid": errorMessage && touched })}
          onClick={() => {
            inputRef?.current?.focus();
          }}
        >
          {tags.map((tag, i) => (
            <React.Fragment key={`${i}-${tag?.id}`}>
              {tag?.naam ? (
                <TagInput index={i} name={name} tag={tag.naam} remove={removeTag} editable={editable} {...props} />
              ) : (
                undefined
              )}
            </React.Fragment>
          ))}
          {showInputField && (
            <div className={classes.tag_component_searchWrapper}>
              <input
                type="text"
                name={name}
                ref={c => {
                  inputRef.current = c;
                }}
                id={`input-tag-component-${name}`}
                data-testid="input-tag-component"
                value={input}
                className={classnames(classes.tag_component_input)}
                placeholder={placeholder}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
                autoComplete={"none"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  setInput(e.target.value);
                }}
              />
            </div>
          )}
        </div>
        {showInputField && !!options?.length && input.trim().length > 0 && (
          <TagInputOptions
            chosenTags={tags}
            indexOfSuggestion={index}
            query={input}
            expanded={input.length >= searchOptionExpands}
            editable={editable}
            addTag={addTag}
            suggestions={suggestions}
          />
        )}
        {appendChildren}
        {errorMessage && touched && <div className="foutmelding">{errorMessage}</div>}
      </div>
    </div>
  );
};
/* istanbul ignore next */
if (process.env.NODE_ENV !== "production") LabeledTagInput.displayName = "LabeledTagInput";
