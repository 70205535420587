import { LabeledTextInput } from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement } from "react";
import {
  Gebruikersactie,
  MagActieUitvoeren,
  Matrixonderdeel
} from "../../shared/autorisatie-matrix/autorisatie-matrix";
import { UserProps } from "../../shared/properties/user-props";
import { MedewerkerState } from "../infra/medewerkers-schema";
import { MedewerkersSubProps } from "../medewerkers";

export const Persoonsgegevens = (props: MedewerkersSubProps & UserProps): ReactElement => {
  useFormikContext<MedewerkerState>();
  const index = props.data.actualSelectedIndex;

  const magSchrijven = MagActieUitvoeren(
    Matrixonderdeel.Medewerkers,
    Gebruikersactie.Schrijven,
    props.user,
    props.userDetails
  );
  const magToevoegen = MagActieUitvoeren(
    Matrixonderdeel.Medewerkers,
    Gebruikersactie.Toevoegen,
    props.user,
    props.userDetails
  );
  const magLezen = MagActieUitvoeren(Matrixonderdeel.Medewerkers, Gebruikersactie.Lezen, props.user, props.userDetails);

  if (!magLezen) return <></>;

  const isReadOnly = !magSchrijven && !magToevoegen;

  return (
    <>
      <LabeledTextInput
        name={`medewerkers[${index}].persoonsgegevens.voornaam`}
        caption="Voornaam"
        verplicht={true}
        readOnly={isReadOnly}
      />
      <LabeledTextInput
        name={`medewerkers[${index}].persoonsgegevens.voorletters`}
        caption="Voorletter(s)"
        verplicht={true}
        readOnly={isReadOnly}
      />
      <LabeledTextInput
        name={`medewerkers[${index}].persoonsgegevens.tussenvoegsel`}
        caption="Tussenvoegsel"
        readOnly={isReadOnly}
      />
      <LabeledTextInput
        name={`medewerkers[${index}].persoonsgegevens.achternaam`}
        caption="Achternaam"
        verplicht={true}
        readOnly={isReadOnly}
      />
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") Persoonsgegevens.displayName = "Persoonsgegevens";
