import { hasNoRoleIsw, LabeledTextInput } from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement } from "react";
import {
  Gebruikersactie,
  MagActieUitvoeren,
  Matrixonderdeel
} from "../../shared/autorisatie-matrix/autorisatie-matrix";
import { UserProps } from "../../shared/properties/user-props";
import { ContractState } from "../infra/contract-schema";
import BedrijfslogoImporteren from "./bedrijfslogo-importeren";

type OrganisatieProps = {};

export const Organisatie = (props: OrganisatieProps & UserProps): ReactElement => {
  useFormikContext<ContractState>();

  const magSchrijven = MagActieUitvoeren(
    Matrixonderdeel.ContractOrganisatie,
    Gebruikersactie.Schrijven,
    props.user,
    props.userDetails
  );
  const magLezen =
    magSchrijven ||
    MagActieUitvoeren(Matrixonderdeel.ContractOrganisatie, Gebruikersactie.Lezen, props.user, props.userDetails);

  if (!magLezen) return <></>;

  const isReadOnly = !magSchrijven;
  const isISWMedewerker = !hasNoRoleIsw(props.user);

  return (
    <>
      <LabeledTextInput name="organisatie.bedrijfsnaam" caption="Bedrijfsnaam" readonly={isReadOnly} />
      <LabeledTextInput name="organisatie.kvkNummer" caption="KVK-nummer" readonly={isReadOnly} />
      {isISWMedewerker && <LabeledTextInput name="organisatie.platformId" caption="Platform-ID" readonly />}
      <BedrijfslogoImporteren readOnly={isReadOnly} />
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") Organisatie.displayName = "Organisatie";
