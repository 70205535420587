import { SettingsType } from "adviesbox-shared";
import { RouteParams } from "../../shared/paramrouting/paramrouting-context";

// Deze class levert op een eenduidige manier de url voor operations op de Licenties API.
export class LicentiesApi {
  static getGebruikersgroepenUrl = (s: SettingsType, p: RouteParams): string =>
    `${s.licentiesOrigin}/Gebruikersgroepen/?organisatieId=${p.organisatie}`;

  static getLicentieovereenkomstenUrl = (s: SettingsType, p: RouteParams): string =>
    `${s.licentiesOrigin}/Licentieovereenkomsten/?organisatieId=${p.organisatie}`;

  static getMedewerkersCurrentUrl = (s: SettingsType): string => `${s.licentiesOrigin}/Medewerkers/current`;

  static getMedewerkerGebruikersgroepenUrl = (s: SettingsType, medewerkerId: string): string =>
    `${s.licentiesOrigin}/Medewerkers/${medewerkerId}/Gebruikersgroepen`;

  static getMedewerkersRechtenUrl = (s: SettingsType, medewerkerId: string): string =>
    `${s.licentiesOrigin}/Medewerkers/${medewerkerId}/Rechten`;

  static getOrganisatieUrl = (s: SettingsType, p: RouteParams): string =>
    `${s.licentiesOrigin}/Organisaties/${p.organisatie}`;

  static getHdnNodenummersUrl = (s: SettingsType, p: RouteParams): string =>
    `${s.licentiesOrigin}/Organisatie/${p.organisatie}/HdnNodenummers`;

  static getLicentiesUrl = (s: SettingsType, p: RouteParams): string =>
    `${s.licentiesOrigin}/Organisaties/${p.organisatie}/Licenties`;

  static getMedewerkersUrl = (s: SettingsType, p: RouteParams): string =>
    `${s?.licentiesOrigin}/Organisatie/${p?.organisatie}/Medewerkers`;

  static getVestigingenUrl = (s: SettingsType, p: RouteParams): string =>
    `${s?.licentiesOrigin}/Organisatie/${p?.organisatie}/Vestigingen`;

  static getProductenUrl = (s: SettingsType): string => `${s.licentiesOrigin}/Producten`;

  static getProductonderdelenUrl = (s: SettingsType, p: RouteParams): string =>
    `${s.licentiesOrigin}/Producten/${p.product}/Productonderdelen`;

  static getProductsamenstellingenUrl = (s: SettingsType, p: RouteParams): string =>
    `${s.licentiesOrigin}/Producten/${p.product}/Productsamenstellingen`;

  static getVestigingUrl = (s: SettingsType, p: RouteParams): string =>
    `${s.licentiesOrigin}/Vestigingen/${p.vestiging}`;
}
