type CreateMapEnumReturnType<S> = {
  to: <D = never>(
    map: { [K in keyof S]: D }
  ) => <X extends S[keyof S] | null | undefined>(
    enumValue: X
  ) => Extract<X, undefined | null> extends never ? D : D | null;
};
export const createMapEnum = <S extends object>(enumeration: S): CreateMapEnumReturnType<S> => ({
  to: <D = never>(map: { [K in keyof S]: D }) => (enumValue: any): any => {
    if (typeof enumValue === "undefined") return null;
    if (enumValue === null) return null;

    const index = Object.values(enumeration).indexOf(enumValue);
    if (index === -1) return null;
    const key = Object.keys(enumeration)[index] as keyof S;
    return map[key];
  }
});
