import * as Yup from "yup";

export const kantoorgegevensSchema = Yup.object({
  bedrijfsgegevens: Yup.string(),
  kvkNummer: Yup.number(),
  algemeenTelefoonnumer: Yup.number(),
  afmVergunningsnummer: Yup.number(),
  postcode: Yup.string(),
  huisnummerToevoeging: Yup.string(),
  straat: Yup.string(),
  plaats: Yup.string(),
  iban: Yup.string()
});

export const contactpersoonSchema = Yup.object({
  voornaam: Yup.string(),
  voorletters: Yup.string(),
  tussenvoegsel: Yup.string(),
  achternaam: Yup.string(),
  geslacht: Yup.string(),
  emailadres: Yup.string(),
  bevestigEmailadres: Yup.string(),
  telefoonnummer: Yup.number()
});

export const accountActiverenSchema = Yup.object({
  wachtwoord: Yup.string()
});
export const tweedeFactorSchema = Yup.object({
  authenticatiecode: Yup.number()
});
export const backupCodesSchema = Yup.object({});
export const overeenkomstSchema = Yup.object({
  overeenkomst: Yup.boolean()
});

export const registratieSchema = Yup.object({
  kantoorgegevens: kantoorgegevensSchema,
  contactpersoon: contactpersoonSchema,
  accountActiveren: accountActiverenSchema,
  tweedeFactor: tweedeFactorSchema,
  backupCodes: backupCodesSchema,
  overeenkomst: overeenkomstSchema
});
