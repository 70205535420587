/* istanbul ignore file */
import { LogboekState } from "./logboek-schema";
import { initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";

/* type ContextType = { } */

export const syncLogboekSideEffects = initISWSideEffect<LogboekState /* ,ContextType */>(
  ({ has, draft /* ,prev */ /* ,context */ }) => {
    if (has.exampleValue.changed) {
      draft.exampleValue = "testSyncSideEffect";
    }
  }
)();
