/* istanbul ignore file */
import { initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { LicentiesState } from "./licenties-schema";

type ContextType = {
  selected: number;
  // formik: FormikContextType<LicentiesState>;
};

export const syncLicentiesSideEffects = initISWSideEffect<LicentiesState, ContextType>(bag => {
  // const { has, draft, context } = bag;
});
