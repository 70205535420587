/* istanbul ignore file */
import { ValidatiePopup } from "adviesbox-shared";
import classNames from "classnames";
import React, { ReactElement, useState } from "react";
import { Button } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router";
import classes from "./SaveButton.module.scss";

export type SaveResult = "platformError" | "clientError" | "default" | "completed";

type SaveButtonNoSaveDataProps = {
  isEdited: boolean;
  isSubmitting?: boolean;
  saveResult?: SaveResult;
  callBack?: () => void;
  save?: boolean;
  name?: string;
};

const SaveButtonNoSaveDataComponent = (props: SaveButtonNoSaveDataProps & RouteComponentProps): ReactElement | null => {
  let result: ReactElement | null = null;
  const { isSubmitting = false, isEdited, saveResult = "default", callBack } = props;
  const [showValidatiePopup, setValidatiePopup] = useState(false);
  const isPlatformError = saveResult === "platformError";
  const isClientError = saveResult === "clientError";

  if (isSubmitting) {
    result = (
      <span id={props.name || "save-button"} data-testid={props.name || "save-button"} className={classes.busy}>
        Bezig met opslaan.
      </span>
    );
  } else if (isClientError) {
    result = (
      <span is={props.name || "save-button"} data-testid={props.name || "save-button"} className={classes.btn_danger}>
        Invoer bevat fouten
      </span>
    )
  } else if (isEdited || isPlatformError) {
    result = (
      <Button
        id={props.name || "save-button"}
        data-testid={props.name || "save-button"}
        className={classNames({
          [classes.btn_danger]: isPlatformError,
          [classes.default]: !isPlatformError
        })}
        variant={isPlatformError ? "danger" : "link"}
        onClick={async (): Promise<void> => {
          setValidatiePopup(isPlatformError);
          if (!isPlatformError) callBack && callBack();
        }}
      >
        {isPlatformError ? "Opslag mislukt. Probeer nogmaals" : "Wijzigingen opslaan"}
      </Button>
    );
  } else {
    result = (
      <span
        id={props.name || "save-button"}
        data-testid={props.name || "save-button"}
        className={classNames({
          [classes.no_save]: saveResult === "default",
          [classes.saved]: saveResult === "completed"
        })}
      >
        Alle gegevens zijn opgeslagen
      </span>
    );
  }

  return (
    <>
      {result}
      <ValidatiePopup
        infotekst={saveResult}
        show={showValidatiePopup}
        onHide={(): void => {
          setValidatiePopup(false);
        }}
      />
    </>
  );
};

SaveButtonNoSaveDataComponent.displayName = "SaveButtonNoSaveData";

export const SaveButtonNoSaveData = withRouter(SaveButtonNoSaveDataComponent);
