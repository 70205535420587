import React, { ReactElement } from "react";
import { CardWrapper, Card } from "adviesbox-shared";
import Product from "../product/product";
import { Licentieonderdelen } from "../licentieonderdelen/licentieonderdelen";
import { Licentiesamenstelling } from "../licentiesamenstelling/licentiesamenstelling";
import { LicentiesAanbodState } from "../infra/licentieaanbod-schema";
import { useFormikContext } from "formik";
import { UserProps } from "../../shared/properties/user-props";

type Props = { selected: number };

export const LicentieaanbodDetails = (props: Props & UserProps): ReactElement => {
  const { values } = useFormikContext<LicentiesAanbodState>();

  return (
    <CardWrapper maxRowCount={2} className={"px-3"}>
      <Card title="Product">
        <Product selected={props.selected} user={props.user} userDetails={props.userDetails} />
      </Card>
      {values.producten[props.selected].id !== null && values.producten[props.selected].id !== undefined && (
        <>
          <Card title="Licentieonderdelen">
            {values.licentieOnderdelen.loading && <div className="loader"></div>}
            {!values.licentieOnderdelen.loading && (
              <Licentieonderdelen selected={props.selected} user={props.user} userDetails={props.userDetails} />
            )}
          </Card>
          <Card title="Licentiesamenstelling">
            {values.licentieSamenstellingen.loading && <div className="loader"></div>}
            {!values.licentieSamenstellingen.loading && (
              <Licentiesamenstelling selected={props.selected} user={props.user} userDetails={props.userDetails} />
            )}
          </Card>
        </>
      )}
    </CardWrapper>
  );
};
