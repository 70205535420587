/* istanbul ignore file */
import React, { ReactElement, ReactNode, InputHTMLAttributes } from "react";
import { HdnKeuzelijstPresentation } from "adviesbox-shared";
import { useHdnData, HdnBerichtSoortType, UseHdnDataResult } from "../../hooks/use-hdn-data";

export type HdnKeuzelijstProps = {
  name: string;
  berichtSoortType: HdnBerichtSoortType;
  keuzelijst: string;
  appendChildren?: ReactNode;
  readonly?: boolean;
  visible?: boolean;
  fieldSize?: "xl" | "l" | "m" | "s";
  emptyValue?: string;
  hdnData?: UseHdnDataResult;
  useHdnData?: (berichtSoortType: HdnBerichtSoortType, keuzelijst: string) => UseHdnDataResult;
};

const HdnKeuzelijst = ({
  berichtSoortType,
  keuzelijst,
  name,
  appendChildren,
  readonly,
  fieldSize,
  emptyValue,
  onChange,
  hdnData
}: HdnKeuzelijstProps & InputHTMLAttributes<HTMLSelectElement>): ReactElement | null => {
  const { error, hdnKeuzelijst } = hdnData ?? useHdnData(berichtSoortType, keuzelijst);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <HdnKeuzelijstPresentation
      name={name}
      hdnKeuzelijst={hdnKeuzelijst}
      appendChildren={appendChildren}
      readonly={readonly}
      fieldSize={fieldSize}
      emptyValue={emptyValue}
      onChange={onChange}
    />
  );
};

HdnKeuzelijst.displayName = "HdnKeuzelijst";

export default HdnKeuzelijst;
