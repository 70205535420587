import {
  Licentie,
  LicentieovereenkomstenOutput,
  LicentiesOutput,
  Product,
  ProductenOutput,
  ProductsamenstellingenOutput
} from "../../.generated/licenties/licentiestypes";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import {
  licentieKaartSchema,
  licentieOvereenKomstSchema,
  LicentieOvereenkomstState,
  licentiesSchema,
  LicentiesState,
  productenSchema,
  ProductenType,
  productSamenstellingenSchema,
  ProductSamenstellingenState,
  productSchema,
  samenstellingSchema
} from "./licenties-schema";

type SamenstellingContext = {
  id: string | null;
  licenties: Licentie[];
  naam: string | null;
};

// Berekening voor de hoeveelheid licenties + welke daarvan wel/niet in gebruik zijn
const licentiesBerekening = (data: Licentie[]): any => {
  const metMedewerkerId = data.filter(v => v.medewerkerId).length;
  const zonderMedewerkerId = data.filter(v => !v.medewerkerId).length;
  const totaleAantal = data.length;

  return { metMedewerkerId, zonderMedewerkerId, totaleAantal };
};

const mapLicentie = createMapToUi(licentieKaartSchema).from<Licentie>({
  platformId: v => v.platformId,
  medewerkerId: v => v.medewerkerId,
  productsamenstellingId: v => v.productsamenstellingId,
  licentieovereenkomstId: v => v.licentieovereenkomstId,
  productsamenstellingNaam: v => v.productsamenstellingNaam
});

const mapSamenstelling = createMapToUi(samenstellingSchema)
  .with<SamenstellingContext>()
  .from<LicentiesOutput>({
    naam: (_v, s) => s.naam,
    id: (_, s) => s.id,
    aantalLicentiesInGebruik: (_v, s) => licentiesBerekening(s.licenties).metMedewerkerId,
    aantalLicenties: (_v, s) => licentiesBerekening(s.licenties).totaleAantal,
    aantalLicentiesBeschikbaar: (_v, s) => licentiesBerekening(s.licenties).zonderMedewerkerId,
    akkoordFacturatie: () => null,
    nieuwAantalLicenties: () => null,
    aangevraagdAantalLicenties: () => null,
    selectedVoorVerwijderen: () => null,
    submitButtonIsClicked: () => false,
    aangevraagdeLicentiesIsNegatief: () => null,
    waarschuwingsBericht: () => ""
  });

const mapSamenstellingen = createMapToUi(licentiesSchema)
  .with<(string | null)[]>()
  .from<LicentiesOutput>({
    licentieOvereenKomstId: _ => null,
    licenties: v => {
      const licentiesArray = [];
      for (const key in v.licenties) {
        if (v.licenties.hasOwnProperty(key)) {
          licentiesArray.push(mapLicentie(v.licenties[key]));
        }
      }
      return licentiesArray;
    },
    samenstelling: (v, samenstelling) =>
      samenstelling.map(id => {
        const context: SamenstellingContext = { id: id, licenties: [], naam: null };
        for (const key in v.licenties) {
          if (v.licenties.hasOwnProperty(key) && v.licenties[key].productsamenstellingId === id) {
            /* istanbul ignore else */
            if (context.naam === null) {
              context.naam = v.licenties[key].productsamenstellingNaam;
            }
            context.licenties.push(mapLicentie(v.licenties[key]));
          }
        }
        return mapSamenstelling(context)(v);
      })
  });

export function mapLicentiesDlToUi(data: LicentiesOutput): LicentiesState | null {
  const licenties = data && data.isValid && data.licenties ? data : null;

  if (licenties) {
    const samenstellingIds: (string | null)[] = [];

    /* istanbul ignore else */ if (licenties.licenties) {
      for (const key in licenties.licenties) {
        /* istanbul ignore else */
        if (licenties.licenties.hasOwnProperty(key)) {
          if (
            samenstellingIds.filter(id => {
              /* istanbul ignore next */
              if (licenties.licenties) {
                return id === licenties.licenties[key].productsamenstellingId;
              }
              return false;
            }).length === 0
          ) {
            samenstellingIds.push(licenties.licenties[key].productsamenstellingId);
          }
        }
      }
    }
    return mapSamenstellingen(samenstellingIds)(licenties);
  }

  return null;
}

const mapProduct = createMapToUi(productSchema)
  .with<string>()
  .from<Product>({
    id: (_, id) => id,
    productNaam: v => v.naam,
    code: v => v.code,
    actief: v => v.isActief,
    isOnlineBestelbaar: v => v.isOnlineBestelbaar,
    platformId: v => v.platformId
  });

const mapProducten = createMapToUi(productenSchema).from<ProductenOutput>({
  producten: (v: ProductenOutput) => {
    const productArray = [];
    for (const key in v.producten) {
      if (v.producten.hasOwnProperty(key)) {
        productArray.push(mapProduct(key)(v.producten[key]));
      }
    }

    return productArray;
  }
});

export function mapProductenDlToUI(data: ProductenOutput): ProductenType | null {
  const licentieaanbod = data && data.isValid && data.producten ? data : null;

  if (licentieaanbod) {
    return mapProducten(licentieaanbod);
  }

  return null;
}

const mapLicentieSamenstellingen = createMapToUi(productSamenstellingenSchema).from<ProductsamenstellingenOutput>({
  licentieSamenstellingen: v => {
    const productOnderdelenArray = [];
    for (const key in v.productSamenstellingen) {
      if (v.productSamenstellingen.hasOwnProperty(key)) {
        const samenstelling = v.productSamenstellingen[key];
        const context: SamenstellingContext = { id: key, licenties: [], naam: samenstelling.naam };

        productOnderdelenArray.push(
          mapSamenstelling(context)({
            isValid: true,
            licenties: null,
            message: "",
            validationResults: null
          })
        );
      }
    }

    return productOnderdelenArray;
  }
});

export function mapSamenstellingDlToUI(data: ProductsamenstellingenOutput): ProductSamenstellingenState | null {
  const productSamenstellingen = data && data.isValid && data.productSamenstellingen ? data : null;

  if (productSamenstellingen) {
    return mapLicentieSamenstellingen(productSamenstellingen);
  }

  return null;
}

const mapLicentieOvereenKomst = createMapToUi(licentieOvereenKomstSchema).from<LicentieovereenkomstenOutput>({
  licentieId: v => {
    const firstOvereenKomstId = null;
    for (const key in v.licentieovereenkomsten) {
      /* istanbul ignore else */ if (v.licentieovereenkomsten.hasOwnProperty(key)) {
        /* istanbul ignore next */ if (firstOvereenKomstId !== null) {
          return;
        }
        return key;
      }
    }
    return firstOvereenKomstId || "";
  }
});

export function mapLicentieOvereenkomstDlToUi(data: LicentieovereenkomstenOutput): LicentieOvereenkomstState | null {
  // && data.isValid && data.Contract ? data.Contract[contractId] : null;
  const licenties = data && data.isValid && data.licentieovereenkomsten ? data : null;

  if (licenties) {
    return mapLicentieOvereenKomst(licenties);
  }

  return null;
}
