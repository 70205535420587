import React, { ReactElement, useState } from "react";
import "adviesbox-shared";
import { DataGrid, AdviesBoxColumn, createSpanWithId, ModalButton, Icon } from "adviesbox-shared";
import {
  LicentieAanbodDeleteType,
  LicentieOnderdelenProductType,
  LicentiesAanbodState,
  ModalTypeEnum,
  ShowDeleteModal
} from "../infra/licentieaanbod-schema";
import { LicentieOnderdelenModal } from "../licentieonderdelen-modal/licentieonderdelen-modal";
import { useFormikContext } from "formik";
import { CellInfo } from "react-table-6";
import { mapLicentieOnderdeelModal } from "../licentieonderdelen-modal/map-onderdelen-modal";
import { LicentieaanbodDeleteModal } from "../delete-modal/licentieaanbod-delete-modal";
import memoizeOne from "memoize-one";
import { Button } from "react-bootstrap";
import { PaginationComponent } from "../../shared/components/pagination/pagination";
import { UserProps } from "../../shared/properties/user-props";
import { Gebruikersactie, MagActieUitvoeren, Matrixonderdeel } from "../../shared/autorisatie-matrix/autorisatie-matrix";

type LicentieonderdelenProps = { selected: number };

export const Licentieonderdelen = (props: LicentieonderdelenProps & UserProps): ReactElement => {
  const formik = useFormikContext<LicentiesAanbodState>();
  const { values } = formik;
  const selectedState = useState(0);
  const [selected, setSelected] = selectedState;
  const [showDeleteModal, setShowDeleteModal] = useState<ShowDeleteModal>({ selected: null, show: false });
  const magSchrijven = MagActieUitvoeren(
    Matrixonderdeel.Licentieaanbod,
    Gebruikersactie.Schrijven,
    props.user,
    props.userDetails
  );
  const magToevoegen = MagActieUitvoeren(
    Matrixonderdeel.Licentieaanbod,
    Gebruikersactie.Toevoegen,
    props.user,
    props.userDetails
  );
  const magVerwijderen = MagActieUitvoeren(
    Matrixonderdeel.Licentieaanbod,
    Gebruikersactie.Verwijderen,
    props.user,
    props.userDetails
  );
  const magLezen =
    magSchrijven ||
    magToevoegen ||
    magVerwijderen ||
    MagActieUitvoeren(Matrixonderdeel.Licentieaanbod, Gebruikersactie.Lezen, props.user, props.userDetails);

  const postNewOnderdeel = (data: LicentieOnderdelenProductType): void => {
    if (data.productNaam === "" || data.code === "") {
      return;
    }
    const onderdeelArray: LicentieOnderdelenProductType[] = values.licentieOnderdelen.licentieOnderdelen.map(
      onderdeel => {
        return onderdeel;
      }
    );

    onderdeelArray.push(data);

    formik.setFieldValue("licentieOnderdelen.loading", true);
    formik.setFieldValue("licentieOnderdelen.licentieOnderdelen", onderdeelArray);
  };

  const editOnderdeel = (data: LicentieOnderdelenProductType): void => {
    if (data.productNaam === "" || data.code === "") {
      return;
    }
    const onderdeelArray = values.licentieOnderdelen.licentieOnderdelen.map(onderdeel => {
      if (onderdeel.id === data.id) {
        onderdeel = data;
        onderdeel.hasChanged = true;
      }

      return onderdeel;
    });

    formik.setFieldValue("licentieOnderdelen.loading", true);
    formik.setFieldValue("licentieOnderdelen.licentieOnderdelen", onderdeelArray);
  };

  const deleteOnderdeel = (data: LicentieAanbodDeleteType): void => {
    formik.setFieldValue("licentieOnderdelen.loading", true);
    formik.setFieldValue(`licentieOnderdelen.licentieOnderdelen[${data.selected}].isDeleted`, true);
  };

  const DeleteButton = memoizeOne(
    (
      magVerwijderen: boolean,
      setShowPopup: React.Dispatch<React.SetStateAction<ShowDeleteModal>>,
      setSelected: React.Dispatch<React.SetStateAction<number>>
    ): ((cellInfo: CellInfo) => ReactElement) => {
      const cellDeleteButton = (c: CellInfo): ReactElement => {
        if (!magVerwijderen) return <></>;
        return (
          <div className="d-flex align-items-center justify-content-center w-100 h-100">
            <Button
              data-testid={`btn-delete-licentieonderdelen-${c.index}`}
              id={`btn-delete-licentieonderdelen-${c.index}`}
              variant="outline-secondary"
              onMouseUp={(event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
                event.button === 0 && event.stopPropagation();
                event.button === 0 && setShowPopup({ show: true, selected: c.index });
                event.button === 0 && setSelected(c.index);
              }}
              aria-label="bewerk knop"
            >
              <Icon name="prullenbak" alt="Verwijderen" />
            </Button>
          </div>
        );
      };

      return cellDeleteButton;
    }
  );

  const EditButton = memoizeOne(
    (
      magSchrijven: boolean
    ): ((cellInfo: CellInfo) => ReactElement) => {
      const cellEditButton = (c: CellInfo): ReactElement => {
        if (!magSchrijven) return <></>;
        return (<ModalButton
          parent={`licentieOnderdelen.licentieOnderdelen[${c.index}]`}
          distinguisher={"edit"}
          size="lg"
          content={<Icon name="pencil" alt="Bewerken" />}
          disabled={!magSchrijven}
        >
          <LicentieOnderdelenModal
            data={mapLicentieOnderdeelModal({ newObject: false, selected: c.index })(formik.values)}
            onSubmit={editOnderdeel}
            edit={true}
            readOnly={!magToevoegen}
          />
        </ModalButton>);
      };
      return cellEditButton;
    }
  );

  const licentieOnderdelenColumns = (): AdviesBoxColumn[] => [
    {
      Header: "Naam onderdeel",
      id: "productnaam",
      Cell: (c): ReactElement =>
        createSpanWithId(c.index, 1, c.original.productNaam, c.original.productNaam, "licentieonderdelen")
    },
    {
      Header: "Code",
      id: "code",
      Cell: (c): ReactElement => createSpanWithId(c.index, 2, c.original.code, c.original.code, "licentieonderdelen")
    },
    {
      Header: "Actief",
      id: "Verzekerden",
      Cell: (c): ReactElement =>
        createSpanWithId(c.index, 3, c.original.actief ? "Ja" : "Nee", "keuzeonderdeel-ja-nee", "licentieonderdelen")
    },
    {
      width: 35,
      id: "EditButton",
      Cell: EditButton(magSchrijven)
    },
    {
      width: 35,
      id: "DeleteButton",
      Cell: DeleteButton(magVerwijderen, setShowDeleteModal, setSelected)
    }
  ];

  if (!magLezen) return <></>;

  return (
    <>
      {
        <>
          <DataGrid
            name={`licentieOnderdelen.licentieOnderdelen`}
            columns={licentieOnderdelenColumns()}
            showButtonAddRow={magToevoegen}
            addNewButtonDistinguisher={"onderdeel"}
            popup={
              <LicentieOnderdelenModal
                data={mapLicentieOnderdeelModal({ newObject: true, selected: null })(values)}
                onSubmit={postNewOnderdeel}
                edit={false}
                readOnly={!magToevoegen}
              />
            }
            defaultPageSize={formik.values.licentieOnderdelen.productenInView}
            pageSize={formik.values.licentieOnderdelen.productenInView}
            showPagination={true}
            PaginationComponent={PaginationComponent(true, "licentieOnderdelen.productenInView", "onderdelen")}
            rowCaption="Onderdeel"
            editable={false}
          />
          <LicentieaanbodDeleteModal
            key={`licentieonderdelen-${selected}-deleteModal`}
            formik={formik}
            onDelete={deleteOnderdeel}
            show={showDeleteModal}
            closeModal={setShowDeleteModal}
            type={ModalTypeEnum.onderdeel}
          />
        </>
      }
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production")
  Licentieonderdelen.displayName = "Licentieonderdelen";
