import React, { ReactElement, useState } from "react";
import "adviesbox-shared";
import { DataGrid, AdviesBoxColumn, createSpanWithId, ModalButton, Icon } from "adviesbox-shared";
import {
  LicentieAanbodDeleteType,
  LicentiesAanbodState,
  LicentieSamenstellingProductType,
  ModalTypeEnum,
  ShowDeleteModal
} from "../infra/licentieaanbod-schema";
import { LicentieSamenStellingModal } from "../licentiesamenstelling-modal/licentiesamenstelling-modal";
import { useFormikContext } from "formik";
import { mapLicentieSamenstellingModal } from "../licentiesamenstelling-modal/map-licentiesamenstelling-modal";
import { CellInfo } from "react-table-6";
import { LicentieaanbodDeleteModal } from "../delete-modal/licentieaanbod-delete-modal";
import memoizeOne from "memoize-one";
import { Button } from "react-bootstrap";
import { PaginationComponent } from "../../shared/components/pagination/pagination";
import { UserProps } from "../../shared/properties/user-props";
import { Gebruikersactie, MagActieUitvoeren, Matrixonderdeel } from "../../shared/autorisatie-matrix/autorisatie-matrix";
type LicentiesamenstellingProps = { selected: number };

export const Licentiesamenstelling = (props: LicentiesamenstellingProps & UserProps): ReactElement => {
  const formik = useFormikContext<LicentiesAanbodState>();
  const { values } = formik;
  const selectedState = useState(0);
  const [selected, setSelected] = selectedState;
  const [showDeleteModal, setShowDeleteModal] = useState<ShowDeleteModal>({ show: false, selected: null });
  const magSchrijven = MagActieUitvoeren(
    Matrixonderdeel.Licentieaanbod,
    Gebruikersactie.Schrijven,
    props.user,
    props.userDetails
  );
  const magToevoegen = MagActieUitvoeren(
    Matrixonderdeel.Licentieaanbod,
    Gebruikersactie.Toevoegen,
    props.user,
    props.userDetails
  );
  const magVerwijderen = MagActieUitvoeren(
    Matrixonderdeel.Licentieaanbod,
    Gebruikersactie.Verwijderen,
    props.user,
    props.userDetails
  );
  const magLezen =
    magSchrijven ||
    magToevoegen ||
    magVerwijderen ||
    MagActieUitvoeren(Matrixonderdeel.Licentieaanbod, Gebruikersactie.Lezen, props.user, props.userDetails);

  const postNewSamenstelling = (data: LicentieSamenstellingProductType): void => {
    if (data.productNaam === "" || data.code === "") {
      return;
    }
    const samenstellingArray: LicentieSamenstellingProductType[] = values.licentieSamenstellingen.licentieSamenstellingen.map(
      samenstelling => {
        return samenstelling;
      }
    );

    samenstellingArray.push(data);

    formik.setFieldValue("licentieSamenstellingen.loading", true);
    formik.setFieldValue("licentieSamenstellingen.licentieSamenstellingen", samenstellingArray);
  };

  const editSamenstelling = (data: LicentieSamenstellingProductType): void => {
    if (data.productNaam === "" || data.code === "") {
      return;
    }
    const samenstellingArray = values.licentieSamenstellingen.licentieSamenstellingen.map(samenstelling => {
      if (samenstelling.id === data.id) {
        samenstelling = data;
        samenstelling.hasChanged = true;
      }

      return samenstelling;
    });

    formik.setFieldValue("licentieSamenstellingen.loading", true);
    formik.setFieldValue("licentieSamenstellingen.licentieSamenstellingen", samenstellingArray);
  };

  const deleteSamenstelling = (data: LicentieAanbodDeleteType): void => {
    formik.setFieldValue("licentieSamenstellingen.loading", true);
    formik.setFieldValue(`licentieSamenstellingen.licentieSamenstellingen[${data.selected}].isDeleted`, true);
  };

  const DeleteButton = memoizeOne(
    (
      magVerwijderen: boolean,
      setShowPopup: React.Dispatch<React.SetStateAction<ShowDeleteModal>>,
      setSelected: React.Dispatch<React.SetStateAction<number>>
    ): ((cellInfo: CellInfo) => ReactElement) => {
      const cellDeleteButton = (c: CellInfo): ReactElement => {
        const showDeleteButton = magVerwijderen &&
          c.original.code !== "ABX_ADM" && c.original.code !== "ABX_BASIS" && c.original.code !== "ABX_BMC";
        return (
          <div className="d-flex align-items-center justify-content-center w-100 h-100">
            {showDeleteButton && (
              <Button
                data-testid={`btn-verwijder-samenstelling-${c.index}`}
                id={`btn-verwijder-samenstelling-${c.index}`}
                variant="outline-secondary"
                onMouseUp={(event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
                  event.button === 0 && event.stopPropagation();
                  event.button === 0 && setSelected(c.index);
                  event.button === 0 && setShowPopup({ show: true, selected: c.index });
                }}
                aria-label="bewerk knop"
              >
                <Icon name="prullenbak" alt="Verwijderen" />
              </Button>
            )}
          </div>
        );
      };

      return cellDeleteButton;
    }
  );

  const editButton = (c: CellInfo): ReactElement => {
    return (
      <ModalButton
        parent={`licentieSamenstellingen.licentieSamenstellingen[${c.index}]`}
        size="lg"
        distinguisher={"edit"}
        content={<Icon name="pencil" alt="Bewerken" />}
      >
        <LicentieSamenStellingModal
          data={mapLicentieSamenstellingModal({ newObject: false, selected: c.index })(formik.values)}
          onSubmit={editSamenstelling}
          edit={true}
          readOnly={!magToevoegen}
        />
      </ModalButton>
    );
  };

  const licentieAanbodColumns = (): AdviesBoxColumn[] => [
    {
      Header: "Naam samenstelling",
      id: "productnaam",
      Cell: (c): ReactElement =>
        createSpanWithId(c.index, 1, c.original.productNaam, c.original.productNaam, "licentiesamenstelling")
    },
    {
      Header: "Code",
      id: "code",
      Cell: (c): ReactElement => createSpanWithId(c.index, 2, c.original.code, c.original.code, "licentiesamenstelling")
    },
    {
      Header: "Actief",
      id: "Verzekerden",
      Cell: (c): ReactElement =>
        createSpanWithId(c.index, 3, c.original.actief ? "Ja" : "Nee", "keuzeonderdeel-ja-nee", "licentiesamenstelling")
    },
    {
      Header: "Gek. Ond.",
      id: "Verzekerden",
      Cell: (c): ReactElement =>
        createSpanWithId(c.index, 4, c.original.onderdelenLength, c.original.onderdelenLength, "licentiesamenstelling")
    },
    {
      width: 35,
      id: "EditButton",
      Cell: editButton
    },
    {
      width: 35,
      id: "DeleteButton",
      Cell: DeleteButton(magVerwijderen, setShowDeleteModal, setSelected)
    }
  ];

  if (!magLezen) return <></>;

  /*:CODE:*/
  return (
    <>
      {
        <>
          <DataGrid
            name={`licentieSamenstellingen.licentieSamenstellingen`}
            columns={licentieAanbodColumns()}
            showButtonAddRow={magToevoegen}
            addNewButtonDistinguisher={"samenstelling"}
            popup={
              <LicentieSamenStellingModal
                data={mapLicentieSamenstellingModal({ newObject: true, selected: null })(formik.values)}
                onSubmit={postNewSamenstelling}
                edit={false}
                readOnly={!magToevoegen}
              />
            }
            rowCaption="Samenstelling"
            editable={false}
            pageSize={formik.values.licentieSamenstellingen.productenInView}
            defaultPageSize={formik.values.licentieSamenstellingen.productenInView}
            showPagination={true}
            PaginationComponent={PaginationComponent(
              true,
              "licentieSamenstellingen.productenInView",
              "samenstellingen"
            )}
          />
          <LicentieaanbodDeleteModal
            formik={formik}
            key={`licentieSamenstellingen-${selected}-deleteModal`}
            onDelete={deleteSamenstelling}
            type={ModalTypeEnum.samenstelling}
            show={showDeleteModal}
            closeModal={setShowDeleteModal}
          />
        </>
      }
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production")
  Licentiesamenstelling.displayName = "Licentiesamenstelling";
