import { Card, CardWrapper, FormFirstFocus, PlatformFoutenSamenvatting } from "adviesbox-shared";
import { Form, FormikErrors, FormikProps, useFormikContext } from "formik";
import React, { ReactElement, useEffect, useState } from "react";
import { Gebruikersactie, MagActieUitvoeren, Matrixonderdeel } from "../shared/autorisatie-matrix/autorisatie-matrix";
import { Debug } from "../shared/components/formik/Debug";
import { ISWSideEffects } from "../shared/components/isw-side-effects/isw-side-effects";
import { SaveButtonNoSaveData, SaveResult } from "../shared/components/save-button/save-button-no-save-data";
import { UserProps } from "../shared/properties/user-props";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { ContractCard } from "./contract/contract";
import { ContractProps, contractSchema, ContractState } from "./infra/contract-schema";
import { asyncContractSideEffects } from "./infra/determine-async-contract-side-effects";
import { syncContractSideEffects } from "./infra/determine-sync-contract-side-effects";
import { Organisatie } from "./organisatie/organisatie";

function hasErrors(c: FormikErrors<ContractState>): boolean {
  return !(
    (c.contracten === null || c.contracten === undefined) &&
    (c.organisatie === null || c.organisatie === undefined)
  );
}

const ContractComponent = (props: FormikProps<ContractState> & ContractProps & UserProps): ReactElement => {
  const formik = useFormikContext<ContractState>();
  const { values, setFieldValue } = formik;
  const [saveResult, setSaveResult] = useState<SaveResult>("default");

  useEffect(() => {
    const errors = formik.errors;
    if (hasErrors(errors)) setSaveResult("clientError");
    else if (saveResult === "clientError") setSaveResult("default");
  }, [formik, saveResult]);

  const magOpslaan =
    MagActieUitvoeren(Matrixonderdeel.Contract, Gebruikersactie.Schrijven, props.user, props.userDetails) ||
    MagActieUitvoeren(
      Matrixonderdeel.ContractBetaalgegevens,
      Gebruikersactie.Schrijven,
      props.user,
      props.userDetails
    ) ||
    MagActieUitvoeren(Matrixonderdeel.ContractContract, Gebruikersactie.Schrijven, props.user, props.userDetails) ||
    MagActieUitvoeren(Matrixonderdeel.ContractOrganisatie, Gebruikersactie.Schrijven, props.user, props.userDetails) ||
    MagActieUitvoeren(
      Matrixonderdeel.ContractContractLicentiesoort,
      Gebruikersactie.Schrijven,
      props.user,
      props.userDetails
    );

  let isLoading = !values.contracten.licenties.every(v => !v.loading);
  if (values.organisatie.loading) isLoading = true;

  if (isLoading) return <></>;

  return (
    <FormFirstFocus>
      <Form>
        <ISWSideEffects<ContractState> sync={syncContractSideEffects} async={asyncContractSideEffects} />

        <CardWrapper className="px-3" maxRowCount={2}>
          <div className="text-container">
            <h2>Contract</h2>

            {magOpslaan && (
              <div className="button-container">
                <SaveButtonNoSaveData
                  isEdited={values.hasChanged}
                  callBack={() => {
                    setFieldValue(`saveIsClicked`, true);
                  }}
                  saveResult={saveResult}
                />
              </div>
            )}
          </div>
        </CardWrapper>
        <PlatformFoutenSamenvatting />
        <CardWrapper className="px-3" maxRowCount={2}>
          {values.contracten.licenties.map((_, i) => (
            <Card title="Contract" key={`${i}-contract`}>
              <ContractCard key={`${i}-contract-card`} index={i} user={props.user} userDetails={props.userDetails} />
            </Card>
          ))}
          <Card title="Organisatie">
            <Organisatie user={props.user} userDetails={props.userDetails} />
          </Card>
        </CardWrapper>
        <Debug />
      </Form>
    </FormFirstFocus>
  );
};

export const Contract = withAdviesboxFormik<ContractProps & UserProps, ContractState>({
  // Transform outer props into form values
  mapPropsToValues: (e: ContractProps): ContractState => e,
  validationSchema: contractSchema
})(ContractComponent);
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") ContractCard.displayName = "Contract";
