import {
  ISWSideEffect,
  ISWSideEffectWithContext,
  ISWAsyncSideEffect,
  ISWAsyncContext,
  ISWAsyncCondition,
  ISWSideEffectSubBag,
  ISWSideEffectBagWithContext,
  forceReturnContext
} from "./isw-types";
/* istanbul ignore file */
export function createISWSideEffect<T>(handler: ISWSideEffect<T>): ISWSideEffect<T>;
export function createISWSideEffect<T, X>(handler: ISWSideEffectWithContext<T, X>): ISWSideEffectWithContext<T, X>;
export function createISWSideEffect(handler: any): any {
  return handler;
}

export function initISWSideEffect<T>(sideEffects: ISWSideEffect<T>): (nocontext?: undefined) => ISWSideEffect<T>;
export function initISWSideEffect<T, X>(
  sideEffects: ISWSideEffectWithContext<T, X>
): (context: X) => ISWSideEffectWithContext<T, X>;
export function initISWSideEffect<T, X>(
  sideEffects: ISWSideEffectWithContext<T, X>
): (context?: X) => ISWSideEffectWithContext<T, X> {
  return context => (bag): void | X => {
    if ((bag as any) === forceReturnContext) return context;
    const bagWithContext = { ...bag, context: context as any };
    return sideEffects({ ...bagWithContext, subset: createSubBag<T, X>([], bagWithContext) });
  };
}

let createISWAsyncSideEffectId = 1;
export function createISWAsyncSideEffect<T>(
  asyncSideEffect: ISWAsyncSideEffect<T, undefined>
): () => ISWAsyncContext<T, undefined>;
export function createISWAsyncSideEffect<T, X = undefined>(
  asyncSideEffect: ISWAsyncSideEffect<T, X>
): (context: X) => ISWAsyncContext<T, X>;
export function createISWAsyncSideEffect<T, X = undefined>(
  asyncSideEffect: ISWAsyncSideEffect<T, X>
): (context: X) => ISWAsyncContext<T, X> {
  const id = createISWAsyncSideEffectId++;
  return (context: X) => ({
    id: id,
    context,
    asyncSideEffect
  });
}
export function initISWAsyncSideEffect<T>(asyncCondition: ISWAsyncCondition<T, any>): () => ISWAsyncCondition<T>;
export function initISWAsyncSideEffect<T, X>(
  asyncCondition: ISWAsyncCondition<T, X>
): (context: X) => ISWAsyncCondition<T, X>;
export function initISWAsyncSideEffect<T, X>(
  asyncCondition: ISWAsyncCondition<T, X>
): (context: X) => ISWAsyncCondition<T, X> {
  return context => bag => {
    if ((bag as any) === forceReturnContext) return context;
    return asyncCondition({ ...bag, context: context as any });
  };
}

function getInObject(obj: { [index: string]: any }, pathArr: (string | number)[]): any {
  if (!obj || !pathArr) return;
  if (pathArr.length === 0) return obj;

  let i = 0;
  for (; i < pathArr.length - 1; i++) {
    obj = obj[pathArr[i]];
    if (typeof obj !== "object") return;
  }

  return obj[pathArr[i]];
}

export function createSubBag<T, X>(
  pathArr: string[],
  bag: Omit<ISWSideEffectBagWithContext<T, X>, "subset">
): ISWSideEffectSubBag<T, X> {
  return new Proxy(
    {},
    {
      get(target: { [index: string]: any }, name: string): any {
        if (name === "createWithContext" && typeof target["createWithContext"] === "undefined") {
          return (newcontext: any) => {
            const newbag: any =
              pathArr.length === 0
                ? { ...bag, context: newcontext }
                : {
                  has: getInObject(bag.has, pathArr),
                  draft: getInObject(bag.draft, pathArr),
                  prev: getInObject(bag.prev, pathArr),
                  init: getInObject(bag.init, pathArr),
                  context: newcontext
                };

            newbag.subset = createSubBag([], newbag);
            return newbag;
          };
        }
        if (name === "create" && typeof target["create"] === "undefined") {
          return () => {
            if (pathArr.length === 0) return bag;
            const newbag: any = {
              has: getInObject(bag.has, pathArr),
              draft: getInObject(bag.draft, pathArr),
              prev: getInObject(bag.prev, pathArr),
              init: getInObject(bag.init, pathArr),
              context: bag.context
            };
            newbag.subset = createSubBag([], newbag);
            return newbag;
          };
        }
        return createSubBag([...pathArr, name], bag);
      }
    }
  ) as any;
}
