import React, { ReactElement, useContext, useMemo } from "react";
import { AuthContext, ErrorPage, PageLoading, useRequestInit, withErrorBoundary } from "adviesbox-shared";
import useAbortableFetch from "use-abortable-fetch";
import { HdnNodenummersOutput, VestigingenOutput } from "../.generated/licenties/licentiestypes";
import { HdnNodenummer } from "./HdnNodenummer";
import { Title } from "../shared/components/title/title";
import { LicentiesApi } from "../licenties/infra/licenties-api";
import { MagActieUitvoeren, Matrixonderdeel } from "../shared/autorisatie-matrix/autorisatie-matrix";
import UserDetailsContext from "../shared/user-details/user-details-context";
import { RouteParams } from "../shared/paramrouting/paramrouting-context";
import { useParams } from "react-router-dom";

const HdnNodenummersAjaxComponent = (): ReactElement => {
  const params = useParams() as RouteParams;
  const { requestInit, settings } = useRequestInit();
  const { user } = useContext(AuthContext);
  const { userDetails } = useContext(UserDetailsContext);

  // als de requestedOrganisatie van de userDetails wijzigt, dan moet MagActieUitvoeren opnieuw bepaald worden
  const heeftToegang = useMemo(() => MagActieUitvoeren(Matrixonderdeel.HdnNodenummers, null, user, userDetails),
    [user, userDetails, userDetails.requestedOrganisatie]); // eslint-disable-line react-hooks/exhaustive-deps
  const hdnUrl = useMemo(() => LicentiesApi.getHdnNodenummersUrl(settings, params), [settings, params]);
  const vestigingenUrl = useMemo(() => LicentiesApi.getVestigingenUrl(settings, params), [settings, params]);

  const { loading: hdnLoading, error: hdnError, data: hdnData } = useAbortableFetch<HdnNodenummersOutput>(
    hdnUrl,
    requestInit
  );
  const { loading: vestigingenLoading, error: vestigingenError, data: vestigingenData } = useAbortableFetch<
    VestigingenOutput
  >(vestigingenUrl, requestInit);

  if (!heeftToegang) {
    return <ErrorPage error={{ ...Error("Geen rechten toegekend voor deze gebruiker"), status: 403 }} data={null} />;
  }

  if (hdnError) {
    return <ErrorPage error={hdnError} data={hdnError} />;
  }

  if (vestigingenError) {
    return <ErrorPage error={vestigingenError} data={vestigingenError} />;
  }

  if (!vestigingenData || !hdnData || vestigingenLoading || hdnLoading) {
    return <PageLoading />;
  }

  if (typeof hdnData === "string" || typeof vestigingenData === "string") {
    return <ErrorPage error={new Error(`invalid data type`)} data={hdnData} />;
  }

  return (
    <>
      <Title appName="HDN nodenummers" altTitle={"HDN nodenummers"} />
      <HdnNodenummer {...hdnData} {...vestigingenData} user={user} userDetails={userDetails}></HdnNodenummer>
    </>
  );
};

export const HdnNodenummersAjax = withErrorBoundary(HdnNodenummersAjaxComponent);
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production")
  HdnNodenummersAjax.displayName = "HdnNodenummersAjax";
