import { MenuConfigType } from "adviesbox-shared";
import { User } from "oidc-client";
import { MagActieUitvoeren, Matrixonderdeel } from "../shared/autorisatie-matrix/autorisatie-matrix";
import { RouteParams } from "../shared/paramrouting/paramrouting-context";
import { Medewerker } from "../shared/user-details/user-details-context";

export const menuConfig = (params: RouteParams, user: User | null, userDetails: Medewerker): MenuConfigType[] => [
  {
    name: "contracts",
    lists: [
      {
        name: "dev",
        title: "DEV menu",
        icon: "dev",
        isAllowed: () => process.env.NODE_ENV === "development",
        items: [
          {
            name: "organisatie",
            title: "Organisatie",
            link: `/organisatie`
          }
        ]
      },
      {
        name: "contract",
        title: "Contract",
        icon: "menucontract",
        link: `/organisatie/${params.organisatie}/contract`,
        isAllowed: () => MagActieUitvoeren(Matrixonderdeel.Contract, null, user, userDetails)
      },
      {
        name: "licenties",
        title: "Licenties",
        icon: "menulicenties",
        link: `/organisatie/${params.organisatie}/licenties`,
        isAllowed: () => MagActieUitvoeren(Matrixonderdeel.Licenties, null, user, userDetails)
      },
      {
        name: "vestigingen",
        title: "Vestigingen",
        icon: "menuvestigingen",
        link: `/organisatie/${params.organisatie}/vestigingen`,
        isAllowed: () => MagActieUitvoeren(Matrixonderdeel.Vestigingen, null, user, userDetails)
      },
      {
        name: "medewerkers",
        title: "Medewerkers",
        icon: "menumedewerkers",
        link: `/organisatie/${params.organisatie}/medewerkers`,
        isAllowed: () => MagActieUitvoeren(Matrixonderdeel.Medewerkers, null, user, userDetails)
      },
      {
        name: "gebruikersgroepen",
        title: "Gebruikersgroepen",
        icon: "menugebruikersgroepen",
        link: `/organisatie/${params.organisatie}/gebruikersgroepen`,
        isAllowed: () => MagActieUitvoeren(Matrixonderdeel.Gebruikersgroepen, null, user, userDetails)
      },
      {
        name: "licentieaanbod",
        title: "Licentieaanbod",
        icon: "menulicentieaanbod",
        link: `/organisatie/${params.organisatie}/licentieaanbod`,
        isAllowed: () => MagActieUitvoeren(Matrixonderdeel.Licentieaanbod, null, user, userDetails)
      },
      {
        name: "HDNNodenummer",
        title: "HDN nodenummers",
        icon: "menuhdnnodenummer",
        link: `/organisatie/${params.organisatie}/hdn-nodenummer`,
        isAllowed: () => MagActieUitvoeren(Matrixonderdeel.HdnNodenummers, null, user, userDetails)
      }
      // Logboek momenteel nog niet bruikbaar
      // {
      //   name: "logboek",
      //   title: "Logboek",
      //   icon: "menulogboek",
      //   link: `/organisatie/${params.organisatie}/logboek`
      // }
    ]
  }
];
