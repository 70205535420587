/* istanbul ignore file */
import React, { ReactElement } from "react";
import { Modal as ModalBootstrap } from "react-bootstrap";
import classes from "../licentieaanbod.module.scss";
import { ShowHasChangesModal } from "../infra/licentieaanbod-schema";

export enum ModalTypeEnum {
  product = 1,
  onderdeel = 2,
  samenstelling = 3
}

export type LicentieAanbodDeleteType = {
  selected: number;
  type: ModalTypeEnum;
};

type HasChangesModalProps = {
  type: ModalTypeEnum;
  onDeleteChanges: () => void;
  onConfirmSave: () => void;
  show: ShowHasChangesModal;
  closeModal: React.Dispatch<React.SetStateAction<ShowHasChangesModal>>;
};

export const HasChangesModal = ({
  closeModal,
  type,
  show,
  onConfirmSave,
  onDeleteChanges
}: HasChangesModalProps): ReactElement => {
  const modalType = (): string | null => {
    if (show.selected !== null) {
      switch (type) {
        case ModalTypeEnum.onderdeel:
          return "Licentieonderdeel";
        case ModalTypeEnum.samenstelling:
          return "Licentiesamenstelling";
        case ModalTypeEnum.product:
          return "Product";
        default:
          return null;
      }
    }

    return null;
  };

  if (show.selected === null) return <></>;
  return (
    <ModalBootstrap show={show.show}>
      <ModalBootstrap.Header>
        <div className="d-flex w-100">
          <ModalBootstrap.Title>Openstaande aanpassingen</ModalBootstrap.Title>
          <button
            type="button"
            className={"close ml-auto"}
            onClick={() => closeModal({ show: false, selected: null })}
            id={`btnClose-licentieaanbod-modal-${show.selected}`}
          >
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </div>
      </ModalBootstrap.Header>
      <ModalBootstrap.Body>
        <div>
          <p className="px-2">Er zijn nog aanpassingen in het huidige scherm wil je deze opslaan of verwijderen?</p>
        </div>
      </ModalBootstrap.Body>
      <ModalBootstrap.Footer className={classes.modal_footer}>
        <button
          type="button"
          data-testid={`${modalType()}-aanpassing-verwijderen-button`}
          className="btn btn-danger"
          id={`bevestig-verwijderen-licentieaanbod-modal-${show.selected}`}
          onClick={onDeleteChanges}
        >
          Gegevens terugzetten
        </button>
        <button
          type="button"
          className="btn btn-link"
          data-testid={`${modalType()}-aanpassing-anuleer-button`}
          id={`annuleer-verwijderen-licentieaanbod-modal-${show.selected}`}
          onClick={onConfirmSave}
        >
          Gegevens opslaan
        </button>

        <button
          type="button"
          className="btn btn-link"
          data-testid={`${modalType()}-aanpassing-anuleer-button`}
          id={`annuleer-verwijderen-licentieaanbod-modal-${show.selected}`}
          onClick={() => closeModal({ show: false, selected: null })}
        >
          Annuleren
        </button>
      </ModalBootstrap.Footer>
    </ModalBootstrap>
  );
};
