import { UiName } from "adviesbox-shared/utils/types";
import { HdnNodenummer, HdnNodenummersOutput, VestigingenOutput } from "../../.generated/licenties/licentiestypes";
import { UserProps } from "../../shared/properties/user-props";
import { FieldMap } from "../../shared/types";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import { target2field } from "../../shared/utils/target-to-field";
import { hdnNodenummerEntrySchema, HdnNodenummerState } from "./HdnNodenummer-schema";

export const mapHdnNodenumberEntries = createMapToUi(hdnNodenummerEntrySchema).from<HdnNodenummer & { id: string }>({
  nodeNummer: v => v.nodenummer,
  HdnNodenummerId: v => v.id,
  vestigingen: v =>
    v.vestigingen?.map(v => ({
      id: v.vestigingId,
      naam: v.naam
    })),
  actief: v => v.geactiveerd,
  geldigVoor: v => v.geldigVoor,
  hasChanges: () => false,
  isNieuw: () => false
});

export const HdnNodenummerdl2ui = (
  dl: HdnNodenummersOutput & VestigingenOutput & UserProps
): HdnNodenummerState & UserProps => {
  return {
    hdnNodenummerEntries: dl.hdnNodenummers
      ? Object.keys(dl.hdnNodenummers).map(key => {
          const current = dl.hdnNodenummers ? dl.hdnNodenummers[key] : /* istanbul ignore next */ null;

          /* istanbul ignore next */
          if (!current) {
            throw Error("key of a object is not available");
          }

          const result = mapHdnNodenumberEntries({ id: key, ...current });
          return { initial: result, current: result };
        })
      : [],
    hdnNodenummerEntriesInView: 10,
    vestigingenOptions: dl.vestigingen
      ? Object.keys(dl.vestigingen).map(key => {
          const current = dl.vestigingen ? dl.vestigingen[key] : /* istanbul ignore next */ null;

          /* istanbul ignore next */
          if (!current) {
            throw Error("key of a object is not available");
          }

          return { naam: current.naam, id: key };
        })
      : [],
    user: dl.user,
    userDetails: dl.userDetails
  };
};

export const mapHdnNodenummerDlTargetToUiField = (selected: number): ((target: string) => UiName | null) => (
  target: string
) => {
  const selectedHumanIndex = selected + 1;
  const map: FieldMap<HdnNodenummer> = {
    nodenummer: {
      field: `hdnNodenummerEntries[${selected}].current.nodeNummer`,
      label: `Product: ${selectedHumanIndex} - HDN Nodenummer`
    },
    vestigingen: {
      field: `hdnNodenummerEntries[${selected}].current.vestigingen`,
      label: `Product: ${selectedHumanIndex} - Vestigingen`
    }
  };

  return target2field(map, target);
};
