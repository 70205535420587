import { createMapToUi } from "../../shared/utils/create-map-to-ui";
/* istanbul ignore file */

import {
  samenstellingModalSchema,
  LicentiesAanbodState,
  LicentieOnderdelenProductType,
  licentieSamenstellingOnderdeelSchema,
  SamenstellingModalState,
  licentieSamenstellingProductSchema,
  LicentieSamenStellingOnderdeelType
} from "../infra/licentieaanbod-schema";

export type MapLicentieSamenstellingModalContext = {
  newObject: boolean;
  selected: number | null;
};

const mapOnderdeelObject = createMapToUi(licentieSamenstellingOnderdeelSchema)
  .with<Array<string>>()
  .from<LicentieOnderdelenProductType>({
    enabled: (v, c) => (v.id !== null ? c.includes(v.id) : false),
    id: v => v.id,
    name: v => v.productNaam
  });

export const mapLicentieSamenstellingModal = createMapToUi(samenstellingModalSchema)
  .with<MapLicentieSamenstellingModalContext>()
  .from<LicentiesAanbodState>({
    id: (v, c) => {
      if (
        c.newObject === false &&
        c.selected !== null &&
        v.licentieSamenstellingen.licentieSamenstellingen[c.selected]
      ) {
        return v.licentieSamenstellingen.licentieSamenstellingen[c.selected].id;
      }

      return "";
    },
    actief: (v, c) => {
      if (
        c.newObject === false &&
        c.selected !== null &&
        v.licentieSamenstellingen.licentieSamenstellingen[c.selected]
      ) {
        return v.licentieSamenstellingen.licentieSamenstellingen[c.selected].actief;
      }

      return true;
    },
    code: (v, c) => {
      if (
        c.newObject === false &&
        c.selected !== null &&
        v.licentieSamenstellingen.licentieSamenstellingen[c.selected]
      ) {
        return v.licentieSamenstellingen.licentieSamenstellingen[c.selected].code;
      }

      return "";
    },
    onderdelenObjectArray: (v, c) => {
      let idArray: string[] = [];

      if (
        c.newObject === false &&
        c.selected !== null &&
        v.licentieSamenstellingen.licentieSamenstellingen[c.selected]
      ) {
        idArray = v.licentieSamenstellingen.licentieSamenstellingen[c.selected].onderdelen;
      }
      const productOnderdelenArray = v.licentieOnderdelen.licentieOnderdelen.map(onderdeel =>
        mapOnderdeelObject(idArray)(onderdeel)
      );

      return productOnderdelenArray;
    },
    productNaam: (v, c) => {
      if (
        c.newObject === false &&
        c.selected !== null &&
        v.licentieSamenstellingen.licentieSamenstellingen[c.selected]
      ) {
        return v.licentieSamenstellingen.licentieSamenstellingen[c.selected].productNaam;
      }

      return "";
    },
    selected: (_, c) => (c.newObject ? null : c.selected)
  });

export const mapLicentieSamenstellingModalToScreen = createMapToUi(licentieSamenstellingProductSchema).from<
  SamenstellingModalState
>({
  actief: v => v.actief,
  id: v => v.id,
  code: v => v.code,
  hasChanged: v => false,
  isDeleted: v => false,
  onderdelen: v => {
    let enabledOnderdelenArray: LicentieSamenStellingOnderdeelType[] = [];

    if (v.onderdelenObjectArray) {
      enabledOnderdelenArray = v.onderdelenObjectArray.filter(onderdeel => {
        return onderdeel.enabled;
      });
    }

    if (enabledOnderdelenArray.length > 0) {
      return enabledOnderdelenArray.map(onderdeel => onderdeel.id);
    }

    return [];
  },
  onderdelenLength: v => {
    let enabledOnderdelenArray: LicentieSamenStellingOnderdeelType[] = [];

    if (v.onderdelenObjectArray) {
      enabledOnderdelenArray = v.onderdelenObjectArray.filter(onderdeel => {
        return onderdeel.enabled;
      });
    }

    return v.onderdelenObjectArray ? enabledOnderdelenArray.length : 0;
  },
  productNaam: v => v.productNaam
});
