import {
  hasNoRoleIsw,
  hasRoleIswBeheerder,
  hasRoleIswKlantBeheerder,
  hasRoleIswKlantUser,
  hasRoleIswUser
} from "adviesbox-shared";
import { User } from "oidc-client";
import { Medewerker } from "../user-details/user-details-context";
import { isEigenOrganisatie, isInterneOrganisatie } from "../user-details/utils/user-details-utils";

export enum Matrixonderdeel {
  // het Contract-scherm als geheel
  Contract,
  // de Contract-kaart op het Contract-scherm
  ContractContract,
  // wijzigen van de licentiesoort
  ContractContractLicentiesoort,
  // wijzigen van de duur proefperiode
  ContractContractDuurProefperiode,
  // de Organisatie-kaart van het Contract-scherm
  ContractOrganisatie,
  // de Betaalgegevens-kaart van het Contract-scherm
  ContractBetaalgegevens,
  // wijzigen van het e-mailadres van de financieel gemachtigde
  ContractBetaalgegevensEmailadresFinancieelGemachtigde,
  // de Betaalwijze-kaart op het Contract-scherm
  ContractBetaalwijze,
  // wijzigen van het contract (van proef) naar betaald
  ContractNaarBetaald,
  // het Vestigingen-scherm
  Vestigingen,
  // het Licenties-scherm
  Licenties,
  // de Licentieskaart op het Licentie-schwerm
  LicentiesLicentie,
  // wijzigen van het aantal licenties
  LicentiesAantalAanpassen,
  // het Medewerkers-scherm
  Medewerkers,
  // de mogelijkheid om medewerkers uit te nodigen
  MedewerkersUitnodigen,
  // het Gebruikersgroepen-scherm
  Gebruikersgroepen,
  // het HDN Nodenummers-scherm
  HdnNodenummers,
  // de mogelijkheid om HDN node nummers te activeren
  HdnNodenummersActiveren,
  // het Licentieaanbod-scherm
  Licentieaanbod
}
export enum Gebruikersactie {
  Lezen,
  Schrijven,
  Toevoegen,
  Verwijderen,
  Uitvoeren
}
export function MagActieUitvoeren(
  onderdeel: Matrixonderdeel,
  actie: Gebruikersactie | null,
  user: User | null,
  medewerker: Medewerker
): boolean {
  const isIswUser = hasRoleIswUser(user);
  const isIswBeheerder = hasRoleIswBeheerder(user);
  const isIswKlantUser = hasRoleIswKlantUser(user);
  const isIswKlantbeheerder = hasRoleIswKlantBeheerder(user);

  if (isInterneOrganisatie(medewerker)) {
    switch (onderdeel) {
      case Matrixonderdeel.Contract:
        switch (actie) {
          case Gebruikersactie.Lezen:
          case null:
            return isIswBeheerder || isIswUser;
        }
        break;
      case Matrixonderdeel.ContractContract:
      case Matrixonderdeel.Licenties:
      case Matrixonderdeel.LicentiesLicentie:
        switch (actie) {
          case Gebruikersactie.Lezen:
          case null:
            return isIswBeheerder || isIswUser;
          case Gebruikersactie.Schrijven:
            return isIswBeheerder;
        }
        break;
      case Matrixonderdeel.ContractBetaalgegevens:
      case Matrixonderdeel.ContractBetaalgegevensEmailadresFinancieelGemachtigde:
      case Matrixonderdeel.ContractOrganisatie:
        switch (actie) {
          case Gebruikersactie.Lezen:
            return isIswBeheerder;
        }
        break;
      case Matrixonderdeel.ContractContractLicentiesoort:
        switch (actie) {
          case Gebruikersactie.Schrijven:
            return isIswBeheerder && isEigenOrganisatie(medewerker);
        }
        break;
      case Matrixonderdeel.Vestigingen:
      case Matrixonderdeel.Medewerkers:
      case Matrixonderdeel.Gebruikersgroepen:
      case Matrixonderdeel.HdnNodenummers:
      case Matrixonderdeel.Licentieaanbod:
        switch (actie) {
          case Gebruikersactie.Lezen:
          case null:
            return isIswBeheerder || isIswUser;
          case Gebruikersactie.Schrijven:
          case Gebruikersactie.Toevoegen:
          case Gebruikersactie.Verwijderen:
            return isIswBeheerder;
        }
        break;
      case Matrixonderdeel.MedewerkersUitnodigen:
      case Matrixonderdeel.HdnNodenummersActiveren:
      case Matrixonderdeel.LicentiesAantalAanpassen:
        switch (actie) {
          case Gebruikersactie.Uitvoeren:
          case null:
            return isIswBeheerder;
        }
        break;
    }
  } else {
    // externe organisaties
    if (hasNoRoleIsw(user)) {
      // externe gebruiker
      switch (onderdeel) {
        case Matrixonderdeel.Contract:
        case Matrixonderdeel.ContractOrganisatie:
        case Matrixonderdeel.ContractBetaalgegevens:
        case Matrixonderdeel.ContractBetaalgegevensEmailadresFinancieelGemachtigde:
        case Matrixonderdeel.Licenties:
          return actie === Gebruikersactie.Lezen || actie === Gebruikersactie.Schrijven || actie === null;
        case Matrixonderdeel.ContractContract:
        case Matrixonderdeel.ContractBetaalwijze:
        case Matrixonderdeel.LicentiesLicentie:
          return actie === Gebruikersactie.Lezen || actie === null;
        case Matrixonderdeel.MedewerkersUitnodigen:
        case Matrixonderdeel.HdnNodenummersActiveren:
          return actie === Gebruikersactie.Uitvoeren || actie === null;
        case Matrixonderdeel.Vestigingen:
        case Matrixonderdeel.Medewerkers:
        case Matrixonderdeel.Gebruikersgroepen:
        case Matrixonderdeel.HdnNodenummers:
          return (
            actie === Gebruikersactie.Lezen ||
            actie === Gebruikersactie.Schrijven ||
            actie === Gebruikersactie.Toevoegen ||
            actie === Gebruikersactie.Verwijderen ||
            actie === null
          );
      }
    } else if (isIswBeheerder && isEigenOrganisatie(medewerker)) {
      switch (onderdeel) {
        case Matrixonderdeel.Contract:
        case Matrixonderdeel.ContractContract:
          return actie === Gebruikersactie.Lezen || actie == null;
        case Matrixonderdeel.ContractContractLicentiesoort:
          return actie === Gebruikersactie.Schrijven;
      }
    } else {
      if (isIswKlantbeheerder) {
        // de isw klant beheerder mag bijna alles
        if (
          onderdeel !== Matrixonderdeel.ContractBetaalgegevensEmailadresFinancieelGemachtigde &&
          onderdeel !== Matrixonderdeel.HdnNodenummersActiveren
        ) {
          if (
            onderdeel === Matrixonderdeel.ContractNaarBetaald ||
            onderdeel === Matrixonderdeel.LicentiesAantalAanpassen
          ) {
            return actie === Gebruikersactie.Uitvoeren || actie === null;
          }

          return true;
        }
      }
      if (isIswKlantUser) {
        // interne gebruikers met toegang tot klantgegevens
        switch (onderdeel) {
          case Matrixonderdeel.Contract:
          case Matrixonderdeel.ContractContract:
          case Matrixonderdeel.ContractBetaalgegevensEmailadresFinancieelGemachtigde:
            return actie === Gebruikersactie.Lezen || actie === null;
          case Matrixonderdeel.ContractBetaalwijze:
            return actie === Gebruikersactie.Lezen || actie === null;
          case Matrixonderdeel.ContractOrganisatie:
          case Matrixonderdeel.ContractBetaalgegevens:
            return actie === Gebruikersactie.Lezen || actie == null;
          case Matrixonderdeel.Vestigingen:
          case Matrixonderdeel.Licenties:
          case Matrixonderdeel.LicentiesLicentie:
          case Matrixonderdeel.Medewerkers:
          case Matrixonderdeel.HdnNodenummers:
          case Matrixonderdeel.Gebruikersgroepen:
            return actie === Gebruikersactie.Lezen || actie === null;
        }
      }
    }
  }
  return false;
}
