import { Icon, LabeledTextInput } from "adviesbox-shared";
import classNames from "classnames";
import React, { ReactElement } from "react";
import { Button } from "react-bootstrap";
import { StepProps } from "../organisatie-registreren";
import classes from "../registreren.module.scss";

export const AccountActiveren = ({ currentStep, setCurrentStep }: StepProps): ReactElement => {
  return (
    <>
      <h2 className={classNames(classes.title)}>Account activeren</h2>
      <div className="px-1">
        Je wachtwoord moet minimaal bevatten: <br />
        <div className={"my-2"}>
          <Icon name={"checkmark"} className={"px-2"} />8 karakters
        </div>
        <div className={"mt-2 mb-4"}>
          <Icon name={"checkmark"} className={"px-2"} />
          Niet gelijk aan uw gebruikersnaam
        </div>
      </div>
      <LabeledTextInput name={"wachtwoord"} caption={"Wachtwoord"} labelColSize={7} />
      <Button
        className={"float-right m-3"}
        onClick={() => {
          setCurrentStep(currentStep + 1);
        }}
      >
        Volgende
      </Button>
    </>
  );
};
