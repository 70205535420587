import {
  AdviesBoxColumn,
  Card,
  CardWrapper,
  createSpanWithId,
  DataGrid,
  hasValue,
  PageLoading,
  PlatformFoutenSamenvatting
} from "adviesbox-shared";
import { LabelValuePair } from "adviesbox-shared/utils/types";
import { Form, FormikProps, useFormikContext } from "formik";
import produce from "immer";
import memoizeOne from "memoize-one";
import React, { ReactElement, useState } from "react";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { CellInfo } from "react-table-6";
import { Gebruikersactie, MagActieUitvoeren, Matrixonderdeel } from "../shared/autorisatie-matrix/autorisatie-matrix";
import { Debug } from "../shared/components/formik/Debug";
import { ISWSideEffects } from "../shared/components/isw-side-effects/isw-side-effects";
import { PaginationComponent } from "../shared/components/pagination/pagination";
import { SaveButtonNoSaveData } from "../shared/components/save-button/save-button-no-save-data";
import { RouteParams } from "../shared/paramrouting/paramrouting-context";
import { UserProps } from "../shared/properties/user-props";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { GebruikersgroepenDetails } from "./gebruikersgroepen-details/gebruikersgroepen-details";
import { HasChangesModal } from "./has-changes-modal/has-changes-modal";
import { asyncGebruikersgroepenSideEffects } from "./infra/determine-async-gebruikersgroepen-side-effects";
import { syncGebruikersgroepenSideEffects } from "./infra/determine-sync-gebruikersgroepen-side-effects";
import { GebruikersgroepDeleteModal } from "./infra/gebruikergroep-delete-modal";
import {
  GebruikersgroepenProps,
  gebruikersgroepenSchema,
  GebruikersgroepenState,
  gebruikersgroepSchema,
  GebruikersgroepType,
  ShowDeleteModal,
  ShowHasChangesModal
} from "./infra/gebruikersgroepen-schema";
import ModalDeleteGebruikersgroepButton from "./infra/modalDeleteButton";

export const filterBeheerderGG = (gebruikersgroepen: GebruikersgroepType[]): GebruikersgroepType[] => {
  return gebruikersgroepen.filter(v => v.groepsNaam !== "Beheerder");
};

const GebruikersgroepenComponent = (
  props: FormikProps<GebruikersgroepenState> & GebruikersgroepenProps & UserProps
): ReactElement => {
  const formik = useFormikContext<GebruikersgroepenState>();
  const { values, setFieldValue } = formik;
  const { gebruikersgroepen } = formik.values;
  const { isSubmitting } = props;
  const selectedState = useState(0);
  const [selected, setSelected] = selectedState;
  const [newSelected, setNewSelected] = useState(0);
  const selectedGebruikersgroep =
    gebruikersgroepen.gebruikersgroep && gebruikersgroepen.gebruikersgroep[selected]
      ? gebruikersgroepen.gebruikersgroep[selected] /* istanbul ignore next */
      : null;
  const params = useParams() as RouteParams;

  const magSchrijven = MagActieUitvoeren(
    Matrixonderdeel.Gebruikersgroepen,
    Gebruikersactie.Schrijven,
    props.user,
    props.userDetails
  );
  const magToevoegen = MagActieUitvoeren(
    Matrixonderdeel.Gebruikersgroepen,
    Gebruikersactie.Toevoegen,
    props.user,
    props.userDetails
  );
  const magVerwijderen = MagActieUitvoeren(
    Matrixonderdeel.Gebruikersgroepen,
    Gebruikersactie.Verwijderen,
    props.user,
    props.userDetails
  );
  const magLezen =
    magSchrijven ||
    magToevoegen ||
    magVerwijderen ||
    MagActieUitvoeren(Matrixonderdeel.Gebruikersgroepen, Gebruikersactie.Lezen, props.user, props.userDetails);

  const [isDeleteModalOpen, setDeleteModalOpen] = useState<ShowDeleteModal>({ show: false, selected: null });
  /* istanbul ignore next */
  if (!hasValue(selectedGebruikersgroep) && gebruikersgroepen.gebruikersgroep.length) {
    setSelected(0);
    setFieldValue(`gebruikersgroepen.gebruikersgroep[0].formIsReset`, true); // to stop savebutton from re-appearing
  }

  /* istanbul ignore next */
  const deleteGebruikersgroep = (res: GebruikersgroepenState): void => {
    if (filterBeheerderGG(res.gebruikersgroepen.gebruikersgroep).length > 1)
      setFieldValue(
        `gebruikersgroepen.gebruikersgroep[${selected}].newGebruikersgroepAfterDelete`,
        res.gebruikersgroepen.gebruikersgroep[selected].newGebruikersgroepAfterDelete
      );
    else setFieldValue(`gebruikersgroepen.gebruikersgroep[${selected}].newGebruikersgroepAfterDelete`, null);
    setFieldValue(`gebruikersgroepen.gebruikersgroep[${selected}].isDeleted`, true);

    setDeleteModalOpen({ show: false, selected: 0 });
  };

  const deleteButton = memoizeOne(
    (
      setShowPopup: React.Dispatch<React.SetStateAction<ShowDeleteModal>>,
      updateSelected: (selected: number) => void,
      magVerwijderen: boolean
    ): ((cellInfo: CellInfo) => ReactElement) => {
      const cellDeleteButton = (c: CellInfo): ReactElement => {
        if (c.original?.groepsNaam === "Beheerder") {
          return <></>;
        }
        return (
          <ModalDeleteGebruikersgroepButton
            index={c.index}
            updateSelected={updateSelected}
            setShowPopup={setShowPopup}
          />
        );
      };
      const noButton = (c: CellInfo): ReactElement => {
        return <></>;
      };
      if (!magVerwijderen) return noButton;

      return cellDeleteButton;
    }
  );

  const [showHasChangesModal, setShowHasChangesModal] = useState<ShowHasChangesModal>({ show: false, selected: null });

  /* istanbul ignore next */
  const updateSelected = (newSelected: React.SetStateAction<number>, skip?: boolean, keepSelected?: boolean): void => {
    if (values.hasChanged && selected !== newSelected && !skip) {
      setShowHasChangesModal({ show: true, selected: selected });
      setSelected(selected);
      setNewSelected(newSelected);
      return;
    }

    if (keepSelected) {
      setSelected(selected);
      return;
    }

    setSelected(newSelected);
  };

  /* istanbul ignore next */
  const closeModalAndSwitchSelected = (keepSelected?: boolean): void => {
    if (showHasChangesModal.show) {
      setShowHasChangesModal({ show: false, selected: null });
      updateSelected(newSelected, true, keepSelected);
    }
  };

  const gebruikersGroepenColumns = (values: GebruikersgroepenState): AdviesBoxColumn[] => [
    {
      Header: "Product",
      id: "groepsnaam",
      accessor: "groepsNaam",
      Cell: (c): ReactElement => createSpanWithId(c.index, 1, c.original.groepsNaam)
    },
    {
      Header: "Toegang tot",
      id: "productonderdelenArray",
      accessor: "productOnderdelen",
      Cell: (c): ReactElement => {
        const newToegangTotArray: string[] = [];

        /* istanbul ignore next */
        if (c.original?.productOnderdelen)
          Object.keys(c.original.productOnderdelen).map(key =>
            key && c.original.productOnderdelen[key]?.gekoppeld ? newToegangTotArray.push(key) : null
          );

        return createSpanWithId(c.index, 2, newToegangTotArray?.join(", "), newToegangTotArray?.join(", "));
      }
    },
    {
      id: "deleteButton",
      Cell: deleteButton(setDeleteModalOpen, updateSelected, magVerwijderen),
      maxWidth: 30
    }
  ];

  const magOpslaan = magSchrijven || magToevoegen;

  if (!magLezen) return <></>;

  return (
    <Form>
      {isSubmitting && <PageLoading />}

      <ISWSideEffects<GebruikersgroepenState>
        sync={syncGebruikersgroepenSideEffects({
          selected,
          formik,
          setSelected: /* istanbul ignore next */ () => closeModalAndSwitchSelected()
        })}
        async={asyncGebruikersgroepenSideEffects({
          selected,
          organisatieId: /* istanbul ignore next */ params.organisatie ? params.organisatie : ""
        })}
      />

      <CardWrapper className="px-3">
        <div className="text-container">
          <h2>Gebruikersgroepen</h2>
        </div>
      </CardWrapper>

      <PlatformFoutenSamenvatting />

      <CardWrapper className="px-3 master-detail-card flex-grow-1">
        <Card className="w-xl-100 w-lg-100 w-md-50 w-50">
          <DataGrid
            masterDetail
            name="gebruikersgroepen.gebruikersgroep"
            rowCaption="Nieuwe gebruikersgroep"
            columns={gebruikersGroepenColumns(values)}
            rowSelected={[selected, updateSelected]}
            showButtonAddRow={magToevoegen}
            errorHighlight={false}
            getNewRowValues={(): GebruikersgroepType => ({
              ...gebruikersgroepSchema.default()
            })}
            showPagination={true}
            pageSize={values.gebruikersgroepenInView}
            defaultPageSize={values.gebruikersgroepenInView}
            PaginationComponent={PaginationComponent(true, "gebruikersgroepenInView", "gebruikersgroepen")}
          />
        </Card>
      </CardWrapper>
      {magOpslaan && (
        <CardWrapper className="pl-4 py-1">
          <div className="button-container">
            <SaveButtonNoSaveData
              isEdited={values.hasChanged}
              callBack={() => {
                setFieldValue(`gebruikersgroepen.gebruikersgroep[${selected}].saveIsClicked`, true);
                setFieldValue(`loading`, true);
              }}
            />
          </div>
        </CardWrapper>
      )}
      {selectedGebruikersgroep && (
        <GebruikersgroepenDetails selected={selected} user={props.user} userDetails={props.userDetails} />
      )}
      <Modal show={isDeleteModalOpen.show}>
        {isDeleteModalOpen.show && (
          <GebruikersgroepDeleteModal
            deleteModal={values.gebruikersgroepen?.gebruikersgroep[selected]?.groepsNaam !== "Beheerder"}
            formikValues={
              filterBeheerderGG(values.gebruikersgroepen.gebruikersgroep).length === 2 //when readonly in the selectInput will appear
                ? produce(values, draft => {
                    draft.gebruikersgroepen.gebruikersgroep[selected].newGebruikersgroepAfterDelete =
                      filterBeheerderGG(draft.gebruikersgroepen.gebruikersgroep) //the beheerder should nvr be an option
                        .find(v => v.groepsNaam !== values.gebruikersgroepen.gebruikersgroep[selected].groepsNaam)
                        ?.id ?? null;
                  })
                : values
            }
            index={selected}
            onDelete={deleteGebruikersgroep}
            closeModal={() => setDeleteModalOpen({ show: false, selected: 0 })}
            gebruikersgroepOptions={filterBeheerderGG(
              values.gebruikersgroepen.gebruikersgroep.filter((_, i) => i !== selected)
            ).map(
              /*istanbul ignore next */
              (v): LabelValuePair => ({
                label: v.groepsNaam,
                value: v.id ?? ""
              })
            )}
          />
        )}
      </Modal>
      <HasChangesModal
        closeModal={setShowHasChangesModal}
        show={showHasChangesModal}
        onDeleteChanges={
          /* istanbul ignore next */ () => {
            if (!values.gebruikersgroepen.gebruikersgroep[selected].id) {
              setFieldValue(`gebruikersgroepen.gebruikersgroep[${selected}].isDeleted`, true);
              return closeModalAndSwitchSelected(true);
            }
            const gebruikersgroepenInView = values.gebruikersgroepenInView;
            formik.resetForm();
            setFieldValue(`gebruikersgroepenInView`, gebruikersgroepenInView);
            setFieldValue(`gebruikersgroepen.gebruikersgroep[${selected}].formIsReset`, true);
            closeModalAndSwitchSelected(true);
          }
        }
        onConfirmSave={
          /* istanbul ignore next */ () => {
            setFieldValue(`gebruikersgroepen.gebruikersgroep[${selected}].formIsReset`, true);
            setFieldValue(`gebruikersgroepen.gebruikersgroep[${selected}].saveIsClicked`, true);
            setFieldValue(`gebruikersgroepen.gebruikersgroep[${selected}].triggeredByModal`, true);
            setFieldValue(`loading`, true);
          }
        }
      />
      <Debug />
    </Form>
  );
};

export const Gebruikersgroepen = withAdviesboxFormik<GebruikersgroepenProps & UserProps, GebruikersgroepenState>({
  // Transform outer props into form values
  mapPropsToValues: (e: GebruikersgroepenProps): GebruikersgroepenState => e,
  validationSchema: gebruikersgroepenSchema
})(GebruikersgroepenComponent);
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production")
  Gebruikersgroepen.displayName = "Gebruikersgroepen";
