import { FormikContextType } from "formik";
import { original } from "immer";
import { KlantdossierRechtenOptions } from "../.generated/licenties/licentiestypes";
import { initISWSideEffect } from "../shared/components/isw-side-effects/create-isw-helpers";
import { hasValue } from "../shared/utils/helpers";
import { LicentieEnum, MedewerkersState, ToegangsrechtenOrderEnum } from "./infra/medewerkers-schema";
import { volledigeNaam } from "./medewerkers-helper";

type Context = {
  selected: number;
  formik: FormikContextType<MedewerkersState>;
  setSelected: () => void;
};

export const syncLicentiebeheerMedewerkersSideEffects = initISWSideEffect<MedewerkersState, Context>(
  ({ has, draft, prev, context }): void => {
    if (hasValue(context.selected) && has.actualSelectedIndex.changed) {
      draft.emailSendSuccess = null;
    }

    if (
      hasValue(context.selected) &&
      (has.medewerkers[context.selected]?.persoonsgegevens.achternaam.changed ||
        has.medewerkers[context.selected]?.persoonsgegevens.voornaam.changed)
    ) {
      draft.medewerkers[context.selected].volledigeNaam = volledigeNaam(
        draft.medewerkers[context.selected].persoonsgegevens.voornaam,
        draft.medewerkers[context.selected].persoonsgegevens.voorletters,
        draft.medewerkers[context.selected].persoonsgegevens.tussenvoegsel,
        draft.medewerkers[context.selected].persoonsgegevens.achternaam,
        "geen naam"
      );
    }

    if (hasValue(context.selected) && has.medewerkers[context.selected]?.licentiegegevens?.gekozenVestigingen.changed) {
      draft.medewerkers[context.selected].gekozenVestigingenNaamList = draft.medewerkers[
        context.selected
      ]?.licentiegegevens?.gekozenVestigingen
        .filter(v => !!v?.naam)
        .map(v => v?.naam)
        .join(" , ");
    }

    if (
      hasValue(context.selected) &&
      has.medewerkers[context.selected]?.licentiegegevens?.gekozenGebruikersgroepen.changed
    ) {
      draft.medewerkers[context.selected].gekozenGebruikersgroepenNaamList = draft.medewerkers[
        context.selected
      ]?.licentiegegevens?.gekozenGebruikersgroepen
        .filter(v => !!v?.naam)
        .map(v => v?.naam)
        .join(" , ");
    }

    if (hasValue(context.selected) && has.medewerkers[context.selected]?.licentiegegevens.isActief.changed) {
      draft.medewerkers[context.selected].readOnlyIsActief = draft.medewerkers[context.selected]?.licentiegegevens
        .isActief
        ? "Ja"
        : "Nee";
    }

    if (
      hasValue(context.selected) &&
      draft.medewerkers[context.selected]?.medewerkerId &&
      has.medewerkers[context.selected]?.licentiegegevens.gekozenProductsamenstellingId.changed &&
      draft.medewerkers[context.selected]?.licentiegegevens.gekozenProductsamenstellingId
    ) {
      const gekozenId = draft.medewerkers[context.selected].licentiegegevens.gekozenProductsamenstellingId;
      draft.medewerkers[context.selected].gekozenlicentieNaam = draft.alleOrganisatieUniekeLicenties.filter(
        v => v.id === gekozenId
      )[0]?.naam;

      const licentieIdsInGebruik = draft.medewerkers.map(v => v.licentiegegevens.medewerkerLicentieId);
      const licentieBeschikbaar =
        gekozenId === LicentieEnum.Geen ||
        draft.alleOrganisatieLicenties?.find(
          v =>
            !v.medewerkerId &&
            v.productsamenstellingId ===
              draft.medewerkers[context.selected].licentiegegevens.gekozenProductsamenstellingId &&
            !licentieIdsInGebruik.includes(v.licentieId)
        );

      draft.licentieBeschikbaar = !!licentieBeschikbaar;
    }

    const organisatieToegangsrechten =
      hasValue(context.selected) && draft.medewerkers[context.selected]?.licentiegegevens?.organisatieToegangsrechten;

    const organisatieVorigeToegangsrechten =
      hasValue(context.selected) && prev.medewerkers[context.selected]?.licentiegegevens?.organisatieToegangsrechten;

    const vestigingVorigeToegangsrechten =
      hasValue(context.selected) && prev.medewerkers[context.selected]?.licentiegegevens?.vestigingToegangsrechten;

    const vestigingToegangsrechten =
      hasValue(context.selected) && draft.medewerkers[context.selected]?.licentiegegevens?.vestigingToegangsrechten;

    if (
      hasValue(context.selected) &&
      draft.medewerkers[context.selected]?.medewerkerId &&
      organisatieToegangsrechten &&
      vestigingToegangsrechten &&
      organisatieVorigeToegangsrechten &&
      vestigingVorigeToegangsrechten
    ) {
      if (
        has.medewerkers[context.selected]?.licentiegegevens.organisatieToegangsrechten.changed &&
        Number(ToegangsrechtenOrderEnum[organisatieToegangsrechten]) >
          Number(ToegangsrechtenOrderEnum[organisatieVorigeToegangsrechten]) &&
        Number(ToegangsrechtenOrderEnum[organisatieToegangsrechten]) >
          Number(ToegangsrechtenOrderEnum[vestigingToegangsrechten])
      )
        draft.medewerkers[context.selected].licentiegegevens.vestigingToegangsrechten =
          KlantdossierRechtenOptions[organisatieToegangsrechten];

      if (
        has.medewerkers[context.selected]?.licentiegegevens.vestigingToegangsrechten.changed &&
        Number(ToegangsrechtenOrderEnum[vestigingToegangsrechten]) <
          Number(ToegangsrechtenOrderEnum[vestigingVorigeToegangsrechten]) &&
        Number(ToegangsrechtenOrderEnum[vestigingToegangsrechten]) <
          Number(ToegangsrechtenOrderEnum[organisatieToegangsrechten])
      )
        draft.medewerkers[context.selected].licentiegegevens.organisatieToegangsrechten =
          KlantdossierRechtenOptions[vestigingToegangsrechten];
    }

    /* all of the below are the save button side-effects*/
    if (
      hasValue(context.selected) &&
      draft.medewerkers[context.selected]?.medewerkerId &&
      (has.medewerkers[context.selected]?.email.changed ||
        has.medewerkers[context.selected]?.contactgegevens?.telefoon.changed ||
        has.medewerkers[context.selected]?.persoonsgegevens?.achternaam.changed ||
        has.medewerkers[context.selected]?.persoonsgegevens?.tussenvoegsel.changed ||
        has.medewerkers[context.selected]?.persoonsgegevens?.voorletters.changed ||
        has.medewerkers[context.selected]?.persoonsgegevens?.voornaam.changed ||
        has.medewerkers[context.selected]?.licentiegegevens.isActief.changed ||
        has.medewerkers[context.selected]?.licentiegegevens?.organisatieToegangsrechten.changed ||
        has.medewerkers[context.selected]?.licentiegegevens?.gekozenVestigingen.changed ||
        has.medewerkers[context.selected]?.licentiegegevens?.vestigingToegangsrechten.changed) &&
      !draft.medewerkers[context.selected]?.formIsReset
    ) {
      draft.medewerkers[context.selected].hasChanged = true;
      draft.medewerkers[context.selected].saveIsClicked = false;
      draft.medewerkers[context.selected].shouldSave.medewerkerChanged = true; //for the medewerker put only
    }
    if (
      hasValue(context.selected) &&
      draft.medewerkers[context.selected]?.medewerkerId &&
      has.medewerkers[context.selected]?.licentiegegevens?.gekozenGebruikersgroepen.changed &&
      draft.heeftGebruikersgroepenInitialvalues &&
      !draft.medewerkers[context.selected]?.formIsReset
    ) {
      draft.medewerkers[context.selected].hasChanged = true;
      draft.medewerkers[context.selected].saveIsClicked = false;
      draft.medewerkers[context.selected].shouldSave.gebrukersGroepenChanged = true; //for the gebrukersGroepen put only
    }
    if (
      hasValue(context.selected) &&
      draft.medewerkers[context.selected]?.medewerkerId &&
      has.medewerkers[context.selected]?.licentiegegevens?.gekozenVestigingen.changed &&
      draft.heeftVestigingInitialvalues &&
      !draft.medewerkers[context.selected]?.formIsReset
    ) {
      draft.medewerkers[context.selected].hasChanged = true;
      draft.medewerkers[context.selected].saveIsClicked = false;
      draft.medewerkers[context.selected].shouldSave.vestigingenChanged = true;
    }
    if (
      hasValue(context.selected) &&
      draft.medewerkers[context.selected]?.medewerkerId &&
      has.medewerkers[context.selected]?.licentiegegevens?.gekozenProductsamenstellingId.changed &&
      !draft.medewerkers[context.selected]?.formIsReset
    ) {
      draft.medewerkers[context.selected].hasChanged = true;
      draft.medewerkers[context.selected].saveIsClicked = false;
      draft.medewerkers[context.selected].shouldSave.licentieRadioButtonChanged = true;
    }
    //if save is clicked and was successfull (button/modal)
    if (
      hasValue(context.selected) &&
      draft.medewerkers[context.selected]?.medewerkerId &&
      ((draft.medewerkers[context.selected]?.saveIsClicked &&
        has.medewerkers[context.selected]?.shouldSave.changed &&
        !draft.medewerkers[context.selected]?.shouldSave.vestigingenChanged &&
        !draft.medewerkers[context.selected]?.shouldSave.gebrukersGroepenChanged &&
        !draft.medewerkers[context.selected]?.shouldSave.medewerkerChanged &&
        !draft.medewerkers[context.selected]?.shouldSave.licentieRadioButtonChanged) ||
        draft.medewerkers[context.selected]?.formIsReset) //or if gegevens terugzetten is clicked
    ) {
      draft.medewerkers[context.selected].hasChanged = false;
      draft.medewerkers[context.selected].saveIsClicked = false;
      draft.medewerkers[context.selected].formIsReset = false;
      draft.medewerkers[context.selected].licentiegegevens.gekozenProductsamenstellingId =
        draft.medewerkers[context.selected].licentiegegevens.prevGekozenProductsamenstellingId;
      draft.licentieBeschikbaar = true;

      if (draft.medewerkers[context.selected].triggeredByModal) {
        //if save is triggered by the modal
        draft.medewerkers[context.selected].triggeredByModal = false;
        context.setSelected();
      }
    }

    //after every PUT
    if (has.shouldResetForm.changed && draft.shouldResetForm) {
      draft.shouldResetForm = false;
      const values = original(draft);
      if (values) {
        context.formik.resetForm({ values });
      }
    }
  }
);
