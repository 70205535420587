import { hasValue } from "adviesbox-shared";
import {
  Licentieovereenkomst,
  LicentiesoortOpties,
  NewOutput,
  Organisatie
} from "../../.generated/licenties/licentiestypes";
import {
  createISWAsyncSideEffect,
  initISWAsyncSideEffect
} from "../../shared/components/isw-side-effects/create-isw-helpers";
import { createMapEnum } from "../../shared/utils/create-map-enum";
import { ContractState, LicentieSoort, LicentieType } from "./contract-schema";

type Context = {
  index?: number;
  contract?: LicentieType;
};

const mapLicentieSoortEnumToDl = createMapEnum(LicentieSoort).to<LicentiesoortOpties>({
  Betaald: LicentiesoortOpties.Betaald,
  Proef: LicentiesoortOpties.Proef,
  Intern: LicentiesoortOpties.Intern
});

export const putOrganisatie = createISWAsyncSideEffect<ContractState>(async ({ draft, fetchData, settings }) => {
  await fetchData<NewOutput, Organisatie>({
    url: `${settings.licentiesOrigin}/Organisaties/${draft.organisatie.id}`,
    method: "PUT",
    body: {
      organisatieId: draft.organisatie.id,
      iban: draft.organisatie.iban,
      kvKnummer: draft.organisatie.kvkNummer,
      logo: draft.organisatie.logo,
      naam: draft.organisatie.bedrijfsnaam,
      platformId: draft.organisatie.platformId
    }
  });

  draft.organisatie.sendPut = false;
});

export const putLicentieOvereenkomst = createISWAsyncSideEffect<ContractState, Context>(
  async ({ draft, fetchData, settings, context }) => {
    if (context.contract && hasValue(context.index)) {
      const beginDatum = context.contract.begindatum.toString() + "T00:00";
      const eindDatum = context.contract.einddatum ? context.contract.einddatum.toString() + "T00:00" : null;
      await fetchData<NewOutput, Licentieovereenkomst>({
        url: `${settings.licentiesOrigin}/Licentieovereenkomsten/${context.contract.id}`,
        method: "PUT",
        body: {
          begindatum: beginDatum,
          contractId: context.contract.contractId,
          einddatum: eindDatum ? eindDatum : null,
          isActief: context.contract.isActief,
          isGeblokkeerd: context.contract.isGeblokkeerd,
          licentiesoort: mapLicentieSoortEnumToDl(context.contract.licentiesoort),
          organisatieId: context.contract.organisatieId,
          productId: context.contract.productId,
          productNaam: context.contract.productNaam,
          emailFinancieelGemachtigde: null,
          licentieovereenkomstDocumenten: null
        }
      });

      draft.contracten.licenties[context.index].sendPut = false;
    }
  }
);
export const asyncContractSideEffects = initISWAsyncSideEffect<ContractState>(({ curr, has, runAsync }) => {
  if (curr.hasChanged && has.saveIsClicked.changed && curr.saveIsClicked) {
    curr.contracten.licenties.forEach((c: LicentieType, i) => {
      if (curr.contracten.licenties[i].sendPut) {
        runAsync(putLicentieOvereenkomst({ contract: c, index: i }));
      }
    });

    if (curr.organisatie.sendPut) {
      runAsync(putOrganisatie());
    }
  }
})();
