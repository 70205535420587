import { hasValue } from "adviesbox-shared";
import { FormikContextType, FormikErrors } from "formik";
import { VestigingenSchermState, VestigingType } from "./infra/vestigingen-schema";

export const validateFormikValues = async (
  formik: FormikContextType<VestigingenSchermState>,
  selected: number
): Promise<FormikErrors<VestigingType> | null> => {
  return await formik.validateForm().then(unfulfilled => {
    if (unfulfilled && unfulfilled.vestigingen && typeof unfulfilled !== "string") {
      const errors =
        hasValue(unfulfilled.vestigingen) && (unfulfilled.vestigingen[selected] as FormikErrors<VestigingType>);
      if (errors) {
        formik.setTouched({
          vestigingen: {
            [selected]: {
              naam: errors?.naam,
              afmRegistratienummer: errors?.afmRegistratienummer,
              telefoonnummer: errors?.telefoonnummer,
              emailadres: errors?.emailadres,
              bezoekadres: errors?.bezoekadres,
              postadres: errors?.postadres
            }
          } as any
        });
        return errors;
      }
    }
    return null;
  });
};
