import { AdviesBoxFormik, ProgressBar, StepType } from "adviesbox-shared";
import { useFormikContext } from "formik";
import { default as React, ReactElement, useState } from "react";
import { ISWSideEffects } from "../shared/components/isw-side-effects/isw-side-effects";
import Modal from "../shared/components/modal/Modal";
import { getConfirmButtonText, getFooterElement, onSubmitWizard } from "./aantal-licenties-aanpassen-helper";
import { Stap1 } from "./aantal-licenties-aanpassen-modal-stap1";
import { Stap2 } from "./aantal-licenties-aanpassen-modal-stap2";
import classes from "./aantal-licenties-aanpassen-modal.module.scss";
import { asyncLicentiesAanvraagModalSideEffects } from "./infra/determine-async-licenties-aanvraag-modal-side-effects";
import { LicentiesState, samenstellingSchema, SamenstellingType } from "./infra/licenties-schema";

export type AantalLicentiesAanpassenProps = {
  data: LicentiesState;
  selected: number;
  onSave?: (data: SamenstellingType) => void;
  closeModal?: () => void;
  initialStep?: number;
  abortController?: AbortController;
};

export const AantalLicentiesAanpassenModal = ({
  data,
  selected,
  onSave,
  closeModal,
  initialStep = 1,
  abortController = new AbortController()
}: AantalLicentiesAanpassenProps): ReactElement => {
  const progressState = useState(initialStep);
  const { values, setFieldValue } = useFormikContext<LicentiesState>();
  const samenstelling = values.samenstelling[selected];
  const licentieOvereenKomstId = values.licentieOvereenKomstId;
  const [confirmed, setConfirmed] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [buttonIsDisabled, setButtonIsDisabled] = useState(true);
  const [currentStep, setCurrentStep] = progressState;
  const [amountOfRequestedLicensesIsNegative, setAmountOfRequestedLicensesIsNegative] = useState(false);

  return (
    <AdviesBoxFormik<SamenstellingType>
      initialValues={{
        ...values.samenstelling[selected],
        nieuwAantalLicenties: null,
        aangevraagdAantalLicenties: null
      }}
      validationSchema={samenstellingSchema}
      closeModal={closeModal}
      onSave={onSave}
      render={(formik): ReactElement => {
        const stepsPositive: { [key: string]: StepType } = {
          "Aantal licenties aanpassen": {
            type: "full",
            id: "AantalLicentiesAanpassenStep",
            component: (
              <Stap1
                amountOfRequestedLicensesIsNegative={amountOfRequestedLicensesIsNegative}
                setButtonIsDisabled={setButtonIsDisabled}
                setAmountOfRequestedLicensesIsNegative={setAmountOfRequestedLicensesIsNegative}
                formik={formik}
              />
            )
          },
          "Licenties bestellen": {
            type: "full",
            id: "LicentiesBestellenStep",
            component: (
              <Stap2
                amountOfRequestedLicensesIsNegative={amountOfRequestedLicensesIsNegative}
                formik={formik}
                showConfirm={showConfirm}
                setConfirmed={setConfirmed}
              />
            )
          }
        };
        const stepsNegative: { [key: string]: StepType } = {
          "Aantal licenties aanpassen": {
            type: "full",
            id: "AantalLicentiesAanpassenStep",
            component: (
              <Stap1
                amountOfRequestedLicensesIsNegative={amountOfRequestedLicensesIsNegative}
                setAmountOfRequestedLicensesIsNegative={setAmountOfRequestedLicensesIsNegative}
                setButtonIsDisabled={setButtonIsDisabled}
                formik={formik}
              />
            )
          },
          "Licenties opzeggen": {
            id: "LicentiesOpzeggenStep",
            type: "full",
            component: (
              <Stap2
                amountOfRequestedLicensesIsNegative={amountOfRequestedLicensesIsNegative}
                formik={formik}
                showConfirm={showConfirm}
                setConfirmed={setConfirmed}
              />
            )
          }
        };
        return (
          <div className="modal-aantal-licenties">
            <Modal
              title={`Aantal licenties ${values.samenstelling[selected].naam} aanpassen`}
              onCancelClick={() => {
                abortController.abort();
                closeModal && closeModal();
              }}
              onSubmitClick={async () => {
                await onSubmitWizard(
                  formik.values,
                  currentStep,
                  amountOfRequestedLicensesIsNegative
                    ? Object.keys(stepsNegative).length
                    : Object.keys(stepsPositive).length,
                  setCurrentStep,
                  setShowConfirm,
                  formik.setFieldValue,
                  amountOfRequestedLicensesIsNegative
                );

                if (formik.values.akkoordFacturatie) {
                  setFieldValue(`samenstelling[${selected}].submitButtonIsClicked`, true);
                }
              }}
              body={
                <>
                  <ISWSideEffects<SamenstellingType>
                    async={asyncLicentiesAanvraagModalSideEffects({ selected, samenstelling, licentieOvereenKomstId })}
                  />
                  <div className={classes.progressBar}>
                    <ProgressBar
                      progressState={progressState}
                      steps={amountOfRequestedLicensesIsNegative ? stepsNegative : stepsPositive}
                    />
                  </div>
                  <div>
                    {
                      Object.values(amountOfRequestedLicensesIsNegative ? stepsNegative : stepsPositive)[
                        currentStep - 1
                      ].component
                    }
                  </div>
                </>
              }
              prependToFooter={getFooterElement(
                currentStep,
                setCurrentStep,
                confirmed,
                setConfirmed,
                formik.setFieldValue
              )}
              submitDisabled={buttonIsDisabled}
              saveButtonTekst={getConfirmButtonText(currentStep, confirmed, amountOfRequestedLicensesIsNegative)}
            />
          </div>
        );
      }}
    />
  );
};
