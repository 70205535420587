/* istanbul ignore file */

import { createGetTextResources } from "../../shared/utils/text-resources-algemeen";

export const getVestigingenTextResources = createGetTextResources({
  naam: "Vul alsjeblieft een naam in.",
  afmRegistratienummer: "Vul alsjeblieft een AFM-vergunningsnummer in.",
  emailadres: "Vul alsjeblieft een e-mailadres in.",
  telefoonnummer: "Vul alsjeblieft een telefoonnummer in.",
  postcode: "Vul alsjeblieft een postcode in.",
  huisnummer: "Vul alsjeblieft een huisnummer in."
});
