import {
  KlantdossierRechtenOptions,
  OrganisatieEx,
  MedewerkerEx,
  MedewerkerExOutput
} from "../../.generated/licenties/licentiestypes";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import { volledigeNaam } from "../medewerkers-helper";
import {
  contactgegevensSchema,
  LicentieEnum,
  licentiegegevensSchema,
  medewerkerSchema,
  medewerkersSchema,
  MedewerkersState,
  OptiesMetExtraIdsType,
  OptiesType,
  OrganisatieLicentiesType,
  persoonsgegevensSchema,
  vestigingenType
} from "./medewerkers-schema";

const mapPersoonsgegevens = createMapToUi(persoonsgegevensSchema).from<MedewerkerEx>({
  voornaam: v => v.voornaam,
  voorletters: v => v.voorletters,
  tussenvoegsel: v => v.tussenvoegsels,
  achternaam: v => v.achternaam,
  geslacht: v => v.geslacht
});

const mapContactgegevens = createMapToUi(contactgegevensSchema).from<MedewerkerEx>({
  telefoon: v => v.telefoon,
  telefoonWerk: v => v.telefoonWerk,
  telefoonMobiel: v => v.telefoonMobiel,
  medewerkerIsGeregistreerd: _ => false
});

const mapLicentiegegevens = createMapToUi(licentiegegevensSchema)
  .with<{
    vestigingen: OptiesType[];
    gebruikersgroepen: OptiesType[];
    alleLicentieIds: OrganisatieLicentiesType[];
    medewerkerId: string;
  }>()
  .from<MedewerkerEx>({
    vestigingenId: v => v.vestigingen,
    gekozenVestigingen: (v, { vestigingen }) =>
      v.vestigingen?.map(id => vestigingen.filter(v => v.id === id)[0] ?? { id, naam: undefined }) ?? [],
    gebruikersgroepenId: v => v.gebruikersgroepen,
    gekozenGebruikersgroepen: (v, { gebruikersgroepen }) =>
      v.gebruikersgroepen?.map(id => gebruikersgroepen.filter(v => v.id === id)[0] ?? { id, naam: undefined }) ?? [],
    isActief: v => v.isActief,
    organisatieToegangsrechten: v => v.klantdossierRechtenOrganisatie ?? KlantdossierRechtenOptions.Geen,
    vestigingToegangsrechten: v => v.klantdossierRechtenVestigingen ?? KlantdossierRechtenOptions.Geen,
    gekozenProductsamenstellingId: v => v.productsamenstellingId ?? LicentieEnum.Geen,
    prevGekozenProductsamenstellingId: v => v.productsamenstellingId ?? LicentieEnum.Geen,
    isModal: _ => false,
    medewerkerLicentieId: (_, { alleLicentieIds, medewerkerId }) =>
      alleLicentieIds.find(v => v.medewerkerId === medewerkerId)?.licentieId ?? null
  });

const mapMedewerker = createMapToUi(medewerkerSchema)
  .with<{
    medewerkerId: string;
    index: number;
    vestigingen: OptiesType[];
    gebruikersgroepen: OptiesType[];
    alleLicentieIds: OrganisatieLicentiesType[];
  }>()
  .from<MedewerkerEx>({
    persoonsgegevens: v => mapPersoonsgegevens(v),
    volledigeNaam: v => volledigeNaam(v.voornaam, v.voorletters, v.tussenvoegsels, v.achternaam, "geen naam"),
    contactgegevens: v => mapContactgegevens(v),
    emailIsAlGebruikt: _ => false,
    email: v => v.email,
    licentiegegevens: (v, obj) => mapLicentiegegevens(obj)(v),
    readOnlyIsActief: v => (v.isActief ? "Ja" : "Nee"),
    gekozenlicentieNaam: v => v.productsamenstelling ?? LicentieEnum.Geen,
    medewerkerId: (_, { medewerkerId }) => medewerkerId,
    productId: _ => null,
    saveIsClicked: _ => false,
    hasChanged: _ => false,
    formIsReset: _ => false,
    triggeredByModal: _ => false,
    errors: _ => [],
    platformId: v => v.platformId,
    shouldSave: _ => ({
      licentieRadioButtonChanged: false,
      vestigingenChanged: false,
      gebrukersGroepenChanged: false,
      medewerkerChanged: false
    }),
    gekozenGebruikersgroepenNaamList: (v, { gebruikersgroepen }) =>
      v.gebruikersgroepen?.map(id => gebruikersgroepen.filter(v => v.id === id)[0]?.naam ?? "").join(" , "),
    gekozenVestigingenNaamList: (v, { vestigingen }) =>
      v.vestigingen?.map(id => vestigingen.filter(v => v.id === id)[0]?.naam ?? "").join(" , "),
    index: (_, { index }) => index
  });

export const mapMedewerkers = createMapToUi(medewerkersSchema)
  .with<{
    vestigingen: OptiesType[];
    gebruikersgroepen: OptiesType[];
    organisatie: OrganisatieEx | null;
    organisatieId: string | null;
    vestigingenDetails: vestigingenType[];
    alleOrganisatieUniekeLicenties: OptiesMetExtraIdsType[];
    alleLicentieIds: OrganisatieLicentiesType[];
  }>()
  .from<MedewerkerExOutput>({
    medewerkers: (v, obj) => {
      const medewerkersList = [];
      let index = 0;
      for (const key in v.medewerkers) {
        if (v.medewerkers.hasOwnProperty(key)) {
          medewerkersList.push(mapMedewerker({ medewerkerId: key, index, ...obj })(v.medewerkers[key]));
          index++;
        }
      }

      return medewerkersList;
    },
    medewerkersInView: _ => 10,
    organisatieGegevens: (_, { organisatie }) => organisatie,
    organisatieId: (_, { organisatieId }) => organisatieId,
    alleOrganisatieVestigingen: (_, { vestigingen }) => vestigingen,
    vestigingen: (_, { vestigingenDetails }) => vestigingenDetails,
    alleOrganisatieGebruikersgroepen: (_, { gebruikersgroepen }) => gebruikersgroepen,
    heeftProductsamenstellingInitialvalues: _ => false,
    heeftVestigingInitialvalues: _ => false,
    heeftGebruikersgroepenInitialvalues: _ => false,
    medewerkerRegistreren: _ => false,
    onLoadingData: _ => false,
    shouldResetForm: _ => false,
    actualSelectedIndex: _ => 0,
    alleOrganisatieUniekeLicenties: (_, { alleOrganisatieUniekeLicenties }) => alleOrganisatieUniekeLicenties,
    alleOrganisatieLicenties: (_, { alleLicentieIds }) => alleLicentieIds,
    emailSendSuccess: _ => null,
    licentieBeschikbaar: _ => null
  });

export function mapMedewerkersDlToUi(
  data: MedewerkerExOutput | null,
  vestigingen: OptiesType[],
  gebruikersgroepen: OptiesType[],
  organisatie: OrganisatieEx | null,
  organisatieId: string,
  vestigingenDetails: vestigingenType[],
  alleOrganisatieUniekeLicenties: OptiesMetExtraIdsType[],
  alleLicentieIds: OrganisatieLicentiesType[]
): MedewerkersState | null {
  const medewerkers = data && data.isValid && data.medewerkers ? data : null;

  if (medewerkers) {
    return mapMedewerkers({
      vestigingen,
      gebruikersgroepen,
      organisatie,
      organisatieId,
      vestigingenDetails,
      alleOrganisatieUniekeLicenties,
      alleLicentieIds
    })(medewerkers);
  }

  return null;
}
