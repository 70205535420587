import { AuthContext, ErrorPage, FeatureComponent, getCookie, getOTAP, Icon, InvalidAdviesboxResultErrorPage, Notificaties2Menu, NotificatiesMenu, PageLoading, SupportMenu, SupportRemoteAssistanceModal, SupportTicketModalAjax, useRequestInit } from "adviesbox-shared";
import classnames from "classnames";
import React, { ReactElement, useContext, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useAbortableFetch from "use-abortable-fetch";
import { LicentiesApi } from "../../../licenties/infra/licenties-api";
import { OrganisatiesOutput, VestigingenOutput } from "../../../.generated/licenties/licentiestypes";
import UserDetailsContext from "../../user-details/user-details-context";
import classes from "./TopNavbar.module.scss";
import "./TopNavbar.scss"

type TopNavBarProps = {
  home?: boolean;
};

type AdvieseurMenuProps = {
  vestigingId: string;
}

const AdviseurMenu = ({ vestigingId }: AdvieseurMenuProps): ReactElement => {
  const { signout, user } = useContext(AuthContext);
  const { userDetails } = useContext(UserDetailsContext);
  const { vestiging: vestigingParam } = useParams<{ vestiging: string }>();
  const { params, settings, requestInit } = useRequestInit();

  let vestigingUrl = vestigingParam ? LicentiesApi.getVestigingUrl(settings, { vestiging: vestigingParam }) : "";

  if (vestigingUrl.length === 0) {
    vestigingUrl = `${settings.licentiesOrigin}/Vestigingen/${vestigingId}`;
  }
  const vestiging = useAbortableFetch<VestigingenOutput>(
    `${settings.licentiesOrigin}/Vestigingen/${vestigingId}`,
    requestInit
  );

  const organisatieId = userDetails.organisatieId ? userDetails.organisatieId : null;
  const organisatieUrl = organisatieId ? `${settings.licentiesOrigin}/Organisaties/${organisatieId}` : null;
  const organisatie = useAbortableFetch<OrganisatiesOutput>(organisatieUrl, requestInit);


  if (vestiging.error) {
    return <ErrorPage error={vestiging.error} />;
  }

  if (organisatie.error) {
    return <ErrorPage error={organisatie.error} />;
  }

  if (vestiging.loading || !vestiging.data || organisatie.loading || !organisatie.data) {
    return <PageLoading />;
  }

  const vestigingNaam =
    vestiging.data && typeof vestiging.data !== "string" && vestiging.data.isValid && params.vestiging
      ? vestiging.data.vestigingen?.[params.vestiging].naam
      : "";
  const organisatieNaam =
    organisatie.data && typeof organisatie.data !== "string" && organisatieId
      ? organisatie.data.organisaties?.[organisatieId].naam
      : "";

  return <Dropdown className="navigation-bar__item">
    <Dropdown.Toggle variant="link" id="dropdown-basic-adviseur" className="nav-link">
      <span className="adviseur-naam" data-testid="dropdown-adviseur">
        <Icon name="menumedewerkers" alt="open" />
      </span>
    </Dropdown.Toggle>

    <Dropdown.Menu>
      <div className={classes.dropdown_item}>
        <span className={classnames("small-text", classes.small_text)}>
          {user && user.profile && user.profile.name}
        </span>
        <div className={classnames("small-text", classes.small_text)}>{vestigingNaam}</div>
        <span className={classnames("small-text", classes.small_text)}>{organisatieNaam}</span>
      </div>
      <Dropdown.Item onClick={signout} id="menu-item-uitloggen">
        Uitloggen
      </Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
}

const TopNavbar = ({ home }: TopNavBarProps): ReactElement => {
  const { user } = useContext(AuthContext);
  const { params } = useRequestInit();
  const [showMeldingmaken, setShowMeldingMaken] = useState(false);
  const [showRemoteAssistance, setShowRemoteAssistance] = useState(false);
  const [showInvalidAdviesboxResultErrorPage, setShowInvalidAdviesboxResultErrorPage] = useState(false);

  const vestigingIdFromCookie = getCookie(`${getOTAP(window.location.hostname)}_vestigingid`);
  const vestigingId = typeof vestigingIdFromCookie === "string" ? vestigingIdFromCookie : "";

  if (showInvalidAdviesboxResultErrorPage) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  if (!user) {
    return <ErrorPage error={new Error("user not logged in")} />;
  }

  return (
    <div className="navigation-bar">
        <div className="navigation-bar__header">
          <h1 className="navigation-bar__title">Contract</h1>
        </div>
        <div className="navigation-bar__content">
          <div className="navigation-bar__actions">
            <SupportMenu
              showMeldingMaken={() => setShowMeldingMaken(true)}
              showRemoteAssistance={() => setShowRemoteAssistance(true)}
              classes={{
                iconblack: classes.iconblack,
                iconpadding: classes.iconpadding
              }}
            />
            <FeatureComponent feature="FeatureNotificaties2">
              <Notificaties2Menu />
            </FeatureComponent>
            <FeatureComponent feature="FeatureNotificaties2" isDisabled>
              <NotificatiesMenu />
            </FeatureComponent>
            <AdviseurMenu vestigingId={vestigingId} />
          </div>
        </div>
        {/* istanbul ignore next */ showMeldingmaken && user && (
          <SupportTicketModalAjax
            adviesdossierId={params.adviesdossier ?? null}
            user={user}
            vestigingId={vestigingId}
            closeModal={() => setShowMeldingMaken(false)}
            showInvalidResultErrorPage={() => setShowInvalidAdviesboxResultErrorPage(true)}
        />
      )}
      {/* istanbul ignore next */ showRemoteAssistance && (
        <SupportRemoteAssistanceModal closeModal={() => setShowRemoteAssistance(false)} />
      )}
    </div>
  );
};

TopNavbar.displayName = "TopNavbar";

export default TopNavbar;
