import React, { ReactElement } from "react";
import { LabeledTextInput, LabeledBevestigingInput } from "adviesbox-shared";
import { AdresInput } from "../../shared/components/adres/adres-input";
import { VestigingenSchermState } from "../infra/vestigingen-schema";
import { useFormikContext } from "formik";
import { UserProps } from "../../shared/properties/user-props";
import {
  Gebruikersactie,
  MagActieUitvoeren,
  Matrixonderdeel
} from "../../shared/autorisatie-matrix/autorisatie-matrix";

type VestigingsgegevensProps = { selected: number };

export const Vestigingsgegevens = (props: VestigingsgegevensProps & UserProps): ReactElement => {
  const { values } = useFormikContext<VestigingenSchermState>();
  const vestiging = values.vestigingen[props.selected];

  const afwijkendPostadresTonen = vestiging.afwijkendPostadresTonen ? true : false;
  const waarschuwingsberichtTonen = vestiging.waarschuwingsBericht ? true : false;

  const isReadOnly = !(
    MagActieUitvoeren(Matrixonderdeel.Vestigingen, Gebruikersactie.Toevoegen, props.user, props.userDetails) ||
    MagActieUitvoeren(Matrixonderdeel.Vestigingen, Gebruikersactie.Schrijven, props.user, props.userDetails)
  );

  return (
    <>
      <LabeledTextInput
        name={`vestigingen[${props.selected}].naam`}
        caption="Naam"
        verplicht={true}
        readOnly={isReadOnly}
      />
      <AdresInput
        name={`vestigingen[${props.selected}].bezoekadres`}
        plaatsCaption="Woonplaats"
        verplicht={true}
        readOnly={isReadOnly}
      />
      <LabeledTextInput
        name={`vestigingen[${props.selected}].afmRegistratienummer`}
        caption="AFM-vergunningsnummer"
        verplicht={true}
        readOnly={isReadOnly}
      />
      <LabeledTextInput name={`vestigingen[${props.selected}].platformId`} caption="Platform-ID" readonly />
      <LabeledBevestigingInput
        name={`vestigingen[${props.selected}].isHoofdvestiging`}
        caption="Hoofdvestiging"
        appendChildren={
          <div className="foutmelding">{waarschuwingsberichtTonen && vestiging.waarschuwingsBericht}</div>
        }
        readonly={isReadOnly}
      />
      <LabeledBevestigingInput
        name={`vestigingen[${props.selected}].afwijkendPostadresTonen`}
        caption="Afwijkend postadres"
        readonly={isReadOnly}
      />
      {afwijkendPostadresTonen && (
        <AdresInput
          name={`vestigingen[${props.selected}].postadres`}
          metLand={true}
          plaatsCaption="Woonplaats"
          verplicht={true}
          readOnly={isReadOnly}
        />
      )}
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production")
  Vestigingsgegevens.displayName = "Vestigingsgegevens";
