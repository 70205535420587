import { hasValue } from "adviesbox-shared";
import { FormikContextType } from "formik";
import { original } from "immer";
import { initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { GebruikersgroepenState } from "./gebruikersgroepen-schema";

type ContextType = {
  selected: number;
  formik: FormikContextType<GebruikersgroepenState>;
  setSelected: () => void;
};

export const syncGebruikersgroepenSideEffects = initISWSideEffect<GebruikersgroepenState, ContextType>((bag): void => {
  const { draft, has, context } = bag;

  //if an unfilled record is deleted
  if (
    has.gebruikersgroepen.gebruikersgroep[context.selected]?.isDeleted.changed &&
    draft.gebruikersgroepen.gebruikersgroep[context.selected]?.isDeleted &&
    !draft.gebruikersgroepen.gebruikersgroep[context.selected]?.id
  ) {
    draft.gebruikersgroepen.gebruikersgroep.splice(context.selected, 1);
  }
  // if gebruikersgroep changed
  if (
    hasValue(context.selected) &&
    has.gebruikersgroepen.gebruikersgroep.changed &&
    !has.gebruikersgroepen.gebruikersgroep[context.selected].saveIsClicked.changed &&
    !has.gebruikersgroepen.gebruikersgroep[context.selected].newGebruikersgroepAfterDelete.changed &&
    !has.gebruikersgroepen.gebruikersgroep[context.selected].isDeleted.changed &&
    !draft.gebruikersgroepen.gebruikersgroep[context.selected].formIsReset
  ) {
    draft.hasChanged = true;
    draft.gebruikersgroepen.gebruikersgroep[context.selected].saveIsClicked = false;
    draft.gebruikersgroepen.gebruikersgroep[context.selected].shouldSave.gebruikersGroepenChanged = true;
  }

  // ToegangTot changed
  if (
    hasValue(context.selected) &&
    has.gebruikersgroepen.gebruikersgroep[context.selected].toegangTot.changed &&
    !has.gebruikersgroepen.gebruikersgroep[context.selected].saveIsClicked.changed &&
    !has.gebruikersgroepen.gebruikersgroep[context.selected].newGebruikersgroepAfterDelete.changed &&
    !has.gebruikersgroepen.gebruikersgroep[context.selected].isDeleted.changed &&
    !draft.gebruikersgroepen.gebruikersgroep[context.selected].formIsReset
  ) {
    draft.hasChanged = true;
    draft.gebruikersgroepen.gebruikersgroep[context.selected].saveIsClicked = false;
    draft.gebruikersgroepen.gebruikersgroep[context.selected].shouldSave.toegangTotChanged = true;
  }

  //Succesvolle savebutton click
  if (
    hasValue(context.selected) &&
    ((draft.gebruikersgroepen.gebruikersgroep[context.selected]?.saveIsClicked &&
      has.gebruikersgroepen.gebruikersgroep[context.selected]?.shouldSave.changed &&
      !draft.gebruikersgroepen.gebruikersgroep[context.selected]?.shouldSave.gebruikersGroepenChanged &&
      !draft.gebruikersgroepen.gebruikersgroep[context.selected].shouldSave.toegangTotChanged) ||
      (draft.gebruikersgroepen.gebruikersgroep[context.selected]?.formIsReset &&
        !has.gebruikersgroepen.gebruikersgroep[context.selected].triggeredByModal.changed)) // Of als gegevens terugzetten geselecteerd wordt
  ) {
    draft.hasChanged = false;
    draft.gebruikersgroepen.gebruikersgroep[context.selected].saveIsClicked = false;
    draft.gebruikersgroepen.gebruikersgroep[context.selected].formIsReset = false;

    //Als save getriggerd wordt door het modal
    if (draft.gebruikersgroepen.gebruikersgroep[context.selected].triggeredByModal) {
      draft.gebruikersgroepen.gebruikersgroep[context.selected].triggeredByModal = false;
      context.setSelected();
    }
  }

  // na iedere PUT of POST
  /* istanbul ignore next */
  if (has.shouldResetForm.changed && draft.shouldResetForm) {
    draft.shouldResetForm = false;
    const values = original(draft);
    if (values) {
      context.formik.resetForm({ values });
    }
    if (draft.gebruikersgroepen.gebruikersgroep[context.selected] !== undefined) {
      draft.gebruikersgroepen.gebruikersgroep[context.selected].formIsReset = true;
    }
  }
});
