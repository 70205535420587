/* istanbul ignore file */
import { logboekSchema, LogboekState } from "./logboek-schema";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import {
  createISWAsyncSideEffect,
  initISWAsyncSideEffect
} from "../../shared/components/isw-side-effects/create-isw-helpers";

/* TODO: fix imports
import { 
    ExampleInput, 
    ExampleOutput
} from "../../.generated/forms/formstypes";
*/

/* TODO: remove these example types */
type ExampleInput = { exampleInput: string };
type ExampleOutput = { exampleResult: string };

export const mapExampleInput = createMapToDl(logboekSchema).to<ExampleInput>({
  exampleInput: v => v.exampleValue
});

export const exampleAsync = createISWAsyncSideEffect<LogboekState>(async ({ draft, settings, fetchData }) => {
  const json = await fetchData<ExampleOutput, ExampleInput>({
    url: `${settings.klantdossiersFormsOrigin}/Logboek/ExampleUrl`,
    body: mapExampleInput
  });

  draft.exampleValue = json.exampleResult;
})();

export const asyncLogboekSideEffects = initISWAsyncSideEffect<LogboekState>(({ has, runAsync }) => {
  if (has.exampleValue.changed) {
    runAsync(exampleAsync);
  }
})();
