import { FormFirstFocus, PlatformFoutenSamenvatting } from "adviesbox-shared";
import { Form, FormikContextType } from "formik";
import React, { Dispatch, ReactElement } from "react";
import { Debug } from "../shared/components/formik/Debug";
import { ISWSideEffects } from "../shared/components/isw-side-effects/isw-side-effects";
import TopNavbar from "../shared/components/topnav-dossier/TopNavbar";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { asyncLicentiebeheerZoekSchermSideEffects } from "./determine-zoekscherm-async-side-effects";
import { zoekschermSchema, ZoekschermState } from "./infra";
import Zoeken from "./zoeken/zoeken";
import classes from "./zoekscherm.module.scss";

const Zoekscherm = ({
  childErrorSetter
}: FormikContextType<ZoekschermState> & {
  childErrorSetter: Dispatch<number | null>;
}): ReactElement => {
  return (
    <div className={classes.loader_correction}>
      <TopNavbar home={true} />
      <FormFirstFocus>
        <Form>
          <PlatformFoutenSamenvatting />
          <ISWSideEffects<ZoekschermState> async={asyncLicentiebeheerZoekSchermSideEffects()} />
          <div className={`${classes.zoekscherm_header} py-4`}>
            <div className="mb-2 d-flex">
              <h1 className="mx-auto">Adviesbox Contract</h1>
            </div>
            <Zoeken childErrorSetter={childErrorSetter} />
          </div>
          <Debug />
        </Form>
      </FormFirstFocus>
    </div>
  );
};

Zoekscherm.displayName = "Zoekscherm";
export default withAdviesboxFormik<ZoekschermState & { childErrorSetter: Dispatch<number | null> }, ZoekschermState>({
  mapPropsToValues: (val): ZoekschermState => val,
  validationSchema: zoekschermSchema
})(Zoekscherm);
