/* istanbul ignore file */
import { Icon } from "adviesbox-shared";
import React, { ReactElement } from "react";
import { Button } from "react-bootstrap";
import { ShowDeleteModal } from "./gebruikersgroepen-schema";

const ModalDeleteGebruikersgroepButton = ({
  index,
  updateSelected,
  setShowPopup
}: {
  index: number;
  updateSelected: (selected: number) => void;
  setShowPopup: React.Dispatch<React.SetStateAction<ShowDeleteModal>>;
}): ReactElement => {
  return (
    <>
      <Button
        data-testid={`btn-verwijder-${index}`}
        id={`btn-verwijder-${index}`}
        variant="outline-secondary"
        onMouseDown={(event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
          event.button === 0 && event.stopPropagation();
          event.button === 0 && updateSelected(index); //to select the record before deleting
          event.button === 0 && setShowPopup({ show: true, selected: index });
        }}
      >
        <Icon name="prullenbak" alt="Verwijderen" />
      </Button>
    </>
  );
};

ModalDeleteGebruikersgroepButton.displayName = "ModalDeleteButton";

export default ModalDeleteGebruikersgroepButton;
