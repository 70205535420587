import { createMapToUi } from "../../shared/utils/create-map-to-ui";
/* istanbul ignore file */

import {
  LicentiesAanbodState,
  onderdelenModalSchema,
  OnderdelenModalState,
  licentieOnderdelenProductSchema
} from "../infra/licentieaanbod-schema";

export type MapLicentieOnderdeelModalContext = {
  newObject: boolean;
  selected: number | null;
};

export const mapLicentieOnderdeelModal = createMapToUi(onderdelenModalSchema)
  .with<MapLicentieOnderdeelModalContext>()
  .from<LicentiesAanbodState>({
    id: (v, c) => {
      if (c.newObject === false && c.selected !== null && v.licentieOnderdelen.licentieOnderdelen[c.selected]) {
        return v.licentieOnderdelen.licentieOnderdelen[c.selected].id;
      }

      return "";
    },
    actief: (v, c) => {
      if (c.newObject === false && c.selected !== null && v.licentieOnderdelen.licentieOnderdelen[c.selected]) {
        return v.licentieOnderdelen.licentieOnderdelen[c.selected].actief;
      }

      return true;
    },
    code: (v, c) => {
      if (c.newObject === false && c.selected !== null && v.licentieOnderdelen.licentieOnderdelen[c.selected]) {
        return v.licentieOnderdelen.licentieOnderdelen[c.selected].code;
      }

      return "";
    },
    productNaam: (v, c) => {
      if (c.newObject === false && c.selected !== null && v.licentieOnderdelen.licentieOnderdelen[c.selected]) {
        return v.licentieOnderdelen.licentieOnderdelen[c.selected].productNaam;
      }

      return "";
    },
    selected: (_, c) => (c.newObject ? null : c.selected),
    aantalGekoppeldeGebruikersgroepen: (v, c) => {
      if (c.newObject === false && c.selected !== null && v.licentieOnderdelen.licentieOnderdelen[c.selected]) {
        return v.licentieOnderdelen.licentieOnderdelen[c.selected].aantalGekoppeldeGebruikersgroepen;
      }

      return 0;
    },
    isInstelbaarDoorKlant: (v, c) => {
      if (c.newObject === false && c.selected !== null && v.licentieOnderdelen.licentieOnderdelen[c.selected]) {
        return v.licentieOnderdelen.licentieOnderdelen[c.selected].isInstelbaarDoorKlant;
      }

      return false;
    }
  });

export const mapLicentieOnderdeelModalToScreen = createMapToUi(licentieOnderdelenProductSchema).from<
  OnderdelenModalState
>({
  actief: v => v.actief,
  id: v => v.id,
  code: v => v.code,
  hasChanged: v => false,
  productNaam: v => v.productNaam,
  aantalGekoppeldeGebruikersgroepen: v => v.aantalGekoppeldeGebruikersgroepen,
  isInstelbaarDoorKlant: v => v.isInstelbaarDoorKlant,
  isDeleted: v => false
});
