import { LabeledPostcodeInput, LabeledTextInput } from "adviesbox-shared";
import classNames from "classnames";
import React, { ReactElement } from "react";
import { Button } from "react-bootstrap";
import { StepProps } from "../organisatie-registreren";
import classes from "../registreren.module.scss";

export const Kantoorgegevens = ({ currentStep, setCurrentStep }: StepProps): ReactElement => {
  return (
    <>
      <h2 className={classNames(classes.title)}>Kantoorgegevens</h2>
      <LabeledTextInput name={"bedrijfsnaam"} caption={"Bedrijfsnaam"} labelColSize={7} />
      <LabeledTextInput name={"kvkNummer"} caption={"KVK-nummer"} labelColSize={7} />
      <LabeledTextInput name={"algemeenTelefoonnumer"} caption={"Algemeen telefoonnumer"} labelColSize={7} />
      {/* TODO: Telefoon input aanmaken */}
      <LabeledTextInput name={"afmVergunningsnummer"} caption={"AFM-vergunningsnummer"} labelColSize={7} />
      <LabeledPostcodeInput name={"postcode"} caption={"Postcode"} fullWidth labelColSize={7} className={"pr-2"} />
      <LabeledTextInput name={"huisnummerToevoeging"} caption={"Huisnummer en toevoeging "} labelColSize={7} />
      <LabeledTextInput name={"straat"} caption={"Straat"} labelColSize={7} />
      <LabeledTextInput name={"plaats"} caption={"Plaats"} labelColSize={7} />
      <LabeledTextInput name={"iban"} caption={"IBAN voor incasso"} labelColSize={7} />
      {/* TODO: IBAN uit personalia halen */}
      <Button
        className={"float-right m-3"}
        onClick={() => {
          setCurrentStep(currentStep + 1);
        }}
      >
        Volgende
      </Button>
    </>
  );
};
