import React, { ReactElement, ReactNode, useState } from "react";
import AutorisatieNiveauContext, { AutorisatieNiveauContextType } from "./autorisatie-niveau-context";

const setContext = (
  isBeheerder: boolean | null,
  setIsBeheerder: (isBeheerder: boolean | null) => void
): AutorisatieNiveauContextType => ({
  isBeheerder,
  setIsBeheerder
});

const AutorisatieNiveauProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [isBeheerder, setIsBeheerder] = useState<boolean | null>(null);

  return (
    <AutorisatieNiveauContext.Provider value={setContext(isBeheerder, setIsBeheerder)}>
      {children}
    </AutorisatieNiveauContext.Provider>
  );
};

export default AutorisatieNiveauProvider;
