/* istanbul ignore file */
// import {
//   LicentiebeheerLogboek as LogboekDlEntry,
//   LicentiebeheerLogboekOutput as LogboekDlOutput
// } from "../../.generated/forms/formstypes";

import { LogboekState, logboekSchema, LogboekType } from "./logboek-schema";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";

const mapLogboek = createMapToUi(logboekSchema).from<LogboekType>({
  exampleValue: () => null
});

export function mapLogboekDlToUi(logboekId: string, data: LogboekState): LogboekState | null {
  // const logboek = data && data.isValid && data.LicentiebeheerLogboek ? data.LicentiebeheerLogboek[logboekId] : null;
  const logboek = data ?? null;

  if (logboek) {
    return mapLogboek(logboek);
  }

  return null;
}
