import React, { ReactElement } from "react";
import { FormikContextType } from "formik";
import { VestigingenSchermState } from "./infra/vestigingen-schema";
import { Modal as ModalBootstrap } from "react-bootstrap";
import classes from "./vestigingen.module.scss";
/* istanbul ignore file */

export type VestigingDeleteType = {
  selected: number;
};

export const DeleteVestigingModal = ({
  closeModal,
  formik,
  index,
  onDelete
}: {
  formik: FormikContextType<VestigingenSchermState>;
  index: number;
  closeModal?: () => void;
  onDelete: (data: VestigingDeleteType) => void;
}): ReactElement => {
  const { values } = formik;

  return (
    <>
      <ModalBootstrap.Header>
        <div className="d-flex w-100">
          <ModalBootstrap.Title>{values.vestigingen[index]?.naam} verwijderen?</ModalBootstrap.Title>
          <button type="button" className={"close ml-auto"} onClick={closeModal} id={`btnClose-vestiging-verwijderen`}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </div>
      </ModalBootstrap.Header>
      <ModalBootstrap.Body>
        <p className="px-2">Weet je zeker dat je {values.vestigingen[index]?.naam} wilt verwijderen?</p>
      </ModalBootstrap.Body>
      <ModalBootstrap.Footer className={classes.modal_footer}>
        <button
          type="button"
          className="btn btn-link"
          id="annuleer-verwijderen-vestiging"
          data-testid="annuleer-verwijderen-vestiging"
          onClick={closeModal}
        >
          Niet verwijderen
        </button>
        <button
          type="button"
          className="btn btn-primary"
          id="bevestig-verwijderen-vestiging"
          data-testid="bevestig-verwijderen-vestiging"
          onClick={() => {
            onDelete({ selected: index });
          }}
        >
          {values.vestigingen[index]?.naam} verwijderen
        </button>
      </ModalBootstrap.Footer>
    </>
  );
};

/* istanbul ignore else */ if (process.env.NODE_ENV !== "production")
  DeleteVestigingModal.displayName = "DeleteVestigingModal";
