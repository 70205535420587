import { hasValue } from "adviesbox-shared";
import * as Yup from "yup";
import adresSchema from "../../shared/generic-parts/adres/schema";
import { getVestigingenTextResources } from "./vestigingen-resources";

const phoneNumberAllowEmptyRegex = /^(((((\+|00)31|0)[\s-]{0,1}([0-9][\s-]{0,1}){9}|((\+|00)(?!31)[0-9]{2}([0-9][\s-]{0,1}){1,13})))|)$/;
const emailAllowEmptyRegex = /^(([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)|)$/i;
const afmRegistratienummerRegex = /^([0-9]{8}|)$/;

export type ShowHasChangesModal = {
  show: boolean;
  selected: number | null;
};

export type ShowDeleteModal = {
  show: boolean;
  selected: number | null;
};

export const organisatieVestigingSchema = Yup.object({
  id: Yup.string().default(""),
  logo: Yup.string().default(""),
  platformId: Yup.string().default(""),
  bedrijfsnaam: Yup.string().default(""),
  kvkNummer: Yup.string().default(""),
  iban: Yup.string().default(""),
  loading: Yup.boolean().default(false),
  hasChanged: Yup.boolean().default(false),
  sendPut: Yup.boolean().default(false)
});
export type OrganisatieVestigingState = Yup.InferType<typeof organisatieVestigingSchema>;

export const vestigingSchema = Yup.object({
  id: Yup.string()
    .nullable()
    .default(null),
  naam: Yup.string()
    .default("")
    .test({
      message: getVestigingenTextResources("naam"),
      test: function(value: string) {
        return hasValue(value) && value !== "";
      }
    }),
  isHoofdvestiging: Yup.boolean().default(false),
  waarschuwingsBericht: Yup.string()
    .default("")
    .nullable(),
  telefoonnummer: Yup.string()
    .default("")
    .matches(phoneNumberAllowEmptyRegex, "Ongeldig telefoonnummer.")
    .test({
      message: getVestigingenTextResources("telefoonnummer"),
      test: function(value: string) {
        return hasValue(value) && value !== "";
      }
    }),
  emailadres: Yup.string()
    .default("")
    .matches(emailAllowEmptyRegex, "Ongeldig e-mailadres.")
    .test({
      message: getVestigingenTextResources("emailadres"),
      test: function(value: string) {
        return hasValue(value) && value !== "";
      }
    }),
  bezoekadres: adresSchema,
  postadres: adresSchema.nullable().default(null),
  afwijkendPostadresTonen: Yup.boolean()
    .default(false)
    .nullable(),
  afmRegistratienummer: Yup.string()
    .default("")
    .matches(afmRegistratienummerRegex, "Ongeldig afm registratienummer. Een geldig registratienummer bestaat uit 8 cijfers.")
    .test({
      message: getVestigingenTextResources("afmRegistratienummer"),
      test: function(value: string) {
        return hasValue(value) && value !== "";
      }
    }),
  platformId: Yup.string()
    .nullable()
    .default(""),
  hasChanged: Yup.boolean().default(false),
  isDeleted: Yup.boolean().default(false),
  putChanges: Yup.boolean().default(false),
  postChanges: Yup.boolean().default(false),
  savedChanges: Yup.boolean().default(false),
  triggeredByModal: Yup.boolean().default(false)
});

export type VestigingType = Yup.InferType<typeof vestigingSchema>;

export const vestigingenSchema = Yup.object({
  vestigingen: Yup.array(vestigingSchema).default([])
});
export type VestigingenType = Yup.InferType<typeof vestigingenSchema>;

export const vestigingenSchermSchema = Yup.object({
  vestigingenInView: Yup.number().default(10),
  vestigingen: Yup.array(vestigingSchema).default([]),
  loading: Yup.bool().default(false),
  shouldResetForm: Yup.boolean().default(false),
  cancelHasChanges: Yup.boolean().default(false),
  organisatie: organisatieVestigingSchema
});

export type VestigingenSchermState = Yup.InferType<typeof vestigingenSchermSchema>;
export type VestigingenSchermProps = Yup.InferType<typeof vestigingenSchermSchema>;
