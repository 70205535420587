import { createContext } from "react";

/* istanbul ignore next */
export const defaultSettings: {
  isBeheerder: boolean | null;
  setIsBeheerder: (isBeheerder: boolean | null) => void;
} = {
  isBeheerder: false,
  setIsBeheerder: (_isbeheerder: boolean | null) => {}
};

export type AutorisatieNiveauContextType = typeof defaultSettings;

const AutorisatieNiveauContext = createContext(defaultSettings);

export default AutorisatieNiveauContext;
