import { LicentiesoortOpties } from "../../../.generated/licenties/licentiestypes";
import { Licentieovereenkomst, Medewerker } from "../user-details-context";

export const isInterneOrganisatie = (medewerker: Medewerker): boolean => {
  let isInterneOrganisatie = false;
  if (!!medewerker.requestedOrganisatie?.licentieovereenkomsten) {
    const licentieovereenkomsten = Object.entries(medewerker.requestedOrganisatie.licentieovereenkomsten).map(
      (value: [string, Licentieovereenkomst]) => {
        return value[1];
      }
    );
    isInterneOrganisatie = Array.isArray(licentieovereenkomsten)
      ? licentieovereenkomsten.find(c => c !== undefined && c.licentiesoort === LicentiesoortOpties.Intern) !==
        undefined
      : false;
  }
  return isInterneOrganisatie;
};

export const isEigenOrganisatie = (medewerker: Medewerker): boolean => {
  return medewerker.organisatieId === medewerker.requestedOrganisatie?.organisatieId;
};
