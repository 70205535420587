import React, { ReactElement } from "react";
import { Button, Modal } from "react-bootstrap";

type HdnNodenummerDeleteModalProps = {
  showModal: boolean;
  onSubmit: () => void;
  onCancelClick: () => void;
};

export const HdnNodenummerDeleteModal = ({
  showModal,
  onSubmit,
  onCancelClick
}: HdnNodenummerDeleteModalProps): ReactElement => {
  return (
    <Modal show={showModal}>
      <Modal.Header>
        <Modal.Title>HDN nodenummer verwijderen</Modal.Title>
        <button onClick={onCancelClick} type="button" className={"close ml-auto"} id={`btnClose-HDN`}>
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <span>
          Weet je zeker dat je dit nodenummer wilt verwijderen? De gekoppelde vestiging(en) kunnen dan geen HDN
          aanvragen meer doen.
        </span>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={onCancelClick}
          id="hdnverwijder-modal-sluiten"
          data-testid="hdnverwijder-modal-sluiten"
          variant=""
        >
          Niet verwijderen
        </Button>
        <Button
          onClick={onSubmit}
          id="hdnverwijder-modal-opslaan"
          data-testid="hdnverwijder-modal-opslaan"
          variant="danger"
          title="Verwijderen"
        >
          HDN nodenummer verwijderen
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
