/* istanbul ignore file */
import { LocalDate } from "@js-joda/core";
import * as Yup from "yup";
import { hasValue } from "../../shared/utils/helpers";
import { yupEnum } from "../../shared/utils/yup-enum";
import { getContractTextResources } from "./contract-resources";
import { nullableLocalDateSchema, localDateSchema } from "adviesbox-shared";

export const organisatieSchema = Yup.object({
  id: Yup.string().default(""),
  logo: Yup.string().default(""),
  platformId: Yup.string().default(""),
  bedrijfsnaam: Yup.string().default(""),
  kvkNummer: Yup.string().default(""),
  iban: Yup.string().default(""),
  loading: Yup.boolean().default(false),
  sendPut: Yup.boolean().default(false),
  hasChanged: Yup.boolean().default(false)
});
export type OrganisatieState = Yup.InferType<typeof organisatieSchema>;

export const factuurgegevensSchema = Yup.object({
  naamPersoon: Yup.string().default(""),
  kostenplaats: Yup.string().default(""),
  vrijeInvoer: Yup.string().default("")
});

export enum LicentieSoort {
  Betaald = "Betaald",
  Proef = "Proef",
  Intern = "Intern"
}

export const documentSchema = Yup.object({
  naam: Yup.string(),
  bestandsnaam: Yup.string(),
  url: Yup.string(),
  id: Yup.string()
});

export const licentieSchema = Yup.object({
  id: Yup.string()
    .nullable()
    .default(null),
  begindatum: localDateSchema,
  contractId: Yup.string()
    .nullable()
    .default(null),
  einddatum: nullableLocalDateSchema
    .test({
      message: getContractTextResources("einddatum"),
      test: function(value: LocalDate | null) {
        if (hasValue(value)) {
          const begindatum: LocalDate | null = this.parent.begindatum;
          if (hasValue(begindatum)) {
            return value >= begindatum;
          }
        }
        return true;
      }
    })
    .test({
      message: getContractTextResources("einddatumLicentieSoortBetaald"),
      test: function(value: LocalDate | null) {
        const licentiesoort = this.parent.licentiesoort;
        if (!hasValue(value) && licentiesoort === LicentieSoort.Proef) return false;
        return true;
      }
    }),
  isActief: Yup.boolean().default(true),
  isGeblokkeerd: Yup.boolean().default(false),
  licentiesoort: yupEnum(LicentieSoort).default(LicentieSoort.Betaald),
  organisatieId: Yup.string(),
  productId: Yup.string(),
  productNaam: Yup.string().default(""),
  sendPut: Yup.boolean().default(false),
  loading: Yup.boolean().default(false),
  licentieOvereenkomstDocumenten: Yup.array(documentSchema)
});

export type DocumentType = Yup.InferType<typeof documentSchema>;
export type LicentieType = Yup.InferType<typeof licentieSchema>;

export const licentieOvereenkomstenSchema = Yup.object({
  licenties: Yup.array(licentieSchema).default([])
});
export type LicentieOvereenkomstenState = Yup.InferType<typeof licentieOvereenkomstenSchema>;
export type LicentieOvereenkomstenProps = Yup.InferType<typeof licentieOvereenkomstenSchema>;

export const contractSchema = Yup.object({
  contracten: licentieOvereenkomstenSchema,
  organisatie: organisatieSchema,
  hasChanged: Yup.boolean().default(false),
  saveIsClicked: Yup.bool().default(false)
});

export type ContractState = Yup.InferType<typeof contractSchema>;
export type ContractProps = Yup.InferType<typeof contractSchema>;
