import { Afbeelding, FileUpload, Icon, Label } from "adviesbox-shared";
import { connect, FormikContextType } from "formik";
import React, { ReactElement } from "react";
import { Button } from "react-bootstrap";
import { ContractState } from "../infra/contract-schema";

export type BedrijfslogoImporterenProps = {
  updateFiles?: File[];
  readOnly: boolean;
};

const BedrijfslogoImporteren = ({
  updateFiles,
  readOnly,
  formik: { values, setFieldValue }
}: BedrijfslogoImporterenProps & {
  formik: FormikContextType<ContractState>;
}): ReactElement => {
  const bedrijfslogo = new Image();
  bedrijfslogo.src = `data:image/png;base64,${values.organisatie.logo}`;

  return (
    <>
      {values.organisatie.logo && (
        <>
          <br />
          <div className="form-group form-row">
            <div className={`col-6`}>
              <Label caption="Bedrijfslogo" name="organisatie.logo" fontWeight="bold" />
            </div>
            <div className={`col-6`}>
              <Afbeelding
                name="organisatie.logo"
                alt="bedrijfslogo"
                location={bedrijfslogo.src}
                style={{ maxHeight: 100 }}
              />
              {!readOnly && (<Button
                data-testid={`btn-verwijder-bedrijfslogo`}
                id={`btn-verwijder-bedrijfslogo`}
                variant="outline-secondary"
                onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
                  event.stopPropagation();
                  // bedrijfslogo verwijderen
                  setFieldValue("organisatie.logo", "");
                }}
              >
                <Icon name="prullenbak" alt="Verwijderen" />
              </Button>)}
            </div>
          </div>
        </>
      )}
      {!values.organisatie.logo && !readOnly && (
        <>
          <div className="form-group form-row">
            <br />
            <Label caption="Bedrijfslogo" name="organisatie.logo" fontWeight="bold" />
          </div>

          <FileUpload
            name="organisatie.logo"
            variant={"Afbeeldingen"}
            updateRef={(f: File[]) => {
              // TODO: a ref should be used.
              const fileReader = new FileReader();
              fileReader.readAsDataURL((updateFiles && updateFiles[0]) ?? f[0]);
              fileReader.onload = () => {
                /* istanbul ignore else */
                if (typeof fileReader.result === "string") {
                  setFieldValue("organisatie.logo", fileReader.result.split(",")[1]);
                  setFieldValue("organisatie.hasChanged", true);
                }
              };
            }}
            hideFormats
            buttonText={"Bestand kiezen"}
            title={
              <span>
                <br />
                <b>Sleep uw logo in het vak</b>
                <br />
                <i>Ondersteunde formaten: JPG, PNG</i>
                <br />
                <br />
              </span>
            }
          />
        </>
      )}
    </>
  );
};
export default connect<BedrijfslogoImporterenProps, ContractState>(BedrijfslogoImporteren);
