import React, { ReactElement, useEffect, useRef, RefObject } from "react";
import { FormikContextType, connect } from "formik";
import { History, LocationState } from "history";
import { submit, SubmitResultTypes } from "../../utils/save-validation";
import { UiError } from "../../types";
import { ValidatiePopup } from "adviesbox-shared";
import { useLocation } from "react-router-dom";

type SaveNavigationProps = {
  history: History<unknown>;
  saveData: (values: any) => Promise<UiError[] | null>;
  berekenSideEffectRef?: RefObject<{ asyncResult: Promise<unknown> }>;
  showValidatiePopup: boolean;
  setValidatiePopup: React.Dispatch<React.SetStateAction<boolean>>;
  setSaveResultText: React.Dispatch<React.SetStateAction<SubmitResultTypes>>;
  triggerPreSaveConfirmation?: boolean;
  preSaveConfirmation?: () => void;
  callBack?: () => void;
};

const SaveNavigation = ({
  formik,
  history,
  saveData,
  berekenSideEffectRef,
  showValidatiePopup,
  triggerPreSaveConfirmation,
  setValidatiePopup,
  setSaveResultText,
  callBack,
  preSaveConfirmation
}: SaveNavigationProps & { formik: FormikContextType<any> }): ReactElement => {
  const continueRef = useRef<(() => void) | null>(null);

  const location = useLocation();

  useEffect((): (() => void) => {
    const initialPush = history.push;

    history.push = async (path: any, state?: LocationState): Promise<void> => {
      if (triggerPreSaveConfirmation && preSaveConfirmation) {
        initialPush(path, state);
        await preSaveConfirmation();
        return;
      }

      if (path.pathname === location.pathname) {
        return;
      }

      const validationResult = await submit(saveData, formik, berekenSideEffectRef, callBack);
      if (validationResult === "default" || validationResult === "completed") {
        initialPush(path, state);
      } else {
        continueRef.current = (): void => initialPush(path, state);
        setValidatiePopup(true);
        setSaveResultText(validationResult);
      }
    };

    return (): void => {
      history.push = initialPush;
    };
  }, [
    saveData,
    formik,
    history.push,
    location,
    berekenSideEffectRef,
    setValidatiePopup,
    showValidatiePopup,
    setSaveResultText,
    history,
    callBack,
    triggerPreSaveConfirmation,
    preSaveConfirmation
  ]);

  return (
    <>
      <ValidatiePopup
        infotekst="navigatieInfo"
        show={showValidatiePopup}
        onHide={(): void => {
          setValidatiePopup(false);
        }}
        onSubmit={(): void => {
          continueRef.current && continueRef.current();
        }}
        navigatiepopup
      />
    </>
  );
};

SaveNavigation.displayName = "SaveNavigation";

export default connect<SaveNavigationProps>(SaveNavigation);
