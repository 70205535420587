import { AuthContext, ErrorPage, hasRoleIswUser, Icon, PageLoading, useRequestInit } from "adviesbox-shared";
import classNames from "classnames";
import React, { ReactElement, useContext, useMemo } from "react";
import { Dropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import useAbortableFetch from "use-abortable-fetch";
import { LicentiesApi } from "../licenties/infra/licenties-api";
import { OrganisatiesExOutput } from "../.generated/licenties/licentiestypes";
import classes from "./organisatie-balk.module.scss";

const OrganisatieBalk = (): ReactElement => {
  const navigate = useHistory();
  const { settings, params, requestInit } = useRequestInit<{ organisatie: string }>();
  const { user } = useContext(AuthContext);
  const isIswMedewerker = hasRoleIswUser(user);

  const urlOrganisatie = useMemo(() => LicentiesApi.getOrganisatieUrl(settings, params), [
    settings,
    params
  ]);
  const { data, error, loading } = useAbortableFetch<OrganisatiesExOutput>(urlOrganisatie, requestInit);

  if (error) {
    return <ErrorPage error={new Error(`Error : ${error}`)} data={data} />;
  }

  if (typeof data === "string") {
    return <ErrorPage error={new Error(`Returned data type is string`)} />;
  }

  if (loading || !data || !data.organisaties || !params.organisatie) {
    return <PageLoading />;
  }

  return (
    <div className={classNames("navbar navbar-static-top second-navbar", classes.organisatie_balk)}>
      <div className="main-nav">
        <ul className="navbar-nav">
          {isIswMedewerker && (
            <li className="nav-item">
              <Dropdown>
                <Dropdown.Toggle variant="link" id="dropdown-container" data-testid="dropdown-container" size="sm">
                  <div>
                    Organisatie
                    <span className={classes.icon_container}>
                      <Icon name="chevron" alt="drop-down" />
                    </span>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    className={classes.dropdown_item}
                    id="dropdown-item"
                    data-testid="dropdown-item"
                    onClick={() => {
                      navigate.push(`/zoeken`);
                    }}
                  >
                    Andere organisatie kiezen
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          )}
        </ul>
        <div className={classes.organisatie_container} id="organisatie-naam-balk-container">
          <div className={classes.organisatie_naam} id="organisatie-naam-balk">
            {params?.organisatie && data?.organisaties[params.organisatie]?.naam ? (
              <div>{data?.organisaties[params.organisatie].naam}</div>
            ) : (
              <>Organisatie</>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

OrganisatieBalk.displayName = "OrganisatieBalk";
export default OrganisatieBalk;
