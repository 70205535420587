import { ValidationResultsModel } from "../.generated/licenties/licentiestypes";
import {
  createISWAsyncSideEffect,
  initISWAsyncSideEffect
} from "../shared/components/isw-side-effects/create-isw-helpers";
import { ZoekschermState } from "./infra/zoekscherm-schema";

export const asyncSideEffectDeleteOrganisatie = createISWAsyncSideEffect<ZoekschermState>(
  async ({ draft, settings, fetchData }) => {
    const url = `${settings.licentiesOrigin}/Organisaties/${draft.deletedOrganisatieId}`;
    await fetchData<ValidationResultsModel>({
      method: "DELETE",
      url
    });
    draft.zoeken.zoekresultaten = draft.zoeken.zoekresultaten.filter(
      v => v.organisatieId !== draft.deletedOrganisatieId
    );
    draft.deletedOrganisatieId = null;
  }
);

export const asyncLicentiebeheerZoekSchermSideEffects = initISWAsyncSideEffect<ZoekschermState>(
  ({ has, curr, runAsync }) => {
    if (has.deletedOrganisatieId.changed && curr.deletedOrganisatieId) {
      runAsync(asyncSideEffectDeleteOrganisatie());
    }
  }
);
