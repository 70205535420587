import { HdnNodenummerGeldigVoorOptions } from "../../.generated/licenties/licentiestypes";
import { initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { HdnNodenummerState } from "./HdnNodenummer-schema";

type Context = {
  selected: number;
};

export const HdnNodenummerSyncSideEffects = initISWSideEffect<HdnNodenummerState, Context>(
  ({ has, draft, context }) => {
    const draftSelectedNodenummer = draft.hdnNodenummerEntries[context.selected]?.current;
    const hasSelectedNodenummer = has.hdnNodenummerEntries[context.selected]?.current;
    const initSelectedNodenummer = draft.hdnNodenummerEntries[context.selected]?.initial;

    if (!draftSelectedNodenummer) {
      return;
    }

    if (
      hasSelectedNodenummer.geldigVoor.changed &&
      draftSelectedNodenummer.geldigVoor === HdnNodenummerGeldigVoorOptions.Organisatie &&
      draftSelectedNodenummer.vestigingen.length
    ) {
      draftSelectedNodenummer.vestigingen = [];
    }

    if (hasSelectedNodenummer.changed && !draftSelectedNodenummer.hasChanges) {
      draftSelectedNodenummer.hasChanges = true;
    }

    if (JSON.stringify({ ...initSelectedNodenummer, hasChanges: true }) === JSON.stringify(draftSelectedNodenummer)) {
      draftSelectedNodenummer.hasChanges = false;
    }
  }
);
