import { Gebruikersgroep, NewOutput, ValidationResultsModel } from "../../.generated/licenties/licentiestypes";
import {
  initISWAsyncSideEffect,
  createISWAsyncSideEffect
} from "../../shared/components/isw-side-effects/create-isw-helpers";
import { validateFormikValues } from "../gebruikersgroepen-helper";
import { GebruikersgroepenState, GebruikersgroepType, ProductOnderdelenState } from "./gebruikersgroepen-schema";
type Context = {
  selected: number;
  organisatieId: string;
  gebruikersGroep?: GebruikersgroepType;
};

const mapProductonderdelen = (
  values: GebruikersgroepType,
  productOnderdelen: ProductOnderdelenState
): Array<string> => {
  const productonderdelen: Array<string> = [];

  Object.keys(values.productOnderdelen).forEach(key => {
    if (values.productOnderdelen[key].gekoppeld) productonderdelen.push(productOnderdelen.productOnderdelen[key].id);
  });
  return productonderdelen;
};

export const postGebruikersgroep = createISWAsyncSideEffect<GebruikersgroepenState, Context>(
  async ({ draft, fetchData, settings, context, formik }) => {
    const draftSelectedGebruikersgroep = draft.gebruikersgroepen.gebruikersgroep[context.selected];

    const productonderdelen = mapProductonderdelen(draftSelectedGebruikersgroep, draft.gebruikersgroepOnderdelen);
    const isError = await validateFormikValues(formik, context.selected);

    if (!isError && context.gebruikersGroep && productonderdelen) {
      const gebruikersgroepResponse = await fetchData<NewOutput, Gebruikersgroep>({
        url: `${settings.licentiesOrigin}/Gebruikersgroepen`,
        method: "POST",
        body: {
          beschrijving: context.gebruikersGroep.beschrijving,
          naam: context.gebruikersGroep.groepsNaam,
          organisatieId: context.organisatieId,
          productonderdelen: productonderdelen
        }
      });

      draftSelectedGebruikersgroep.postChanges = false;
      draftSelectedGebruikersgroep.saveIsClicked = false;
      draft.shouldResetForm = true;
      draft.hasChanged = false;
      draft.loading = false;

      draft.gebruikersgroepen.gebruikersgroep.map(gebruikersgroep => {
        if (gebruikersgroep.id === null && gebruikersgroepResponse.id) {
          gebruikersgroep.id = gebruikersgroepResponse.id;
        }
        return gebruikersgroep;
      });
      draft.hasChanged = false;
    }
  }
);

export const putGebruikersgroep = createISWAsyncSideEffect<GebruikersgroepenState, Context>(
  async ({ draft, fetchData, settings, context, formik }) => {
    const draftSelectedGebruikersgroep = draft.gebruikersgroepen.gebruikersgroep[context.selected];

    const productonderdelen = mapProductonderdelen(draftSelectedGebruikersgroep, draft.gebruikersgroepOnderdelen);
    const isError = await validateFormikValues(formik, context.selected);

    if (!isError && context.gebruikersGroep) {
      await fetchData<NewOutput, Gebruikersgroep>({
        url: `${settings.licentiesOrigin}/Gebruikersgroepen/${context.gebruikersGroep.id}`,
        method: "PUT",
        body: {
          beschrijving: context.gebruikersGroep.beschrijving,
          naam: context.gebruikersGroep.groepsNaam,
          organisatieId: context.organisatieId,
          productonderdelen: productonderdelen
        }
      });

      draftSelectedGebruikersgroep.postChanges = false;
      draftSelectedGebruikersgroep.saveIsClicked = false;
      draft.shouldResetForm = true;
      draft.hasChanged = false;
      draft.loading = false;
      return;
    }
  }
);

export const reassignEnDeleteGebruikersgroep = createISWAsyncSideEffect<GebruikersgroepenState, Context>(
  async ({ draft, fetchData, settings, context }) => {
    if (context.gebruikersGroep && context.gebruikersGroep.isDeleted) {
      const switchGebruiker = `${settings.licentiesOrigin}/Gebruikersgroepen/From/${context.gebruikersGroep.id}/To/${context.gebruikersGroep.newGebruikersgroepAfterDelete}`;
      await fetchData<ValidationResultsModel>({
        url: switchGebruiker,
        method: "POST",
        body: JSON.stringify(false)
      });
      const deleteGebruikerUrl = `${settings.licentiesOrigin}/Gebruikersgroepen/${context.gebruikersGroep.id}`;
      await fetchData<NewOutput, Gebruikersgroep>({
        url: deleteGebruikerUrl,
        method: "DELETE"
      });
      draft.shouldResetForm = true;
      draft.loading = false;
      draft.gebruikersgroepen.gebruikersgroep.splice(context.selected, 1);
      return;
    }
  }
);

export const deleteGebruikersgroep = createISWAsyncSideEffect<GebruikersgroepenState, Context>(
  async ({ draft, fetchData, settings, context }) => {
    if (context.gebruikersGroep && context.gebruikersGroep.isDeleted) {
      const removeGebruikerFromAllMedewerkers = `${settings.licentiesOrigin}/Gebruikersgroepen/From/${context.gebruikersGroep.id}/To/${context.gebruikersGroep.id}`; //reset it to the same id to remove it
      await fetchData<ValidationResultsModel>({
        url: removeGebruikerFromAllMedewerkers,
        method: "POST",
        body: JSON.stringify(false)
      });
      const deleteGebruikerUrl = `${settings.licentiesOrigin}/Gebruikersgroepen/${context.gebruikersGroep.id}`;
      await fetchData<NewOutput, Gebruikersgroep>({
        url: deleteGebruikerUrl,
        method: "DELETE"
      });
      draft.shouldResetForm = true;
      draft.loading = false;
      draft.gebruikersgroepen.gebruikersgroep.splice(context.selected, 1);
      return;
    }
  }
);

export const asyncGebruikersgroepenSideEffects = initISWAsyncSideEffect<GebruikersgroepenState, Context>(
  ({ curr, runAsync, context }) => {
    curr.gebruikersgroepen.gebruikersgroep.forEach(gebruikersgroep => {
      if (!gebruikersgroep.id || curr.hasChanged || (gebruikersgroep.isDeleted && gebruikersgroep?.id)) {
        const ContextWithGebruikersGroep: Context = {
          selected: context.selected,
          gebruikersGroep: gebruikersgroep,
          organisatieId: curr.organisatieId ?? context.organisatieId
        };

        if (!gebruikersgroep.id && gebruikersgroep.saveIsClicked) {
          runAsync(postGebruikersgroep(ContextWithGebruikersGroep));
        }

        if (gebruikersgroep.id && gebruikersgroep.saveIsClicked) {
          runAsync(putGebruikersgroep(ContextWithGebruikersGroep));
        }

        if (
          gebruikersgroep.id &&
          gebruikersgroep.isDeleted &&
          gebruikersgroep.newGebruikersgroepAfterDelete &&
          curr.gebruikersgroepen.gebruikersgroep.length > 1
        ) {
          runAsync(reassignEnDeleteGebruikersgroep(ContextWithGebruikersGroep));
        }

        if (
          gebruikersgroep.id &&
          gebruikersgroep.isDeleted &&
          !gebruikersgroep.newGebruikersgroepAfterDelete &&
          curr.gebruikersgroepen.gebruikersgroep.filter(v => v.groepsNaam !== "Beheerder").length === 1
        ) {
          runAsync(deleteGebruikersgroep(ContextWithGebruikersGroep));
        }
      }
    });
  }
);
