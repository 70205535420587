import React, { ReactElement } from "react";
import { CheckBox } from "adviesbox-shared";
import { useFormikContext } from "formik";
import { GebruikersgroepenState } from "../infra/gebruikersgroepen-schema";
import { UserProps } from "../../shared/properties/user-props";
import {
  Gebruikersactie,
  MagActieUitvoeren,
  Matrixonderdeel
} from "../../shared/autorisatie-matrix/autorisatie-matrix";

type ToegangTotProps = { selected: number };

export const ToegangTot = ({ selected, user, userDetails }: ToegangTotProps & UserProps): ReactElement => {
  const formik = useFormikContext<GebruikersgroepenState>();
  const { values } = formik;
  const selectedGebruikersGroep = values.gebruikersgroepen?.gebruikersgroep[selected];
  const groepsNaamIsBeheerder = selectedGebruikersGroep?.groepsNaam === "Beheerder";
  const licentieBeheer = "Contract";
  const deactivatedProducten = [licentieBeheer, "Instellingen"];

  const magLezen = MagActieUitvoeren(Matrixonderdeel.Gebruikersgroepen, Gebruikersactie.Lezen, user, userDetails);
  const magSchrijven = MagActieUitvoeren(
    Matrixonderdeel.Gebruikersgroepen,
    Gebruikersactie.Schrijven,
    user,
    userDetails
  );

  const isReadOnly = magLezen && !magSchrijven;

  return (
    <>
      {Object.keys(values.gebruikersgroepOnderdelen.productOnderdelen)
        .filter(v => {
          if (!groepsNaamIsBeheerder) return v !== licentieBeheer;
          else return v;
        })
        .map((key, index) => {
          return (
            <CheckBox
              key={`${key}-${index}`}
              caption={`${key}`}
              disabled={isReadOnly || groepsNaamIsBeheerder || deactivatedProducten.includes(key) ? true : false}
              name={`gebruikersgroepen.gebruikersgroep[${selected}].productOnderdelen[${key}].gekoppeld`}
            />
          );
        })}
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") ToegangTot.displayName = "ToegangTot";
