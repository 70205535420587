import React from "react";
import { ReactElement } from "react";
import { Label, LabeledNumberInput } from "adviesbox-shared";
import { SamenstellingType } from "./infra/licenties-schema";
import { FormikContextType } from "formik";
import { CheckBox } from "adviesbox-shared";

export type AantalLicentiesAanpassenStap1Props = {
  setConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
  formik: FormikContextType<SamenstellingType>;
  showConfirm: boolean;
  amountOfRequestedLicensesIsNegative: boolean;
};

export const Stap2 = ({
  showConfirm,
  formik,
  setConfirmed,
  amountOfRequestedLicensesIsNegative
}: AantalLicentiesAanpassenStap1Props): ReactElement => {
  const { setFieldValue, values } = formik;
  const licenceToGenerate = (values.nieuwAantalLicenties ?? 0) - values.aantalLicenties;

  const setAgreeToInvoice = (): void => {
    setConfirmed(!values.akkoordFacturatie);
    setFieldValue("akkoordFacturatie", !values.akkoordFacturatie);
  };

  return (
    <>
      {amountOfRequestedLicensesIsNegative && (
        <div>
          <div></div>
          <div className="form-group form-row">
            <div className={`col-6`}>
              <Label caption={"Aantal op te zeggen licenties"} name={"aangevraagdAantalLicenties"} />
            </div>
            <div id={"AantalOpTeZeggenLicenties"} className={`col-1`}>{`${values.aangevraagdAantalLicenties}`}</div>
          </div>
          <br />
          <CheckBox
            checked={true}
            onChange={() => setAgreeToInvoice()}
            caption={`Ik ga akkoord dat de op te zeggen licenties per direct niet meer bruikbaar zijn.`}
            name={`akkoordFacturatie`}
          ></CheckBox>
          <div className="foutmelding">{values.waarschuwingsBericht}</div>
        </div>
      )}

      {!amountOfRequestedLicensesIsNegative && (
        <div>
          <LabeledNumberInput name="aantalLicenties" caption="Huidig aantal licenties" readonly fontWeight="bold" />
          <LabeledNumberInput name="nieuwAantalLicenties" caption="Nieuw aantal licenties" readonly />
          <LabeledNumberInput name="aangevraagdAantalLicenties" caption="Hoeveelheid nieuwe licenties" readonly />
          {values.nieuwAantalLicenties && values.nieuwAantalLicenties > values.aantalLicenties && showConfirm && (
            <div className={"px-1"}>
              <CheckBox
                checked={true}
                onChange={() => setAgreeToInvoice()}
                caption={`Ik ga ermee akkoord dat Intersoftware vanaf de volgende factuur ${licenceToGenerate} extra ${
                  licenceToGenerate > 1 ? "licenties" : "licentie"
                } factureert en voor de lopende factuurperiode de kosten pro rato factureert.`}
                name={`akkoordFacturatie`}
              ></CheckBox>
              <div className="foutmelding">{values.waarschuwingsBericht}</div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
