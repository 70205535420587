import { formatNameToId } from "adviesbox-shared";
import React, { MutableRefObject, ReactElement } from "react";
import classes from "./tagInput.module.scss";

export type TagInputProps = {
  tag: string;
  index: number;
  name: string;
  remove: (index: number) => void;
  editable: boolean;
  inputRef?: MutableRefObject<HTMLDivElement | null>;
};

export const TagInputComponent = ({ tag, index, name, remove, editable, ...props }: TagInputProps): ReactElement => {
  const removeTagClass = !!editable
    ? classes.tag_component_remove
    : `${classes.tag_component_remove} ${classes.tag_component_remove_readonly}`;

  return (
    <div
      id={`${index}-${formatNameToId(tag)}-${name}`}
      data-testid={`${index}-${tag}`}
      className={classes.tag_component_tag}
    >
      <div
        className={classes.tag_component_content}
        id={`${index}-tag-${name}`}
        data-testid={`${index}-tag`}
        {...props}
      >
        {tag}
      </div>
      <div
        id={`${index}-remove-btn-${name}`}
        data-testid={`${index}-remove-btn`}
        className={removeTagClass}
        onClick={() => {
          remove(index);
        }}
      />
    </div>
  );
};
/* istanbul ignore next */
if (process.env.NODE_ENV !== "production") TagInputComponent.displayName = "TagInput";
