import { hasValue } from "adviesbox-shared";
import {
  NewOutput,
  Product,
  Productonderdeel,
  ProductonderdelenOutput,
  Productsamenstelling,
  ProductsamenstellingenOutput
} from "../../.generated/licenties/licentiestypes";
import {
  createISWAsyncSideEffect,
  initISWAsyncSideEffect
} from "../../shared/components/isw-side-effects/create-isw-helpers";
import {
  LicentieOnderdelenProductType,
  LicentiesAanbodState,
  LicentieSamenstellingProductType,
  ProductType
} from "./licentieaanbod-schema";
import { mapProductOnderdelenDlToUi, mapProductSamenstellingenDlToUi } from "./map-licentieaanbod-dl-to-ui";

type Context = {
  selected: number;
  setSelected: () => void;
  samenstelling?: LicentieSamenstellingProductType;
  onderdeel?: LicentieOnderdelenProductType;
  product?: ProductType;
};

let prevSelected = 0;

export const fetchNewLicentieSamenstellingen = createISWAsyncSideEffect<LicentiesAanbodState, Context>(
  async ({ draft, settings, fetchData, context }) => {
    const selectedProduct = draft.producten[context.selected];
    const licentieOnderdelenJson = await fetchData<ProductsamenstellingenOutput>({
      url: `${settings.licentiesOrigin}/Producten/${selectedProduct.id}/Productsamenstellingen`,
      method: "GET"
    });

    const mappedData = mapProductSamenstellingenDlToUi(licentieOnderdelenJson);

    if (mappedData !== null) {
      draft.licentieSamenstellingen = mappedData;
      draft.licentieSamenstellingen.loading = false;
      return;
    }

    draft.licentieSamenstellingen.licentieSamenstellingen = [];
    draft.licentieSamenstellingen.loading = false;
  }
);

export const fetchNewLicentieOnderdelen = createISWAsyncSideEffect<LicentiesAanbodState, Context>(
  async ({ draft, settings, fetchData, context }) => {
    const selectedProduct = draft.producten[context.selected];
    const licentieOnderdelenJson = await fetchData<ProductonderdelenOutput>({
      url: `${settings.licentiesOrigin}/Producten/${selectedProduct.id}/Productonderdelen`,
      method: "GET"
    });

    const mappedData = mapProductOnderdelenDlToUi(licentieOnderdelenJson);

    if (mappedData !== null) {
      draft.licentieOnderdelen = mappedData;
      draft.licentieOnderdelen.loading = false;
      return;
    }

    draft.licentieOnderdelen.licentieOnderdelen = [];
    draft.licentieOnderdelen.loading = false;
  }
);

export const postNewLicentiesamenstelling = createISWAsyncSideEffect<LicentiesAanbodState, Context>(
  async ({ draft, fetchData, settings, context }) => {
    const selectedProduct = draft.producten[context.selected];

    if (context.samenstelling && context.samenstelling.productNaam !== "" && context.samenstelling.code !== "") {
      const samenstellingResponse = await fetchData<NewOutput, Productsamenstelling>({
        url: `${settings.licentiesOrigin}/Producten/${selectedProduct.id}/Productsamenstellingen`,
        method: "POST",
        body: {
          aantalGekoppeldeLicenties: context.samenstelling.onderdelenLength,
          code: context.samenstelling.code,
          isActief: context.samenstelling.actief,
          naam: context.samenstelling.productNaam,
          productId: selectedProduct.id,
          productonderdelen: context.samenstelling.onderdelen
        }
      });

      if (samenstellingResponse !== null && samenstellingResponse.isValid) {
        draft.licentieSamenstellingen.licentieSamenstellingen.map(samenstelling => {
          if (samenstelling.id === "" && samenstellingResponse.id) {
            samenstelling.id = samenstellingResponse.id;
          }
          return samenstelling;
        });
        draft.licentieSamenstellingen.loading = false;
        return;
      }
    }
  }
);

export const putLicentieSamenstelling = createISWAsyncSideEffect<LicentiesAanbodState, Context>(
  async ({ draft, fetchData, settings, context }) => {
    const selectedProduct = draft.producten[context.selected];

    if (context.samenstelling && context.samenstelling.productNaam !== "" && context.samenstelling.code !== "") {
      await fetchData<void, Productsamenstelling>({
        url: `${settings.licentiesOrigin}/Productsamenstellingen/${context.samenstelling.id}`,
        method: "PUT",
        body: {
          aantalGekoppeldeLicenties: context.samenstelling.onderdelenLength,
          code: context.samenstelling.code,
          isActief: context.samenstelling.actief,
          naam: context.samenstelling.productNaam,
          productId: selectedProduct.id,
          productonderdelen: context.samenstelling.onderdelen
        }
      });

      draft.licentieSamenstellingen.loading = false;
      return;
    }
  }
);

export const postNewLicentieOnderdeel = createISWAsyncSideEffect<LicentiesAanbodState, Context>(
  async ({ draft, fetchData, settings, context }) => {
    const selectedProduct = draft.producten[context.selected];

    if (context.onderdeel && context.onderdeel.productNaam !== "" && context.onderdeel.code !== "") {
      const onderdeelResponse = await fetchData<NewOutput, Productonderdeel>({
        url: `${settings.licentiesOrigin}/Producten/${selectedProduct.id}/Productonderdelen`,
        method: "POST",
        body: {
          isInstelbaarDoorKlant: context.onderdeel.isInstelbaarDoorKlant,
          aantalGekoppeldeGebruikersgroepen: context.onderdeel.aantalGekoppeldeGebruikersgroepen,
          code: context.onderdeel.code,
          isActief: context.onderdeel.actief,
          naam: context.onderdeel.productNaam,
          productId: selectedProduct.id
        }
      });

      if (onderdeelResponse !== null && onderdeelResponse.isValid) {
        draft.licentieOnderdelen.licentieOnderdelen.map(onderdeel => {
          if (onderdeel.id === "" && onderdeelResponse.id) {
            onderdeel.id = onderdeelResponse.id;
          }
          return onderdeel;
        });
        draft.licentieOnderdelen.loading = false;
        return;
      }
    }
  }
);

export const putLicentieOnderdeel = createISWAsyncSideEffect<LicentiesAanbodState, Context>(
  async ({ draft, fetchData, settings, context }) => {
    const selectedProduct = draft.producten[context.selected];

    if (context.onderdeel && context.onderdeel.productNaam !== "" && context.onderdeel.code !== "") {
      await fetchData<void, Productonderdeel>({
        url: `${settings.licentiesOrigin}/Productonderdelen/${context.onderdeel.id}`,
        method: "PUT",
        body: {
          isInstelbaarDoorKlant: context.onderdeel.isInstelbaarDoorKlant,
          aantalGekoppeldeGebruikersgroepen: context.onderdeel.aantalGekoppeldeGebruikersgroepen,
          code: context.onderdeel.code,
          isActief: context.onderdeel.actief,
          naam: context.onderdeel.productNaam,
          productId: selectedProduct.id
        }
      });

      draft.licentieOnderdelen.loading = false;
    }
  }
);

export const deleteLicentieOnderdeel = createISWAsyncSideEffect<LicentiesAanbodState, Context>(
  async ({ draft, fetchData, settings, context }) => {
    if (context.onderdeel && context.onderdeel.id && context.onderdeel.isDeleted) {
      //Ontkoppel Productonderdeel

      await fetchData<void>({
        url: `${settings.licentiesOrigin}/Productonderdelen/OntkoppelGebruikersgroepenVanProductonderdeel?productonderdeelId=${context.onderdeel.id}`,
        method: "POST"
      });

      //Delete Productonderdelen
      await fetchData<void>({
        url: `${settings.licentiesOrigin}/Productonderdelen/${context.onderdeel.id}`,
        method: "DELETE"
      });
      draft.licentieOnderdelen.licentieOnderdelen = draft.licentieOnderdelen.licentieOnderdelen.filter(
        onderdeel => onderdeel.id !== context.onderdeel?.id
      );
      draft.licentieOnderdelen.loading = false;
    }
  }
);

export const deleteLicentieSamenstelling = createISWAsyncSideEffect<LicentiesAanbodState, Context>(
  async ({ draft, fetchData, settings, context }) => {
    if (context.samenstelling && context.samenstelling?.id && context.samenstelling.isDeleted) {
      //Ontkoppel Alle Productonderdelen
      const deletedSamenstelling = draft.licentieSamenstellingen.licentieSamenstellingen.find(
        samenstelling => samenstelling.id === context.samenstelling?.id
      );

      if (deletedSamenstelling)
        await Promise.all(
          deletedSamenstelling.onderdelen.map(async v => {
            await fetchData<void>({
              url: `${settings.licentiesOrigin}/Productsamenstellingen/${context.samenstelling?.id}/Productonderdelen/${v}`,
              method: "DELETE"
            });
          })
        );

      //Delete Productsamenstellingen
      await fetchData<void>({
        url: `${settings.licentiesOrigin}/Productsamenstellingen/${context.samenstelling.id}`,
        method: "DELETE"
      });
      draft.licentieSamenstellingen.licentieSamenstellingen = draft.licentieSamenstellingen.licentieSamenstellingen.filter(
        samenstelling => samenstelling.id !== context.samenstelling?.id
      );
      draft.licentieSamenstellingen.loading = false;
    }
  }
);

export const deleteLicentieProduct = createISWAsyncSideEffect<LicentiesAanbodState, Context>(
  async ({ draft, fetchData, settings, context }) => {
    const selectedProduct = draft.producten[context.selected];

    if (selectedProduct && selectedProduct.isDeleted) {
      await fetchData<void>({
        url: `${settings.licentiesOrigin}/Producten/${selectedProduct.id}`,
        method: "DELETE"
      });
      draft.producten = draft.producten.filter(product => product.id !== selectedProduct.id);
    }

    if (selectedProduct && selectedProduct?.id === null) {
      const index = draft.producten.indexOf(selectedProduct);
      draft.producten.splice(index, 1);
    }

    draft.shouldResetForm = true;
    draft.loading = false;
  }
);

export const putProduct = createISWAsyncSideEffect<LicentiesAanbodState, Context>(
  async ({ draft, fetchData, settings, context, formik }) => {
    if (context.product) {
      const selectedProduct = context.product;

      await fetchData<void, Product>({
        url: `${settings.licentiesOrigin}/Producten/${selectedProduct.id}`,
        method: "PUT",
        body: {
          code: selectedProduct.code,
          isActief: selectedProduct.actief,
          isOnlineBestelbaar: selectedProduct.isOnlineBestelbaar,
          naam: selectedProduct.productNaam,
          platformId: selectedProduct.platformId
        }
      });

      draft.producten[context.selected].hasChanged = false;
      draft.producten[context.selected].putChanges = false;
      draft.producten[context.selected].savedChanges = true;
      draft.loading = false;
      draft.shouldResetForm = true;

      if (selectedProduct.triggeredByModal) {
        draft.producten[context.selected].triggeredByModal = false;
        context.setSelected();
      }
    }
  }
);

export const postProduct = createISWAsyncSideEffect<LicentiesAanbodState, Context>(
  async ({ draft, fetchData, settings, context }) => {
    if (context.product && context.product.productNaam !== "" && context.product.code !== "") {
      const selectedProduct = context.product;

      const productResponse = await fetchData<NewOutput, Product>({
        url: `${settings.licentiesOrigin}/Producten`,
        method: "POST",
        body: {
          code: selectedProduct.code,
          isActief: selectedProduct.actief,
          isOnlineBestelbaar: selectedProduct.isOnlineBestelbaar,
          naam: selectedProduct.productNaam,
          platformId: selectedProduct.platformId
        }
      });

      /* istanbul ignore next */
      if (productResponse !== null && productResponse.isValid && productResponse.id) {
        draft.producten[context.selected].id = productResponse.id;
        draft.producten[context.selected].hasChanged = false;
        draft.producten[context.selected].putChanges = false;
        draft.producten[context.selected].savedChanges = true;

        if (selectedProduct.triggeredByModal) {
          draft.producten[context.selected].triggeredByModal = false;
          context.setSelected();
        }

        draft.loading = false;
        draft.shouldResetForm = true;
        return;
      }
    }
  }
);

export const asyncLicentieaanbodSideEffects = initISWAsyncSideEffect<LicentiesAanbodState, Context>(
  ({ has, runAsync, context, curr }) => {
    if (
      context.selected !== prevSelected &&
      hasValue(curr.producten[context.selected]) &&
      hasValue(curr.producten[context.selected]?.id)
    ) {
      prevSelected = context.selected;
      runAsync(fetchNewLicentieOnderdelen(context));
      runAsync(fetchNewLicentieSamenstellingen(context));
    }

    if (has.producten.changed) {
      const producten = curr.producten;
      producten.forEach(product => {
        const ContextWithProduct: Context = {
          selected: context.selected,
          setSelected: context.setSelected,
          product: product
        };

        if (product.isDeleted && product?.id) {
          runAsync(deleteLicentieProduct(ContextWithProduct));
          return;
        }

        if (product.hasChanged && product.putChanges && product.id) {
          runAsync(putProduct(ContextWithProduct));
          return;
        }

        if (!product.id && product.putChanges && product.hasChanged) {
          runAsync(postProduct(ContextWithProduct));
          return;
        }
      });
    }

    if (has.licentieSamenstellingen.licentieSamenstellingen.changed) {
      const licentieSamenstellingen = curr.licentieSamenstellingen.licentieSamenstellingen;
      licentieSamenstellingen.forEach(samenstelling => {
        if (!samenstelling.id || samenstelling.hasChanged || (samenstelling.isDeleted && samenstelling.id)) {
          // TODO: alleen index meegeven zoals in .forEach hierboven gebeurd
          const ContextWithSamenstelling: Context = {
            selected: context.selected,
            setSelected: context.setSelected,
            samenstelling: samenstelling
          };

          if (!samenstelling.id) {
            runAsync(postNewLicentiesamenstelling(ContextWithSamenstelling));
            return;
          }

          if (samenstelling.hasChanged) {
            runAsync(putLicentieSamenstelling(ContextWithSamenstelling));
            return;
          }

          if (samenstelling.isDeleted && samenstelling.id) {
            runAsync(deleteLicentieSamenstelling(ContextWithSamenstelling));
            return;
          }
        }
      });
    }

    if (has.licentieOnderdelen.licentieOnderdelen.changed) {
      const licentieOnderdelen = curr.licentieOnderdelen.licentieOnderdelen;

      licentieOnderdelen.forEach(onderdeel => {
        if (!onderdeel.id || onderdeel.hasChanged || (onderdeel.isDeleted && onderdeel.id)) {
          // TODO: alleen index meegeven
          const ContextWithOnderdeel: Context = {
            selected: context.selected,
            setSelected: context.setSelected,
            onderdeel: onderdeel
          };

          if (!onderdeel.id) {
            runAsync(postNewLicentieOnderdeel(ContextWithOnderdeel));
            return;
          }

          if (onderdeel.isDeleted && onderdeel.id) {
            runAsync(deleteLicentieOnderdeel(ContextWithOnderdeel));
            return;
          }

          if (onderdeel.hasChanged) {
            runAsync(putLicentieOnderdeel(ContextWithOnderdeel));
            return;
          }
        }
      });
    }
  }
);
