import { KlantdossierRechtenOptions } from "../../.generated/licenties/licentiestypes";
import { initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { MedewerkerState, ToegangsrechtenOrderEnum } from "../infra/medewerkers-schema";

export const syncAddMedewerkerSideEffects = initISWSideEffect<MedewerkerState>(({ has, draft, prev }): void => {
  const organisatieToegangsrechten = draft.licentiegegevens?.organisatieToegangsrechten;
  const organisatieVorigeToegangsrechten = prev.licentiegegevens?.organisatieToegangsrechten;
  const vestigingVorigeToegangsrechten = prev?.licentiegegevens?.vestigingToegangsrechten;
  const vestigingToegangsrechten = draft.licentiegegevens?.vestigingToegangsrechten;

  if (
    organisatieToegangsrechten &&
    vestigingToegangsrechten &&
    organisatieVorigeToegangsrechten &&
    vestigingVorigeToegangsrechten
  ) {
    if (
      has.licentiegegevens.organisatieToegangsrechten.changed &&
      Number(ToegangsrechtenOrderEnum[organisatieToegangsrechten]) >
        Number(ToegangsrechtenOrderEnum[organisatieVorigeToegangsrechten]) &&
      Number(ToegangsrechtenOrderEnum[organisatieToegangsrechten]) >
        Number(ToegangsrechtenOrderEnum[vestigingToegangsrechten])
    )
      draft.licentiegegevens.vestigingToegangsrechten = KlantdossierRechtenOptions[organisatieToegangsrechten];

    if (
      has?.licentiegegevens.vestigingToegangsrechten.changed &&
      Number(ToegangsrechtenOrderEnum[vestigingToegangsrechten]) <
        Number(ToegangsrechtenOrderEnum[vestigingVorigeToegangsrechten]) &&
      Number(ToegangsrechtenOrderEnum[vestigingToegangsrechten]) <
        Number(ToegangsrechtenOrderEnum[organisatieToegangsrechten])
    )
      draft.licentiegegevens.organisatieToegangsrechten = KlantdossierRechtenOptions[vestigingToegangsrechten];
  }
});
