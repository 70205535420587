/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  CardWrapper,
  createSpanWithId,
  DataGrid,
  FilterType,
  FormFirstFocus,
  PageLoading,
  PlatformFoutenSamenvatting
} from "adviesbox-shared";
import { Form, FormikContextType, FormikProps, useFormikContext } from "formik";
import React, { ReactElement, useState } from "react";
import { Column } from "react-table-6";
import { Debug } from "../shared/components/formik/Debug";
import { ISWSideEffects } from "../shared/components/isw-side-effects/isw-side-effects";
import { PaginationComponent } from "../shared/components/pagination/pagination";
import { SaveButtonNoSaveData } from "../shared/components/save-button/save-button-no-save-data";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { AddMedewerkerModal } from "./add-medewerker-modal/add-medewerker-modal";
import { Contactgegevens } from "./contactgegevens/contactgegevens";
import { determineMedewerkersAsyncSideEffects } from "./determine-medewerkers-async-side-effects";
import { syncLicentiebeheerMedewerkersSideEffects } from "./determine-medewerkers-sync-side-effects";
import { HasChangesModal } from "./has-changes-modal/has-changes-modal";
import {
  medewerkerSchema,
  MedewerkersProps,
  medewerkersSchema,
  MedewerkersState,
  MedewerkerState,
  ShowHasChangesModal,
  OrganisatieLicentiesType,
  OptiesMetExtraIdsType
} from "./infra/medewerkers-schema";
import { Licentiegegevens } from "./licentiegegevens/licentiegegevens";
import { volledigeNaam } from "./medewerkers-helper";
import { Persoonsgegevens } from "./persoonsgegevens/persoonsgegevens";
import classes from "./medewerkers.module.scss";
import { UserProps } from "../shared/properties/user-props";
import { Gebruikersactie, MagActieUitvoeren, Matrixonderdeel } from "../shared/autorisatie-matrix/autorisatie-matrix";

export type MedewerkersSubProps = {
  data: MedewerkersState;
};

export const saveMedewerker = async (
  result: MedewerkerState,
  medewerkers: MedewerkerState[],
  alleOrganisatieLicenties: OrganisatieLicentiesType[],
  alleOrganisatieUniekeLicenties: OptiesMetExtraIdsType[],
  setFieldValue: FormikContextType<MedewerkerState>["setFieldValue"],
  setSelected: React.Dispatch<React.SetStateAction<number>>
): Promise<void> => {
  if (result) {
    const medewerker = medewerkerSchema.default();
    medewerker.persoonsgegevens = result.persoonsgegevens;
    medewerker.contactgegevens = result.contactgegevens;
    medewerker.licentiegegevens.isActief = result.licentiegegevens.isActief;
    medewerker.readOnlyIsActief = result.licentiegegevens.isActief ? "Ja" : "Nee";
    medewerker.licentiegegevens.isActief = result.licentiegegevens.isActief;
    medewerker.email = result.email;
    medewerker.emailIsAlGebruikt = result.emailIsAlGebruikt;
    medewerker.volledigeNaam = volledigeNaam(
      result.persoonsgegevens.voornaam,
      result.persoonsgegevens.voorletters,
      result.persoonsgegevens.tussenvoegsel,
      result.persoonsgegevens.achternaam,
      "geen naam"
    );

    const licentie = alleOrganisatieUniekeLicenties?.find(
      l => l.id === result.licentiegegevens.gekozenProductsamenstellingId
    );
    medewerker.gekozenlicentieNaam = licentie?.naam ?? "Geen";
    medewerker.licentiegegevens.organisatieToegangsrechten = result.licentiegegevens.organisatieToegangsrechten;
    medewerker.licentiegegevens.vestigingToegangsrechten = result.licentiegegevens.vestigingToegangsrechten;
    medewerker.licentiegegevens.gekozenGebruikersgroepen = result.licentiegegevens.gekozenGebruikersgroepen;
    medewerker.gekozenGebruikersgroepenNaamList = result.licentiegegevens.gekozenGebruikersgroepen
      .map(v => v.naam)
      .join(" , ");
    medewerker.licentiegegevens.gekozenVestigingen = result.licentiegegevens.gekozenVestigingen;
    medewerker.gekozenVestigingenNaamList = result.licentiegegevens.gekozenVestigingen.map(v => v.naam).join(" , ");
    medewerker.licentiegegevens.gekozenProductsamenstellingId = result.licentiegegevens.gekozenProductsamenstellingId;
    medewerker.medewerkerId = result.medewerkerId;
    medewerker.index = medewerkers.length;
    const update = medewerkers.concat([medewerker]);
    setFieldValue(`medewerkers`, update);
    setSelected(update.length - 1);
    setFieldValue("actualSelectedIndex", update.length - 1);
    const availableLicentie = alleOrganisatieLicenties?.find(
      v => !v.medewerkerId && v.productsamenstellingId === result.licentiegegevens.gekozenProductsamenstellingId
    );
    if (availableLicentie) {
      const availableLicentieIndex = alleOrganisatieLicenties.findIndex(
        v => v.licentieId === availableLicentie.licentieId
      );
      setFieldValue(`alleOrganisatieLicenties[${availableLicentieIndex}].medewerkerId`, result.medewerkerId);
    }
  }
};
const MedewerkersComponent = (props: FormikProps<MedewerkersState> & MedewerkersProps & UserProps): ReactElement => {
  const formik = useFormikContext<MedewerkersState>();
  const { values, setFieldValue, isSubmitting } = props;
  const selectedState = useState(0);
  const [newSelected, setNewSelected] = useState(0); //this is the selected-row in the displayed datagrid but not the actual index in the medewerkers array
  const [selectedBeforeSortOrFilter, setSelectedBeforeSortOrFilter] = useState(0); //will trigger the correct item side-effect
  const [selected, setSelected] = selectedState;
  const medewerkerDefault = medewerkerSchema.default();
  const [showHasChangesModal, setShowHasChangesModal] = useState<ShowHasChangesModal>({ show: false, selected: null });
  const saveIsTriggeredByModal = values.medewerkers.find(v => v.triggeredByModal === true)?.index;

  const magSchrijven = MagActieUitvoeren(
    Matrixonderdeel.Medewerkers,
    Gebruikersactie.Schrijven,
    props.user,
    props.userDetails
  );
  const magToevoegen = MagActieUitvoeren(
    Matrixonderdeel.Medewerkers,
    Gebruikersactie.Toevoegen,
    props.user,
    props.userDetails
  );
  const magVerwijderen = MagActieUitvoeren(
    Matrixonderdeel.Medewerkers,
    Gebruikersactie.Verwijderen,
    props.user,
    props.userDetails
  );
  const magMedewerkeruitnodigen = MagActieUitvoeren(
    Matrixonderdeel.MedewerkersUitnodigen,
    Gebruikersactie.Uitvoeren,
    props.user,
    props.userDetails
  );
  const magLezen =
    magSchrijven ||
    magToevoegen ||
    magVerwijderen ||
    magMedewerkeruitnodigen ||
    MagActieUitvoeren(Matrixonderdeel.Medewerkers, Gebruikersactie.Lezen, props.user, props.userDetails);

  /* istanbul ignore next */
  const updateSelected = (newSelected: React.SetStateAction<number>, skip?: boolean, keepSelected?: boolean): void => {
    if (values.medewerkers[values.actualSelectedIndex]?.hasChanged && selected !== newSelected && !skip) {
      setShowHasChangesModal({ show: true, selected: selected });
      setSelected(selected);
      setNewSelected(newSelected);
      return;
    }

    if (keepSelected) {
      setSelected(selected);
      return;
    }

    setSelected(newSelected);
  };

  /* istanbul ignore next */
  const closeModalAndSwitchSelected = (keepSelected?: boolean): void => {
    if (showHasChangesModal.show) {
      setShowHasChangesModal({ show: false, selected: null });
      updateSelected(newSelected, true, keepSelected);
    }
  };

  /* istanbul ignore next */
  const onDataGridSortChange = (data?: MedewerkerState[]): void => {
    if (!data || !data.length) return;
    if (values.medewerkers[values.actualSelectedIndex]?.hasChanged) {
      setShowHasChangesModal({ show: true, selected: selected });
      setSelectedBeforeSortOrFilter(values.actualSelectedIndex);
    }
    setFieldValue("actualSelectedIndex", data[selected].index);
  };
  /* istanbul ignore next */
  const onDataGridFilterChange = (data?: MedewerkerState[]): void => {
    if (!data || !data.length) return;
    if (values.medewerkers[values.actualSelectedIndex]?.hasChanged) {
      setShowHasChangesModal({ show: true, selected: selected });
      setSelectedBeforeSortOrFilter(values.actualSelectedIndex);
    }
    setSelected(0);
    setFieldValue("actualSelectedIndex", data[0].index);
  };

  const medewerkersColumns: Column[] = [
    {
      Header: "Naam",
      id: "volledigeNaam",
      accessor: "volledigeNaam",
      Cell: (c): ReactElement => createSpanWithId(c.index, 0, c.original?.volledigeNaam, c.original?.volledigeNaam)
    },
    {
      Header: "E-mailadres",
      id: "email",
      accessor: "email",
      Cell: (c): ReactElement => createSpanWithId(c.index, 1, c.original?.email, c.original?.email)
    },
    {
      Header: "Licentie",
      id: "gekozenlicentieNaam",
      accessor: "gekozenlicentieNaam",
      Cell: (c): ReactElement =>
        createSpanWithId(c.index, 2, c.original?.gekozenlicentieNaam, c.original?.gekozenlicentieNaam)
    },
    {
      Header: "Vestiging",
      id: "gekozenVestigingenNaamList",
      accessor: "gekozenVestigingenNaamList",
      Cell: (c): ReactElement =>
        createSpanWithId(c.index, 3, c.original?.gekozenVestigingenNaamList, c.original?.gekozenVestigingenNaamList)
    },
    {
      Header: "Gebruikersgroep",
      id: "gekozenGebruikersgroepenNaamList",
      accessor: "gekozenGebruikersgroepenNaamList",
      Cell: (c): ReactElement =>
        createSpanWithId(
          c.index,
          4,
          c.original?.gekozenGebruikersgroepenNaamList,
          c.original?.gekozenGebruikersgroepenNaamList
        )
    },
    {
      Header: "Actief",
      id: "readOnlyIsActief",
      accessor: "readOnlyIsActief",
      Cell: (c): ReactElement => createSpanWithId(c.index, 5, c.original?.readOnlyIsActief),
      maxWidth: 50
    }
  ];

  if (!magLezen) return <></>;

  const magOpslaan = magSchrijven || magToevoegen;

  return (
    <FormFirstFocus>
      <Form>
        {(isSubmitting || values?.onLoadingData) && <PageLoading />}

        <ISWSideEffects<MedewerkersState>
          sync={syncLicentiebeheerMedewerkersSideEffects({
            selected: saveIsTriggeredByModal ?? values.actualSelectedIndex,
            formik,
            setSelected: /* istanbul ignore next */ () => closeModalAndSwitchSelected(true) //keepSelected should always be true
          })}
          async={determineMedewerkersAsyncSideEffects({
            selected: saveIsTriggeredByModal ?? values.actualSelectedIndex
          })}
          runOnFirstRender
        />
        <CardWrapper className="px-3">
          <div className="text-container">
            <h2>Medewerkers</h2>
          </div>
        </CardWrapper>

        <PlatformFoutenSamenvatting />
        <CardWrapper className="px-3 master-detail-card flex-grow-1">
          <Card className={`w-xl-100 w-lg-100 w-md-50 w-50 ${classes.remove_overflow}`}>
            <DataGrid
              masterDetail
              rowCaption="Nieuwe medewerker"
              name="medewerkers"
              columns={medewerkersColumns}
              rowSelected={[selected, updateSelected]}
              showButtonAddRow={magToevoegen}
              defaultPageSize={values.medewerkersInView}
              pageSize={values.medewerkersInView}
              errorHighlight={false}
              showPagination={true}
              PaginationComponent={PaginationComponent(true, "medewerkersInView", "gebruikers")}
              sortable={false} // will only stops the initial sort from auto happening
              sortedCallback={onDataGridSortChange}
              filterable={true}
              filterConfig={{
                volledigeNaam: FilterType.Text,
                email: FilterType.Text,
                isActief: FilterType.Text,
                gekozenlicentieNaam: FilterType.Text,
                gekozenVestigingenNaamList: FilterType.Text,
                gekozenGebruikersgroepenNaamList: FilterType.Text
              }}
              filteredCallback={onDataGridFilterChange}
              getTrProps={
                /* istanbul ignore next */ (_: any, rowInfo: any) => {
                  return {
                    onClick: () => {
                      if (!values.medewerkers[values.actualSelectedIndex]?.hasChanged) {
                        setSelectedBeforeSortOrFilter(rowInfo.original.index);
                        setFieldValue("actualSelectedIndex", rowInfo.original.index);
                      }
                    }
                  };
                }
              }
              popup={
                <AddMedewerkerModal
                  data={{
                    ...medewerkerDefault,
                    licentiegegevens: { ...medewerkerDefault.licentiegegevens, isModal: true }
                  }}
                  alleOrganisatieLicenties={values.alleOrganisatieLicenties}
                  alleOrganisatieUniekeLicenties={values.alleOrganisatieUniekeLicenties}
                  alleOrganisatieGebruikersgroepen={values.alleOrganisatieGebruikersgroepen}
                  alleOrganisatieVestigingen={values.alleOrganisatieVestigingen}
                  licentieBeschikbaar={values.licentieBeschikbaar}
                  organisatieId={values?.organisatieId}
                  onSave={async (result: MedewerkerState): Promise<void> => {
                    await saveMedewerker(
                      result,
                      values.medewerkers,
                      values.alleOrganisatieLicenties,
                      values.alleOrganisatieUniekeLicenties,
                      setFieldValue,
                      setSelected
                    );
                  }}
                />
              }
            />
          </Card>
        </CardWrapper>
        {!!values.medewerkers?.length && (
          <>
            {magOpslaan && (
              <CardWrapper className="pl-4 py-1">
                <div className="button-container">
                  <SaveButtonNoSaveData
                    isEdited={values.medewerkers[values.actualSelectedIndex]?.hasChanged}
                    callBack={() => {
                      setFieldValue(`medewerkers[${values.actualSelectedIndex}].saveIsClicked`, true);
                    }}
                  />
                </div>
              </CardWrapper>
            )}
            <CardWrapper className="px-3">
              <Card title="Persoonsgegevens">
                <Persoonsgegevens data={values} user={props.user} userDetails={props.userDetails} />
              </Card>
              <Card title="Contactgegevens">
                <Contactgegevens data={values} user={props.user} userDetails={props.userDetails} />
              </Card>
              <Card title="Licentiegegevens">
                <Licentiegegevens data={values} user={props.user} userDetails={props.userDetails} />
              </Card>
            </CardWrapper>
          </>
        )}
        <HasChangesModal
          closeModal={setShowHasChangesModal}
          show={showHasChangesModal}
          onDeleteChanges={
            /* istanbul ignore next */ () => {
              const medewerkersInView = values.medewerkersInView;
              const actualSelectedIndex = values.actualSelectedIndex;
              formik.resetForm();
              setFieldValue(`actualSelectedIndex`, actualSelectedIndex);
              setFieldValue(`medewerkers[${actualSelectedIndex}].formIsReset`, true);
              setFieldValue(`medewerkersInView`, medewerkersInView);
              closeModalAndSwitchSelected(true);
            }
          }
          onConfirmSave={
            /* istanbul ignore next */ () => {
              setFieldValue(`medewerkers[${selectedBeforeSortOrFilter}].saveIsClicked`, true);
              setFieldValue(`medewerkers[${selectedBeforeSortOrFilter}].triggeredByModal`, true);
            }
          }
        />

        <Debug />
      </Form>
    </FormFirstFocus>
  );
};

export const Medewerkers = withAdviesboxFormik<MedewerkersProps & UserProps, MedewerkersState>({
  mapPropsToValues: (e: MedewerkersProps): MedewerkersState => e,
  validationSchema: medewerkersSchema
})(MedewerkersComponent);
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") Medewerkers.displayName = "Medewerkers";
