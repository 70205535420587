import { createGetTextResources } from "../../shared/utils/text-resources-algemeen";
/* istanbul ignore file */
export const getLicentiesTextResources = createGetTextResources({
  aantalLicentiesAanpassen:
    "Een nieuw aangevraagde licentie kun je na bevestiging gelijk gebruiken en koppelen aan een medewerker. \n\nWil je een licentie opzeggen? Zorg er dan voor dat deze licentie beschikbaar is en dus niet meer gekoppeld is aan een medewerker. Na opzegging kun je de licentie niet meer gebruiken.",
  VerderNaarLicentiesOpzeggen: "Verder naar licenties opzeggen",
  LicentiesOpzeggen: "Licenties opzeggen",
  VerderNaarLicentiesBestellen: "Verder naar nieuwe licenties bestellen",
  LicentiesBestellen: "Licenties bestellen",
  productNaamLeeg: "Productnaam is verplicht",
  productCodeLeeg: "Code is verplicht",
  aantalLicentiesMinderDanGebruikt:
    "Zorg er alsjeblieft voor dat er voldoende licenties beschikbaar zijn om op te zeggen.",
  productNaamDoesNotMatch: "De ingevoerde naam komt niet overeen met de naam van het product.",
  licentieSamenstellingNaamDoesNotMatch:
    "De ingevoerde naam komt niet overeen met de naam van de licentiesamenstelling.",
  licentieOnderdeelNaamDoesNotMatch: "De ingevoerde naam komt niet overeen met de naam van het licentieonderdeel."
});
