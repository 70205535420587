import * as Yup from "yup";
import { getLicentieaanbodTextResources } from "./licentieaanbod-resources";

export type ShowDeleteModal = {
  show: boolean;
  selected: number | null;
};

export type ShowHasChangesModal = {
  show: boolean;
  selected: number | null;
};
export type LicentieAanbodDeleteType = {
  selected: number;
  type: ModalTypeEnum;
};

export enum ModalTypeEnum {
  product = 1,
  onderdeel = 2,
  samenstelling = 3
}

export const productSchema = Yup.object({
  id: Yup.string(),
  productNaam: Yup.string()
    .required(getLicentieaanbodTextResources("productNaamLeeg"))
    .default(""),
  code: Yup.string()
    .required(getLicentieaanbodTextResources("productCodeLeeg"))
    .default(""),
  platformId: Yup.string().default(""),
  isOnlineBestelbaar: Yup.boolean().default(false),
  actief: Yup.boolean().default(true),
  hasChanged: Yup.boolean().default(false),
  isDeleted: Yup.boolean().default(false),
  putChanges: Yup.boolean().default(false),
  savedChanges: Yup.boolean().default(false),
  triggeredByModal: Yup.boolean().default(false)
});
export type ProductType = Yup.InferType<typeof productSchema>;

export const licentieOnderdelenProductSchema = Yup.object({
  id: Yup.string()
    .nullable()
    .default(null),
  productNaam: Yup.string()
    .default("")
    .required(getLicentieaanbodTextResources("productNaamLeeg")),
  hasChanged: Yup.boolean().default(false),
  isDeleted: Yup.boolean().default(false),
  code: Yup.string()
    .default("")
    .required(getLicentieaanbodTextResources("productCodeLeeg")),
  isInstelbaarDoorKlant: Yup.boolean().default(false),
  aantalGekoppeldeGebruikersgroepen: Yup.number().default(0),
  actief: Yup.boolean().default(true)
});

export type LicentieOnderdelenProductType = Yup.InferType<typeof licentieOnderdelenProductSchema>;

export const licentieOnderdelenSchema = Yup.array(licentieOnderdelenProductSchema).default([
  licentieOnderdelenProductSchema.default()
]);

export type LicentieOnderdelenType = Yup.InferType<typeof licentieOnderdelenSchema>;

export const licentieSamenstellingOnderdeelSchema = Yup.object({
  id: Yup.string(),
  name: Yup.string(),
  enabled: Yup.boolean().default(false)
});

export type LicentieSamenStellingOnderdeelType = Yup.InferType<typeof licentieSamenstellingOnderdeelSchema>;

export const licentieSamenstellingProductSchema = Yup.object({
  id: Yup.string().default(""),
  productNaam: Yup.string()
    .default("")
    .required(getLicentieaanbodTextResources("productNaamLeeg")),
  code: Yup.string()
    .default("")
    .required(getLicentieaanbodTextResources("productCodeLeeg")),
  actief: Yup.boolean().default(true),
  onderdelen: Yup.array(Yup.string()).default([]),
  hasChanged: Yup.boolean().default(false),
  isDeleted: Yup.boolean().default(false),
  onderdelenLength: Yup.number()
});

export type LicentieSamenstellingProductType = Yup.InferType<typeof licentieSamenstellingProductSchema>;

export const productSamenstellingenSchema = Yup.object({
  productenInView: Yup.number().default(10),
  licentieSamenstellingen: Yup.array(licentieSamenstellingProductSchema).default([]),
  loading: Yup.bool().default(false)
});

export type ProductSamenstellingenState = Yup.InferType<typeof productSamenstellingenSchema>;
export type ProductSamenstellingenProps = Yup.InferType<typeof productSamenstellingenSchema>;

export const onderdelenModalSchema = Yup.object({
  id: Yup.string().default(""),
  productNaam: Yup.string()
    .default("")
    .required(getLicentieaanbodTextResources("productNaamLeeg")),
  code: Yup.string()
    .default("")
    .required(getLicentieaanbodTextResources("productCodeLeeg")),
  actief: Yup.boolean().default(true),
  isInstelbaarDoorKlant: Yup.boolean().default(false),
  aantalGekoppeldeGebruikersgroepen: Yup.number().default(0),
  selected: Yup.number()
    .nullable()
    .default(null)
});

export type OnderdelenModalState = Yup.InferType<typeof onderdelenModalSchema>;
export type OnderdelenModalProps = Yup.InferType<typeof onderdelenModalSchema>;

export const samenstellingModalSchema = Yup.object({
  id: Yup.string().default(""),
  productNaam: Yup.string()
    .default("")
    .required(getLicentieaanbodTextResources("productNaamLeeg")),
  code: Yup.string()
    .default("")
    .required(getLicentieaanbodTextResources("productCodeLeeg")),
  actief: Yup.boolean().default(true),
  selected: Yup.number()
    .nullable()
    .default(null),
  onderdelenObjectArray: Yup.array(licentieSamenstellingOnderdeelSchema).default([])
});

export type SamenstellingModalState = Yup.InferType<typeof samenstellingModalSchema>;
export type SamenstellingModalProps = Yup.InferType<typeof samenstellingModalSchema>;

export const productOnderdelenSchema = Yup.object({
  productenInView: Yup.number().default(10),
  licentieOnderdelen: Yup.array(licentieOnderdelenProductSchema).default([]),
  loading: Yup.bool().default(false)
});

export type ProductOnderdelenState = Yup.InferType<typeof productOnderdelenSchema>;
export type ProductOnderdelenProps = Yup.InferType<typeof productOnderdelenSchema>;

export const licentiesAanbodProductSchema = Yup.object({
  productenInView: Yup.number().default(10),
  producten: Yup.array(productSchema)
});

export type LicentiesAanbodProductState = Yup.InferType<typeof licentiesAanbodProductSchema>;
export type LicentiesaanbodProductProps = Yup.InferType<typeof licentiesAanbodProductSchema>;

export const licentiesAanbodSchema = Yup.object({
  productenInView: Yup.number().default(10),
  producten: Yup.array(productSchema),
  licentieOnderdelen: productOnderdelenSchema,
  licentieSamenstellingen: productSamenstellingenSchema,
  shouldResetForm: Yup.boolean().default(false),
  cancelHasChanges: Yup.boolean().default(false),
  loading: Yup.boolean().default(false),
  saveResult: Yup.mixed<"platformError" | "clientError" | "default" | "completed">()
    .required()
    .oneOf(["platformError", "clientError", "default", "completed"])
    .default("default")
});

export type LicentiesAanbodState = Yup.InferType<typeof licentiesAanbodSchema>;
export type LicentiesaanbodProps = Yup.InferType<typeof licentiesAanbodSchema>;
