/* istanbul ignore file */
import * as Yup from "yup";
import {
  Geslacht,
  KlantdossierRechtenOptions,
  OrganisatieEx,
  Vestiging
} from "../../.generated/licenties/licentiestypes";
import { LabelValuePairs } from "../../shared/types";
import { hasValue } from "../../shared/utils/helpers";
import { yupEnum } from "../../shared/utils/yup-enum";
import { getMedewerkerTextResources } from "./medewerkers-resources";

const phoneNumberAllowEmptyRegex = /^(((((\+|00)31|0)[\s-]{0,1}([0-9][\s-]{0,1}){9}|((\+|00)(?!31)[0-9]{2}([0-9][\s-]{0,1}){1,13})))|)$/;

export type ShowHasChangesModal = {
  show: boolean;
  selected: number | null;
};

export enum ToegangsrechtenOrderEnum {
  Geen,
  AlleenLezen,
  LezenEnSchrijven
}
export enum LicentieEnum { // For the extra values in this radio-button
  Geen = "Geen"
}

export const ToegangsrechtenEnumInterface: LabelValuePairs = [
  {
    label: "Geen",
    value: KlantdossierRechtenOptions.Geen
  },
  {
    label: "Alleen lezen",
    value: KlantdossierRechtenOptions.AlleenLezen
  },
  {
    label: "Lezen en schrijven",
    value: KlantdossierRechtenOptions.LezenEnSchrijven
  }
];

export const optiesSchema = Yup.object({
  id: Yup.string().default(""),
  naam: Yup.string().default("")
});
export type OptiesType = Yup.InferType<typeof optiesSchema>;

export const optiesMetExtraIdsSchema = optiesSchema.shape({
  // shape from productsamenstellingId & productsamenstellingNaam
  productId: Yup.string()
    .nullable()
    .default(null), //licentieaanbod productId
  licentieovereenkomstId: Yup.string()
    .nullable()
    .default(null),
  licentieId: Yup.string()
    .nullable()
    .default(null)
});
export type OptiesMetExtraIdsType = Yup.InferType<typeof optiesMetExtraIdsSchema>;

export const organisatieLicentiesSchema = Yup.object({
  licentieId: Yup.string()
    .nullable()
    .default(null),
  medewerkerId: Yup.string()
    .nullable()
    .default(null),
  licentieovereenkomstId: Yup.string()
    .nullable()
    .default(null),
  productsamenstellingId: Yup.string()
    .nullable()
    .default(null)
});
export type OrganisatieLicentiesType = Yup.InferType<typeof organisatieLicentiesSchema>;

export const persoonsgegevensSchema = Yup.object({
  voornaam: Yup.string()
    .nullable()
    .default(null)
    .test({
      message: getMedewerkerTextResources("voornaam"),
      test: function(value: string) {
        return hasValue(value) && value !== "";
      }
    }),
  voorletters: Yup.string()
    .default("")
    .test({
      message: getMedewerkerTextResources("voorletters"),
      test: function(value: string) {
        return hasValue(value) && value !== "";
      }
    }),
  tussenvoegsel: Yup.string()
    .nullable()
    .default(null),
  achternaam: Yup.string()
    .default("")
    .test({
      message: getMedewerkerTextResources("achternaam"),
      test: function(value: string) {
        return hasValue(value) && value !== "";
      }
    }),
  geslacht: yupEnum(Geslacht).default(Geslacht.Man)
});
export type PersoonsgegevensType = Yup.InferType<typeof persoonsgegevensSchema>;

export const contactgegevensSchema = Yup.object({
  telefoon: Yup.string()
    .default("")
    .matches(phoneNumberAllowEmptyRegex, "Vul alsjeblieft een geldig telefoonnummer in."),
  telefoonWerk: Yup.string()
    .nullable()
    .default(null),
  telefoonMobiel: Yup.string()
    .nullable()
    .default(null),
  medewerkerIsGeregistreerd: Yup.bool().default(false)
});
export type ContactgegevensType = Yup.InferType<typeof contactgegevensSchema>;

export const vestigingenSchema = Yup.object({
  vestigingId: Yup.string()
    .nullable()
    .default(""),
  extraVestigingGegevens: Yup.object<Vestiging>()
    .nullable()
    .default(null)
});

export type vestigingenType = Yup.InferType<typeof vestigingenSchema>;

export const licentiegegevensSchema = Yup.object({
  vestigingenId: Yup.array(
    Yup.string()
      .nullable()
      .default(null)
  ).default([]),
  gekozenVestigingen: Yup.array(optiesSchema)
    .default([])
    .test({
      message: getMedewerkerTextResources("vestigingenIsLeeg"),
      test: function(value: any) {
        return this.parent.isModal || !!value.length;
      }
    }),
  gebruikersgroepenId: Yup.array(
    Yup.string()
      .nullable()
      .default(null)
  ).default([]),
  gekozenGebruikersgroepen: Yup.array(optiesSchema)
    .default([])
    .test({
      message: getMedewerkerTextResources("gebruikersgroepenIsLeeg"),
      test: function(value: any) {
        return this.parent.isModal || !!value.length;
      }
    }),
  isActief: Yup.boolean()
    .nullable()
    .default(true),
  gekozenProductsamenstellingId: Yup.string().default(LicentieEnum.Geen),
  prevGekozenProductsamenstellingId: Yup.string().default(LicentieEnum.Geen),
  organisatieToegangsrechten: Yup.mixed<KlantdossierRechtenOptions>()
    .oneOf(Object.values(KlantdossierRechtenOptions))
    .default(KlantdossierRechtenOptions.Geen),
  vestigingToegangsrechten: Yup.mixed<KlantdossierRechtenOptions>()
    .oneOf(Object.values(KlantdossierRechtenOptions))
    .default(KlantdossierRechtenOptions.Geen),
  isModal: Yup.bool().default(false),
  medewerkerLicentieId: Yup.string()
    .nullable()
    .default(null) //the unique licentie id for this medewerker
});
export type LicentiegegevensType = Yup.InferType<typeof licentiegegevensSchema>;

export const medewerkerSchema = Yup.object({
  persoonsgegevens: persoonsgegevensSchema,
  volledigeNaam: Yup.string().default(""),
  contactgegevens: contactgegevensSchema,
  emailIsAlGebruikt: Yup.bool().default(false),
  email: Yup.string()
    .default("")
    .test({
      message: getMedewerkerTextResources("emailIsGebruikt"),
      test: function() {
        return !this.parent.emailIsAlGebruikt;
      }
    })
    .test({
      message: getMedewerkerTextResources("email"),
      test: function(value: string) {
        return hasValue(value) && value !== "";
      }
    }),
  licentiegegevens: licentiegegevensSchema,
  gekozenlicentieNaam: Yup.string().default(LicentieEnum.Geen),
  readOnlyIsActief: Yup.string() //for the datagrid
    .nullable()
    .default("Ja"),
  gekozenGebruikersgroepenNaamList: Yup.string()
    .nullable()
    .default(null),
  gekozenVestigingenNaamList: Yup.string()
    .nullable()
    .default(null),
  medewerkerId: Yup.string()
    .nullable()
    .default(null),
  productId: Yup.string()
    .nullable()
    .default(null),
  platformId: Yup.string()
    .nullable()
    .default(null),
  saveIsClicked: Yup.bool().default(false), //aka putChanges
  hasChanged: Yup.bool().default(false),
  formIsReset: Yup.bool().default(false),
  triggeredByModal: Yup.boolean().default(false), //the modal triggered the save
  errors: Yup.array(
    Yup.object({
      field: Yup.string()
        .nullable()
        .default(""),
      label: Yup.string()
        .nullable()
        .default(""),
      error: Yup.string()
        .nullable()
        .default("")
    }).default({})
  ).default([]),
  shouldSave: Yup.object({
    //flags for the async side-effect
    licentieRadioButtonChanged: Yup.bool().default(false),
    vestigingenChanged: Yup.bool().default(false),
    gebrukersGroepenChanged: Yup.bool().default(false),
    medewerkerChanged: Yup.bool().default(false)
  }),
  index: Yup.number().default(0)
});
export type MedewerkerState = Yup.InferType<typeof medewerkerSchema>;

export const medewerkersSchema = Yup.object({
  medewerkersInView: Yup.number().default(10),
  medewerkers: Yup.array(medewerkerSchema)
    .default([])
    .test("Minstens 1 gebruiker deel uitmaken van de 'Beheerder' gebruikersgroep", "", function(list) {
      const heeftGeenBeheerder = list?.every((m: MedewerkerState) => {
        const medewerkerGebruikersgroepen = m?.licentiegegevens?.gekozenGebruikersgroepen;
        if (
          medewerkerGebruikersgroepen &&
          medewerkerGebruikersgroepen.every((v: OptiesType) => v.naam !== "Beheerder")
        ) {
          return true;
        }
        return false;
      });
      if (heeftGeenBeheerder) {
        return this.createError({
          path: `${this.path}[${this.parent.actualSelectedIndex}].licentiegegevens.gekozenGebruikersgroepen`,
          message: getMedewerkerTextResources("minstens1Beheerder")
        });
      }

      return true;
    }),
  actualSelectedIndex: Yup.number().default(0),
  licentieBeschikbaar: Yup.bool().default(true),
  organisatieGegevens: Yup.object<OrganisatieEx>()
    .nullable()
    .default(null),
  organisatieId: Yup.string().default(""),
  alleOrganisatieVestigingen: Yup.array(optiesSchema).default([]),
  vestigingen: Yup.array(vestigingenSchema).default([]),
  alleOrganisatieGebruikersgroepen: Yup.array(optiesSchema).default([]),
  heeftProductsamenstellingInitialvalues: Yup.bool().default(false),
  heeftVestigingInitialvalues: Yup.bool().default(false),
  heeftGebruikersgroepenInitialvalues: Yup.bool().default(false),
  medewerkerRegistreren: Yup.bool().default(false),
  emailSendSuccess: Yup.bool()
    .nullable()
    .default(null),
  onLoadingData: Yup.bool().default(false),
  shouldResetForm: Yup.boolean().default(false), //after every PUT the formik is reset
  alleOrganisatieUniekeLicenties: Yup.array(optiesMetExtraIdsSchema).default([]),
  alleOrganisatieLicenties: Yup.array(organisatieLicentiesSchema).default([])
});
export type MedewerkersState = Yup.InferType<typeof medewerkersSchema>;
export type MedewerkersProps = Yup.InferType<typeof medewerkersSchema>;
