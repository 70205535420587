import { Icon } from "adviesbox-shared";
import React, { ReactElement } from "react";
import { Button } from "react-bootstrap";
import { ShowDeleteModal } from "./vestigingen-schema";

const ModalDeleteButton = ({
  index,
  updateSelected,
  setShowPopup
}: {
  index: number;
  updateSelected: (selected: number, skip?: boolean) => void;
  setShowPopup: React.Dispatch<React.SetStateAction<ShowDeleteModal>>;
}): ReactElement => {
  return (
    <>
      <Button
        data-testid={`btn-verwijder-${index}`}
        id={`btn-verwijder-${index}`}
        variant="outline-secondary"
        onMouseDown={(event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
          if (event.button !== 0) return;
          event.stopPropagation();
          updateSelected(index);
          setShowPopup({ show: true, selected: index });
        }}
      >
        <Icon name="prullenbak" alt="Verwijderen" />
      </Button>
    </>
  );
};

ModalDeleteButton.displayName = "ModalDeleteButton";

export default ModalDeleteButton;
