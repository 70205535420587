import { hasValue } from "adviesbox-shared";
import { FormikContextType, FormikErrors } from "formik";
import { GebruikersgroepenState, GebruikersgroepType } from "./infra/gebruikersgroepen-schema";

export const validateFormikValues = async (
  formik: FormikContextType<GebruikersgroepenState>,
  selected: number
): Promise<FormikErrors<GebruikersgroepType> | null> => {
  return await formik.validateForm().then(unfulfilled => {
    if (unfulfilled && unfulfilled.gebruikersgroepen?.gebruikersgroep && typeof unfulfilled !== "string") {
      const errors =
        hasValue(unfulfilled.gebruikersgroepen?.gebruikersgroep) &&
        (unfulfilled.gebruikersgroepen?.gebruikersgroep[selected] as FormikErrors<GebruikersgroepType>);
      if (errors) {
        formik.setTouched({
          gebruikersgroepen: {
            gebruikersgroep: {
              [selected]: {
                groepsNaam: errors?.groepsNaam
              }
            } as GebruikersgroepType
          } as any
        });
        return errors;
      }
    }
    return null;
  });
};
