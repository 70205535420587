import {
  AdviesBoxColumn,
  Card,
  CardWrapper,
  createSpanWithId,
  DataGrid,
  FormFirstFocus,
  PageLoading,
  PlatformFoutenSamenvatting
} from "adviesbox-shared";
import { Form, FormikProps, useFormikContext } from "formik";
import React, { ReactElement, useState } from "react";
import { Gebruikersactie, MagActieUitvoeren, Matrixonderdeel } from "../shared/autorisatie-matrix/autorisatie-matrix";
import { Debug } from "../shared/components/formik/Debug";
import { ISWSideEffects } from "../shared/components/isw-side-effects/isw-side-effects";
import { UserProps } from "../shared/properties/user-props";
import { WithSaveData } from "../shared/utils/save-data";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { syncLicentiesSideEffects } from "./infra/determine-sync-licenties-side-effects";
import { LicentiesProps, licentiesSchema, LicentiesState } from "./infra/licenties-schema";
import { LicentieCard } from "./licentieCard/licentieCard";

const LicentiesComponent = (props: FormikProps<LicentiesState> & LicentiesProps & UserProps): ReactElement => {
  const { isSubmitting } = props;
  const selectedState = useState(0);
  const { values } = useFormikContext<LicentiesState>();
  const [selected] = selectedState;

  const magSchrijven = MagActieUitvoeren(
    Matrixonderdeel.Licenties,
    Gebruikersactie.Schrijven,
    props.user,
    props.userDetails
  );
  const magToevoegen = MagActieUitvoeren(
    Matrixonderdeel.Licenties,
    Gebruikersactie.Toevoegen,
    props.user,
    props.userDetails
  );
  const magVerwijderen = MagActieUitvoeren(
    Matrixonderdeel.Licenties,
    Gebruikersactie.Verwijderen,
    props.user,
    props.userDetails
  );
  const magLezen =
    magSchrijven ||
    magToevoegen ||
    magVerwijderen ||
    MagActieUitvoeren(Matrixonderdeel.Licenties, Gebruikersactie.Lezen, props.user, props.userDetails);

  if (!magLezen) return <></>;

  const licentieAanbodColumns = (): AdviesBoxColumn[] => [
    {
      Header: "Soort licentie",
      id: "naam",
      Cell: (c): ReactElement => createSpanWithId(c.index, 1, c.original.naam, c.original.naam, "naam")
    },
    {
      Header: "Aantal licenties",
      id: "aantalLicenties",
      Cell: (c): ReactElement =>
        createSpanWithId(
          c.index,
          2,
          `${c.original.aantalLicenties}`,
          `${c.original.aantalLicenties}`,
          "aantalLicenties"
        )
    },
    {
      Header: "Licenties in gebruik",
      id: "aantalLicentiesInGebruik",
      Cell: (c): ReactElement =>
        createSpanWithId(
          c.index,
          2,
          `${c.original.aantalLicentiesInGebruik}`,
          `${c.original.aantalLicentiesInGebruik}`,
          "aantalLicentiesInGebruik"
        )
    },
    {
      Header: "Licenties beschikbaar",
      id: "aantalLicentiesBeschikbaar",
      Cell: (c): ReactElement =>
        createSpanWithId(
          c.index,
          2,
          `${c.original.aantalLicentiesBeschikbaar}`,
          `${c.original.aantalLicentiesBeschikbaar}`,
          "aantalLicentiesBeschikbaar"
        )
    }
  ];

  return (
    <FormFirstFocus>
      <Form>
        {isSubmitting && <PageLoading />}
        <ISWSideEffects<LicentiesState> sync={syncLicentiesSideEffects({ selected })} />
        <CardWrapper className="px-3">
          <div className="text-container">
            <h2>Licenties</h2>
            <div className="save-btn-position">
              <div className="button-container"></div>
            </div>
          </div>
        </CardWrapper>

        <PlatformFoutenSamenvatting />

        <CardWrapper className="px-3 master-detail-card flex-grow-1">
          <Card className="w-xl-100 w-lg-100 w-md-50 w-50">
            <DataGrid
              masterDetail
              rowCaption="Nieuw product"
              columns={licentieAanbodColumns()}
              rowSelected={selectedState}
              name="samenstelling"
            />
          </Card>
        </CardWrapper>
        {values.samenstelling[selected] && (
          <CardWrapper maxRowCount={2} className={"px-3"}>
            <Card className="w-50" title={`${values.samenstelling[selected].naam}`}>
              <LicentieCard
                selected={selected}
                data={licentiesSchema.default()}
                user={props.user}
                userDetails={props.userDetails}
              />
            </Card>
          </CardWrapper>
        )}

        <Debug />
      </Form>
    </FormFirstFocus>
  );
};

export const Licenties = withAdviesboxFormik<LicentiesProps & UserProps & WithSaveData<LicentiesState>, LicentiesState>(
  {
    // Transform outer props into form values
    mapPropsToValues: (e: LicentiesProps): LicentiesState => e,
    validationSchema: licentiesSchema
  }
)(LicentiesComponent);
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") Licenties.displayName = "Licenties";
