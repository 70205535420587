import React, { ReactElement, ReactNode, useState } from "react";
import UserDetailsContext, { defaultUserDetails, Medewerker } from "./user-details-context";
import { Geslacht } from "../../.generated/licenties/licentiestypes";

const setUserDetailsProviderValue = (
  setUserDetails: (userDetails: Medewerker) => void,
  userDetails: Medewerker
): typeof defaultUserDetails => ({
  userDetails,
  setUserDetails
});

const UserDetailsProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [userDetails, setUserDetails] = useState({
    voornaam: "",
    tussenvoegsels: "",
    achternaam: "",
    roepnaam: null,
    voorletters: "",
    geslacht: Geslacht.Man,
    telefoon: null,
    telefoonWerk: null,
    telefoonMobiel: null,
    email: "",
    functie: null,
    platformId: null,
    klantId: null,
    isActief: true,
    klantdossierRechtenVestigingen: null,
    klantdossierRechtenOrganisatie: null,
    medewerkerId: "",
    organisatieId: ""
  } as Medewerker);

  const val = setUserDetailsProviderValue(setUserDetails, userDetails);
  return <UserDetailsContext.Provider value={val}>{children}</UserDetailsContext.Provider>;
};

export default UserDetailsProvider;
