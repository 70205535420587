import { AuthContext, ErrorPage, PageLoading, useRequestInit, withErrorBoundary, ForceRerenderContext } from "adviesbox-shared";
import React, { ReactElement, useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import useAbortableFetch from "use-abortable-fetch";
import { LicentiesApi } from "../licenties/infra/licenties-api";
import { LicentiesOutput } from "../.generated/licenties/licentiestypes";
import { MagActieUitvoeren, Matrixonderdeel } from "../shared/autorisatie-matrix/autorisatie-matrix";
import { RouteParams } from "../shared/paramrouting/paramrouting-context";
import UserDetailsContext from "../shared/user-details/user-details-context";
import { mapLicentiesDlToUi } from "./infra/map-licenties-dl-to-ui";
import { LicentieSamenstellingAjax } from "./licentie-samenstelling-ajax";

const LicentiesAjaxComponent = (): ReactElement => {
  const params = useParams() as RouteParams;
  const { user } = useContext(AuthContext);
  const { userDetails } = useContext(UserDetailsContext);

  // als de requestedOrganisatie van de userDetails wijzigt, dan moet MagActieUitvoeren opnieuw bepaald worden
  const heeftToegang = useMemo(() => MagActieUitvoeren(Matrixonderdeel.Licenties, null, user, userDetails),
    [user, userDetails, userDetails.requestedOrganisatie]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadingDone = useContext(ForceRerenderContext);
  const { requestInit, settings } = useRequestInit();

  const url = useMemo(() => (heeftToegang ? LicentiesApi.getLicentiesUrl(settings, params) : null), [
    heeftToegang,
    settings,
    params
  ]);

  const { loading, error: platformError, data: platformData } = useAbortableFetch<LicentiesOutput>(url, requestInit);

  const data = useMemo(
    () =>
      platformData && typeof platformData !== "string"
        ? mapLicentiesDlToUi(platformData) /* istanbul ignore next */
        : null,
    [platformData]
  );

  useEffect((): void => {
    if (loading && platformData && loadingDone) {
      loadingDone();
    }
  }, [loading, platformData, loadingDone]);

  if (!heeftToegang) {
    return <ErrorPage error={{ ...Error("Geen rechten toegekend voor deze gebruiker"), status: 403 }} data={null} />;
  }

  if (platformError) {
    return <ErrorPage error={platformError} data={platformData} />;
  }

  if (!data || loading) {
    return <PageLoading />;
  }

  return <LicentieSamenstellingAjax licentiesData={data} user={user} userDetails={userDetails} />;
};

export const LicentiesAjax = withErrorBoundary(LicentiesAjaxComponent);
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") LicentiesAjax.displayName = "LicentiesAjax";
