import React, { ReactElement } from "react";
import { Button, Modal } from "react-bootstrap";

type HdnNodenummerAanpassingenModalProps = {
  showModal: boolean;
  onRevertClick: () => void;
  onSubmitClick: () => void;
  onCancelClick: () => void;
};

export const HdnNodenummerAanpassingenModal = ({
  onCancelClick,
  onSubmitClick,
  onRevertClick,
  showModal
}: HdnNodenummerAanpassingenModalProps): ReactElement => {
  return (
    <Modal show={showModal}>
      <Modal.Header>
        <Modal.Title>Openstaande aanpassingen</Modal.Title>
        <button onClick={onCancelClick} type="button" className={"close ml-auto"} id={`btnClose-HDN`}>
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <span>Er zijn nog aanpassingen in het huidige scherm. wil je deze verwijderen of opslaan?</span>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={onRevertClick}
          id="hdnAanpassenModal-modal-terugdraaien"
          data-testid="hdnAanpassenModal-modal-terugdraaien"
          variant="danger"
        >
          Verwijderen
        </Button>
        <Button
          onClick={onSubmitClick}
          id="hdnAanpassenModal-modal-opslaan"
          data-testid="hdnAanpassenModal-modal-opslaan"
          variant=""
        >
          Opslaan
        </Button>
        <Button
          onClick={onCancelClick}
          id="hdnAanpassenModal-modal-sluiten"
          data-testid="hdnAanpassenModal-modal-sluiten"
          variant=""
        >
          Annuleren
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
