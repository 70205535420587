/* istanbul ignore file */

// import {
//   LicentiebeheerLogboek as LogboekDlEntry
// } from "../../.generated/forms/formstypes";

import { logboekSchema, LogboekState } from "./logboek-schema";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";

export const mapLogboekUiToDl = createMapToDl(logboekSchema)
  // TODO: Remove any
  .to<any | LogboekState>({
    // TODO: map keys
  });
