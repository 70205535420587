import { SettingsType, hasValue } from "adviesbox-shared";

import { User } from "oidc-client";

export const deleteOrganisatie = async (
  organisatieId: string | null | undefined,
  settings: SettingsType,
  user: User | null
): Promise<boolean> => {
  if (!hasValue(organisatieId) || organisatieId === "")
    throw Error(`Data bevat geen organisatie-Id om te verwijderen.`);

  const url = `${settings.licentiesOrigin}/Organisaties/${organisatieId}`;
  const rsp = await fetch(url, {
    method: "DELETE",
    headers: {
      authorization: `${user?.token_type} ${user?.access_token}`,
      "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
      "Content-Type": "application/json;charset=UTF-8"
    }
  });

  if (!rsp.ok) throw Error(`Delete is mislukt '${url}'.`);
  return rsp.ok;
};
