import { LabeledTextInput, FormFirstFocus, AdviesBoxFormik, LabeledBevestigingInput } from "adviesbox-shared";
import React, { ReactElement } from "react";
import Modal from "../../shared/components/modal/Modal";
import {
  licentieOnderdelenProductSchema,
  OnderdelenModalState,
  LicentieOnderdelenProductType
} from "../infra/licentieaanbod-schema";
import { mapLicentieOnderdeelModalToScreen } from "./map-onderdelen-modal";

type LicentieOnderdelenModalProps = {
  data: OnderdelenModalState;
  closeModal?: any;
  onSubmit: (data: LicentieOnderdelenProductType) => void;
  edit: boolean;
  readOnly: boolean;
};

export const LicentieOnderdelenModal = ({
  data,
  closeModal,
  onSubmit,
  edit,
  readOnly
}: LicentieOnderdelenModalProps): ReactElement => {
  return (
    <AdviesBoxFormik<OnderdelenModalState>
      initialValues={data}
      validationSchema={licentieOnderdelenProductSchema}
      closeModal={closeModal}
      render={(formik): ReactElement => (
        <Modal
          onSubmitClick={() => {
            if (readOnly) { closeModal(); return; }
            if (formik.values.code === "" || formik.values.productNaam === "") {
              return;
            }
            onSubmit(mapLicentieOnderdeelModalToScreen(formik.values));
            closeModal();
          }}
          onCancelClick={closeModal}
          title={`Licentieonderdeel ${edit ? "bewerken" : "toevoegen"}`}
          body={
            <FormFirstFocus selector={`input[name="newKopieOptieNaam"]`}>
              <div className="w-100 px-3">
                <LabeledTextInput verplicht={true} caption="Naam onderdeel" name="productNaam" readOnly={readOnly} />
                <LabeledTextInput verplicht={true} caption="Code" name="code" readOnly={readOnly} />
                <LabeledBevestigingInput caption="Actief" name="actief" readonly={readOnly} />
              </div>
            </FormFirstFocus>
          }
        />
      )}
    />
  );
};
