import { ErrorPage, PageLoading, useRequestInit, withErrorBoundary, ForceRerenderContext } from "adviesbox-shared";
import React, { ReactElement, useContext, useEffect, useMemo } from "react";
import useAbortableFetch from "use-abortable-fetch";
import { LicentiesApi } from "../licenties/infra/licenties-api";
import { ProductenOutput, ProductsamenstellingenOutput } from "../.generated/licenties/licentiestypes";
import { MagActieUitvoeren, Matrixonderdeel } from "../shared/autorisatie-matrix/autorisatie-matrix";
import { UserProps } from "../shared/properties/user-props";
import { SamenstellingType, LicentiesState } from "./infra/licenties-schema";
import { mapSamenstellingDlToUI, mapProductenDlToUI } from "./infra/map-licenties-dl-to-ui";
import { LicentiesContractAjax } from "./licentie-contract-ajax";

/* istanbul ignore file */

type Props = {
  licentiesData: LicentiesState;
};

const LicentieSamenstellingAjaxComponent = (props: Props & UserProps): ReactElement => {
  const loadingDone = useContext(ForceRerenderContext);
  const { requestInit, settings } = useRequestInit();
  const usersOrganisatieId = props.userDetails.organisatieId ? props.userDetails.organisatieId : null;

  const heeftToegang = MagActieUitvoeren(Matrixonderdeel.Licenties, null, props.user, props.userDetails);

  const urlPRD = useMemo(() => (heeftToegang ? LicentiesApi.getProductenUrl(settings) : null), [
    heeftToegang,
    settings
  ]);
  const { loading: loadingPRD, error: platformErrorPRD, data: platformDataPRD } = useAbortableFetch<ProductenOutput>(
    urlPRD,
    requestInit
  );

  const data = useMemo(
    () => (platformDataPRD && typeof platformDataPRD !== "string" ? mapProductenDlToUI(platformDataPRD) : null),
    [platformDataPRD]
  );

  const firstProductId = data?.producten[0].id ?? null;

  const { requestInit: requestInitPS, settings: settingPS } = useRequestInit();

  const urlPS = useMemo((): string | null => {
    if (heeftToegang && firstProductId !== null) {
      return LicentiesApi.getProductsamenstellingenUrl(settingPS, { product: firstProductId });
    }
    return null;
  }, [heeftToegang, firstProductId, settingPS]);

  const { loading: loadingPS, error: platformErrorPS, data: platformDataPS } = useAbortableFetch<
    ProductsamenstellingenOutput
  >(urlPS, requestInitPS);
  const productSamenstellingenData = useMemo(
    () => (platformDataPS && typeof platformDataPS !== "string" ? mapSamenstellingDlToUI(platformDataPS) : null),
    [platformDataPS]
  );

  useEffect((): void => {
    if (urlPS && loadingPRD && platformDataPRD && loadingPS && platformDataPS && loadingDone) {
      loadingDone();
    }
  }, [urlPS, loadingPRD, platformDataPRD, loadingPS, platformDataPS, loadingDone]);

  if (!heeftToegang) return <ErrorPage error={new Error("Geen toegang")} />;

  if (platformErrorPRD) {
    return <ErrorPage error={platformErrorPRD} data={platformDataPRD} />;
  }

  if (platformErrorPS) {
    return <ErrorPage error={platformErrorPS} data={platformDataPS} />;
  }

  if (!data || loadingPRD || loadingPS || !usersOrganisatieId || !productSamenstellingenData) {
    return <PageLoading />;
  }

  const licentieSamenstellingen = (
    original: SamenstellingType[],
    availableSamenstellingen: SamenstellingType[]
  ): SamenstellingType[] => {
    const samenstellingen: SamenstellingType[] = [];
    availableSamenstellingen.forEach(samenstelling => {
      const filtered = original.filter(ogSamenstelling => ogSamenstelling.id === samenstelling.id);
      if (filtered.length === 0) {
        samenstellingen.push(samenstelling);
        return;
      }
    });

    return original.concat(samenstellingen);
  };

  const filteredData = {
    ...props.licentiesData,
    samenstelling: licentieSamenstellingen(
      props.licentiesData.samenstelling,
      productSamenstellingenData.licentieSamenstellingen
    )
  };

  return <LicentiesContractAjax licentiesData={filteredData} user={props.user} userDetails={props.userDetails} />;
};

export const LicentieSamenstellingAjax = withErrorBoundary(LicentieSamenstellingAjaxComponent);
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production")
  LicentieSamenstellingAjax.displayName = "LicentieSamenstellingAjax";
