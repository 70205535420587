/* istanbul ignore file */

import { AuthContext, MenuWrapper } from "adviesbox-shared";
import React, { ReactElement, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import brandLogoIcon from "../assets/new-brand-logo.svg";
import brandCollapsedLogoIcon from "../assets/new-collapsed-brand-logo.svg";
import { RouteParams } from "../shared/paramrouting/paramrouting-context";
import UserDetailsContext from "../shared/user-details/user-details-context";
import { useExtendUserDetailsWithRequestedOrganisatie } from "../shared/user-details/utils/user-details-hooks";
import { menuConfig } from "./menu-config";

type NavigationListProps = {
  collapsed: boolean;
  collapse(): void;
};

const NavigationList = ({ collapsed, collapse }: NavigationListProps): ReactElement => {
  const params = useParams() as RouteParams;
  const { user } = useContext(AuthContext);
  const { userDetails } = useContext(UserDetailsContext);

  useExtendUserDetailsWithRequestedOrganisatie(params.organisatie ?? "");

  // als de requestedOrganisatie van de userDetails wijzigt, dan moet de menuConfig ook opnieuw bepaald worden
  const config = useMemo(() => {
    return menuConfig(params, user, userDetails);
  }, [params, user, userDetails, userDetails.requestedOrganisatie]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MenuWrapper
      brandLogoIcon={brandLogoIcon}
      brandCollapsedLogoIcon={brandCollapsedLogoIcon}
      collapse={collapse}
      collapsed={collapsed}
      hasMenuInfo={true}
      loading={false}
      menuConfig={config}
      appName={"contract"}
    />
  );
};

NavigationList.displayName = "NavigationList";

export default NavigationList;
