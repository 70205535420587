/* istanbul ignore file */
import React, { ReactElement } from "react";
import { FormikProps, Form } from "formik";
import { FormFirstFocus, PageLoading, CardWrapper, Card, LabeledTextInput, PlatformFoutenSamenvatting } from "adviesbox-shared";
import { LogboekState, LogboekProps, logboekSchema } from "./infra/logboek-schema";
import { syncLogboekSideEffects } from "./infra/determine-sync-logboek-side-effects";
import { asyncLogboekSideEffects } from "./infra/determine-async-logboek-side-effects";
import { ISWSideEffects } from "../shared/components/isw-side-effects/isw-side-effects";
import { SaveButton } from "../shared/components/save-button/save-button";
import { Debug } from "../shared/components/formik/Debug";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { WithSaveData } from "../shared/utils/save-data";

const LogboekComponent = (props: FormikProps<LogboekState> & LogboekProps): ReactElement => {
  const { isSubmitting } = props;

  return (
    <FormFirstFocus>
      <Form>
        {isSubmitting && <PageLoading />}

        <ISWSideEffects<LogboekState> sync={syncLogboekSideEffects} async={asyncLogboekSideEffects} />

        <CardWrapper className="px-3">
          <div className="text-container">
            <h2>Logboek</h2>
            <div className="save-btn-position">
              <div className="button-container">
                <SaveButton context={props} />
              </div>
            </div>
          </div>
        </CardWrapper>

        <PlatformFoutenSamenvatting />

        <CardWrapper>
          <Card title="Logboek">
            <LabeledTextInput caption="Datagrid komt hier" name="placeholder" />
          </Card>
        </CardWrapper>

        <Debug />
      </Form>
    </FormFirstFocus>
  );
};

export const Logboek = withAdviesboxFormik<LogboekProps & WithSaveData<LogboekState>, LogboekState>({
  // Transform outer props into form values
  mapPropsToValues: (e: LogboekProps): LogboekState => e,
  validationSchema: logboekSchema
})(LogboekComponent);
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") Logboek.displayName = "Logboek";
