import React, { ReactElement } from "react";

import "adviesbox-shared";
import { SelectInput } from "adviesbox-shared";
import classes from "./zoeken-dropdown.module.scss";
import { LabelValuePairs } from "adviesbox-shared/utils/types";
import { SearchCategories, ZoekschermState } from "../infra";
import { useFormikContext } from "formik";

type ZoekenDropdownProps = {};

export const ZoekenDropdown = (_props: ZoekenDropdownProps): ReactElement => {
  const formik = useFormikContext<ZoekschermState>();
  const { setFieldValue } = formik;
  const searchOptions: LabelValuePairs = [
    {
      label: "naam",
      value: SearchCategories.Naam
    },
    {
      label: "postcode",
      value: SearchCategories.Postcode
    },
    {
      label: "woonplaats",
      value: SearchCategories.Woonplaats
    },
    {
      label: "KVK-nummer",
      value: SearchCategories.KVKnummer
    }
  ];

  return (
    <>
      <div className={`${classes.dropdown} d-flex flex-row`}>
        <div className={`${classes.dropdown_caption}`}>
          <span>Zoeken op</span>
        </div>
        <div>
          <SelectInput
            valueAsUppercase={true}
            name="zoeken.searchCategory"
            className={classes.select_dropdown}
            options={searchOptions}
            onChange={event => {
              setFieldValue("zoeken.searchValue", "");
              setFieldValue("zoeken.searchValueDate", null);
              setFieldValue("zoeken.searchCategory", event.target.value);
            }}
          />
        </div>
      </div>
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") ZoekenDropdown.displayName = "ZoekenDropdown";
