/* istanbul ignore file */
import { Adres, AdresReadOnly } from "../../../.generated/forms/formstypes";
import { AdresInput } from "../../../.generated/licenties/licentiestypes";
import { AdresType } from "../../types";

export function mapAdresUiNaarDl(value: AdresType): Adres | AdresReadOnly {
  let postcode = value.postcode;

  if (value.landCode === "NL") {
    postcode = value.postcode.toUpperCase().replace(/\s/g, "");
    const letters = postcode.slice(4, 6);
    const numbers = postcode.slice(0, 4);

    if (numbers.charAt(0) !== "") {
      postcode = `${numbers} ${letters}`;
    }
  }

  const result: Adres | AdresReadOnly = {
    straat: value.straat || "",
    huisnummer: typeof value.huisnummer === "number" ? value.huisnummer : null,
    toevoeging: value.toevoeging || "",
    postcode: postcode || "",
    locatie: value.locatie || "",
    plaats: value.plaats || "",
    landCode: value.landCode
  };
  return result;
}

// TODO: vervangen door bovenstaande functie zodra mogelijk
export function mapAdresUiNaarDlAdresInput(value: AdresType): AdresInput {
  const baseAdres = mapAdresUiNaarDl(value);
  const adresInput: AdresInput = {
    huisnummer: baseAdres.huisnummer?.toString() ?? "",
    toevoeging: baseAdres.toevoeging,
    straatnaam: baseAdres.straat ?? "",
    plaats: baseAdres.plaats ?? "",
    postcode: baseAdres.postcode ?? "",
    landCode: baseAdres.landCode ?? "",
    land: baseAdres.landCode === "NL" ? "Nederland" : ""
  };

  return adresInput;
}
