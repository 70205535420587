import { VestigingenSchermState } from "./vestigingen-schema";
import { initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { FormikContextType } from "formik";
import { original } from "immer";
import adresSchema from "../../shared/generic-parts/adres/schema";

type ContextType = {
  selected: number;
  formik: FormikContextType<VestigingenSchermState>;
};

export const syncVestigingenSideEffects = initISWSideEffect<VestigingenSchermState, ContextType>((bag): void => {
  const { draft, has, context } = bag;
  const selected = bag.context.selected;
  const selectedVestiging = draft.vestigingen[selected];

  //if an unfilled record is deleted
  if (
    has.vestigingen[context.selected]?.isDeleted.changed &&
    draft.vestigingen[context.selected]?.isDeleted &&
    !draft.vestigingen[context.selected]?.id
  ) {
    draft.vestigingen.splice(context.selected, 1);
  }

  const vestigingMetHoofdvestiging = draft.vestigingen.map(vestiging => {
    return vestiging.isHoofdvestiging === true;
  });

  const filteredVestigingMetHoofdVestigingArray = vestigingMetHoofdvestiging.filter(value => {
    return value === true;
  });

  /* istanbul ignore next */
  if (
    selectedVestiging &&
    has.vestigingen[selected]?.isHoofdvestiging.changed &&
    filteredVestigingMetHoofdVestigingArray.length > 1
  ) {
    selectedVestiging.waarschuwingsBericht = "Er kan maar één hoofdvestiging zijn.";
  }

  if (selectedVestiging && has.vestigingen[selected].changed && filteredVestigingMetHoofdVestigingArray.length === 1)
    selectedVestiging.waarschuwingsBericht = "";

  if (
    has.vestigingen[context.selected]?.afwijkendPostadresTonen.changed &&
    draft.vestigingen[context.selected]?.afwijkendPostadresTonen
  ) {
    draft.vestigingen[context.selected].postadres = adresSchema.default(); //set the default landcode to NL
  }

  if (
    has.vestigingen[context.selected].changed &&
    !has.vestigingen[context.selected].isDeleted.changed &&
    !has.vestigingen[context.selected].hasChanged.changed &&
    !has.vestigingen[context.selected].putChanges.changed &&
    !has.vestigingen[context.selected].savedChanges.changed &&
    !has.vestigingen[context.selected].triggeredByModal.changed
  ) {
    draft.vestigingen[context.selected].hasChanged = true;
    draft.vestigingen[context.selected].putChanges = false;
  }

  if (has.shouldResetForm.changed && draft.shouldResetForm) {
    const values = original(draft);
    /* istanbul ignore else */
    if (values) {
      context.formik.resetForm({ values });
      draft.shouldResetForm = false;
      /* istanbul ignore else */
      if (draft.vestigingen[context.selected] !== undefined) {
        draft.vestigingen[context.selected].hasChanged = false;
        draft.vestigingen[context.selected].putChanges = false;
      }
      draft.cancelHasChanges = true;
    }
  }
});
