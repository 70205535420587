import React from "react";
import { ReactElement } from "react";
import { LabeledNumberInput } from "adviesbox-shared";
import { getLicentiesTextResources } from "./infra/licenties-resources";
import { SamenstellingType } from "./infra/licenties-schema";
import { FormikContextType } from "formik";

export type AantalLicentiesAanpassenStap1Props = {
  amountOfRequestedLicensesIsNegative: boolean;
  setAmountOfRequestedLicensesIsNegative: React.Dispatch<React.SetStateAction<boolean>>;
  formik: FormikContextType<SamenstellingType>;
  setButtonIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Stap1 = ({
  formik,
  amountOfRequestedLicensesIsNegative,
  setAmountOfRequestedLicensesIsNegative,
  setButtonIsDisabled
}: AantalLicentiesAanpassenStap1Props): ReactElement => {
  const { values } = formik;

  return (
    <>
      <div>
        <LabeledNumberInput
          name={`aantalLicenties`}
          caption="Huidig totaal aantal licenties"
          readonly
          fontWeight="bold"
        />
        <LabeledNumberInput name={`aantalLicentiesInGebruik`} caption="Licenties in gebruik" readonly />
        <LabeledNumberInput name={`aantalLicentiesBeschikbaar`} caption="Licenties beschikbaar" readonly />
      </div>
      <br />

      <div>
        <LabeledNumberInput
          name="nieuwAantalLicenties"
          caption="Nieuw totaal aantal licenties"
          allowNull={false}
          onKeyUp={event => {
            try {
              const value = parseInt(event.currentTarget.value);

              if (value !== values.aantalLicenties) {
                setButtonIsDisabled(false);
              }

              if (
                value === values.aantalLicenties ||
                Number.isNaN(value) ||
                value < 0 ||
                value < values.aantalLicentiesInGebruik
              ) {
                setButtonIsDisabled(true);
              }

              if (value - values.aantalLicenties < 0 && !Number.isNaN(value - values.aantalLicenties)) {
                setAmountOfRequestedLicensesIsNegative(true);
                formik.setFieldValue("aangevraagdAantalLicenties", Math.abs(value - values.aantalLicenties) || 0);
                formik.setFieldValue("aangevraagdeLicentiesIsNegatief", true);

                return;
              }

              formik.setFieldValue("aangevraagdAantalLicenties", value - values.aantalLicenties || 0);

              setAmountOfRequestedLicensesIsNegative(false);
              formik.setFieldValue("aangevraagdeLicentiesIsNegatief", false);
            } catch (error) {
              formik.setFieldValue("aangevraagdAantalLicenties", 0);
            }
          }}
          tooltip={getLicentiesTextResources("aantalLicentiesAanpassen")}
        />
        {!amountOfRequestedLicensesIsNegative && (
          <LabeledNumberInput name="aangevraagdAantalLicenties" caption="Licenties aan te vragen" readonly />
        )}
        {amountOfRequestedLicensesIsNegative && (
          <LabeledNumberInput name="aangevraagdAantalLicenties" caption="Licenties op te zeggen" readonly />
        )}
      </div>
    </>
  );
};
