import { LabeledTextInput, FormFirstFocus, AdviesBoxFormik, LabeledBevestigingInput, CheckBox } from "adviesbox-shared";
import React, { ReactElement } from "react";
import Modal from "../../shared/components/modal/Modal";
import {
  licentieOnderdelenProductSchema,
  SamenstellingModalState,
  LicentieSamenstellingProductType
} from "../infra/licentieaanbod-schema";
import { mapLicentieSamenstellingModalToScreen } from "./map-licentiesamenstelling-modal";

type LicentieSamenStellingModalProps = {
  data: SamenstellingModalState;
  closeModal?: any;
  onSubmit: (data: LicentieSamenstellingProductType) => void;
  edit: boolean;
  readOnly: boolean;
};
export const LicentieSamenStellingModal = ({
  data,
  closeModal,
  onSubmit,
  edit,
  readOnly
}: LicentieSamenStellingModalProps): ReactElement => {
  return (
    <AdviesBoxFormik<SamenstellingModalState>
      initialValues={data}
      validationSchema={licentieOnderdelenProductSchema}
      closeModal={closeModal}
      render={(formik): ReactElement => (
        <Modal
          onCancelClick={closeModal}
          submitDisabled={readOnly}
          onSubmitClick={() => {
            if (formik.values.code === "" || formik.values.productNaam === "") {
              return;
            }
            onSubmit(mapLicentieSamenstellingModalToScreen(formik.values));
            closeModal();
          }}
          title={`Licentiesamenstelling ${edit ? "bewerken" : "toevoegen"}`}
          body={
            <FormFirstFocus selector={`input[name="productNaam"]`}>
              <div className="w-100 px-3">
                <LabeledTextInput verplicht={true} caption="Naam samenstelling" name="productNaam" readOnly={readOnly} />
                <LabeledTextInput verplicht={true} caption="Code" name="code" readOnly={readOnly} />
                <LabeledBevestigingInput caption="Actief" name="actief" readonly={readOnly} />
                <div className={`d-flex px-0`}>
                  <div className={`col-6 px-1`}>Gekoppelde onderdelen</div>
                  <div className={`col-6 px-1`}>
                    {data.onderdelenObjectArray.map(function (element, i) {
                      return (
                        <CheckBox
                          key={`${element.id}-${i}`}
                          caption={`${element.name}`}
                          name={`onderdelenObjectArray[${i}].enabled`}
                          disabled={readOnly}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </FormFirstFocus>
          }
        />
      )}
    />
  );
};
