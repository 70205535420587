/* istanbul ignore file */
import { hasValue } from "adviesbox-shared";
import * as Yup from "yup";
import { getLicentieaanbodTextResources } from "../../licentieaanbod/infra/licentieaanbod-resources";
import { getGebruikersgroepenTextResources } from "./gebruikersgroepen-resources";

export type ShowHasChangesModal = {
  show: boolean;
  selected: number | null;
};

export type ShowDeleteModal = {
  show: boolean;
  selected: number | null;
};

export const productSchema = Yup.object({
  id: Yup.string()
    .nullable()
    .default(null),
  productNaam: Yup.string()
    .required(getLicentieaanbodTextResources("productNaamLeeg"))
    .default(""),
  code: Yup.string()
    .required(getLicentieaanbodTextResources("productCodeLeeg"))
    .default(""),
  platformId: Yup.string().default(""),
  isOnlineBestelbaar: Yup.boolean().default(false),
  actief: Yup.boolean().default(true),
  isDeleted: Yup.boolean().default(false),
  putChanges: Yup.boolean().default(false),
  triggeredByModal: Yup.boolean().default(false)
});
export type ProductType = Yup.InferType<typeof productSchema>;

export const gebruikersgroepOnderdeelProductSchema = Yup.object({
  id: Yup.string()
    .nullable()
    .default(null),
  productNaam: Yup.string()
    .default("")
    .required(getLicentieaanbodTextResources("productNaamLeeg")),
  isDeleted: Yup.boolean().default(false),
  code: Yup.string()
    .default("")
    .required(getLicentieaanbodTextResources("productCodeLeeg")),
  isInstelbaarDoorKlant: Yup.boolean().default(false),
  aantalGekoppeldeGebruikersgroepen: Yup.number().default(0),
  actief: Yup.boolean().default(true)
});

export type gebruikersgroepOnderdelenProductType = Yup.InferType<typeof gebruikersgroepOnderdeelProductSchema>;

export const toegangTotSchema = Yup.object({
  toegangTotArray: Yup.array(Yup.string()).default([])
});

export type ToegangTotType = Yup.InferType<typeof toegangTotSchema>;

export interface ProductOnderdeel {
  [key: string]: {
    id: string;
  };
}

export interface GekoppeldProductOnderdeel {
  [key: string]: {
    id: string;
    gekoppeld: boolean;
  };
}

export const gebruikersgroepSchema = Yup.object({
  id: Yup.string()
    .nullable()
    .default(null),
  groepsNaam: Yup.string()
    .default("")
    .test({
      message: getGebruikersgroepenTextResources("groepsNaam"),
      test: function(value: string) {
        return hasValue(value) && value !== "";
      }
    }),
  organisatieId: Yup.string().default(""),
  productOnderdelen: Yup.object<GekoppeldProductOnderdeel>().default({}),
  productonderdelenArray: Yup.array(Yup.string()).default([]),
  loading: Yup.boolean().default(false),
  saveIsClicked: Yup.boolean().default(false),
  postChanges: Yup.boolean().default(false),
  beschrijving: Yup.string()
    .nullable()
    .default(null),
  triggeredByModal: Yup.boolean().default(false),
  formIsReset: Yup.bool().default(false),
  isDeleted: Yup.boolean().default(false),
  newGebruikersgroepAfterDelete: Yup.string()
    .nullable()
    .default(null),
  shouldSave: Yup.object({
    //flags voor async side-effects
    gebruikersGroepenChanged: Yup.bool().default(false),
    toegangTotChanged: Yup.bool().default(false)
  }),
  toegangTot: toegangTotSchema
});

export type GebruikersgroepType = Yup.InferType<typeof gebruikersgroepSchema>;

export const gebruikersgroepProductSchema = Yup.object({
  productenInView: Yup.number().default(10),
  producten: Yup.array(productSchema)
});

export type gebruikersgroepProductState = Yup.InferType<typeof gebruikersgroepProductSchema>;
export const productOnderdelenSchema = Yup.object({
  productOnderdelen: Yup.object<ProductOnderdeel>().default({}),
  productenInView: Yup.number().default(10),
  gebruikersgroepOnderdeel: Yup.array(gebruikersgroepOnderdeelProductSchema).default([]),
  loading: Yup.bool().default(false)
});

export type ProductOnderdelenState = Yup.InferType<typeof productOnderdelenSchema>;

export const gebruikersGroepDetailsSchema = Yup.object({
  gebruikersgroep: Yup.array(gebruikersgroepSchema)
    .default([])
    .test("uniekNaam", "", function(list) {
      const errors: Yup.ValidationError[] = [];
      list?.forEach((gg: GebruikersgroepType, index: number) => {
        const propertyValue = gg.groepsNaam;
        if (propertyValue && list.filter((v: GebruikersgroepType) => v.groepsNaam === propertyValue).length > 1) {
          errors.push(
            this.createError({
              path: `${this.path}[${index}].groepsNaam`,
              message: getGebruikersgroepenTextResources("uniekNaam")
            })
          );
        }
      });

      if (errors.length) {
        return errors[errors.length - 1]; // Error is only displayed in the last/new record in the datagrid
      }

      return true;
    }),
  productenInView: Yup.number().default(10)
});
export type gebruikersGroepDetailsType = Yup.InferType<typeof gebruikersGroepDetailsSchema>;

export const gebruikersgroepenSchema = Yup.object({
  gebruikersgroepenInView: Yup.number().default(10),
  producten: Yup.array(productSchema),
  gebruikersgroepOnderdelen: productOnderdelenSchema,
  gebruikersgroepen: gebruikersGroepDetailsSchema,
  organisatieId: Yup.string()
    .nullable()
    .default(null),
  loading: Yup.bool().default(false),
  shouldResetForm: Yup.boolean().default(false),
  hasChanged: Yup.bool().default(false)
});
export type GebruikersgroepenState = Yup.InferType<typeof gebruikersgroepenSchema>;
export type GebruikersgroepenProps = Yup.InferType<typeof gebruikersgroepenSchema>;
