import { FormikContextType, FormikErrors } from "formik";
import { MedewerkerState, MedewerkersState } from "./infra/medewerkers-schema";
import { SettingsType, hasValue } from "adviesbox-shared";

import { User } from "oidc-client";
import { emailInGebruikApi } from "./infra/medewerkers-api";

export const emailInGebruik = async (
  settings: SettingsType,
  user: User | null,
  emailToCheck: string
): Promise<boolean> => {
  const email = await emailInGebruikApi(settings, user, emailToCheck);
  if (email.inGebruik) {
    return true;
  }
  return false;
};

export const volledigeNaam = (
  voornaam: string | null,
  voorletters: string | null,
  tussenvoegsel: string | null,
  achternaam: string | null,
  fallbackNaam?: string
): string => {
  const voorAchternaam = `${voornaam} ${achternaam}`;
  const voorLettersAchternaam = `${voorletters} ${achternaam}`;
  const tussenAchternaam = `${tussenvoegsel} ${achternaam}`;
  const voorTussenAchternaam = `${voornaam} ${tussenvoegsel} ${achternaam}`;
  const voorLettersTussenAchternaam = `${voorletters} ${tussenvoegsel} ${achternaam}`;
  const defaultNaam = `${fallbackNaam}`;

  if (achternaam && voornaam && tussenvoegsel) {
    return voorTussenAchternaam;
  }

  if (achternaam && voornaam && !tussenvoegsel) {
    return voorAchternaam;
  }

  if (achternaam && voorletters && tussenvoegsel && !voornaam) {
    return voorLettersTussenAchternaam;
  }

  if (achternaam && tussenvoegsel && !voornaam && !voorletters) {
    return tussenAchternaam;
  }

  if (achternaam && voorletters && !voornaam && !tussenvoegsel) {
    return voorLettersAchternaam;
  }

  if (achternaam && !voornaam && !voorletters && !tussenvoegsel) {
    return achternaam;
  }

  if (voornaam && !achternaam && !voorletters && !tussenvoegsel) {
    return voornaam;
  }

  return defaultNaam;
};

export const validateFormikValues = async (
  formik: FormikContextType<MedewerkersState>,
  selected: number
): Promise<FormikErrors<MedewerkerState> | null> => {
  return await formik.validateForm().then(unfulfilled => {
    if (unfulfilled && unfulfilled.medewerkers && typeof unfulfilled !== "string") {
      const errors =
        hasValue(unfulfilled.medewerkers) && (unfulfilled.medewerkers[selected] as FormikErrors<MedewerkerState>);
      if (errors) {
        formik.setTouched({
          medewerkers: {
            [selected]: {
              contactgegevens: errors?.contactgegevens,
              persoonsgegevens: errors?.persoonsgegevens,
              licentiegegevens: {
                gekozenVestigingen: errors?.licentiegegevens?.gekozenVestigingen,
                gekozenGebruikersgroepen: errors?.licentiegegevens?.gekozenGebruikersgroepen
              }
            }
          } as any
        });
        return errors;
      }
    }
    return null;
  });
};
