import * as Yup from "yup";
import { getLicentiesTextResources } from "./licenties-resources";

export const licentieKaartSchema = Yup.object({
  platformId: Yup.string()
    .nullable()
    .default(null),
  medewerkerId: Yup.string()
    .nullable()
    .default(null),
  productsamenstellingId: Yup.string(),
  licentieovereenkomstId: Yup.string(),
  productsamenstellingNaam: Yup.string()
    .nullable()
    .default(null)
});

export type LicentieKaartType = Yup.InferType<typeof licentieKaartSchema>;

export const samenstellingSchema = Yup.object({
  naam: Yup.string().default(""),
  id: Yup.string().default(""),
  selectedVoorVerwijderen: Yup.boolean().default(false),
  aantalLicenties: Yup.number().default(0),
  aantalLicentiesInGebruik: Yup.number().default(0),
  aantalLicentiesBeschikbaar: Yup.number().default(0),
  nieuwAantalLicenties: Yup.number()
    .nullable()
    .default(null)
    .test({
      message: getLicentiesTextResources("aantalLicentiesMinderDanGebruikt"),
      test: function(value: number): boolean {
        if (
          (this.parent.aangevraagdeLicentiesIsNegatief && value < 0) ||
          Number.isNaN(value) ||
          value < this.parent.aantalLicentiesInGebruik
        ) {
          return false;
        }
        return true;
      }
    }),
  aangevraagdAantalLicenties: Yup.number()
    .nullable()
    .default(null),
  akkoordFacturatie: Yup.boolean().default(false),
  submitButtonIsClicked: Yup.boolean().default(false),
  aangevraagdeLicentiesIsNegatief: Yup.boolean().default(false),
  waarschuwingsBericht: Yup.string().default("")
});

export type SamenstellingType = Yup.InferType<typeof samenstellingSchema>;

export const licentiesSchema = Yup.object({
  licentieOvereenKomstId: Yup.string().default(""),
  licenties: Yup.array(licentieKaartSchema).default([]),
  samenstelling: Yup.array(samenstellingSchema).default([])
});

export type LicentiesState = Yup.InferType<typeof licentiesSchema>;
export type LicentiesProps = Yup.InferType<typeof licentiesSchema>;

export const productSchema = Yup.object({
  id: Yup.string()
    .nullable()
    .default(null),
  productNaam: Yup.string()
    .required(getLicentiesTextResources("productNaamLeeg"))
    .default(""),
  code: Yup.string()
    .required(getLicentiesTextResources("productCodeLeeg"))
    .default(""),
  platformId: Yup.string().default(""),
  isOnlineBestelbaar: Yup.boolean().default(false),
  actief: Yup.boolean().default(true)
});

export const productenSchema = Yup.object({
  producten: Yup.array(productSchema)
});

export type ProductenType = Yup.InferType<typeof productenSchema>;

export const productSamenstellingenSchema = Yup.object({
  licentieSamenstellingen: Yup.array(samenstellingSchema).default([])
});

export type ProductSamenstellingenState = Yup.InferType<typeof productSamenstellingenSchema>;

export const licentieOvereenKomstSchema = Yup.object({
  licentieId: Yup.string().default("")
});
export type LicentieOvereenkomstState = Yup.InferType<typeof licentieOvereenKomstSchema>;
