/* istanbul ignore file */

// import { LicentiebeheerLogboek as LogboekDlEntry } from "../../.generated/forms/formstypes";
import { LogboekState } from "./logboek-schema";
import { UiName, FieldMap } from "../../shared/types";
import { target2field } from "../../shared/utils/target-to-field";

export function mapDlTargetToLogboekUiField(target: string): UiName | null {
  const map: FieldMap<LogboekState> = {};

  return target2field(map, target);
}
