/* istanbul ignore file */

import {
    ErrorBoundary,
    ErrorPage,
    FeatureComponent,
    getCookie,
    getNaamAdviseur,
    getOTAP,
    hasNoRoleIsw,
    Notificaties2Provider,
    NotificatiesProvider,
    PageLoading,
    SettingsType,
    useRequestInit,
    useUpscope
  } from "adviesbox-shared";
  import React, { ReactElement, useContext, useEffect, useMemo } from "react";
  import { Redirect, Route, Switch } from "react-router-dom";
  import useAbortableFetch from "use-abortable-fetch";
  import { LicentiesApi } from "../licenties/infra/licenties-api";
  import { GebruikersgroepenOutput, RechtenOutPut } from "../.generated/licenties/licentiestypes";
  import AutorisatieNiveauContext from "../shared/autorisatie-niveau-context/autorisatie-niveau-context";
  import UserDetailsContext from "../shared/user-details/user-details-context";
  import { hasValue } from "../shared/utils/helpers";
  import { ZoekschermAjax } from "../zoekscherm/zoekscherm-ajax";
  import AuthenticatedLicentieBeheerAppWithDrawer from "./AuthenticatedLicentieBeheerAppWithDrawer";
  
  const createGebruikersGroepenUrl = (s: SettingsType, medewerkerId: string | null): string | null =>
    medewerkerId ? LicentiesApi.getMedewerkerGebruikersgroepenUrl(s, medewerkerId) : null;
  
  const AuthenticatedLicentieBeheerApp = (): ReactElement => {
    const { setUserDetails, userDetails } = useContext(UserDetailsContext);
    const { isBeheerder, setIsBeheerder } = useContext(AutorisatieNiveauContext);
    const { user, settings, requestInit } = useRequestInit();
    const medewerkerUrl = user && user.profile ? LicentiesApi.getMedewerkersCurrentUrl(settings) : null;
    const medewerker = useAbortableFetch(medewerkerUrl, requestInit) as any;
    const usersOrganisatieId = medewerker?.data?.organisatieId;
  
    const vestigingIdCookie = getCookie(`${getOTAP(window.location.hostname)}_vestigingid`);
    const vestigingId = typeof vestigingIdCookie === "boolean" ? "" : vestigingIdCookie;
  
    const iswUser = useMemo(() => !hasNoRoleIsw(user), [user]);
    const { requestInit: requestInitGG, settings: settingsGG } = useRequestInit();
    const gebruikersgroepenUrl = useMemo(() => createGebruikersGroepenUrl(settingsGG, medewerker?.data?.medewerkerId), [
      settingsGG,
      medewerker
    ]);
  
    const { data: gebruikersData, error: gebruikersError, loading: gebruikersLoading } = useAbortableFetch<
      GebruikersgroepenOutput
    >(gebruikersgroepenUrl, requestInitGG);
  
    const rechtenUrl =
      medewerker.data && typeof medewerker.data !== "string"
        ? LicentiesApi.getMedewerkersRechtenUrl(settings, medewerker.data.medewerkerId)
        : null;
    const { data: authorization, error: authorizationError, loading: authorizationLoading } = useAbortableFetch<
      RechtenOutPut
    >(rechtenUrl, requestInit);
  
    useUpscope(user?.profile.name, [getNaamAdviseur(userDetails)]);
  
    useEffect(() => {
      if (
        !medewerker ||
        !gebruikersData ||
        !medewerker.data ||
        typeof medewerker.data === "string" ||
        typeof gebruikersData === "string" ||
        !medewerker.data.isValid ||
        !medewerker.data.medewerker ||
        !medewerker.data.medewerkerId ||
        !medewerker.data.organisatieId ||
        medewerker.data.medewerkerId === userDetails.medewerkerId ||
        !gebruikersData.gebruikersgroepen ||
        !authorization
      )
        return;
  
      const newUserDetails = {
        ...medewerker.data.medewerker,
        ...userDetails.requestedOrganisatie,
        medewerkerId: medewerker.data.medewerkerId,
        organisatieId: medewerker.data.organisatieId
      };
  
      setUserDetails(newUserDetails);
  
      const gebruikerIsBeheerder = Object.keys(gebruikersData.gebruikersgroepen).some(
        Ggid => gebruikersData.gebruikersgroepen && gebruikersData.gebruikersgroepen[Ggid]?.naam === "Beheerder"
      );
  
      setIsBeheerder(gebruikerIsBeheerder);
    }, [
      setUserDetails,
      medewerker,
      userDetails.medewerkerId,
      gebruikersData,
      setIsBeheerder,
      authorization,
      userDetails.requestedOrganisatie
    ]);
  
    if (!user || !user.profile) {
      return <ErrorPage error={Error("Geen rechten toegekend voor deze gebruiker")} data={null} />;
    }
  
    if (medewerker.error) {
      return <ErrorPage error={medewerker.error} data={medewerker.data} />;
    }
  
    if (!medewerker.data || medewerker.loading || gebruikersLoading || authorizationLoading) {
      return <PageLoading />;
    }
  
    if (typeof medewerker.data === "string" || !medewerker.data.isValid) {
      return <ErrorPage error={Error("Communicatie fout, neem contact op met klantenservice")} data={null} />;
    }
  
    if (authorizationError) {
      return <ErrorPage error={authorizationError} data={authorization} />;
    }
  
    if (gebruikersError) {
      return <ErrorPage error={gebruikersError} data={gebruikersError} />;
    }
  
    if (!authorization || !hasValue(isBeheerder) || !hasValue(usersOrganisatieId)) {
      return <PageLoading />;
    }
  
    if (typeof authorization === "string" || !authorization.isValid) {
      return <ErrorPage error={Error("Communicatie fout, neem contact op met klantenservice")} data={null} />;
    }
  
    if (!iswUser && authorization.rechten && !authorization.rechten.some((recht: any) => recht === "LIC")) {
      return <ErrorPage error={{ ...Error("Geen rechten toegekend voor deze gebruiker"), status: 403 }} data={null} />;
    }
  
    if (!vestigingId) {
      return <ErrorPage error={Error("Geen vestigingId aanwezig")} vestiging={true} />;
    }
  
    return (
      <div data-testid="authenticated-app" className="adviesbox-advies">
        <FeatureComponent feature="FeatureNotificaties2">
          <Notificaties2Provider vestigingId={vestigingId} sourceApp={"LIC"}>
            <Switch>
              <Route
                path="/zoeken"
                render={() => (
                  <ErrorBoundary>
                    <ZoekschermAjax usersOrganisatieId={usersOrganisatieId} />
                  </ErrorBoundary>
                )}
              />
              <Route path="/organisatie/:organisatie" component={AuthenticatedLicentieBeheerAppWithDrawer} />
    
              <Route
                path={"/"}
                render={() => {
                  if (iswUser) return <Redirect to={`/zoeken`} />;
                  return <Redirect to={`/organisatie/${usersOrganisatieId}/contract`} />;
                }}
              />
            </Switch>
          </Notificaties2Provider>
        </FeatureComponent>
        <FeatureComponent feature="FeatureNotificaties2" isDisabled>
          <NotificatiesProvider reloadTimeInSeconds={60} medewerkerId={userDetails.medewerkerId} vestigingId={vestigingId} sourceApp={"LIC"}>
            <Switch>
              <Route
                path="/zoeken"
                render={() => (
                  <ErrorBoundary>
                    <ZoekschermAjax usersOrganisatieId={usersOrganisatieId} />
                  </ErrorBoundary>
                )}
              />
              <Route path="/organisatie/:organisatie" component={AuthenticatedLicentieBeheerAppWithDrawer} />
    
              <Route
                path={"/"}
                render={() => {
                  if (iswUser) return <Redirect to={`/zoeken`} />;
                  return <Redirect to={`/organisatie/${usersOrganisatieId}/contract`} />;
                }}
              />
            </Switch>
          </NotificatiesProvider>
        </FeatureComponent>
      </div>
    );
  };
  
  AuthenticatedLicentieBeheerApp.displayName = "AuthenticatedLicentieBeheerApp";
  export default AuthenticatedLicentieBeheerApp;
  