/* istanbul ignore file */

import { ErrorPage, PageLoading, useRequestInit, withErrorBoundary, ForceRerenderContext } from "adviesbox-shared";
import React, { ReactElement, useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import useAbortableFetch from "use-abortable-fetch";
import { LicentiesApi } from "../licenties/infra/licenties-api";
import { LicentieovereenkomstenOutput } from "../.generated/licenties/licentiestypes";
import { MagActieUitvoeren, Matrixonderdeel } from "../shared/autorisatie-matrix/autorisatie-matrix";
import { Title } from "../shared/components/title/title";
import { RouteParams } from "../shared/paramrouting/paramrouting-context";
import { UserProps } from "../shared/properties/user-props";
import { LicentiesState } from "./infra/licenties-schema";
import { mapLicentieOvereenkomstDlToUi } from "./infra/map-licenties-dl-to-ui";
import { Licenties } from "./licenties";

type Props = {
  licentiesData: LicentiesState;
};

const LicentiesContractComponent = (props: Props & UserProps): ReactElement => {
  const params = useParams() as RouteParams;

  const heeftToegang = MagActieUitvoeren(Matrixonderdeel.Licenties, null, props.user, props.userDetails);

  const loadingDone = useContext(ForceRerenderContext);
  const { requestInit: requestInitLOK, settings: settingsLOK } = useRequestInit();

  const urlLOK = useMemo(() => (heeftToegang ? LicentiesApi.getLicentieovereenkomstenUrl(settingsLOK, params) : null), [
    heeftToegang,
    settingsLOK,
    params
  ]);

  const { loading: loadingLOK, error: platformErrorLOK, data: platformDataLOK } = useAbortableFetch<
    LicentieovereenkomstenOutput
  >(urlLOK, requestInitLOK);

  const dataLOK = useMemo(
    () =>
      platformDataLOK && typeof platformDataLOK !== "string" ? mapLicentieOvereenkomstDlToUi(platformDataLOK) : null,
    [platformDataLOK]
  );

  useEffect((): void => {
    if (loadingLOK && platformDataLOK && dataLOK && loadingDone) {
      loadingDone();
    }
  }, [loadingLOK, platformDataLOK, loadingDone, dataLOK]);

  if (!heeftToegang) return <ErrorPage error={new Error("Geen toegang")} />;

  if (platformErrorLOK) {
    return <ErrorPage error={platformErrorLOK} data={platformDataLOK} />;
  }

  if (!dataLOK || loadingLOK) {
    return <PageLoading />;
  }

  const filteredData = {
    ...props.licentiesData,
    licentieOvereenKomstId: dataLOK.licentieId
  };

  return (
    <>
      <Title appName="Contract" altTitle={"Licenties"} />
      <Licenties saveData={async () => null} {...filteredData} user={props.user} userDetails={props.userDetails} />
    </>
  );
};

export const LicentiesContractAjax = withErrorBoundary(LicentiesContractComponent);
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production")
  LicentiesContractAjax.displayName = "LicentiesContractAjax";
