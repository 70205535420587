import { AdviesBoxFormik, LabeledSelectInput } from "adviesbox-shared";
import { FormikProps } from "formik";
import React, { ReactElement } from "react";
import { Modal as ModalBootstrap } from "react-bootstrap";
import { LabelValuePairs } from "../../shared/types";
import classes from "../gebruikersgroepen.module.scss";
import { gebruikersgroepenSchema, GebruikersgroepenState } from "./gebruikersgroepen-schema";

export const GebruikersgroepDeleteModal = ({
  closeModal,
  formikValues,
  index,
  onDelete,
  gebruikersgroepOptions,
  deleteModal
}: {
  formikValues: GebruikersgroepenState;
  index: number;
  onDelete: (data: GebruikersgroepenState) => void;
  gebruikersgroepOptions: LabelValuePairs;
  closeModal?: () => void;
  deleteModal: boolean;
}): ReactElement => {
  return (
    <AdviesBoxFormik<GebruikersgroepenState>
      initialValues={{ ...formikValues }}
      validationSchema={gebruikersgroepenSchema}
      render={({ values }: FormikProps<GebruikersgroepenState>): ReactElement => (
        <>
          <ModalBootstrap.Header>
            <div className="d-flex w-100">
              <ModalBootstrap.Title>
                {deleteModal && `${values.gebruikersgroepen.gebruikersgroep[index].groepsNaam} verwijderen`}
                {!deleteModal && `Kan medewerker niet ontkoppelen van deze gebruikersgroep`}
              </ModalBootstrap.Title>
              <button
                type="button"
                className={"close ml-auto"}
                onClick={closeModal}
                id={`btnClose-gebruikersgroep-verwijderen`}
              >
                <span aria-hidden="true">×</span>
                <span className="sr-only">Close</span>
              </button>
            </div>
          </ModalBootstrap.Header>
          <ModalBootstrap.Body>
            {deleteModal && (
              <div>
                <p className="px-2">
                  Weet je zeker dat je {values.gebruikersgroepen.gebruikersgroep[index].groepsNaam} wilt verwijderen?
                </p>
                {!!gebruikersgroepOptions.length && (
                  <>
                    <p className="px-2">
                      Om door te gaan met het verwijderen van de gebruikersgroep moet je aangeven aan welke
                      gebruikersgroep de gebruikers van de verwijderde groep gekoppeld moeten worden.
                    </p>

                    <LabeledSelectInput
                      name={`gebruikersgroepen.gebruikersgroep[${index}].newGebruikersgroepAfterDelete`}
                      caption="Gebruikers koppelen aan gebruikersgroep"
                      options={gebruikersgroepOptions}
                      readonly={gebruikersgroepOptions.length === 1}
                    />
                  </>
                )}
              </div>
            )}
            {!deleteModal && (
              <div>
                <p className="px-2">
                  Deze medewerker kan niet worden ontkoppeld van de gebruikersgroep Beheerder. Er dient altijd minimaal
                  één medewerker aan deze gebruikersgroep te zijn gekoppeld.
                </p>
              </div>
            )}
          </ModalBootstrap.Body>
          <ModalBootstrap.Footer className={classes.modal_footer}>
            {deleteModal && (
              <>
                <button
                  type="button"
                  className="btn btn-link"
                  id="annuleer-verwijderen-gebruikersgroep"
                  onClick={closeModal}
                >
                  Niet verwijderen
                </button>

                <button
                  type="button"
                  data-testid={"gebruikersgroep-verwijderen-button"}
                  className="btn btn-primary"
                  id="bevestig-verwijderen-gebruikersgroep"
                  onClick={() => onDelete(values)}
                >
                  {values.gebruikersgroepen.gebruikersgroep[index].groepsNaam} verwijderen
                </button>
              </>
            )}
            {!deleteModal && (
              <button
                id="btnsluiten"
                data-testid="btnsluiten"
                type="button"
                className="btn btn-primary"
                onClick={closeModal}
              >
                Sluiten
              </button>
            )}
          </ModalBootstrap.Footer>
        </>
      )}
    />
  );
};

/* istanbul ignore else */ if (process.env.NODE_ENV !== "production")
  GebruikersgroepDeleteModal.displayName = "DeleteGebruikersgroepModal";
