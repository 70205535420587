import { SettingsType, hasJSONResponse } from "adviesbox-shared";

import { User } from "oidc-client";
import { Zoekresultaat } from "../../.generated/licenties/licentiestypes";

export const searchOrganisaties = async (
  query: string,
  category: string,
  settings: SettingsType,
  user: User,
  signal: AbortSignal
): Promise<Zoekresultaat[] | null | { code: number }> => {
  const searchCategories: Record<string, string> = {
    Naam: "Organisatienaam",
    Postcode: "Postcode",
    Woonplaats: "Plaats",
    "KVK-nummer": "KvkNummer"
  };

  const url = `${settings.licentiesOrigin}/Organisaties/Zoeken/${searchCategories[category]}?q=${query}`;
  try {
    const rsp = await fetch(url, {
      method: "GET",
      headers: {
        authorization: `${user.token_type} ${user.access_token}`,
        "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
        "Content-Type": "application/json;charset=UTF-8"
      },
      signal
    });

    if (!rsp.ok) {
      return { code: rsp.status };
    }

    if (!hasJSONResponse(rsp)) throw Error(`Fout bij aanroep van web-api '${url}'. Resultaat bevat geen JSON.`);

    const json = await rsp.json();

    if (!json.isValid) throw Error(`Fout bij aanroep van web-api '${url}'. Input is niet valid.`);

    return json.zoekresultaten;
  } catch (err) {
    if (err.name !== "AbortError") throw err;
  }
  return [];
};
