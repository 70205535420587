import { FormikContextType } from "formik";
import { original } from "immer";
import { initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { LicentiesAanbodState } from "./licentieaanbod-schema";

type Context = {
  selected: number;
  formik: FormikContextType<LicentiesAanbodState>;
};
let prevSelected = 0;

export const syncLicentieaanbodSideEffects = initISWSideEffect<LicentiesAanbodState, Context>(
  ({ has, draft, context }) => {
    //if an unfilled record is deleted
    if (
      has.producten[context.selected]?.isDeleted.changed &&
      draft.producten[context.selected]?.isDeleted &&
      !draft.producten[context.selected]?.id
    ) {
      draft.producten.splice(context.selected, 1);
    }

    if (
      context.selected !== prevSelected &&
      draft.producten[context.selected]?.id !== null &&
      draft.producten[context.selected]?.id !== undefined
    ) {
      prevSelected = context.selected;
      draft.licentieOnderdelen.loading = true;
      draft.licentieSamenstellingen.loading = true;
    }

    if (
      has.producten[context.selected].changed &&
      draft.producten[context.selected] !== undefined &&
      !draft.shouldResetForm
    ) {
      if (draft.producten[context.selected]?.savedChanges) {
        draft.producten[context.selected].savedChanges = false;

        if (!draft.cancelHasChanges) {
          return;
        }
      }

      draft.producten[context.selected].hasChanged = true;
      draft.cancelHasChanges = false;
    }

    /* istanbul ignore next */
    if (has.shouldResetForm.changed && draft.shouldResetForm) {
      const values = original(draft);
      if (values) {
        context.formik.resetForm({ values });
        draft.shouldResetForm = false;
        if (draft.producten[context.selected] !== undefined) {
          draft.producten[context.selected].hasChanged = false;
        }
        draft.cancelHasChanges = true;
      }
    }
  }
);
