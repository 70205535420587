import React, { ReactElement } from "react";
import { LabeledBevestigingInput, LabeledTextInput } from "adviesbox-shared";
import { connect } from "formik";
import { ProductType } from "../infra/licentieaanbod-schema";
import { UserProps } from "../../shared/properties/user-props";
import {
  Gebruikersactie,
  MagActieUitvoeren,
  Matrixonderdeel
} from "../../shared/autorisatie-matrix/autorisatie-matrix";

type ProductProps = { selected: number };

const Product = (props: ProductProps & UserProps): ReactElement => {
  const magSchrijven = MagActieUitvoeren(
    Matrixonderdeel.Licentieaanbod,
    Gebruikersactie.Schrijven,
    props.user,
    props.userDetails
  );
  const magToevoegen = MagActieUitvoeren(
    Matrixonderdeel.Licentieaanbod,
    Gebruikersactie.Toevoegen,
    props.user,
    props.userDetails
  );

  const isReadOnly = !(magSchrijven || magToevoegen);
  return (
    <>
      <LabeledTextInput
        verplicht={true}
        name={`producten[${props.selected}].productNaam`}
        caption="Productnaam"
        readOnly={isReadOnly}
      />
      <LabeledTextInput
        verplicht={true}
        name={`producten[${props.selected}].code`}
        caption="Code"
        readOnly={isReadOnly}
      />
      <LabeledBevestigingInput name={`producten[${props.selected}].actief`} caption="Actief" readonly={isReadOnly} />
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") Product.displayName = "Product";

export default connect<ProductProps & UserProps, ProductType>(Product);
