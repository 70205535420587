import { LabeledNumberInput, ModalButton } from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement } from "react";
import { AantalLicentiesAanpassenModal } from "../aantal-licenties-aanpassen-modal";
import { licentiesSchema, LicentiesState } from "../infra/licenties-schema";
import { UserProps } from "../../shared/properties/user-props";
import {
  Gebruikersactie,
  MagActieUitvoeren,
  Matrixonderdeel
} from "../../shared/autorisatie-matrix/autorisatie-matrix";
/* istanbul ignore file */
type licentieCardProps = {
  data: LicentiesState;
  selected: number;
};

export const LicentieCard = (props: licentieCardProps & UserProps): ReactElement => {
  const { setFieldValue } = useFormikContext<LicentiesState>();

  const magSchrijven = MagActieUitvoeren(
    Matrixonderdeel.LicentiesLicentie,
    Gebruikersactie.Schrijven,
    props.user,
    props.userDetails
  );
  const magToevoegen = MagActieUitvoeren(
    Matrixonderdeel.LicentiesLicentie,
    Gebruikersactie.Toevoegen,
    props.user,
    props.userDetails
  );
  const magVerwijderen = MagActieUitvoeren(
    Matrixonderdeel.LicentiesLicentie,
    Gebruikersactie.Verwijderen,
    props.user,
    props.userDetails
  );
  const magLezen =
    magSchrijven ||
    magToevoegen ||
    magVerwijderen ||
    MagActieUitvoeren(Matrixonderdeel.LicentiesLicentie, Gebruikersactie.Lezen, props.user, props.userDetails);
  const magAantalAanpassen = MagActieUitvoeren(
    Matrixonderdeel.LicentiesAantalAanpassen,
    Gebruikersactie.Uitvoeren,
    props.user,
    props.userDetails
  );

  if (!magLezen) return <></>;
  return (
    <>
      <LabeledNumberInput
        name={`samenstelling[${props.selected}].aantalLicenties`}
        caption="Aantal licenties"
        readonly
      />
      <LabeledNumberInput
        name={`samenstelling[${props.selected}].aantalLicentiesInGebruik`}
        caption="Licenties in gebruik"
        readonly
      />
      <LabeledNumberInput
        name={`samenstelling[${props.selected}].aantalLicentiesBeschikbaar`}
        caption="Licenties beschikbaar"
        readonly
      />

      {magAantalAanpassen && (
        <div className={"button-container"}>
          <ModalButton
            size={"lg"}
            className="btn btn-primary"
            parent="samenstelling"
            content={
              <div>
                <span id="aantalLicentiesAanpassen-modal-button">Aantal licenties aanpassen</span>
              </div>
            }
          >
            <AantalLicentiesAanpassenModal
              selected={props.selected}
              data={licentiesSchema.default()}
              onSave={
                /* istanbul ignore next */ data => {
                  setFieldValue(`samenstelling[${props.selected}]`, data);
                }
              }
            />
          </ModalButton>
        </div>
      )}
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") LicentieCard.displayName = "LicentieCard";
