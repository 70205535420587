import { LabeledTextInput, LoadingSpinner } from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement } from "react";
import { Button } from "react-bootstrap";
import {
  MagActieUitvoeren,
  Matrixonderdeel,
  Gebruikersactie
} from "../../shared/autorisatie-matrix/autorisatie-matrix";
import { UserProps } from "../../shared/properties/user-props";
import { MedewerkersState } from "../infra/medewerkers-schema";
import { MedewerkersSubProps } from "../medewerkers";
import classes from "../medewerkers.module.scss";

export const Contactgegevens = (props: MedewerkersSubProps & UserProps): ReactElement => {
  const { setFieldValue, setFieldTouched } = useFormikContext<MedewerkersState>();
  const selected = props.data.actualSelectedIndex;
  const registeratie =
    props.data.medewerkers[selected]?.medewerkerId &&
    !props.data.medewerkers[selected]?.contactgegevens?.medewerkerIsGeregistreerd;

  const magSchrijven = MagActieUitvoeren(
    Matrixonderdeel.Medewerkers,
    Gebruikersactie.Schrijven,
    props.user,
    props.userDetails
  );
  const magToevoegen = MagActieUitvoeren(
    Matrixonderdeel.Medewerkers,
    Gebruikersactie.Toevoegen,
    props.user,
    props.userDetails
  );
  const magVerwijderen = MagActieUitvoeren(
    Matrixonderdeel.Medewerkers,
    Gebruikersactie.Verwijderen,
    props.user,
    props.userDetails
  );
  const magMedewerkeruitnodigen = MagActieUitvoeren(
    Matrixonderdeel.MedewerkersUitnodigen,
    Gebruikersactie.Uitvoeren,
    props.user,
    props.userDetails
  );
  const magLezen = MagActieUitvoeren(Matrixonderdeel.Medewerkers, Gebruikersactie.Lezen, props.user, props.userDetails);

  const buttonDisabled = props.data.emailSendSuccess ?? false;

  const buttonText = (() => {
    switch (props.data.emailSendSuccess) {
      case true:
        return "De mail is verzonden";
      case false:
        return "Mislukt, opnieuw proberen";
      default:
        return "Registratiemail verzenden";
    }
  })();

  const isReadOnly = (magLezen || magVerwijderen) && !(magSchrijven || magToevoegen);

  return (
    <>
      <LabeledTextInput
        name={`medewerkers[${selected}].contactgegevens.telefoon`}
        caption="Telefoonnummer"
        readOnly={isReadOnly}
      />
      <LabeledTextInput name={`medewerkers[${selected}].email`} caption="E-mailadres" readonly />

      {registeratie && magMedewerkeruitnodigen && (
        <>
          <div className={`button-container`}>
            {props.data.medewerkerRegistreren && (
              <LoadingSpinner className={classes.loading_indicator} disablePadding size="S" />
            )}
            {!props.data.medewerkerRegistreren && (
              <Button
                disabled={buttonDisabled}
                id={`medewerkers[${selected}].contactgegevensBtn.registratie`}
                data-testid={`medewerkers[${selected}].contactgegevensBtn.registratie`}
                variant="primary"
                className={`${props.data.emailSendSuccess === false && "btn-delete"}`}
                onClick={async (): Promise<void> => {
                  setFieldTouched(`medewerkers[${selected}].licentiegegevens.gekozenVestigingen`);
                  setFieldTouched(`medewerkers[${selected}].licentiegegevens.gekozenGebruikersgroepen`);

                  if (
                    props.data.medewerkerRegistreren ||
                    !props.data.medewerkers[selected].licentiegegevens.gekozenVestigingen.length ||
                    !props.data.medewerkers[selected].licentiegegevens.gekozenGebruikersgroepen.length
                  ) {
                    return;
                  }

                  setFieldValue("medewerkerRegistreren", true);
                }}
              >
                <span>{buttonText}</span>
              </Button>
            )}
          </div>
        </>
      )}
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") Contactgegevens.displayName = "Contactgegevens";
