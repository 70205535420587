/* istanbul ignore file */

import { createGetTextResources } from "../../shared/utils/text-resources-algemeen";

export const getMedewerkerTextResources = createGetTextResources({
  vestigingenIsLeeg: "Vestigingen mag niet leeg zijn zijn.",
  gebruikersgroepenIsLeeg: "Gebruikersgroepen mag niet leeg zijn zijn.",
  emailIsGebruikt: "Het e-mailadres is al in gebruik.",
  voornaam: "Vul alsjeblieft een voornaam in.",
  achternaam: "Vul alsjeblieft een achternaam in.",
  voorletters: "Vul alsjeblieft de voorletters in.",
  telefoon: "Vul alsjeblieft een telefoonnummer in.",
  email: "Vul alsjeblieft een e-mailadres in.",
  minstens1Beheerder: "Er dient minimaal één gebruiker aan 'Beheerder' gebruikersgroep te zijn gekoppeld."
});
