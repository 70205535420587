/* istanbul ignore file */
import { assertValue, hasValue } from "adviesbox-shared";
import {
  GebruikersgroepenOutput,
  ProductsamenstellingenOutput,
  ValidationResultsModel,
  VestigingenOutput
} from "../.generated/licenties/licentiestypes";
import {
  createISWAsyncSideEffect,
  initISWAsyncSideEffect
} from "../shared/components/isw-side-effects/create-isw-helpers";
import { mapMedewerkerUIToDl } from "./infra/map-medewerkers-ui-to-dl";
import { LicentieEnum, MedewerkersState, optiesMetExtraIdsSchema, OptiesType } from "./infra/medewerkers-schema";
import { validateFormikValues } from "./medewerkers-helper";

type Context = {
  selected: number;
};

export const asyncProductsamenstellingSideEffects = createISWAsyncSideEffect<MedewerkersState, Context>(
  async ({ draft, settings, fetchData }) => {
    if (draft.alleOrganisatieUniekeLicenties.length) {
      await Promise.all(
        draft.alleOrganisatieUniekeLicenties.map(async (organisatieLicentieIds, index) => {
          if (organisatieLicentieIds.id) {
            const url = `${settings.licentiesOrigin}/Productsamenstellingen/${organisatieLicentieIds.id}`;
            const rsp = await fetchData<ProductsamenstellingenOutput>({
              url,
              method: "GET"
            });

            if (rsp.isValid && rsp.productSamenstellingen) {
              const productSamenstellingenDl = rsp.productSamenstellingen;
              const productsamenstellingNaam: string = productSamenstellingenDl[organisatieLicentieIds.id]?.naam ?? "";
              const productId: string = productSamenstellingenDl[organisatieLicentieIds.id].productId ?? "";
              draft.alleOrganisatieUniekeLicenties[index] = {
                ...organisatieLicentieIds,
                naam: productsamenstellingNaam,
                productId
              };
            }
          }
        })
      );
    }
    // add extra options in the radio-button
    draft.alleOrganisatieUniekeLicenties.push({
      ...optiesMetExtraIdsSchema.default(),
      naam: LicentieEnum.Geen,
      id: LicentieEnum.Geen
    });
    draft.heeftProductsamenstellingInitialvalues = true;
    draft.onLoadingData = false;
  }
);

export const asyncMedewerkerRegistratieSideEffects = createISWAsyncSideEffect<MedewerkersState, Context>(
  async ({ draft, settings, fetchData, context }) => {
    const medewerkerId = draft.medewerkers[context.selected].medewerkerId;
    const url = `${settings.licentiesOrigin}/Medewerkers/${medewerkerId}/Uitnodiging`;
    const res = await fetchData<ValidationResultsModel>({
      url,
      method: "POST"
    });

    draft.medewerkerRegistreren = false;
    draft.emailSendSuccess = res.isValid ?? false;
  }
);

export const asyncVestigingenInitialValuesSideEffect = createISWAsyncSideEffect<MedewerkersState, Context>(
  async ({ draft, settings, fetchData }) => {
    await Promise.all(
      draft.medewerkers.map(async (v, medewerkerIndex) => {
        const vestigingenWithoutNames = v.licentiegegevens.gekozenVestigingen.filter(v => v?.naam === undefined);
        if (vestigingenWithoutNames.length) {
          const vestigingen = await Promise.all(
            vestigingenWithoutNames
              .filter(v => !!v.id && !v.naam)
              .map(
                async (v): Promise<any> => {
                  assertValue(v.id);
                  const url = `${settings.licentiesOrigin}/Vestigingen/${v.id}`;
                  const rsp = await fetchData<VestigingenOutput>({
                    url,
                    method: "GET"
                  });

                  if (rsp.isValid && rsp.vestigingen) {
                    const vestigingenDl = rsp.vestigingen;
                    const vestigingen: OptiesType = { id: v.id, naam: vestigingenDl[v.id].naam };
                    return vestigingen;
                  }
                }
              )
          );
          if (vestigingen && vestigingen.length) {
            draft.medewerkers[medewerkerIndex].licentiegegevens.gekozenVestigingen = draft.medewerkers[
              medewerkerIndex
            ].licentiegegevens.gekozenVestigingen.map(gekozenVestiging => {
              if (!gekozenVestiging.naam) {
                return vestigingen.find(resp => resp.id === gekozenVestiging.id);
              }
              return gekozenVestiging;
            });
            draft.medewerkers[medewerkerIndex].gekozenVestigingenNaamList = draft.medewerkers[
              medewerkerIndex
            ]?.licentiegegevens.gekozenVestigingen
              .filter(v => v?.naam)
              .map(v => v.naam)
              .join(" , ");
          }
        }
      })
    );

    draft.heeftVestigingInitialvalues = true;
    draft.shouldResetForm = true;
  }
);

export const asyncGebruikersgroepenInitialValuesSideEffect = createISWAsyncSideEffect<MedewerkersState, Context>(
  async ({ draft, settings, context, fetchData }) => {
    await Promise.all(
      draft.medewerkers.map(async (v, medewerkerIndex) => {
        const groepenWithoutNames = v.licentiegegevens.gekozenGebruikersgroepen.filter(v => v?.naam === undefined);

        if (groepenWithoutNames.length) {
          const gebruikersgroepen = await Promise.all(
            groepenWithoutNames
              .filter(v => !!v.id && !v.naam)
              .map(
                async (v): Promise<any> => {
                  assertValue(v.id);
                  const url = `${settings.licentiesOrigin}/Gebruikersgroepen/${v.id}`;
                  const rsp = await fetchData<GebruikersgroepenOutput>({
                    url,
                    method: "GET"
                  });

                  if (rsp.isValid && rsp.gebruikersgroepen) {
                    const gebruikersgroepenDl = rsp.gebruikersgroepen;
                    const gebruikersgroepen: OptiesType = { id: v.id, naam: gebruikersgroepenDl[v.id].naam };
                    return gebruikersgroepen;
                  }
                }
              )
          );
          if (gebruikersgroepen && gebruikersgroepen.length) {
            draft.medewerkers[medewerkerIndex].licentiegegevens.gekozenGebruikersgroepen = draft.medewerkers[
              medewerkerIndex
            ].licentiegegevens.gekozenGebruikersgroepen.map(gekozenGG => {
              if (!gekozenGG.naam) {
                return gebruikersgroepen.find(resp => resp.id === gekozenGG.id);
              }
              return gekozenGG;
            });
            draft.medewerkers[medewerkerIndex].gekozenVestigingenNaamList = draft.medewerkers[
              medewerkerIndex
            ]?.licentiegegevens.gekozenVestigingen
              .filter(v => v?.naam)
              .map(v => v.naam)
              .join(" , ");
          }
        }
      })
    );

    draft.heeftGebruikersgroepenInitialvalues = true;
    draft.shouldResetForm = true;
  }
);

export const asyncMedewerkersSideEffects = createISWAsyncSideEffect<MedewerkersState, Context>(
  async ({ draft, context, settings, fetchData, formik }) => {
    const selected = context.selected;
    const medewerker = draft.medewerkers[selected];
    const url = `${settings.licentiesOrigin}/Medewerkers/${medewerker.medewerkerId}`;
    const isError = await validateFormikValues(formik, context.selected);
    if (!isError) {
      const medewerkerDl = mapMedewerkerUIToDl(medewerker);
      await fetchData<ValidationResultsModel>({
        url,
        method: "PUT",
        body: JSON.stringify(medewerkerDl)
      });
      draft.medewerkers[context.selected].shouldSave.medewerkerChanged = false;
      draft.shouldResetForm = true;
    }
  }
);

export const asyncLicentieSideEffectPUT = createISWAsyncSideEffect<MedewerkersState, Context>(
  async ({ draft, context, settings, fetchData, formik }) => {
    const selected = context.selected;
    const medewerker = draft.medewerkers[selected];
    const gekozenProductsamenstellingId =
      draft.medewerkers[context.selected].licentiegegevens?.gekozenProductsamenstellingId;
    if (gekozenProductsamenstellingId) {
      const isError = await validateFormikValues(formik, context.selected);
      if (!isError) {
        const medewerkerLicentieId = medewerker?.licentiegegevens?.medewerkerLicentieId;
        const licentieIdsInGebruik = draft.medewerkers.map(v => v.licentiegegevens.medewerkerLicentieId);
        const availableLicentie =
          draft.alleOrganisatieLicenties?.find(
            v =>
              !v.medewerkerId &&
              v.productsamenstellingId === gekozenProductsamenstellingId &&
              !licentieIdsInGebruik.includes(v.licentieId)
          ) ?? null;

        if (availableLicentie) {
          const licentieData = draft.alleOrganisatieLicenties?.find(v => v.licentieId === medewerkerLicentieId) ?? null;
          //first decouple the previous selection
          const decoupleUrl = `${settings.licentiesOrigin}/Licenties/${medewerkerLicentieId}`;

          await fetchData<ValidationResultsModel>({
            url: decoupleUrl,
            method: "PUT",
            body: JSON.stringify({
              platformId: "",
              medewerkerId: null,
              productsamenstellingId: licentieData?.productsamenstellingId,
              licentieovereenkomstId: licentieData?.licentieovereenkomstId
            })
          });
          const removedLicentie = draft.alleOrganisatieLicenties?.findIndex(v => v.licentieId === medewerkerLicentieId);
          draft.alleOrganisatieLicenties[removedLicentie] = {
            ...draft.alleOrganisatieLicenties[removedLicentie],
            medewerkerId: null
          };

          //then add the new one
          const url = `${settings.licentiesOrigin}/Licenties/${availableLicentie.licentieId}`;
          await fetchData<ValidationResultsModel>({
            url,
            method: "PUT",
            body: JSON.stringify({
              platformId: "",
              medewerkerId: medewerker.medewerkerId,
              productsamenstellingId: availableLicentie?.productsamenstellingId,
              licentieovereenkomstId: availableLicentie?.licentieovereenkomstId
            })
          });

          draft.medewerkers[context.selected].licentiegegevens.medewerkerLicentieId = availableLicentie.licentieId;
          draft.medewerkers[
            context.selected
          ].licentiegegevens.prevGekozenProductsamenstellingId = gekozenProductsamenstellingId;
          const index = draft.alleOrganisatieLicenties?.findIndex(v => v.licentieId === availableLicentie.licentieId);
          draft.alleOrganisatieLicenties[index] = { ...availableLicentie, medewerkerId: medewerker.medewerkerId };
        }
        draft.medewerkers[context.selected].shouldSave.licentieRadioButtonChanged = false;
        draft.shouldResetForm = true;
      }
    }
  }
);

export const asyncLicentieSideEffectsPOST = createISWAsyncSideEffect<MedewerkersState, Context>(
  async ({ draft, context, settings, fetchData, formik }) => {
    const selected = context.selected;
    const medewerker = draft.medewerkers[selected];
    const gekozenProductsamenstellingId =
      draft.medewerkers[context.selected].licentiegegevens?.gekozenProductsamenstellingId;
    if (gekozenProductsamenstellingId) {
      const isError = await validateFormikValues(formik, context.selected);
      if (!isError) {
        const licentieIdsInGebruik = draft.medewerkers.map(v => v.licentiegegevens.medewerkerLicentieId);
        const availableLicentie =
          draft.alleOrganisatieLicenties?.find(
            v =>
              !v.medewerkerId &&
              v.productsamenstellingId === gekozenProductsamenstellingId &&
              !licentieIdsInGebruik.includes(v.licentieId)
          ) ?? null;

        if (availableLicentie) {
          const url = `${settings.licentiesOrigin}/Licenties/${availableLicentie.licentieId}`;

          await fetchData<ValidationResultsModel>({
            url,
            method: "PUT",
            body: JSON.stringify({
              platformId: "",
              medewerkerId: medewerker.medewerkerId,
              productsamenstellingId: availableLicentie.productsamenstellingId,
              licentieovereenkomstId: availableLicentie.licentieovereenkomstId
            })
          });
          draft.medewerkers[
            context.selected
          ].licentiegegevens.prevGekozenProductsamenstellingId = gekozenProductsamenstellingId;
          draft.medewerkers[context.selected].licentiegegevens.medewerkerLicentieId = availableLicentie.licentieId;
          const index = draft.alleOrganisatieLicenties?.findIndex(v => v.licentieId === availableLicentie.licentieId);
          draft.alleOrganisatieLicenties[index] = { ...availableLicentie, medewerkerId: medewerker.medewerkerId };
        }
        draft.medewerkers[context.selected].shouldSave.licentieRadioButtonChanged = false;
        draft.shouldResetForm = true;
      }
    }
  }
);

export const asyncLicentieSideEffectsDelete = createISWAsyncSideEffect<MedewerkersState, Context>(
  async ({ draft, context, settings, fetchData, formik }) => {
    const selected = context.selected;
    const medewerker = draft.medewerkers[selected];
    const gekozenProductsamenstellingId =
      draft.medewerkers[context.selected].licentiegegevens?.gekozenProductsamenstellingId;
    if (gekozenProductsamenstellingId) {
      const licentieData =
        draft.alleOrganisatieLicenties?.find(v => v.licentieId === medewerker.licentiegegevens?.medewerkerLicentieId) ??
        null;
      const medewerkerLicentieId = medewerker?.licentiegegevens?.medewerkerLicentieId;
      const url = `${settings.licentiesOrigin}/Licenties/${medewerkerLicentieId}`;
      const isError = await validateFormikValues(formik, context.selected);
      if (!isError && licentieData) {
        await fetchData<ValidationResultsModel>({
          url,
          method: "PUT",
          body: JSON.stringify({
            platformId: "",
            medewerkerId: null,
            productsamenstellingId: licentieData.productsamenstellingId,
            licentieovereenkomstId: licentieData.licentieovereenkomstId
          })
        });
        draft.medewerkers[context.selected].shouldSave.licentieRadioButtonChanged = false;
        draft.medewerkers[
          context.selected
        ].licentiegegevens.prevGekozenProductsamenstellingId = gekozenProductsamenstellingId;
        const index = draft.alleOrganisatieLicenties?.findIndex(v => v.licentieId === medewerkerLicentieId);
        draft.alleOrganisatieLicenties[index] = {
          ...licentieData,
          medewerkerId: null
        };
        draft.medewerkers[context.selected].licentiegegevens.medewerkerLicentieId = null;
        draft.shouldResetForm = true;
      }
    }
  }
);

export const asyncVestigingenSideEffects = createISWAsyncSideEffect<MedewerkersState, Context>(
  async ({ draft, context, settings, fetchData, formik }) => {
    const medewerkerId = draft.medewerkers[context.selected].medewerkerId;
    const vestigingenNieuweIds = draft.medewerkers[context.selected].licentiegegevens.gekozenVestigingen?.map(
      v => v?.id
    );

    const url = `${settings.licentiesOrigin}/Medewerkers/${medewerkerId}/Vestigingen`;
    const isError = await validateFormikValues(formik, context.selected);
    if (!isError) {
      await fetchData<ValidationResultsModel>({
        url,
        method: "PUT",
        body: JSON.stringify(vestigingenNieuweIds)
      });

      draft.medewerkers[context.selected].licentiegegevens.vestigingenId = vestigingenNieuweIds;
      draft.medewerkers[context.selected].shouldSave.vestigingenChanged = false;
      draft.shouldResetForm = true;
    }
  }
);

export const asyncGebruikersgroepenSideEffects = createISWAsyncSideEffect<MedewerkersState, Context>(
  async ({ draft, context, settings, fetchData, formik }) => {
    const medewerkerId = draft.medewerkers[context.selected].medewerkerId;
    const gebruikersgroepenNieuweIds = draft.medewerkers[
      context.selected
    ].licentiegegevens.gekozenGebruikersgroepen?.map(g => g?.id);

    const url = `${settings.licentiesOrigin}/Medewerkers/${medewerkerId}/gebruikersgroepen`;
    const isError = await validateFormikValues(formik, context.selected);
    if (!isError) {
      await fetchData<ValidationResultsModel>({
        url,
        method: "PUT",
        body: JSON.stringify(gebruikersgroepenNieuweIds)
      });

      draft.medewerkers[context.selected].licentiegegevens.gebruikersgroepenId = gebruikersgroepenNieuweIds;
      draft.medewerkers[context.selected].shouldSave.gebrukersGroepenChanged = false;
      draft.shouldResetForm = true;
    }
  }
);

export const determineMedewerkersAsyncSideEffects = initISWAsyncSideEffect<MedewerkersState, Context>(
  ({ runAsync, curr, context, has }) => {
    if (!curr.heeftProductsamenstellingInitialvalues) runAsync(asyncProductsamenstellingSideEffects(context));
    if (!curr.heeftVestigingInitialvalues) runAsync(asyncVestigingenInitialValuesSideEffect(context));
    if (!curr.heeftGebruikersgroepenInitialvalues) runAsync(asyncGebruikersgroepenInitialValuesSideEffect(context));
    if (curr.medewerkerRegistreren) runAsync(asyncMedewerkerRegistratieSideEffects(context));

    if (
      hasValue(context.selected) &&
      curr.medewerkers[context.selected]?.medewerkerId &&
      has.medewerkers[context.selected]?.saveIsClicked.changed &&
      curr.medewerkers[context.selected]?.saveIsClicked
    ) {
      if (curr.medewerkers[context.selected].shouldSave.medewerkerChanged)
        runAsync(asyncMedewerkersSideEffects(context));

      if (curr.medewerkers[context.selected].shouldSave.vestigingenChanged && curr.heeftVestigingInitialvalues)
        runAsync(asyncVestigingenSideEffects(context));

      if (
        curr.medewerkers[context.selected].shouldSave.gebrukersGroepenChanged &&
        curr.heeftGebruikersgroepenInitialvalues
      )
        runAsync(asyncGebruikersgroepenSideEffects(context));
      if (curr.medewerkers[context.selected].shouldSave.licentieRadioButtonChanged) {
        const heeftLicentieId = curr.medewerkers[context.selected]?.licentiegegevens?.medewerkerLicentieId;
        if (
          curr.medewerkers[context.selected]?.licentiegegevens?.gekozenProductsamenstellingId !== LicentieEnum.Geen &&
          !heeftLicentieId
        )
          runAsync(asyncLicentieSideEffectsPOST(context));
        if (
          curr.medewerkers[context.selected]?.licentiegegevens?.gekozenProductsamenstellingId === LicentieEnum.Geen &&
          heeftLicentieId
        )
          runAsync(asyncLicentieSideEffectsDelete(context));
        if (
          curr.medewerkers[context.selected]?.licentiegegevens?.gekozenProductsamenstellingId !== LicentieEnum.Geen &&
          heeftLicentieId
        )
          runAsync(asyncLicentieSideEffectPUT(context));
      }
    }
  }
);
