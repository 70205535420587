import { LabeledBevestigingInput, LabeledDateInput, LabeledSelectInput, LabeledTextInput } from "adviesbox-shared";
import { LabelValuePairs } from "adviesbox-shared/utils/types";
import { useFormikContext } from "formik";
import React, { ReactElement } from "react";
import {
  Gebruikersactie,
  MagActieUitvoeren,
  Matrixonderdeel
} from "../../shared/autorisatie-matrix/autorisatie-matrix";
import { UserProps } from "../../shared/properties/user-props";
import { ContractState, LicentieSoort } from "../infra/contract-schema";

type ContractProps = {
  index: number;
};

export const ContractCard = (props: ContractProps & UserProps): ReactElement => {
  const formik = useFormikContext<ContractState>();
  const { values } = formik;
  const licentieovereenkomst = values.contracten.licenties[props.index];
  const pathToLicentieovereenkomst = `contracten.licenties[${props.index}]`;

  const magSchrijven = MagActieUitvoeren(
    Matrixonderdeel.ContractContract,
    Gebruikersactie.Schrijven,
    props.user,
    props.userDetails
  );
  const magLicentiesoortSchrijven = MagActieUitvoeren(
    Matrixonderdeel.ContractContractLicentiesoort,
    Gebruikersactie.Schrijven,
    props.user,
    props.userDetails
  );
  const magLezen =
    magSchrijven ||
    magLicentiesoortSchrijven ||
    MagActieUitvoeren(Matrixonderdeel.ContractContract, Gebruikersactie.Lezen, props.user, props.userDetails);

  if (!magLezen) return <></>;

  const isReadOnly = !magSchrijven;

  const licentiesoortOpties: LabelValuePairs = [
    {
      label: "Betaald",
      value: LicentieSoort.Betaald
    },
    {
      label: "Proef",
      value: LicentieSoort.Proef
    },
    {
      label: "Intern",
      value: LicentieSoort.Intern
    }
  ];
  const appendChildrenStyle: React.CSSProperties = {};
  if (isReadOnly) appendChildrenStyle.marginLeft = "6px";

  return (
    <>
      {licentieovereenkomst?.loading && <div className="loader"></div>}
      {!licentieovereenkomst?.loading && (
        <>
          <LabeledTextInput name={`${pathToLicentieovereenkomst}.productNaam`} caption="Product" readonly={true} />
          <LabeledDateInput name={`${pathToLicentieovereenkomst}.begindatum`} caption="Begindatum" readonly={true} />
          <LabeledDateInput name={`${pathToLicentieovereenkomst}.einddatum`} caption="Einddatum" />
          <LabeledSelectInput
            name={`${pathToLicentieovereenkomst}.licentiesoort`}
            caption="Licentiesoort"
            options={licentiesoortOpties}
            readonly={!magLicentiesoortSchrijven}
          />
          <LabeledBevestigingInput
            name={`${pathToLicentieovereenkomst}.isGeblokkeerd`}
            caption="Geblokkeerd"
            readonly={isReadOnly}
          />
          <LabeledBevestigingInput
            name={`${pathToLicentieovereenkomst}.isActief`}
            caption="Actief"
            readonly={isReadOnly}
          />
        </>
      )}
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") ContractCard.displayName = "Contract";
