/* istanbul ignore file */
import classnames from "classnames";
import React, { ReactElement, ReactNode, useState } from "react";
import { AppDataProvider } from "../../../navigation/appdata-context";
import NavigationList from "../../../navigation/NavigationList";

type DrawerProps = {
  children: ReactNode;
};

const Drawer = (props: DrawerProps): ReactElement => {
  const { children } = props;
  const [collapsed, setcollapsed] = useState(false);

  return (
    <div className="drawer-wrapper">
      <AppDataProvider>
        <div className={classnames("drawer-panel", { collapsed })}>
          <NavigationList collapsed={collapsed} collapse={(): void => setcollapsed(!collapsed)} />
        </div>
        <div className="drawer-content">{children}</div>
      </AppDataProvider>
    </div>
  );
};

Drawer.displayName = "Drawer";

export default Drawer;
