import { initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { ContractState } from "./contract-schema";

export const syncContractSideEffects = initISWSideEffect<ContractState /* ,ContextType */>(({ has, draft, subset }) => {
  if (has.contracten.licenties.changed) {
    draft.contracten.licenties.forEach((_, i) => {
      if (has.contracten.licenties[i].changed && !has.contracten.licenties[i].sendPut.changed) {
        draft.contracten.licenties[i].sendPut = true;
        draft.hasChanged = true;
        draft.saveIsClicked = false; //reset save
      }
    });
  }

  const allIsSaved = draft.contracten.licenties.every(v => !v.sendPut) && !draft.organisatie.sendPut;
  if (allIsSaved && (draft.hasChanged || draft.saveIsClicked)) {
    draft.hasChanged = false;
    draft.saveIsClicked = false; //save is finished
  }

  if (has.organisatie.changed && !has.organisatie.sendPut.changed) {
    draft.organisatie.sendPut = true;
    draft.hasChanged = true;
    draft.saveIsClicked = false; //reset save
  }
})();
