import { Card, getAppLocation, SettingsContext } from "adviesbox-shared";
import classNames from "classnames";
import React, { ReactElement, useContext } from "react";
import { Button } from "react-bootstrap";
import classes from "../registreren.module.scss";

export const RegistratieSuccesvol = (): ReactElement => {
  const { baseUrls } = useContext(SettingsContext);
  const linkToPortal = getAppLocation(window.location.origin, "LIC", "POR", baseUrls);

  return (
    <>
      <div className={classNames("container", classes.result)}>
        <Card className={"col-6 offset-3"}>
          <h2 className={classNames(classes.title)}>Registratie succesvol</h2>
          <p className={"p-3"}>Je bent nu succesvol geregistreerd en kunt gebruik maken van Adviesbox.</p>
          <div className={"text-center"}>
            <Button
              variant={"light"}
              className={" mb-3 px-4"}
              onClick={() => {
                window.location.href = linkToPortal;
              }}
            >
              Naar de applicatie
            </Button>
          </div>
        </Card>
      </div>
    </>
  );
};
