import { hasValue } from "adviesbox-shared";
import {
  createISWAsyncSideEffect,
  initISWAsyncSideEffect
} from "../../shared/components/isw-side-effects/create-isw-helpers";
import { SamenstellingType } from "./licenties-schema";

type ContextType = {
  selected: number;
  samenstelling: SamenstellingType;
  licentieOvereenKomstId: string;
};

// TODO No available post type for licentiePost replace with actual type
type LicentiePostType = {
  licentieovereenkomstId: string;
  productsamenstellingId: string;
  aantalLicenties: number;
};

export const postLicentie = createISWAsyncSideEffect<SamenstellingType, ContextType>(
  async ({ fetchData, settings, context, draft, formik }) => {
    if (hasValue(draft.nieuwAantalLicenties)) {
      await fetchData<void, LicentiePostType>({
        url: `${settings.licentiesOrigin}/Licentieovereenkomst/${context.licentieOvereenKomstId}/Productsamenstelling/${context.samenstelling.id}/Licenties/${context.samenstelling.nieuwAantalLicenties}`,
        method: "POST"
      });
      draft.aantalLicenties = draft.nieuwAantalLicenties;
      draft.aantalLicentiesBeschikbaar = draft.nieuwAantalLicenties - draft.aantalLicentiesInGebruik;
      draft.submitButtonIsClicked = false;

      formik.setValues({
        ...draft
      });

      await formik.submitForm();
    }
  }
);

export const asyncLicentiesAanvraagModalSideEffects = initISWAsyncSideEffect<SamenstellingType, ContextType>(
  ({ runAsync, context, curr }) => {
    const samenstelling = curr;

    if (samenstelling.submitButtonIsClicked && hasValue(samenstelling.nieuwAantalLicenties)) {
      const contextForPost: ContextType = {
        selected: context.selected,
        samenstelling: samenstelling,
        licentieOvereenKomstId: context.licentieOvereenKomstId
      };
      return runAsync(postLicentie(contextForPost));
    }
  }
);
