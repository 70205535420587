import React, { MutableRefObject, ReactElement } from "react";
import classes from "./tagInput.module.scss";

type tagType = {
  id: string;
  naam: string;
};

export type TagOptionsProps = {
  suggestions: tagType[];
  query: string;
  indexOfSuggestion: number;
  addTag: (value: tagType) => void;
  expanded?: boolean;
  inputRef?: MutableRefObject<HTMLDivElement | null>;
  editable?: boolean;
  chosenTags?: tagType[];
};

export const TagInputOptions = ({
  suggestions,
  chosenTags,
  query,
  indexOfSuggestion,
  expanded,
  addTag,
  editable,
  ...props
}: TagOptionsProps): ReactElement => {
  if (!expanded || !suggestions.length) {
    return <></>;
  }

  const onMouseUp = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, item: tagType): void => {
    e.preventDefault();
    addTag(item);
  };

  const options = suggestions.map((item, index) => {
    let className = "";
    const key = `${index}-${item.id}`;

    if (indexOfSuggestion === index) {
      className = classes.tag_component_options_active;
    }

    return (
      <li
        key={key}
        id={key}
        data-testid={key}
        aria-disabled={!editable}
        onMouseUp={e => onMouseUp(e, item)}
        className={className}
      >
        {item.naam}
      </li>
    );
  });

  return (
    <div className={classes.tag_component_options} {...props}>
      <ul role="listbox" id="suggestions-list" data-testid="suggestions-list">
        {options}
      </ul>
    </div>
  );
};
/* istanbul ignore next */
if (process.env.NODE_ENV !== "production") TagInputOptions.displayName = "TagInput";
