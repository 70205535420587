import { AuthContext, ErrorPage, PageLoading, useRequestInit, withErrorBoundary, ForceRerenderContext } from "adviesbox-shared";
import React, { ReactElement, useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import useAbortableFetch from "use-abortable-fetch";
import { LicentiesApi } from "../licenties/infra/licenties-api";
import {
  GebruikersgroepenOutput,
  ProductenOutput,
  ProductonderdelenOutput
} from "../.generated/licenties/licentiestypes";
import { MagActieUitvoeren, Matrixonderdeel } from "../shared/autorisatie-matrix/autorisatie-matrix";
import { Title } from "../shared/components/title/title";
import { RouteParams } from "../shared/paramrouting/paramrouting-context";
import UserDetailsContext from "../shared/user-details/user-details-context";
import { Gebruikersgroepen } from "./gebruikersgroepen";
import { GebruikersgroepenState } from "./infra/gebruikersgroepen-schema";
import {
  mapGebruikersgroepenDlToUi,
  mapGebruikersgroepProductDlToUi,
  mapProductOnderdelenDlToUi
} from "./infra/map-gebruikersgroepen-dl-to-ui";

/* istanbul ignore file */

const GebruikersgroepenAjaxComponent = (): ReactElement => {
  const loadingDone = useContext(ForceRerenderContext);
  const { requestInit, settings } = useRequestInit();
  const params = useParams() as RouteParams;
  const { user } = useContext(AuthContext);
  const { userDetails } = useContext(UserDetailsContext);

  // als de requestedOrganisatie van de userDetails wijzigt, dan moet MagActieUitvoeren opnieuw bepaald worden
  const heeftToegang = useMemo(() => MagActieUitvoeren(Matrixonderdeel.Gebruikersgroepen, null, user, userDetails),
    [user, userDetails, userDetails.requestedOrganisatie]); // eslint-disable-line react-hooks/exhaustive-deps
  const url = useMemo(() => LicentiesApi.getProductenUrl(settings), [settings]);
  const { loading: loadingPRD, error: platformErrorPRD, data: platformDataPRD } = useAbortableFetch<ProductenOutput>(
    url,
    requestInit
  );

  const initialData = useMemo(
    () =>
      platformDataPRD && typeof platformDataPRD !== "string" ? mapGebruikersgroepProductDlToUi(platformDataPRD) : null,
    [platformDataPRD]
  );

  // Er is op dit moment (nov 2020) alleen maar sprake van 1 product, namelijk Adviesbox Online
  // Vandaar dat hier nu altijd met het 1e productID gewerkt wordt
  const firstProductId = initialData?.producten[0].id ?? null;

  //Begin request for gebruikersgroep productonderdelen (PO)
  const { requestInit: requestInitPO, settings: settingPO } = useRequestInit();

  const urlGebruikersgroepOnderdelen = useMemo((): string | null => {
    if (firstProductId !== null) {
      return LicentiesApi.getProductonderdelenUrl(settingPO, { product: firstProductId });
    }
    return null;
  }, [settingPO, firstProductId]);

  const { loading: loadingPO, error: platformErrorPO, data: platformDataPO } = useAbortableFetch<
    ProductonderdelenOutput
  >(urlGebruikersgroepOnderdelen, requestInitPO);
  const productOnderdeelData = useMemo(
    () => (platformDataPO && typeof platformDataPO !== "string" ? mapProductOnderdelenDlToUi(platformDataPO) : null),
    [platformDataPO]
  );

  //Begin request for gebruikersgroepen (GG)
  const { requestInit: requestInitGG, settings: settingGG } = useRequestInit();

  const urlGebruikersgroepen = useMemo((): string | null => {
    if (firstProductId !== null) {
      return LicentiesApi.getGebruikersgroepenUrl(settingGG, { organisatie: params.organisatie });
    }
    return null;
  }, [settingGG, params.organisatie, firstProductId]);

  const { loading: loadingGG, error: platformErrorGG, data: platformDataGG } = useAbortableFetch<
    GebruikersgroepenOutput
  >(urlGebruikersgroepen, requestInitGG);
  const gebruikersgroepenData = useMemo(
    () =>
      platformDataGG && typeof platformDataGG !== "string" && productOnderdeelData
        ? mapGebruikersgroepenDlToUi(platformDataGG, productOnderdeelData)
        : null,
    [platformDataGG, productOnderdeelData]
  );

  useEffect((): void => {
    if (
      urlGebruikersgroepOnderdelen &&
      urlGebruikersgroepen &&
      loadingPRD &&
      platformDataGG &&
      loadingPO &&
      platformDataPO &&
      loadingGG &&
      platformDataGG &&
      loadingDone
    ) {
      loadingDone();
    }
  }, [
    urlGebruikersgroepOnderdelen,
    urlGebruikersgroepen,
    loadingPRD,
    platformDataPRD,
    loadingPO,
    platformDataPO,
    loadingGG,
    platformDataGG,
    loadingDone
  ]);

  if (!heeftToegang) {
    return <ErrorPage error={{ ...Error("Geen rechten toegekend voor deze gebruiker"), status: 403 }} data={null} />;
  }

  if (platformErrorPRD) {
    return <ErrorPage error={platformErrorPRD} data={platformDataPRD} />;
  }

  if (platformErrorPO) {
    return <ErrorPage error={platformErrorPO} data={platformDataPO} />;
  }

  if (platformErrorGG) {
    return <ErrorPage error={platformErrorGG} data={platformDataGG} />;
  }

  if (
    loadingPO ||
    !gebruikersgroepenData ||
    !productOnderdeelData ||
    !initialData ||
    loadingPRD ||
    !params.organisatie
  ) {
    return <PageLoading />;
  }

  const screenData: GebruikersgroepenState = {
    gebruikersgroepen: {
      ...gebruikersgroepenData
    },
    gebruikersgroepOnderdelen: {
      ...productOnderdeelData
    },
    producten: initialData.producten,
    loading: false,
    shouldResetForm: false,
    hasChanged: false,
    gebruikersgroepenInView: 10,
    organisatieId: params.organisatie
  };

  return (
    <>
      <Title appName="Contract" altTitle={"Gebruikersgroepen"} />
      <Gebruikersgroepen {...screenData} user={user} userDetails={userDetails} />
    </>
  );
};

export const GebruikersgroepenAjax = withErrorBoundary(GebruikersgroepenAjaxComponent);
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production")
  GebruikersgroepenAjax.displayName = "GebruikersgroepenAjax";
