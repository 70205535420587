import {
  AuthContext,
  ErrorPage,
  PageLoading,
  useRequestInit,
  withErrorBoundary,
  ForceRerenderContext
} from "adviesbox-shared";
import React, { ReactElement, useContext, useEffect, useMemo } from "react";
import useAbortableFetch from "use-abortable-fetch";
import { LicentiesApi } from "../licenties/infra/licenties-api";
import {
  ProductenOutput,
  ProductonderdelenOutput,
  ProductsamenstellingenOutput
} from "../.generated/licenties/licentiestypes";
import { MagActieUitvoeren, Matrixonderdeel } from "../shared/autorisatie-matrix/autorisatie-matrix";
import { Title } from "../shared/components/title/title";
import UserDetailsContext from "../shared/user-details/user-details-context";
import { LicentiesAanbodState } from "./infra/licentieaanbod-schema";
import {
  mapLicentieaanbodDlToUi,
  mapProductOnderdelenDlToUi,
  mapProductSamenstellingenDlToUi
} from "./infra/map-licentieaanbod-dl-to-ui";
import { Licentieaanbod } from "./licentieaanbod";

/* istanbul ignore file */

type RouteParams = {
  organisatie?: string | null;
  product?: string | null;
};

const LicentieaanbodAjaxComponent = (): ReactElement => {
  const loadingDone = useContext(ForceRerenderContext);
  const { requestInit, settings } = useRequestInit();
  const { userDetails } = useContext(UserDetailsContext);

  const { user } = useContext(AuthContext);

  // als de requestedOrganisatie van de userDetails wijzigt, dan moet MagActieUitvoeren opnieuw bepaald worden
  const heeftToegang = useMemo(() => MagActieUitvoeren(Matrixonderdeel.Licentieaanbod, null, user, userDetails),
  [user, userDetails, userDetails.requestedOrganisatie]); // eslint-disable-line react-hooks/exhaustive-deps

  const urlProducten = useMemo(() => LicentiesApi.getProductenUrl(settings), [settings]);
  const { loading: loadingProducten, error: platformErrorProducten, data: platformDataProducten } = useAbortableFetch<
    ProductenOutput
  >(urlProducten, requestInit);

  const dataProducten = useMemo(() => {
    return platformDataProducten && typeof platformDataProducten !== "string"
      ? mapLicentieaanbodDlToUi(platformDataProducten)
      : null;
  }, [platformDataProducten]);

  const firstProductId = useMemo(() => dataProducten?.producten[0].id ?? null, [dataProducten]);

  //Begin request for licentie onderdelen
  const { requestInit: requestInitPO, settings: settingPO } = useRequestInit();

  const urlLicentieOnderdelen = useMemo((): string | null => {
    if (firstProductId !== null) {
      return LicentiesApi.getProductonderdelenUrl(settingPO, { product: firstProductId });
    }
    return null;
  }, [settingPO, firstProductId]);

  const { loading: loadingPO, error: platformErrorPO, data: platformDataPO } = useAbortableFetch<
    ProductonderdelenOutput
  >(urlLicentieOnderdelen, requestInitPO);
  const productOnderdeelData = useMemo(() => {
    return platformDataPO && typeof platformDataPO !== "string" ? mapProductOnderdelenDlToUi(platformDataPO) : null;
  }, [platformDataPO]);

  //Begin request for licentie samenstelling LS is licenitesamenstelling
  const { requestInit: requestInitLS, settings: settingLS } = useRequestInit();

  const urlLicentieSamenstellingen = useMemo((): string | null => {
    if (firstProductId !== null) {
      return LicentiesApi.getProductsamenstellingenUrl(settingLS, { product: firstProductId });
    }
    return null;
  }, [settingLS, firstProductId]);

  const { loading: loadingPS, error: platformErrorPS, data: platformDataPS } = useAbortableFetch<
    ProductsamenstellingenOutput
  >(urlLicentieSamenstellingen, requestInitLS);

  const productSamenstellingenData = useMemo(() => {
    return platformDataPS && typeof platformDataPS !== "string"
      ? mapProductSamenstellingenDlToUi(platformDataPS)
      : null;
  }, [platformDataPS]);

  useEffect((): void => {
    if (
      urlLicentieOnderdelen &&
      urlLicentieSamenstellingen &&
      !loadingProducten &&
      !loadingPO &&
      !loadingPS &&
      platformDataProducten &&
      platformDataPS &&
      platformDataPO &&
      loadingDone
    ) {
      loadingDone();
    }
  }, [
    urlLicentieOnderdelen,
    urlLicentieSamenstellingen,
    loadingProducten,
    platformDataProducten,
    loadingPO,
    platformDataPO,
    loadingPS,
    platformDataPS,
    loadingDone
  ]);

  if (!heeftToegang) {
    return <ErrorPage error={{ ...Error("Geen rechten toegekend voor deze gebruiker"), status: 403 }} data={null} />;
  }

  if (platformErrorProducten) {
    return <ErrorPage error={platformErrorProducten} data={platformDataProducten} />;
  }

  if (platformErrorPO) {
    return <ErrorPage error={platformErrorPO} data={platformDataPO} />;
  }

  if (platformErrorPS) {
    return <ErrorPage error={platformErrorPS} data={platformDataPS} />;
  }

  if (
    loadingPO ||
    loadingProducten ||
    loadingPS //||
    //!usersOrganisatieId ||
  ) {
    return <PageLoading />;
  }

  if (!dataProducten || !productOnderdeelData || !productSamenstellingenData) {
    return <PageLoading />;
  }

  const screenData: LicentiesAanbodState = {
    producten: dataProducten.producten,
    productenInView: 10,
    saveResult: "default",
    loading: false,
    shouldResetForm: false,
    cancelHasChanges: false,
    licentieOnderdelen: {
      ...productOnderdeelData
    },
    licentieSamenstellingen: {
      ...productSamenstellingenData
    }
  };
  return (
    <>
      <Title appName="Contract" altTitle={"Licentieaanbod"} />
      <Licentieaanbod {...screenData} user={user} userDetails={userDetails} />
    </>
  );
};

export const LicentieaanbodAjax = withErrorBoundary(LicentieaanbodAjaxComponent);
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production")
  LicentieaanbodAjax.displayName = "LicentieaanbodAjax";
