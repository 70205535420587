import React, { ChangeEvent, ReactElement, useState } from "react";
import { FormikContextType } from "formik";
import { Modal as ModalBootstrap } from "react-bootstrap";
import classes from "../licentieaanbod.module.scss";
import { LicentiesAanbodState, ModalTypeEnum, ShowDeleteModal } from "../infra/licentieaanbod-schema";
import { LicentieAanbodDeleteType } from "../has-changes-modal/has-changes-modal";
import { getLicentieaanbodTextResources } from "../infra/licentieaanbod-resources";

type LicentieAanbodDeleteModalProps = {
  formik: FormikContextType<LicentiesAanbodState>;
  type: ModalTypeEnum;
  onDelete: (data: LicentieAanbodDeleteType) => void;
  show: ShowDeleteModal;
  closeModal: React.Dispatch<React.SetStateAction<ShowDeleteModal>>;
};

export const LicentieaanbodDeleteModal = ({
  closeModal,
  formik,
  type,
  show,
  onDelete
}: LicentieAanbodDeleteModalProps): ReactElement => {
  let confirmCheck = "";
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const getErrorMessage = (): string | null => {
    /* istanbul ignore else */
    if (show.selected !== null) {
      switch (type) {
        case ModalTypeEnum.onderdeel:
          return getLicentieaanbodTextResources("licentieOnderdeelNaamDoesNotMatch");
        case ModalTypeEnum.samenstelling:
          return getLicentieaanbodTextResources("licentieSamenstellingNaamDoesNotMatch");
        case ModalTypeEnum.product:
          return getLicentieaanbodTextResources("productNaamDoesNotMatch");
      }
    }

    return null;
  };

  const name = (): string | null => {
    /* istanbul ignore else */
    if (show.selected !== null) {
      switch (type) {
        case ModalTypeEnum.onderdeel:
          if (formik.values.licentieOnderdelen.licentieOnderdelen[show.selected]) {
            return formik.values.licentieOnderdelen.licentieOnderdelen[show.selected].productNaam;
          }
          break;
        case ModalTypeEnum.samenstelling:
          if (formik.values.licentieSamenstellingen.licentieSamenstellingen[show.selected]) {
            return formik.values.licentieSamenstellingen.licentieSamenstellingen[show.selected].productNaam;
          }
          break;
        case ModalTypeEnum.product:
          if (formik.values.producten[show.selected]) {
            return formik.values.producten[show.selected].productNaam;
          }
          break;
        default:
          return null;
      }
    }

    return null;
  };

  const modalType = (): string | null => {
    /* istanbul ignore else */
    if (show.selected !== null) {
      switch (type) {
        case ModalTypeEnum.onderdeel:
          return "Licentieonderdeel";
        case ModalTypeEnum.samenstelling:
          return "Licentiesamenstelling";
        case ModalTypeEnum.product:
          return "Product";
        default:
          return null;
      }
    }

    return null;
  };

  const checkIfConfirmCorrect = (): boolean => {
    if (confirmCheck === name()) {
      return true;
    }
    return false;
  };

  if (show.selected === null) return <></>;

  return (
    <ModalBootstrap show={show.show}>
      <ModalBootstrap.Header>
        <div className="d-flex w-100">
          <ModalBootstrap.Title>{modalType()} verwijderen</ModalBootstrap.Title>
          <button
            type="button"
            className={"close ml-auto"}
            onClick={() => {
              setErrorMessage(null);
              closeModal({ show: false, selected: null });
            }}
            id={`btnClose-licentieaanbod-modal-${show.selected}`}
          >
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </div>
      </ModalBootstrap.Header>
      <ModalBootstrap.Body>
        <div>
          <p className="px-2">Weet je zeker dat je {name()} wilt verwijderen?</p>
          <div className="d-flex">
            <p className="col-6 px-2">Typ hier {name()} ter bevestiging voor het verwijderen</p>
            <div className="col-6 h-25">
              <input
                className={errorMessage ? "is-invalid" : ""}
                name={"setConfirm"}
                id={`confirm-delete-${modalType()}`}
                data-testid={`confirm-delete-${modalType()}`}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  confirmCheck = e.target.value;
                }}
              />
              <div className="foutmelding">{errorMessage}</div>
            </div>
          </div>
        </div>
      </ModalBootstrap.Body>
      <ModalBootstrap.Footer className={classes.modal_footer}>
        <button
          type="button"
          className="btn btn-link"
          data-testid={`${modalType()}-anuleer-button`}
          id={`annuleer-verwijderen-licentieaanbod-modal-${show.selected}`}
          onClick={() => {
            setErrorMessage(null);
            closeModal({ show: false, selected: null });
          }}
        >
          Niet verwijderen
        </button>
        <button
          type="button"
          data-testid={`${modalType()}-verwijderen-button`}
          className="btn btn-danger"
          id={`bevestig-verwijderen-licentieaanbod-modal-${show.selected}`}
          onClick={() => {
            if (checkIfConfirmCorrect() && show.selected !== null) {
              onDelete({ selected: show.selected, type: type });
              setErrorMessage(null);
              return;
            }

            setErrorMessage(getErrorMessage());
          }}
        >
          {name()} verwijderen
        </button>
      </ModalBootstrap.Footer>
    </ModalBootstrap>
  );
};
